import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteWorkTask, FetchGasWorkTask, FetchProcedureMapping } from '../../../Service/ProcedureMapping';

// Your options for the first dropdown
const options = [
  { value: "Distribution/Construction", id: 4 },
  { value: "Meter Services", id: 5 },
  { value: "I&R", id: 6 },
];

function MyDropdown() {
  // State to manage the selected option in the first dropdown
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  // State to manage the selected task type
  const [selectedTaskType, setSelectedTaskType] = useState('');
  // State to hold the selected work task data
  const [selectedWorkTaskData, setSelectedWorkTaskData] = useState(null);
  // Local state to manage the filtered tasks
  const [filteredWorkTaskData, setFilteredWorkTaskData] = useState([]);

  // Redux dispatch
  const dispatch = useDispatch();

  // Effect to fetch GasWorkTask based on the selected option
  useEffect(() => {
    const selectedId = options.find((option) => option.value === selectedOption)?.id;
    if (selectedId !== undefined) {
      console.log(`Selected Option ID: ${selectedId}`);
      dispatch(FetchGasWorkTask(selectedId));
    } else {
      console.log('No selection');
    }
  }, [selectedOption]);

  // Retrieve the WorkTaskData from Redux store
  const workTaskData = useSelector(
    (state) => state.procedureMapping.WorkTaskData || []
  );

  // Effect to set the default selected task type and selected work task data
  useEffect(() => {
    if (workTaskData.length > 0) {
      setSelectedTaskType(workTaskData[0].taskType);
      setSelectedWorkTaskData(workTaskData[0]);
    }
  }, [workTaskData]);

  // Handler for changing the selected option in the first dropdown
  const handleOptionChange = (e) => {
    setSelectedOption(e.value);
  };

  // Handler for changing the selected task type in the second dropdown
  const handleTaskTypeChange = (e) => {
    if (e.value === "Add More") {
      console.log("Selected Task Type: Add More");
      // Optionally, you can perform any other actions related to "Add More" here
    } else {
      const selectedTask = workTaskData.find((task) => task.taskType === e.value);
      if (selectedTask) {
        setSelectedTaskType(selectedTask.taskType);
        setSelectedWorkTaskData(selectedTask);
  
        // Dispatch the action with the selected task's data
        dispatch(FetchProcedureMapping({ id: selectedTask.templateID, name: selectedTask.taskType }));
      }
    }
  };
  

  // Retrieve the Procedure Data from Redux store
  const procedureData = useSelector(
    (state) => state.procedureMapping.procedureMappingData || []
  );

  const customTaskTypeItemTemplate = (option) => {
    // Check if the option is "Add More" and skip rendering the delete icon
    if (option === "Add More") {
      return <div>{option}</div>;
    }
    return (
      <div className="p-d-flex p-ai-center">
        <span className="p-ml-2">{option}</span>
        <span className="p-ml-auto">
          <i
            className="pi pi-trash"
            onClick={(event) => handleTrashIconClick(event, option)}
            style={{ cursor: 'pointer' }}
          ></i>
        </span>
      </div>
    );
  };
  
  useEffect(() => {
    setFilteredWorkTaskData([...workTaskData, { taskType: 'Add More' }]);
  
    // Manually set the selectedTaskType to 'Add More'
    setSelectedTaskType('Add More');
  }, [workTaskData]);
  
  // <Dropdown
  //   value={selectedTaskType}
  //   options={filteredWorkTaskData.map((task) => task.taskType)}
  //   onChange={handleTaskTypeChange}
  //   placeholder="Select a task type"
  //   itemTemplate={customTaskTypeItemTemplate}
  // />
  
  
  // Handler for clicking the trash icon to delete a task type
  

  const handleTrashIconClick = (event, taskType) => {
    event.stopPropagation();
    const selectedTask = workTaskData.find((task) => task.taskType === taskType);
    if (selectedTask) {
      console.log(`ID associated with Task Type ${taskType}: ${selectedTask.id}`);
      
      // Dispatch the delete action (if needed)
      // Ensure the delete action correctly updates the Redux state
      
      // Update filteredWorkTaskData by filtering out the deleted item
      setFilteredWorkTaskData(filteredWorkTaskData.filter((task) => task.taskType !== taskType));
      dispatch(DeleteWorkTask(selectedTask.id))
    }
  };
  
  useEffect(() => {
    setFilteredWorkTaskData([...workTaskData, { taskType: 'Add More' }]); // Add the "Add More" option
  }, [workTaskData]);

  // Effect to update filteredWorkTaskData when workTaskData changes
  // useEffect(() => {
  //   setFilteredWorkTaskData(workTaskData);
  // }, [workTaskData]);

  return (
    <div>
      <h3>Selected Option: {selectedOption}</h3>
      <Dropdown id="drd_selectOption"
        value={selectedOption}
        options={options.map(option => option.value)}
        onChange={handleOptionChange}
        placeholder="Select an option"
      />

      <h3>Selected Task Type: {selectedTaskType}</h3>
      <Dropdown id="drd_selectedTaskType"
        value={selectedTaskType}
        options={filteredWorkTaskData.map(task => task.taskType)} // Use filteredWorkTaskData
        onChange={handleTaskTypeChange}
        placeholder="Select a task type"
        itemTemplate={customTaskTypeItemTemplate} 
      />

      <div>
        <h3>Selected Work Task Data:</h3>
        {selectedWorkTaskData && (
          <ul>
            <li>ID: {selectedWorkTaskData.id}</li>
            <li>Template ID: {selectedWorkTaskData.templateID}</li>
            <li>Task Type: {selectedWorkTaskData.taskType}</li>
          </ul>
        )}
      </div>

      <div>
        <h3>Procedure Data:</h3>
        <ul>
          {procedureData.map((procedure) => (
            <li key={procedure.id}>{procedure.docName}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MyDropdown;