import React, { useState, useEffect } from "react";
import "./gas.scss";
import { TabView, TabPanel } from "primereact/tabview";
import "primeicons/primeicons.css";
import DataInput from "../Input";
import Labels from "../../../Constants/Label";

const Gas = ({ gasCommonData, gasData }) => {
  const uniqueGroup = [
    ...new Set(gasCommonData.map((item) => item.sectionInternalName)),
  ];

  const Data = (data) => {
    const groupedData = {};

    data.forEach((item) => {
      const { templateName, sectionInternalName, ...details } = item;

      if (!groupedData[templateName]) {
        groupedData[templateName] = {};
      }

      if (!groupedData[templateName][sectionInternalName]) {
        groupedData[templateName][sectionInternalName] = [];
      }

      groupedData[templateName][sectionInternalName].push(details);
    });
    return groupedData;
  };

  const groupedData = Data(gasData);

  return (
    <div className="gas">
      <TabView>
        <TabPanel header="Gas Common" id="tabPanel_temp_gasCommon" className="tabPanel_temp_gasCommon">
          <div className="gas-content">
            {uniqueGroup.map((subgroup, index) => {
              return (
                <div className="gas-sections" key={index}>
                  <div className="gas-heading">{Labels?.SectionName[subgroup]}</div>
                  {gasCommonData
                    .filter((item) => item.sectionInternalName === subgroup)
                    .map((item, index) => {
                      return (
                        <DataInput value={item} key={index} />
                      );
                    })}
                </div>
              );
            })}
          </div>
        </TabPanel>
        {Object.entries(groupedData).map(
          ([templateName, sectionInternalName]) => (
            <TabPanel id={"tabPanel_gtemp"+templateName} key={templateName} header={templateName.slice(5)} className={"tabPanel_gtemp"+templateName}>
              <div className="gas-content">
                {Object.entries(sectionInternalName).map(
                  ([sectionInternalName, details]) => (
                    <div className="gas-sections" key={sectionInternalName}>
                      <div className="gas-heading">{Labels?.SectionName[sectionInternalName]}</div>
                      <div>
                        {details.map((item, index) => (
                          <div key={index}>
                            <DataInput
                              value={item}
                              key={index}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </TabPanel>
          )
        )}
      </TabView>
    </div>
  );
};

export default Gas;
