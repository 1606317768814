import { createAsyncThunk } from "@reduxjs/toolkit";
import { fileUploadInstance, sharedInstance, } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";


export const FetchSWIHazardMessage = createAsyncThunk(
  "api/SwiHazardMessaging/FetchSwiHazardMessagingDetails",
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      let res = await sharedInstance.get(EndPoints.FetchSwiHazardMessaging);
      if (res?.data?.data && res.data.statusCode === 200) {
        dispatch(setLoader(false));
        return res.data.data;
      } else {
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);
export const addSwiMessagingMappingDetails = createAsyncThunk(
  "/api/SwiHazardMessaging/UpdateSwiHazardMessagingDetails?hazardId=85&notificationMessage=Updating Message from postman",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(`${EndPoints.updateSwiHazardMessaging}?hazardId=${args.id}&notificationMessage=${args.message}`);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        args.callbackfn(true)
        return res?.data?.data;
      }
      else {
        throw Error();
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
export const deleteSwiMessagingMappingDetails = createAsyncThunk(
  "/api/SwiHazardMessaging/DeleteSwiHazardMessagingDetails?hazardId",
  async ({id, deleteSuccess}, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(`${EndPoints.deleteSwiHazardMessaging}?hazardId=${id}`);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(id);
        return id;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));

     }
  }
);

const fetchImages = async (filePath, thunkApi) => {
  try {
    let res = await fileUploadInstance.post(EndPoints.fetchUploadFile, {
      filePath: filePath,
    });
    if (res?.data?.data?.streamData) {
      return res.data?.data?.streamData;
    } else {
      let errorCode = res?.data?.statusCode;
      let errorMessage = res?.data?.message || "Oops, Something went wrong";
      HandleError(thunkApi.dispatch, errorCode, errorMessage);
    }
  } catch (error) {
  }
};


