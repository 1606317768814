import { createSlice, current } from "@reduxjs/toolkit";
import {
  fetchConfigurationDetails,
  updateConfigurationDetails,
} from "../../Service/Configuration";

const initialState = {
  configData: [],
  loading: false,
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurationDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchConfigurationDetails.fulfilled, (state, action) => {
      state.configData = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(fetchConfigurationDetails.rejected, (state) => {
      state.formData = [];
      state.loading = false;
      state.error = "Something went wrong";
    });
    builder.addCase(updateConfigurationDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateConfigurationDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateConfigurationDetails.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });
  },
});

export default configurationSlice.reducer;
