import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

export const fetchCriticalContactDetails = createAsyncThunk(
  "criticalcontacts/fetchCriticalContactTemplate",
  async (dispatch, thunkAPI) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Critical Contacts",
        actionName: "Get Contact Details",
        message: "Request - Contact Details",
        thunkApi: thunkAPI,
      });

      let res = await sharedInstance.get(EndPoints.criticalContacts);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Critical Contacts",
          actionName: "Get Contact Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });

        return res.data.data;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Critical Contacts",
          actionName: "Get Contact Details",
          thunkApi: thunkAPI,
        });

        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Critical Contacts",
        actionName: "Get Contact Details",
        thunkApi: thunkAPI,
      });

      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteCriticalContactDetails = createAsyncThunk(
  "criticalcontacts/deleteCriticalContactDetails",
  async ({ id, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Critical Contacts",
        actionName: "Delete Contact Details",
        message: "Request - Contact Details",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.delete(
        `${EndPoints.deleteCriticalContacts}?contactId=${id}`
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Critical Contacts",
          actionName: "Delete Contact Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        deleteSuccess(id);
        return id;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Critical Contacts",
          actionName: "Delete Contact Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
        callLoggerError({
          errorCode: errorCode,
          errorDetails: errorMessage,
          screenName: "Critical Contacts",
          actionName: "Delete Contact Details",
          thunkApi: thunkAPI,
        });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const addCriticalContactDetails = createAsyncThunk(
  "criticalcontacts/addCriticalContactDetails",
  async (contacts, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Critical Contacts",
        actionName: "Add Contact Details",
        message: "Request - Contact Details",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.post(EndPoints.addContacts, contacts);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Critical Contacts",
          actionName: "Add Contact Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        contacts.saveSuccess(contacts.contactName);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Critical Contacts",
          actionName: "Add Contact Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
        callLoggerError({
          errorCode: errorCode,
          errorDetails: errorMessage,
          screenName: "Critical Contacts",
          actionName: "Add Contact Details",
          thunkApi: thunkAPI,
        });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateCriticalContactDetails = createAsyncThunk(
  "criticalcontacts/updateCriticalContactDetails",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Critical Contacts",
        actionName: "Update Contact Details",
        message: "Request - Contact Details",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.put(
        `${EndPoints.updateContacts}?contactId=${args.id}`,
        args
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Critical Contacts",
          actionName: "Update Contact Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        args.saveSuccess(args.contactName);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Critical Contacts",
          actionName: "Update Contact Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Critical Contacts",
        actionName: "Update Contact Details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);