import React from 'react'
import successLogo from '../../Assets/Images/tick.png';
import { Button } from 'primereact/button';
import "./successPopup.scss";

function SendSuccess({ closePopup, heading, content }) {
    return (
        <div className="success-popup">
            <img id="img_sendsuccessLogo" src={successLogo} className="successLogo" />
            <div className="successFormWrapperInner">
                <p className="successTitleHead">Sent {heading} Successfully!</p>
                <p className="successTitle">You have successfully sent {content}.</p>
                <div className="popupButtonWrapper">
                    <Button id="btn_sendSuccessClose" className="close-button" label="Close" onClick={closePopup}></Button>
                </div>
            </div>
        </div>
    )
}

export default SendSuccess;