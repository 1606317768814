import { createSlice } from '@reduxjs/toolkit';
import { FetchSWIHazardMessage, addSwiMessagingMappingDetails, deleteSwiMessagingMappingDetails } from '../../Service/SWIHazardMessaging';

const initialState = {
    SwiHazardMessagingData: [],
    loading: false,
}

export const SwiHazardMessagingDataSlice = createSlice({
  name: 'FetchSwiHazardMessaging',
  initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(FetchSWIHazardMessage.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(FetchSWIHazardMessage.fulfilled, (state, action) => {
            state.SwiHazardMessagingData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(FetchSWIHazardMessage.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(addSwiMessagingMappingDetails.pending, (state) => {
            state.loading = true;
            state.error = "";
          });
          builder.addCase(addSwiMessagingMappingDetails.fulfilled, (state, action) => {
            let arr = JSON.parse(JSON.stringify(state.SwiHazardMessagingData))
            const hazardItems = arr.filter((value) => value.hazardsId === action.payload.id);
            debugger
            hazardItems.forEach(element => {
              element.notificationMessage = action.payload.notificationMessage
            });
            state.SwiHazardMessagingData = arr;
            state.loading = false;
            state.error = "";
          });
          builder.addCase(addSwiMessagingMappingDetails.rejected, (state) => {
            state.formData = [];
            state.loading = true;
            state.error = "Something went wrong";
          });
          builder.addCase(deleteSwiMessagingMappingDetails.pending, (state) => {
            state.loading = true;
            state.error = "";
          });
          builder.addCase(deleteSwiMessagingMappingDetails.fulfilled, (state, action) => {
            let arr = JSON.parse(JSON.stringify(state.SwiHazardMessagingData))
            const hazardItems = arr.filter((value) => value.hazardsId === action.payload);
            hazardItems.forEach(element => {
              element.notificationMessage = ''
            });

            state.SwiHazardMessagingData = arr;
            state.loading = false;
            state.error = "";
          });
          builder.addCase(deleteSwiMessagingMappingDetails.rejected, (state) => {
            state.formData = [];
            state.loading = true;
            state.error = "Something went wrong";
          });
    },
})

export default SwiHazardMessagingDataSlice.reducer