import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import "./searchTextBox.scss";
import { searchMasterTableData } from "../../../Service/SwiMapping";
import { isSearchTextBoxRegex } from "../../../Utils/regex";

export const SEARCH_TYPE = {
  SWIICON: 1,
  HIGHENERGY: 2,
  HAZARD: 3,
  DIRECTCONTROL: 4,
  SAFEGUARD: 5,
};

export default function SearchTextBox({
  searchType = SEARCH_TYPE.SWIICON,
  inputProps = {},
  onSelect = () => {},
}) {
  const [error, setError] = useState("");
  const [searchBoxData, setSearchBoxData] = useState([]);
  const [message, setMessage] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const timeout = useRef();
  const dispatch = useDispatch();
  const popup = useRef(null);
  const [focused, setFocused] = useState(false);

  let maxLength = inputProps.maxLength || 100;
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showPopover && popup.current && !popup.current.contains(e.target)) {
        setShowPopover(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });

  function handleDebounceSearch(val) {
    if (inputProps.onChangeText) {
      inputProps.onChangeText(val);
    }

    if (val.length > 3) {
      timeout.current = setTimeout(() => {
        dispatch(
          searchMasterTableData({
            searchKey: val,
            searchType: searchType,
            callBackFn: (responseData, msg) => {
              setShowPopover(true);
              setSearchBoxData(responseData);
              if (responseData !== null && responseData.length === 0) {
                setMessage(msg || "No records found");
              } else {
                setMessage("");
              }
            },
          })
        );
      }, 500);
    } else setShowPopover(false);
  }

  function onChange(e) {
    clearTimeout(timeout.current);

    if (e.target.value.length > maxLength) {
      setError(`*Max. ${maxLength} characters are allowed`);
      handleDebounceSearch(e.target.value.substring(0, maxLength));
    } else {
      if (error) setError("");
      handleDebounceSearch(e.target.value);
    }
  }

  const clearTextInput = () => {
    if (
      inputProps.updateSearchTextBoxClearState &&
      inputProps.value.length > 0
    ) {
      inputProps.updateSearchTextBoxClearState();
    }
    handleDebounceSearch("");
    setError(false);
  };

  return (
    <div>
      <div className="searchbar">
        {inputProps.searchIconVisible && (
          <i className="pi pi-search searchicon" />
        )}
        <InputText id="txt_SearchTextBox"
          className="inputElement"
          {...inputProps}
          style={{
            ...inputProps.style,
          }}
          placeholder={inputProps.placeholder}
          maxLength={maxLength + 1}
          keyfilter={isSearchTextBoxRegex()}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        ></InputText>
        {inputProps.clearTextIconVisible && (
          <i className="pi pi-times-circle" onClick={clearTextInput}></i>
        )}
      </div>

      {error && (
        <div
          style={{
            color: "red",
            fontSize: "12px",
            ...inputProps.styleErrorMsg,
          }}
        >
          {error}
        </div>
      )}
      {showPopover && (
        <div
          className="searchDataList"
          style={{
            ...inputProps.styleLocation,
          }}
          ref={popup}
          onClick={(e) => {
            setShowPopover(false);
          }}
        >
          {searchBoxData.length > 0 &&
            searchBoxData !== undefined &&
            searchBoxData.map((e) => (
              <div
                key={e.placeId}
                onClick={() => {
                  onSelect(e);
                  setShowPopover(false);
                }}
              >
                <div
                  style={{
                    padding: "4px 10px",
                    color: "#687782",
                  }}
                >{`${
                  searchType === SEARCH_TYPE.HIGHENERGY
                    ? e.highEnergyName:
                  searchType === SEARCH_TYPE.HAZARD
                    ? e.hazardsMaster
                    : (searchType === SEARCH_TYPE.DIRECTCONTROL ||
                        searchType === SEARCH_TYPE.SAFEGUARD) &&
                      e.directControlsSafegaurdsName
                }`}</div>
              </div>
            ))}
          {focused && message && (
            <div style={{ padding: 8, color: "#687782" }}>{message}</div>
          )}
        </div>
      )}
    </div>
  );
}
