import { createSlice } from '@reduxjs/toolkit';
import { fetchNotificationDetails, addNotificationDetails, deleteNotificationDetails, updateNotificationDetails } from '../../Service/Notification';

const initialState = {
    notificationData: [],
    loading: false,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchNotificationDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(fetchNotificationDetails.fulfilled, (state, action) => {
            state.notificationData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchNotificationDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })

        builder.addCase(addNotificationDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(addNotificationDetails.fulfilled, (state, action) => {
            state.notificationData.push(action.payload)
            state.loading = false
            state.error = ''
        })
        builder.addCase(addNotificationDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })

        builder.addCase(deleteNotificationDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(deleteNotificationDetails.fulfilled, (state, action) => {
            state.notificationData = state.notificationData.filter((item) => item.notificationId !== action.payload)
            state.loading = false
            state.error = ''
        })
        builder.addCase(deleteNotificationDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })

        builder.addCase(updateNotificationDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(updateNotificationDetails.fulfilled, (state, action) => {
            let arr = [...state.notificationData]
            const index = arr.findIndex((value) => value.notificationId === action.payload.notificationId);
            arr[index] = action.payload;
            state.notificationData = arr;
            state.loading = false
            state.error = ''
        })
        builder.addCase(updateNotificationDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
    },
})

export default notificationSlice.reducer