import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "../Pages/Auth";
import NoMatch from "../Pages/NotFound";

function PublicRoute() {
  return (
    <Routes>
      <Route index element={<Auth />} />
      <Route path="*" element={<Auth />} />
    </Routes>
  );
}

export default PublicRoute;
