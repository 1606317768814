import React from "react";
import "../layouts.scss";
import "./access.scss";
import HeaderContainer from "../HeaderContainer";
import lock from '../../Assets/Images/lock.png';
import { useMsal } from '@azure/msal-react';

function AccessDenied() {
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <main>
        <div className="header_wrapper_layout">
          <HeaderContainer />
        </div>
      </main>
      <div className="access-denied">
        <img id="img_AccessLock" className="access-lock" src={lock}></img>
        <p className="access-heading">Access Denied!</p>
        <p className="acess-content">You are not authorized to access this page.</p>
        <button id="btn_AccessLogout" className="logout" onClick={handleLogoutRedirect}>Logout</button>
      </div>
    </div>
  );
}

export default AccessDenied;
