import { createSlice } from '@reduxjs/toolkit';
import { HazardousEnergiesControlsSwiMessaging , addHECSwimessagingDetails, deleteHECSwimessagingDetails } from '../../Service/HazardousEnergiesControlsSwiMessaging';

const initialState = {
    SwiHazardenergiescontrolMessagingData: [],
    loading: false,
}

export const SwiHazardenergiescontrolMessagingSlice = createSlice({
  name: 'fetchHazardousEnergiesControlsSwiMessaging',
  initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(HazardousEnergiesControlsSwiMessaging.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(HazardousEnergiesControlsSwiMessaging.fulfilled, (state, action) => {
            state.SwiHazardenergiescontrolMessagingData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(HazardousEnergiesControlsSwiMessaging.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(addHECSwimessagingDetails.pending, (state) => {
            state.loading = true;
            state.error = "";
          });
          builder.addCase(addHECSwimessagingDetails.fulfilled, (state, action) => {

            let arr = JSON.parse(JSON.stringify(state.SwiHazardenergiescontrolMessagingData))
            const hazardItems = arr.filter((value) => value.hazardsId === action.payload.id);
            hazardItems.forEach(element => {
              element.notificationMessage = action.payload.notificationMessage
            });
            state.SwiHazardenergiescontrolMessagingData = arr;
            state.loading = false;
            state.error = "";
          });
          builder.addCase(addHECSwimessagingDetails.rejected, (state) => {
            state.formData = [];
            state.loading = true;
            state.error = "Something went wrong";
          });
          builder.addCase(deleteHECSwimessagingDetails.pending, (state) => {
            state.loading = true;
            state.error = "";
          });
          builder.addCase(deleteHECSwimessagingDetails.fulfilled, (state, action) => {
            // state.SwiHazardenergiescontrolMessagingData = state.SwiHazardenergiescontrolMessagingData.filter((item) => item.hazardsId === action.payload)
            let arr = JSON.parse(JSON.stringify(state.SwiHazardenergiescontrolMessagingData))
            const hazardItems = arr.filter((value) => value.hazardsId === action.payload);
            hazardItems.forEach(element => {
              element.notificationMessage = ''
            });
            state.SwiHazardenergiescontrolMessagingData = arr;
            state.loading = false;
            state.error = "";
          });
          builder.addCase(deleteHECSwimessagingDetails.rejected, (state) => {
            state.formData = [];
            state.loading = true;
            state.error = "Something went wrong";
          });

    },
})

export default SwiHazardenergiescontrolMessagingSlice.reducer