import React from "react";
import "./lookup.scss";
import Labels from "../../Constants/Label";
import { TabView, TabPanel } from "primereact/tabview";
import CriticalContacts from "./CriticalContacts";
import FetchEE from "./EmergencyEquipment/FetchEE";
import Mutcdplan from "./MUTCDplan";
import PotentialAocList from "./PotentialAocList";
import EmergencyEquipment from "./EmergencyEquipment";
// import ProcedureMapping from "./ProcedureMapping";
import CustomStyledDropdown from "./ProcedureMapping";
import FetchPPE from "./PPE/FetchPPE";
import ProcedureMapping from "./PM";
import SwiAndHazard from "./SwiAndHazard";
import SWIMapping from "./SwiMapping/SWIMapping";
import HEC from "./HEC";

const Lookup = () => {
  return (
    <div className="lookup">
      <div className="lookup-title">
        <header className="lookup-header">{Labels?.Sidebar?.Lookup}</header>
      </div>
      <div className="lookup-content">
        <TabView scrollable>
          <TabPanel header="Critical contacts" id="tabPanel_critical">
            <CriticalContacts />
          </TabPanel>
        
        
           

          <TabPanel header="SWI Mapping" id="tabPanel_swiMapping">
            <SWIMapping />
          </TabPanel>
          <TabPanel header="SWI & Hazard Messaging" id="tabPanel_SWIHazard">
            <SwiAndHazard/>
          </TabPanel>

          {/* <TabPanel header="Procedure Mapping">
            <MyDropdown/>
           
          </TabPanel> */}
          <TabPanel header="Emergency Equipment" id="tabPanel_EmergencyEquipment">
            <FetchEE />
          </TabPanel>

          <TabPanel header="PPE" id="tabPanel_PPE">
            <FetchPPE />
          </TabPanel>
          
          <TabPanel header="Potential AOC List" id="tabPanel_PAOCL">
            <PotentialAocList />
          </TabPanel>

          <TabPanel header="Hazardous Energies & Control" id="tabPanel_HEC">
            <HEC />
          </TabPanel>

          <TabPanel header="Procedure Mapping" id="tabPanel_ProcedureMapping">
            <ProcedureMapping />
          </TabPanel>

          <TabPanel header="MUTCD plan" id="tabPanel_MUTCDPlan">
            <Mutcdplan />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Lookup;
