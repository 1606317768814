import React, { useState, useEffect, useRef } from "react";
import "./notification.scss";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import Labels from "../../Constants/Label";
import "primeicons/primeicons.css";
import Switch from "../Toggle";
import ModalWrapper from "../GenericPopup";
import DeletePopup from "../GenericPopup/deletePopup";
import SavePopup from "../GenericPopup/savePopup";
import SendPopUp from "../GenericPopup/sendPopup";
import DeleteSuccess from "../GenericPopup/deleteSuccess";
import SaveSuccess from "../GenericPopup/saveSuccess";
import SendSuccess from "../GenericPopup/sendSuccess";
import {
  fetchNotificationDetails,
  addNotificationDetails,
  deleteNotificationDetails,
  updateNotificationDetails,
} from "../../Service/Notification";
import { useDispatch, useSelector } from "react-redux";
import trash from "../../Assets/Images/trash.png";
import Loader from "../Loader";
import { setSave } from "../../Store/saveSlice";

const Notification = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [count, setCount] = useState(0);
  const [updateValue, setUpdateValue] = useState(null);
  const [name, setName] = useState("");
  const [receipients, setReceipients] = useState("");
  const [msg, setMsg] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showSendSuccess, setShowSendSuccess] = useState(false);
  const [id, setId] = useState(0);
  const [updateId, setUpdateId] = useState(0);
  const [valueName, setValueName] = useState("");
  const [clearDisable, setClearDisable] = useState(true);
  const [saveDisable, setSaveDisable] = useState(true);
  const [sendDisable, setSendDisable] = useState(true);
  const [toggleState, setToggleState] = useState({});
  const [show, setShow] = useState(false);
  const [sortKey, setSortKey] = useState("notificationId");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortDraftKey, setSortDraftKey] = useState("notificationId");
  const [sortDraftOrder, setSortDraftOrder] = useState("desc");
  const [fileError, setFileError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const minDate = new Date();
  const minToDate = fromDate;
  const ref = useRef();

  const today = new Date();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotificationDetails(dispatch));
  }, []);

  let notificationDetails = useSelector(
    (state) => state?.notification?.notificationData || []
  );

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  useEffect(() => {
    {
      notificationDetails?.map((item, index) => {
        const newData = { ...toggleState };
        newData[item?.notificationId] = item?.isEnabled;
        toggleState[item?.notificationId] = item?.isEnabled;
      });
    }
  });

  const handleSavedValue = (val) => {
    dispatch(setSave(val));
  };

  // api call for adding a new notification
  const handleDraftAdd = (data) => {
    dispatch(addNotificationDetails({ data, success: showSavePopUp }));
    setClearDisable(true);
    setSaveDisable(true);
    handleSavedValue(true);
  };

  const handleActiveAdd = (data) => {
    dispatch(addNotificationDetails({ data, success: showSendSuccessPopUp }));
    setClearDisable(true);
    setSaveDisable(true);
    handleSavedValue(true);
  };
  // ------------------------------------------------

  // api call for deleting a notification
  const handleDelete = (id) => {
    dispatch(
      deleteNotificationDetails({ id, deleteSuccess: showDeleteSuccessPopup })
    );
    // setShowDelete((pre) => !pre);
    setClearDisable(true);
    setSaveDisable(true);
  };
  //----------------------------------------------------

  //api call for updating a notification
  const handleDraftUpdate = (data) => {
    dispatch(updateNotificationDetails({ data, success: showSavePopUp }));
    setClearDisable(true);
    setSaveDisable(true);
    handleSavedValue(true);
  };

  const handleActiveUpdate = (data) => {
    dispatch(
      updateNotificationDetails({ data, success: showSendSuccessPopUp })
    );
    setClearDisable(true);
    setSaveDisable(true);
    handleSavedValue(true);
  };

  const handleToggleUpdate = (
    id,
    name,
    recip,
    from,
    to,
    msg,
    file,
    active,
    enabled
  ) => {
    const data = {
      id: id,
      NotificationName: name,
      Recipients: recip,
      ValidityFrom: from.toString().substring(4, 24),
      ValidityTill: to.toString().substring(4, 24),
      NotificationMessage: msg,
      FilesUpload: null,
      Active: active,
      isEnabled: enabled,
    };
    dispatch(updateNotificationDetails({ data, callBackFn: handleNewData }));
    setClearDisable(true);
    setSaveDisable(true);
  };

  const handleNewData = (success, res) => {
    if (success) {
      setCount((pre) => pre + 1);
      let arr = [...sortedData];
      const index = arr.findIndex(
        (value) => value.notificationId === res.notificationId
      );
      arr[index] = res;
      sortedData = arr;
    }
  };

  useEffect(() => {
    notificationDetails = sortedData;
  }, [count]);
  //------------------------------------------------------

  function showPopUp(data) {
    setValueName(data?.NotificationName);
    setShowSave((pre) => !pre);
    setNameError(false);
    setMsgError(false);
  }

  function showSendPopUp(data) {
    setValueName(data?.NotificationName);
    setShowSend((pre) => !pre);
    setNameError(false);
    setMsgError(false);
  }

  function showDeletePopUp(id, name) {
    setId(id);
    setValueName(name);
    setShowDelete((pre) => !pre);
  }

  function showDeleteSuccessPopup() {
    setShowDeleteSuccess((pre) => !pre);
    setShowDelete(false);
    setUpdateId(0);
    setName("");
    setReceipients("");
    setMsg("");
    setFromDate("");
    setToDate("");
    setIsSelected(false);
    setClearDisable(true);
  }

  function showSavePopUp(value) {
    setValueName(value);
    setShowSaveSuccess((pre) => !pre);
    setShowSave(false);
    setUpdateId(0);
    setName("");
    setReceipients("");
    setMsg("");
    setFromDate("");
    setToDate("");
    setIsSelected(false);
    setSaveDisable(true);
    setSendDisable(true);
    setSelectedFile(null);
  }

  function showSendSuccessPopUp(data) {
    setShowSendSuccess((pre) => !pre);
    setShowSend(false);
    setUpdateId(0);
    setName("");
    setReceipients("");
    setMsg("");
    setFromDate("");
    setToDate("");
    setIsSelected(false);
    setSendDisable(true);
    setSelectedFile(null);
  }

  // when clicked on any row in the draft notifications the values should populate in the input field for editing
  const handleClick = (val) => {
    setUpdateValue(val);
    let startDate = "";
    let endDate = "";
    if (val.validityFrom) {
      startDate = new Date(val?.validityFrom);
    }
    if (val.validityTill) {
      endDate = new Date(val?.validityTill);
    }
    setUpdateId(val?.notificationId);
    setName(val?.notificationName);
    setReceipients(val?.recipients);
    setFromDate(startDate);
    setToDate(endDate);
    setMsg(val?.notificationMessage);
    setSelectedFile(val?.filesUploaded);
    setIsSelected(true);
    setClearDisable(false);
    setSaveDisable(false);
    if (
      val?.notificationName?.length > 0 &&
      val?.recipients &&
      val?.validityFrom &&
      val?.validityTill &&
      val?.notificationMessage?.length > 0
    ) {
      setSendDisable(false);
    }
  };

  // to clear the values on the input field when clicked on clear button
  const handleClear = () => {
    setUpdateId(0);
    setName("");
    setReceipients("");
    setMsg("");
    setFromDate("");
    setToDate("");
    setIsSelected(false);
    setClearDisable(true);
    setSaveDisable(true);
    setSendDisable(true);
    handleSavedValue(true);
    setFileError(false);
    setSizeError(false);
    setNameError(false);
    setMsgError(false);
  };

  const allowedFileExt = [".jpg", ".jpeg", ".png", ".pdf", ".mp4"];

  //file upload is performed here
  const changeHandler = (event) => {
    setSelectedFile(null);
    let fileSize = event.target.files[0].size;
    if (fileSize > 10000000) {
      setSizeError(true);
    } else {
      setSizeError(false);
      let file = event.target.files[0];
      let fileExt = `.${file.name.split(".").pop()}`.toLowerCase();
      if (file) {
        if (allowedFileExt.includes(fileExt)) {
          setFileError(false);
          setSelectedFile(event.target.files[0]);
          setIsSelected(true);
          setClearDisable(false);
          setSaveDisable(false);
          handleSendEnable();
          handleSavedValue(false);
        } else {
          setFileError(true);
          setSelectedFile(null);
          handleSavedValue(true);
        }
      }
    }
  };

  //handles the change in the notification name
  const nameChange = (e) => {
    const namevalue = e.target.value.trimStart();
    const regex = /^[a-zA-Z0-9'*()\-"\//.\s]*$/;
    if (regex.test(namevalue)) {
      setName(namevalue);
      setClearDisable(false);
      setSaveDisable(false);
      handleSavedValue(false);
    }
    if (namevalue.length === 100) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    handleSendEnable();
  };

  //handles the change in the recipients
  const handleRecipientChange = (value) => {
    setReceipients(value);
    setShow(false);
    setClearDisable(false);
    setSaveDisable(false);
    handleSendEnable();
    handleSavedValue(false);
  };

  //handles the change in the message
  const msgChange = (e) => {
    const msgvalue = e.target.value.trimStart();
    const regex = /^[a-zA-Z0-9!@#$%^&'*_+=,<>?;:()\-"\//.\s]*$/;
    //4/14/24, SB - Fix regex for notification message
    //const regex = /(^[a-zA-Z0-9\'\"\*\(\)\-\/\.\@\#\&\_\=\+\,\:\;\%\?\!\$\^\<\>\ ]+$)/;
    //const regex = /^[a-zA-Z\d+.!?%&$,-_;:"'*(\/)\#\s]+$/;
    //const regex = "^[a-zA-Z\\d+.!@%^*()?&$,-_;:\"\'*(\\/)\\#\\s]*$";
    //orig const regex = /^[a-zA-Z0-9'*()\-"\//.\s]*$/;


    if (regex.test(msgvalue)) {
      setMsg(msgvalue);
    }
    setClearDisable(false);
    setSaveDisable(false);
    handleSavedValue(false);
    // 4/4/2024, MD - Change character length to max length
    // if (msgvalue.length === 200) {
    if (msgvalue.length === 5000) {
      setMsgError(true);
    } else {
      setMsgError(false);
    }
    handleSendEnable();
  };

  //sets the starting date
  const hanldeFromDate = (e) => {
    setFromDate(e.value);
    setToDate("");
    setClearDisable(false);
    setSaveDisable(false);
    handleSendEnable();
    handleSavedValue(false);
  };

  //sets the ending date
  const handleToDate = (e) => {
    setToDate(e.value);
    setClearDisable(false);
    setSaveDisable(false);
    handleSendEnable();
    handleSavedValue(false);
  };

  //to check whether the send button should be enabled
  const handleSendEnable = () => {
    if (
      name?.length !== 0 &&
      receipients?.length !== 0 &&
      fromDate?.length !== 0 &&
      toDate?.length !== 0 &&
      msg?.length !== 0
    ) {
      setSendDisable(false);
    } else {
      setSendDisable(true);
    }
  };

  const notifDraftData = {
    id: updateId,
    NotificationName: name,
    Recipients: receipients,
    ValidityFrom: fromDate.toString().substring(4, 24),
    ValidityTill: toDate.toString().substring(4, 24),
    NotificationMessage: msg,
    FilesUpload: selectedFile,
    Active: false,
    isEnabled: true,
  };

  const notifActiveData = {
    id: updateId,
    NotificationName: name,
    Recipients: receipients,
    ValidityFrom: fromDate.toString().substring(4, 24),
    ValidityTill: toDate.toString().substring(4, 24),
    NotificationMessage: msg,
    FilesUpload: selectedFile,
    Active: true,
    isEnabled: true,
  };

  const recipData = ["All", "Electric", "Gas"];

  //Sort functionality for active notification
  const handleSort = (key) => {
    if (key === sortKey) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("desc");
    }
  };

  let sortedData = notificationDetails.slice().sort((a, b) => {
    let aValue = a[sortKey];
    let bValue = b[sortKey];
    if (sortKey === "filesUploaded") {
      aValue = a[sortKey].split("///").pop().split("/").pop().split("_").pop();
      bValue = b[sortKey].split("///").pop().split("/").pop().split("_").pop();
    }
    if (sortOrder === "asc") {
      return aValue < bValue ? 1 : -1;
    } else {
      return aValue > bValue ? 1 : -1;
    }
  });

  // Sort functionality for draft notification
  const handleDraftSort = (key) => {
    if (key === sortDraftKey) {
      setSortDraftOrder(sortDraftOrder === "asc" ? "desc" : "asc");
    } else {
      setSortDraftKey(key);
      setSortDraftOrder("desc");
    }
  };

  const sortedDraftData = notificationDetails.slice().sort((a, b) => {
    let aValue = a[sortDraftKey];
    let bValue = b[sortDraftKey];
    if (sortDraftKey === "filesUploaded") {
      aValue = a[sortDraftKey]
        .split("///")
        .pop()
        .split("/")
        .pop()
        .split("_")
        .pop();
      bValue = b[sortDraftKey]
        .split("///")
        .pop()
        .split("/")
        .pop()
        .split("_")
        .pop();
    }
    if (sortDraftOrder === "asc") {
      return aValue < bValue ? 1 : -1;
    } else {
      return aValue > bValue ? 1 : -1;
    }
  });

  //--------------------------------------------------
  const handleToggle = (id, data) => {
    const from = new Date(data.validityFrom);
    const end = new Date(data.validityTill);
    const newToggleState = { ...toggleState };
    newToggleState[id] = !newToggleState[id];
    toggleState[id] = newToggleState[id];
    handleToggleUpdate(
      id,
      data.notificationName,
      data.recipients,
      from,
      end,
      data.notificationMessage,
      data.filesUploaded,
      true,
      toggleState[id]
    );
  };

  const handleOptionClick = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showOptions && ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showOptions]);

  return (
    <div className="notification-wrapper">
      {isLoading && <Loader />}
      <div className="notification-title">
        <header className="notification-header">
          {Labels?.Sidebar?.Notification}
          <div className="notification-button">
            <button id="btn_NotifClear"
              className={clearDisable ? "clear-disabled" : "clear"}
              onClick={() => handleClear()}
              disabled={clearDisable}
            >
              Clear
            </button>
            <button id="btn_NotifiSaveDraft"
              className={
                !saveDisable && name?.length !== 0 && receipients?.length !== 0
                  ? "save-draft"
                  : "save-draft-disabled"
              }
              onClick={() => showPopUp(notifDraftData)}
              disabled={saveDisable}
            >
              Save Draft
            </button>
            <button id="btn_NotifiSend"
              className={sendDisable ? "send-disabled" : "send"}
              onClick={() => showSendPopUp(notifActiveData)}
            >
              Send
            </button>
          </div>
        </header>
      </div>
      <div className="notification-content">
        <div className="create-notification">
          <div className="section-heading">Create Notification</div>
          <div className="section-content">
            <table>
              <tr className="notif-input">
                <td colSpan="2"></td>
              </tr>
              <tr className="notif-input">
                <td className="label-name">Notification Name *</td>
                <td>
                  <input
                    id="txt_NotifiName"
                    type="text"
                    maxLength="100"
                    className={nameError ? "notif-error" : "notif-name"}
                    value={name}
                    onChange={(e) => nameChange(e)}
                  />
                  {nameError && name.length === 100 && (
                    <p className="name-error">
                      Maximum characters should not exceed 100.
                    </p>
                  )}
                </td>
              </tr>
              <tr className="notif-input">
                <td className="label-name">Recipients *</td>
                <td>
                  <div
                    className="dropdown"
                    ref={ref}
                    onClick={() => handleOptionClick()}
                  >
                    <div
                      className="dropdown-wrapper"
                      onClick={() => setShow(true)}
                    >
                      <div className="recipients">{receipients}</div>
                      <div className="dropdown-caret">
                        {showOptions ? (
                          <i className="pi pi-caret-up"></i>
                        ) : (
                          <i className="pi pi-caret-down"></i>
                        )}
                      </div>
                    </div>
                    {show && showOptions && (
                      <div className="dropdown-select">
                        {recipData.map((item, index) => {
                          return (
                            <div
                              className="recipient-option"
                              key={index}
                              value={item}
                              onClick={() => handleRecipientChange(item)}
                            >
                              {item}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              <tr className="notif-input">
                <td className="label-name">Validity *</td>
                <td className="date-input">
                  <span>From</span>
                  <Calendar
                    id="calendar-12h"
                    value={fromDate}
                    onChange={(e) => hanldeFromDate(e)}
                    showTime
                    hourFormat="24"
                    showIcon
                    minDate={minDate}
                  />
                  <span>To</span>
                  <Calendar
                    id="calendar-12h"
                    value={toDate}
                    onChange={(e) => handleToDate(e)}
                    showTime
                    hourFormat="24"
                    showIcon
                    minDate={minToDate}
                  />
                </td>
              </tr>
              <tr className="notif-input">
                <td className="label-name">Upload File</td>
                <td>
                  <div className="file-row">
                    <label for="file-button" className="file-upload-button">
                      Select File
                    </label>
                    <input
                      id="file-button"
                      type="file"
                      name="file"
                      accept=".pdf, .jpg, .jpeg, .png, .mp4"
                      onChange={(e) => changeHandler(e)}
                    />
                    <p className="file-name">
                      {isSelected &&
                        (selectedFile?.name
                          ? selectedFile?.name?.replace(/[^a-zA-Z0-9.]/g, "")
                          : selectedFile
                              ?.split("///")
                              .pop()
                              .split("/")
                              .pop()
                              .split("_")
                              .pop())}
                    </p>
                    {fileError && (
                      <p className="file-error">
                        *Please select a file with the extension .pdf, .jpg,
                        .jpeg, .png, .mp4.
                      </p>
                    )}
                    {sizeError && (
                      <p className="file-error">
                        *Maximum size should not exceed 10mb.
                      </p>
                    )}
                  </div>
                  <p className="file-support">
                    Supported formats: PDF, JPG, PNG, MP4. Maximum size 10 MB
                  </p>
                </td>
              </tr>
              <tr className="notif-input">
                <td className="msg-label">Message *</td>
                <td>
                  <textarea
                    cols="77"
                    rows="5"
                    className={msgError ? "error" : "message"}
                    // 4/4/2024, MD - Change character length to max length
                    // maxLength="200"
                    maxLength="5000"
                    value={msg}
                    onChange={(e) => msgChange(e)}
                  />
                  {/* 4/4/2024, MD - Change character length to max length */}
                  {/* {msgError && msg.length === 200 && ( */}
                  {msgError && msg.length === 5000 && (
                    <p className="msg-error">
                      {/* Maximum characters should not exceed 200. */}
                      Maximum characters should not exceed 5000.
                    </p>
                  )}
                </td>
              </tr>
            </table>
          </div>

          <ModalWrapper
            visible={showDelete}
            onDismiss={() => {
              showDeletePopUp();
            }}
          >
            <DeletePopup
              closePopup={() => {
                showDeletePopUp();
              }}
              deleteValue={() => handleDelete(id)}
              successDelete={() => {
                showDeleteSuccessPopup();
              }}
              heading="Notification"
              content={valueName}
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showSave}
            onDismiss={() => {
              showPopUp();
            }}
          >
            <SavePopup
              closePopup={() => {
                showPopUp();
              }}
              saveValue={() => {
                if (notifDraftData.id === 0) {
                  handleDraftAdd(notifDraftData);
                  showPopUp();
                } else {
                  handleDraftUpdate(notifDraftData);
                  showPopUp();
                }
              }}
              heading="Notification"
              content={valueName}
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showSend}
            onDismiss={() => {
              showSendPopUp();
            }}
          >
            <SendPopUp
              closePopup={() => {
                showSendPopUp();
              }}
              sendValue={() => {
                if (notifDraftData.id === 0) {
                  handleActiveAdd(notifActiveData);
                } else {
                  handleActiveUpdate(notifActiveData);
                }
              }}
              heading="Notification"
              content={valueName}
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showDeleteSuccess}
            onDismiss={() => {
              showDeleteSuccessPopup();
            }}
          >
            <DeleteSuccess
              closePopup={() => {
                showDeleteSuccessPopup();
              }}
              heading="Notification"
              content={valueName}
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showSaveSuccess}
            onDismiss={() => {
              showSavePopUp();
            }}
          >
            <SaveSuccess
              closePopup={() => {
                showSavePopUp();
              }}
              heading="Notification"
              content={valueName}
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showSendSuccess}
            onDismiss={() => {
              showSendSuccessPopUp();
            }}
          >
            <SendSuccess
              closePopup={() => {
                showSendSuccessPopUp();
              }}
              heading="Notification"
              content={valueName}
            />
          </ModalWrapper>
        </div>
        <div className="notification-table">
          <TabView>
            <TabPanel header="Active Notifications" id="tabPanel_notif_active">
              <div className="notif-table">
                <table>
                  <tr>
                    <td className="colspan" colSpan="7"></td>
                  </tr>
                  <tr className="table-heading">
                    <th>
                      <div className="heading-icon">
                        No
                        {sortOrder === "desc" &&
                        sortKey === "notificationId" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleSort("notificationId")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleSort("notificationId")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        Name
                        {sortOrder === "desc" &&
                        sortKey === "notificationName" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleSort("notificationName")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleSort("notificationName")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        Recipients
                        {sortOrder === "desc" && sortKey === "recipients" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleSort("recipients")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleSort("recipients")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        Validity
                        {sortOrder === "desc" && sortKey === "validityFrom" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleSort("validityFrom")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleSort("validityFrom")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        File
                        {sortOrder === "desc" && sortKey === "filesUploaded" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleSort("filesUploaded")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleSort("filesUploaded")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">Messages</div>
                    </th>
                  </tr>
                  {sortedData?.map((item, index) => {
                    if (item?.active === true)
                      return (
                        <tr key={index}>
                          <td>{item.notificationId}</td>
                          <td>{item.notificationName}</td>
                          <td>{item?.recipients}</td>
                          <td>
                            {item.validityFrom} - {item.validityTill}
                          </td>
                          <td>
                            {item?.filesUploaded
                              ?.split("///")
                              .pop()
                              .split("/")
                              .pop()
                              .split("_")
                              .pop()}
                          </td>
                          <td>
                            {item.notificationMessage?.length > 80
                              ? `${item.notificationMessage.slice(0, 80)}...`
                              : `${item.notificationMessage}`}
                          </td>
                          <td>
                            <img id="imgNotifiTrashIco"
                              src={trash}
                              className="trash-icon"
                              onClick={() =>
                                showDeletePopUp(
                                  item.notificationId,
                                  item.notificationName
                                )
                              }
                            ></img>
                          </td>
                          <td>
                            <Switch
                              isToggled={toggleState[item.notificationId]}
                              onToggle={() =>
                                handleToggle(item.notificationId, item)
                              }
                            />
                          </td>
                        </tr>
                      );
                  })}
                </table>
              </div>
            </TabPanel>

            <TabPanel header="Draft Notifications" id="tabPanel_draftNotifi">
              <div className="notif-table">
                <table>
                  <tr>
                    <td className="colspan" colSpan="8"></td>
                  </tr>
                  <tr className="table-heading">
                    <th>
                      <div className="heading-icon">
                        No
                        {sortDraftOrder === "desc" &&
                        sortDraftKey === "notificationId" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleDraftSort("notificationId")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleDraftSort("notificationId")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        Name
                        {sortDraftOrder === "desc" &&
                        sortDraftKey === "notificationName" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleDraftSort("notificationName")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleDraftSort("notificationName")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        Recipients
                        {sortDraftOrder === "desc" &&
                        sortDraftKey === "recipients" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleDraftSort("recipients")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleDraftSort("recipients")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        Validity
                        {sortDraftOrder === "desc" &&
                        sortDraftKey === "validityFrom" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleDraftSort("validityFrom")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleDraftSort("validityFrom")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">
                        File
                        {sortDraftOrder === "desc" &&
                        sortDraftKey === "filesUploaded" ? (
                          <i
                            className="pi pi-arrow-up"
                            onClick={() => handleDraftSort("filesUploaded")}
                          ></i>
                        ) : (
                          <i
                            className="pi pi-arrow-down"
                            onClick={() => handleDraftSort("filesUploaded")}
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="heading-icon">Messages</div>
                    </th>
                  </tr>
                  {sortedDraftData?.map((item, index) => {
                    if (item?.active === false)
                      return (
                        <tr key={index} onClick={() => handleClick(item)}>
                          <td>{item.notificationId}</td>
                          <td>{item.notificationName}</td>
                          <td>{item.recipients}</td>
                          <td>
                            {item.validityFrom} - {item.validityTill}
                          </td>
                          <td>
                            {item?.filesUploaded
                              ?.split("///")
                              .pop()
                              .split("/")
                              .pop()
                              .split("_")
                              .pop()}
                          </td>
                          <td>
                            {item.notificationMessage?.length > 80
                              ? `${item.notificationMessage.slice(0, 80)}...`
                              : `${item.notificationMessage}`}
                          </td>
                          <td>
                            <img id="img_NotifiTrash"
                              src={trash}
                              className="trash-icon"
                              onClick={() =>
                                showDeletePopUp(
                                  item.notificationId,
                                  item.notificationName
                                )
                              }
                            ></img>
                          </td>
                        </tr>
                      );
                  })}
                </table>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default Notification;
