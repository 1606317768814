import React, { useEffect } from "react";
import "./layouts.scss";
import HeaderContainer from "./HeaderContainer";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Header() {
  const navigate = useNavigate();
  const hasError = useSelector((state) => state.errorSlice.hasError);

  ////redirecting to error page if any api error occured
  useEffect(() => {
    if (hasError) {
      navigate("/error-page");
    }
  }, [hasError]);

  return (
    <main>
      <div className="header_wrapper_layout">
        <HeaderContainer />
      </div>
      <div className="main_wrapper">
        <Outlet />
      </div>
    </main>
  );
}

export default Header;
