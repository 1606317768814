import React from "react";
import successLogo from "../../Assets/Images/tick.png";
import { Button } from "primereact/button";
import "./successPopup.scss";

function DeleteSuccess({ closePopup, heading, content, header }) {
  return (
    <div className="success-popup">
      <img id="img_poppsuccess" src={successLogo} className="successLogo" alt="deleteSuccessImg" />
      <div className="successFormWrapperInner">
        {header ? (
          <>
            <p className="successTitleHead">{heading}</p>
            <p className="successTitle">
              You have successfully deleted {content}
            </p>
          </>
        ) : (
          <>
            {" "}
            <p className="successTitleHead">Deleted {heading} Successfully!</p>
            <p className="successTitle">
              You have successfully deleted{" "}
              <span style={{ fontWeight: "bold" }}> {content}</span>.
            </p>
          </>
        )}
        <div className="popupButtonWrapper">
          <Button id="btn_PopClose"
            className="close-button"
            label="Close"
            onClick={closePopup}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteSuccess;
