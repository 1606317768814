import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance, fileUploadInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

export const fetchResourceDetails = createAsyncThunk(
  "resource/fetchResourceDetails",
  async (dispatch, thunkApi) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Hyperlink",
        actionName: "Fetch hyperlink details",
        message: "Request - Hyperlink Details",
        thunkApi: thunkApi,
      });
      let res = await sharedInstance.get(EndPoints.hyperlink);
      if (res?.data?.data && res.data.statusCode === 200) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Hyperlink",
          actionName: "Fetch hyperlink details",
          message: res?.data,
          thunkApi: thunkApi,
        });
        if (res.data?.statusCode === 200 && res?.data?.data) {
          let d = [...res?.data?.data];
          let newD = [];
          for (let i = 0; i < d.length; i++) {
            let element = { ...d[i] };
            if (element?.images) {
              try {
                let res = await fetchImages(element.images);
                newD.push({ ...element, base64: res });
              } catch (err) {
                newD.push({ ...element, base64: null });
              }
            } else {
              newD.push({ ...element, base64: null });
            }
          }
          console.log(newD);
          return newD;
        } else {
          throw Error();
        }
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Hyperlink",
          actionName: "Fetch hyperlink details",
          thunkApi: thunkApi,
        });
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Hyperlink",
        actionName: "Fetch hyperlink details",
        thunkApi: thunkApi,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteResourceDetails = createAsyncThunk(
  "resource/deleteResourceDetails",
  async ({ id, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Hyperlink",
        actionName: "Delete hyperlink details",
        message: "Request - Hyperlink Details",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.delete(
        `${EndPoints.deleteHyperlink}?resourceId=${id}`
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Hyperlink",
          actionName: "Delete hyperlink details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        deleteSuccess(id);
        return id;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Hyperlink",
          actionName: "Delete hyperlink details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Hyperlink",
        actionName: "Delete hyperlink details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const addResourceDetails = createAsyncThunk(
  "resource/addResourceDetails",
  async (resource, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Hyperlink",
        actionName: "Add hyperlink details",
        message: "Request - Hyperlink Details",
        thunkApi: thunkAPI,
      });
      let fileName = "";
      if (resource?.image) {
        fileName = resource?.image?.name.replace(/[^a-zA-Z0-9.]/g, '')
      } else {
        fileName = "";
      }
      const formData = new FormData();
      if (fileName !== "") {
        formData.append("UploadIcon", resource.image, fileName);
      } else {
        formData.append("UploadIcon", resource.image);
      }
      formData.append("ResourceName", resource.name);
      formData.append("ResourceURL", resource.link);
      formData.append("Active", resource.active);

      let res = await sharedInstance.post(EndPoints.addHyperLink, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Hyperlink",
          actionName: "Add hyperlink details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        resource.saveSuccess(resource.name);
        let element = res?.data?.data;
        let newD = element;
        if (element?.images) {
          try {
            let res = await fetchImages(element.images);
            newD.base64 = res ;
          } catch (err) {
            newD.base64 = null ;
          }
        } else {
          newD.base64 = null ;
        }
        return newD;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Hyperlink",
          actionName: "Add hyperlink details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Hyperlink",
        actionName: "Add hyperlink details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateResourceDetails = createAsyncThunk(
  "resource/updateResourceDetails",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Hyperlink",
        actionName: "Update hyperlink details",
        message: "Request - Hyperlink Details",
        thunkApi: thunkAPI,
      });
      let fileName = "";
      if (args?.image) {
        fileName = args?.image?.name?.replace(/[^a-zA-Z0-9.]/g, '')
      } else {
        fileName = "";
      }
      const formData = new FormData();
      if (fileName !== "" && fileName !== undefined) {
        formData.append("UploadIcon", args.image, fileName);
      } else {
        formData.append("UploadIcon", args.image);
      }
      formData.append("ResourceName", args.editname);
      formData.append("ResourceURL", args.link);
      formData.append("Active", args.active);

      let res = await sharedInstance.put(
        `${EndPoints.updateHyperlink}?resourceId=${args.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Hyperlink",
          actionName: "Update hyperlink details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        if (args?.action === "") {
          args?.saveSuccess(args.editname);
        }
        let element = res?.data?.data;
        let newD = element;
        if (element?.images) {
          try {
            let res = await fetchImages(element.images);
            newD.base64 = res ;
          } catch (err) {
            newD.base64 = null ;
          }
        } else {
          newD.base64 = null ;
        }
        return newD;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Hyperlink",
          actionName: "Update hyperlink details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Hyperlink",
        actionName: "Update hyperlink details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

const fetchImages = async (filePath, thunkApi) => {
  try {
    callLoggerInfo({
      screenName: "All",
      actionName: "Fetch images",
      message: { filePath: filePath },
      thunkApi: thunkApi,
    });

    let res = await fileUploadInstance.post(EndPoints.fetchUploadFile, {
      filePath: filePath,
    });
    if (res?.data?.data?.streamData) {
      callLoggerInfo({
        screenName: "All",
        actionName: "Fetch images",
        message: res?.data,
        thunkApi: thunkApi,
      });

      return res.data?.data?.streamData;
    } else {
      callLoggerError({
        errorCode: res?.data?.statusCode,
        errorDetails: res?.data?.message,
        screenName: "All",
        actionName: "Fetch images",
        thunkApi: thunkApi,
      });

      return null;
    }
  } catch (error) {
    let errorCode = error?.response?.status || error?.code;
    let errorMessage = error?.response?.statusText || "Sorry, unexpected error!";

    callLoggerError({
      errorCode: errorCode,
      errorDetails: errorMessage,
      screenName: "All",
      actionName: "Fetch images",
      thunkApi: thunkApi,
    });
  }
};
