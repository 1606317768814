import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchProcedureMapping,
  FetchGasWorkTask,
  UpdateDocList,
  DeleteDocList,
  AddDocument,
  UpdateWorkTask,
  DeleteWorkTask,
  AddWorkTask,
} from "../../../Service/ProcedureMapping";
import trash from "../../../Assets/Images/trash.png";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import Loader from "../../Loader";

import "./pm.scss";
import CustomStyledDropdown from "../ProcedureMapping";
import ModalWrapper from "../../GenericPopup";
import SaveSuccess from "../../GenericPopup/commonSavePopup";
import DeletePopUp from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/commonDeletePopup";
import WarnPopup from "../../GenericPopup/warningPMPopup";

const ProcedureMapping = () => {
  // Options for the first dropdown
  const options = [
    {
      label: "Distribution/Construction",
      value: "Distribution/Construction",
      id: 4,
    },
    { label: "Meter Services", value: "Meter Services", id: 5 },
    { label: "I&R", value: "I&R", id: 6 },
  ];

  // State for the selected tab
  const [taskType, setTaskType] = useState();
  const [showSaveSuccess, setshowSaveSuccess] = useState();
  const [showDeleteDoc, setshowDeleteDoc] = useState(false);
  const [showDeleteSuccessDoc, setshowDeleteSuccessDoc] = useState(false);
  const [selectedTab, setSelectedTab] = useState(options[0].value);
  const [isAddMoreSelected, setIsAddMoreSelected] = useState(false);
  // State for the selected task with id, name, and workTaskId
  const [selectedTask, setSelectedTask] = useState({
    id: options[0].id,
    name: null,
    workTaskId: null,
  });
  const [editingDocId, setEditingDocId] = useState(null);

  const [saveItem, setsaveItem] = useState();
  const [showDelete, setshowDelete] = useState(false);
  const [deleteItem, setdeleteItem] = useState({});
  const [showSaveSuccessTask, setshowSaveSuccessTask] = useState();
  const [showDeleteSuccess, setshowDeleteSuccess] = useState(false);
  // State for the edited docName
  const [editedDocName, setEditedDocName] = useState("");
  const [editedDocLink, setEditedDocLink] = useState("");
  const [newDocLink, setNewDocLink] = useState("");
  const [hyperlinkError, setHyperlinkError] = useState("");
  const [ErrorMsg, setErrorMsg] = useState("");

  const [deleteDocId, setdeleteDocId] = useState();

  // State for the list of work tasks
  const [workTasks, setWorkTasks] = useState([]);

  // State for the list of documents
  const [updatedList, setupdateList] = useState();
  const [doclist, setDocList] = useState([]);
  const [updatedDocList, setUpdatedDocList] = useState(doclist);
  const [editedTaskName, setEditedTaskName] = useState(selectedTask.name);
  const [isAddingDocument, setIsAddingDocument] = useState(false);
  const [newDocumentName, setNewDocumentName] = useState("");
  const [newDocumentLink, setnewDocumentLink] = useState();
  const [isEditingTaskName, setIsEditingTaskName] = useState(false);
  const [newWorkTask, setNewWorkTask] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showWarning, setshowWarning] = useState();

  // The URL should start with 'http', 'https', or 'ftp', followed by '://'
  // It allows any character except whitespace, '$', '/', '.', '?', and '#'.
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  // Function to start editing the selected task name
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchGasWorkTask(selectedTask.id));
  }, [selectedTask.id]);

  // Select work task data from Redux store
  const workTaskData = useSelector(
    (state) => state.procedureMapping.WorkTaskData || []
  );

  // Fetch work tasks when the selected task ID changes

  // Initialize the local state with work task data from Redux
  useEffect(() => {
    setWorkTasks(workTaskData);
    setEditedTaskName(workTaskData[0]?.taskType);
  }, [workTaskData]);

  // Log values on page load

  // Set the initial value of the second dropdown on page load
  // useEffect(() => {
  //   if (workTasks.length > 0) {
  //     setSelectedTask({
  //       id: selectedTask.id,
  //       name: workTasks[0].taskType,
  //       workTaskId: workTasks[0].id,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (workTasks.length > 0) {
      setSelectedTask({
        id: selectedTask.id,
        name: editedTaskName,
        workTaskId: workTasks[0].id,
      });
    }
  }, [workTasks]);

  const procedureData = useSelector(
    (state) => state.procedureMapping.procedureMappingData || []
  );

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);
  // Fetch procedure mapping data when the selected task changes
  useEffect(() => {
    dispatch(FetchProcedureMapping(selectedTask));
  }, [selectedTask]);

  // Select procedure data from Redux store

  // Initialize the local state with procedure data from Redux
  useEffect(() => {
    if (procedureData !== null || procedureData.length > 0) {
      setDocList(procedureData);
    }
  }, [procedureData]);
  // Function to handle tab change
  const handleTabChange = (event) => {
    setSelectedTab(event.value);
    setNewWorkTask("")

    const selectedOption = options.find(
      (option) => option.value === event.value
    );
    if (selectedOption) {
      setSelectedTask({
        id: selectedOption.id,
        name: null,
        workTaskId: null,
      });
    }
  };

  const handleEditDocLinkChange = (newValue) => {
    setEditedDocLink(newValue);
  };
  // Function to handle changes in the second dropdown
  const handleWorkTaskChange = (event) => {
    if (event.value === "Add More") {
      // If "Add More" is selected, toggle the "edit-task-name" class visibility
      setIsAddMoreSelected(true);
    } else {
      // If another option is selected, update the selected work task
      setIsAddMoreSelected(false);

      // Find the selected work task
      const selectedWorkTask = workTasks.find(
        (workTask) => workTask.taskType === event.value
      );

      // Update the selectedTask with name and workTaskId
      setSelectedTask({
        id: selectedTask.id,
        name: event.value,
        workTaskId: selectedWorkTask.id,
      });
      setEditedTaskName(event.value);
    }
    setIsAddingDocument(false);
    setNewDocumentName(""); // Clear the input field
    setnewDocumentLink("");
    setErrorMsg("");
    setNewWorkTask("")

  };

  // Function to handle the edit button
  const handleEdit = (doc) => {
    setEditingDocId(doc.id);
    setEditedDocLink(doc.docLink);
    setErrorMsg("");
    // Clone the doclist to avoid modifying the state directly
    const updatedDoclist = doclist.map((d) =>
      d.id === doc.id
        ? {
            ...d,
            isEditing: true,
            editedDocName: d.docName, // Reset the edited name to the original docName
          }
        : d
    );

    // Update the component state with the updated doclist
    setUpdatedDocList(updatedDoclist);
  };

  // Function to handle the delete button
  const handleDelete = (id, name) => {
    // setsaveItem(name)
    setdeleteDocId({ id: id, name: name });

    setshowDeleteDoc(true);
  };

  const confirmDeleteDoc = () => {
    dispatch(DeleteDocList(deleteDocId.id));
    dispatch(FetchProcedureMapping(selectedTask));
    setshowDeleteDoc(false);
    setshowDeleteSuccessDoc(true);
  };
  // Function to cancel the editing of docName
  const handleCancelEdit = (doc) => {
    // Clone the doclist to avoid modifying the state directly
    const updatedDoclist = doclist.map((d) =>
      d.id === doc.id
        ? {
            ...d,
            isEditing: false,
            editedDocName: d.docName, // Reset the edited name to the original docName
          }
        : d
    );

    // Update the component state with the updated doclist
    setUpdatedDocList(updatedDoclist);
    setEditingDocId(null);
  };

  const handleEditDocNameChange = (docId, newValue) => {

    if(newValue.trimStart().length ==30){
      setErrorMsg("max length 30")
    }else{
      
      setErrorMsg("")
    }
    // Clone the doclist to avoid modifying the state directly
    const updatedDoclist = doclist.map((doc) => {
      if (doc.id === docId) {
        return {
          ...doc,
          editedDocName: newValue, // Update the edited docName
        };
      }
      return doc;
    });

    // Update the component state with the updated doclist
    setDocList(updatedDoclist);
  };
  // ...

  const handleSaveEdit = (docToEdit, docName) => {
    const isDuplicate = doclist.some(
      (doc) => doc.docName === docToEdit.editedDocName
    );
    // Save the edited docName and docLink

    if (
      docToEdit.editedDocName !== docName ||
      (docToEdit.docLink !== editedDocLink && urlPattern.test(editedDocLink))
    ) {
      if (!isDuplicate || docToEdit.docLink !== editedDocLink) {
        const reqBody = {
          docName: docToEdit.editedDocName,
          docLink: editedDocLink, // Use editedDocLink
          workTaskId: docToEdit.workTaskId,
          docId: docToEdit.id,
        };

        dispatch(UpdateDocList({ reqBody ,cb:callBack}));
        setsaveItem(docToEdit.editedDocName);
        setshowSaveSuccess(true);
        setErrorMsg("");
        dispatch(FetchProcedureMapping(selectedTask));

        function callBack() {
          // dispatch(FetchProcedureMapping(selectedTask))
          dispatch(FetchProcedureMapping(selectedTask))
          
        }
        // Clone the updatedDocList to avoid modifying the state directly
        const updatedDoclist = updatedDocList.map((d) =>
          d.id === docToEdit.id
            ? {
                ...d,
                isEditing: false,
                docName: docToEdit.editedDocName,
                docLink: editedDocLink, // Update docLink
              }
            : d
        );

        // Update the component state with the updated doclist
        setUpdatedDocList(updatedDoclist);
      } else {
        setErrorMsg(
          "This document name is already present in the list. Please use a different name."
        );
      }
    } else {
      setErrorMsg("please enter vallid inputs");
    }
    setEditingDocId(null);
  };

  // Use a useEffect to sync docList with updatedDocList
  useEffect(() => {
    setDocList(updatedDocList);
  }, [updatedDocList]);

  // ...

  // Function to handle adding a new document
  const handleAddDocument = () => {
    setIsAddingDocument(true);
  };

  // Function to handle canceling the addition of a new document
  const handleCancelAddDocument = () => {
    setIsAddingDocument(false);
    setNewDocumentName(""); // Clear the input field
    setnewDocumentLink("");
    setErrorMsg("");
  };

  // Function to handle saving the added document
  // Function to handle saving the added document
  const handleSaveDocument = () => {
    const isDuplicate = doclist.some((doc) => doc.docName === newDocumentName);

    if (
      newDocumentName &&
      newDocumentLink &&
      urlPattern.test(newDocumentLink)
    ) {
      // Create a new document object with the required properties
      if (!isDuplicate) {
        const newDocument = {
          // You can implement a function to generate a unique ID
          docName: newDocumentName,
          docLink: newDocumentLink,
          isEditing: false, // Ensure isEditing is set to false for new documents
        };

        // You can dispatch an action to save the new document here

        const reqBody = {
          docName: newDocumentName,
          workTaskId: selectedTask.workTaskId,
          docLink: newDocumentLink,
        };

        // Dispatch the action to add the document
        dispatch(AddDocument(reqBody))
          .then(() => {
            // If AddDocument succeeds, dispatch the FetchProcedureMapping action
            dispatch(FetchProcedureMapping(selectedTask));
          })
          .catch((error) => {
            // Handle the error as needed, e.g., show an error message
          });

        setsaveItem(newDocumentName);
        setshowSaveSuccess(true);
        setErrorMsg("");
        // Add the new document to the doclist state
        setDocList([...doclist, newDocument]);
        // setUpdatedDocList(doclist)
        setIsAddingDocument(false);
        setNewDocumentName(""); // Clear the input field
        setnewDocumentLink("");
      } else {
        setErrorMsg(
          "This document name is already present in the list. Please use a different name."
        );
      }
    } else {
      setErrorMsg("Please enter a valid document name and hyperlink.");
    }
  };

  const handleEditTaskName = (name) => {
    setEditedTaskName(name);
    setIsEditingTaskName(true);
  };

  // Function to save the edited task name
  const handleSaveTaskName = () => {
    setTaskType(editedTaskName);
    if (editedTaskName !== selectedTask.name) {
      const reqBody = {
        templateID: selectedTask.id,
        taskType: editedTaskName,
        workTaskId: selectedTask.workTaskId,
      };

      dispatch(UpdateWorkTask(reqBody))
        .then(() => {
          // After updating the task name, fetch procedure mapping data with the new task name
          const updatedTask = {
            id: selectedTask.id,
            name: editedTaskName,
            workTaskId: selectedTask.workTaskId,
          };
          dispatch(FetchGasWorkTask(selectedTask.id));
          dispatch(FetchProcedureMapping(updatedTask));

          // You may also update the value of the second dropdown with the new task name
          setSelectedTask(updatedTask);
        })
        .catch((error) => {
          // Handle the error if the task name update fails
        });
      setshowSaveSuccessTask(true);

      setIsEditingTaskName(false);
    }
  };

  // Function to cancel editing the task name
  const handleCancelTaskNameEdit = () => {
    setIsEditingTaskName(false);
  };
  const renderOption = (option) => {
    // Check if the option is "Add More"
    if (option.value === "Add More") {
      return <div className="option-item">{option.label}</div>;
    }

    const handleDeleteClick = (e) => {
      debugger;
      // setshowDelete(true)
      e.stopPropagation(); // Stop propagation to prevent the dropdown from closing
      handleDeleteWorkTask(option.value); // Call your delete function with the selected value
    };

    return (
      <>
        <div className="option-item">
          {option.label}
          <i
            className="pi pi-trash"
            onClick={(e) => handleDeleteClick(e)} // Call your delete function with the selected value
          ></i>
        </div>
      </>
    );
  };

  function handleDeleteWorkTask(selectedValue) {
    const selectedWorkTask = workTaskData.find(
      (workTask) => workTask.taskType === selectedValue
    );

    if (selectedWorkTask) {
      // Perform the deletion logic here, for example, dispatch the delete action
      //  confirmDeleteHandle(selectedWorkTask.id)
      setdeleteItem(selectedWorkTask);
      setshowDelete(true);
      // dispatch(FetchGasWorkTask(selectedTask.id));
    } else {
    }
  }

  function handleDocName(e) {
    setNewDocumentName(e.target.value.trimStart())
    if(e.target.value.trimStart().length ==30){
      setErrorMsg("max length 30")
    }else{
      
      setErrorMsg("")
    }
  }

  // };
  const callbackDelete = () => {
    dispatch(FetchGasWorkTask(selectedTask.id));
    setshowDelete(false);
    setshowDeleteSuccess(true);
  };
  const showWarningFlag = () => {
    setshowWarning(true);
  };

  const confirmDeleteHandle = (id) => {
    dispatch(
      DeleteWorkTask({ id: id, cb: callbackDelete, cb1: showWarningFlag })
    );
    setshowDelete(false);
  };

  const handleAddNewWorkTask = () => {
    // Log the input value or perform any other actions
    // You can also dispatch an action to save the new work task here
    if (
      newWorkTask !== selectedTask.name &&
      !workTaskData.some((task) => task.taskType === newWorkTask)
    ) {
      setTaskType(newWorkTask);
      if (newWorkTask !== selectedTask.name) {
        dispatch(
          AddWorkTask({ taskType: newWorkTask, templateID: selectedTask.id })
        );
        // Reset the input field
        setNewWorkTask("");

        setshowSaveSuccessTask(true);
      }
    } else {
      setErrorMsg("already exist Work Task");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="PM">
        <div className="dropdown-section">
          <Dropdown id="drd_selectedTab"
            className="dropdown"
            options={options}
            value={selectedTab}
            onChange={handleTabChange}
            placeholder="Select a tab"
            dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
          />
          <Dropdown id="drd_taskType"
            options={[
              ...workTasks.map((workTask) => ({
                label: workTask.taskType,
                value: workTask.taskType,
              })),
              {
                label: "Add More",
                value: "Add More",
              },
            ]}
            className="dropdown"
            value={isAddMoreSelected ? "Add More" : selectedTask.name}
            onChange={handleWorkTaskChange}
            placeholder="Select a Work Task"
            dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
            itemTemplate={renderOption}
          />
        </div>
        <hr />
        {isAddMoreSelected ? (
          <>
            <div className="edit-task-name-input">
              {/* <span className="Error-Span">{ErrorMsg}</span> */}
              <input id="txt_PMNewWorkTask"
                type="text"
                value={newWorkTask}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  // Define your character limit
                  const characterLimit = 30; // Replace with your desired limit

                  if (inputValue.length <= characterLimit) {
                    // Update the state if the input length is within the limit
                    setNewWorkTask(inputValue.trimStart());
                  }
                }}
                // maxLength={30}
                placeholder="Enter a new work task"
              />
              
              {newWorkTask ? (
                <>
                  <img id="img_PMSave"
                    src={editEnable}
                    className="edit-add"
                    onClick={handleAddNewWorkTask}
                    alt="Save"
                  />
                  <img id="img_PMCancel"
                  className="edit-add"
                    src={cancelIcon}
                     onClick={()=>{setNewWorkTask("")}}
                    alt="Cancel"
                  />
                </>
              ) : (
                <>
                <img id="img_PMEditAdd" className="edit-add" src={editDisable} alt="Save" />
                
                </>
              )}
            </div>
          </>
        ) : (
          <div className="content">
            <div className="selected-work-task">
              {isEditingTaskName ? (
                <div>
                  <input id="txt_PMEditedTaskName"
                    type="text"
                    value={editedTaskName}
                    onChange={(e) =>
                      setEditedTaskName(e.target.value.trimStart())
                    }
                    maxLength={30}
                  />
                  <img id="img_PMeditEnable"
                    src={editEnable}
                    onClick={handleSaveTaskName}
                    alt="Save"
                  />

                  <img id="img_PMCancelIcon"
                    src={cancelIcon}
                    onClick={handleCancelTaskNameEdit}
                    alt="Cancel"
                  />
                </div>
              ) : (
                <>
                  <span>{selectedTask.name}</span>

                  <img id="img_PMEditIcon"
                    src={editIcon}
                    onClick={() => {
                      handleEditTaskName(selectedTask.name);
                    }}
                    alt="Edit"
                  />
                </>
              )}
            </div>
            <span className="Error-Span">{ErrorMsg}</span>
            <div className="title-div"> Applicable Gas Documents</div>
            <ul>
              {doclist.map((doc) => (
                <li key={doc.id}>
                  {editingDocId === doc.id ? (
                    <>
                      <input id="txt_PMEditedDocName"
                        type="text"
                        value={doc.editedDocName}
                        onChange={(e) =>
                          handleEditDocNameChange(
                            doc.id,
                            e.target.value.trimStart()
                          )
                        } // Handle input change
                        maxLength={30}
                      />

                      <input  id="txt_PMEditedDocLink"
                        type="text"
                        value={editedDocLink}
                        onChange={(e) =>
                          handleEditDocLinkChange(e.target.value.trimStart())
                        }
                      />
                      <img id="img_PMSaveEdit"
                        src={editEnable}
                        onClick={() => handleSaveEdit(doc, doc.docName)}
                        alt="Save"
                      />

                      <img id="img_PMCancelEdit"
                        src={cancelIcon}
                        onClick={() => handleCancelEdit(doc)}
                        v
                        alt="Cancel"
                      />
                    </>
                  ) : (
                    <>
                      {/* <span className="li-docname">{doc.docName}</span> */}
                      <span className="li-docname">
                        <a
                        id={`hyperLink_${doc.docName}`}
                          href={doc.docLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {doc.docName}
                        </a>
                      </span>
                      <img id="img_PMEditDoc"
                        src={editIcon}
                        onClick={() => handleEdit(doc)}
                        alt="Edit"
                      />

                      <img id="img_PMDelete"
                        src={trash}
                        onClick={() => handleDelete(doc.id, doc.docName)}
                        alt="Delete"
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* <CustomStyledDropdown/> */}

        {isAddingDocument ? (
          <div className="add-new-doc">
            <div className="add-document-input">
              <input id="txt_PMNewDocumentName"
                type="text"
                value={newDocumentName}
                // onChange={(e) => setNewDocumentName(e.target.value.trimStart())}
                onChange={(e)=>{handleDocName(e)}}

                placeholder="Enter document name"
                maxLength={30}
              />

              <input id="txt_PMNewDocumentLink"
                type="text"
                value={newDocumentLink}
                onChange={(e) => setnewDocumentLink(e.target.value.trimStart())}
                placeholder="Enter document Hyperlink"
              />

              <img id="img_PMSaveDocs" src={editEnable} onClick={handleSaveDocument} alt="Save" />

              <img id="img_PMCancelDocs"
                src={cancelIcon}
                onClick={handleCancelAddDocument}
                alt="Cancel"
              />
            </div>
            <div className="footer">
              <button id="btn_PMDocument"
                className="new-equipment-button"
                onClick={handleAddDocument}
              >
                + Document
              </button>
            </div>
          </div>
        ) : (
          <div className="footer">
            <button id="btn_PMDocs"
              className="new-equipment-button"
              onClick={handleAddDocument}
              disabled={isAddMoreSelected}
            >
              + Document
            </button>
          </div>
        )}
        <>
          <ModalWrapper
            visible={showSaveSuccessTask}
            // onDismiss={() => {
            //   showSavePopUp();
            // }}
          >
            <SaveSuccess
              closePopup={() => {
                setshowSaveSuccessTask(false);
              }}
              heading="Procedure Mapping"
              content={taskType}
            />
          </ModalWrapper>
          <ModalWrapper
            visible={showSaveSuccess}
            // onDismiss={() => {
            //   showSavePopUp();
            // }}
          >
            <SaveSuccess
              closePopup={() => {
                setshowSaveSuccess(false);
              }}
              heading="Procedure Mapping"
              // tempName ={editedData.appNumber}
              content={saveItem}
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showDeleteDoc}
            onDismiss={() => {
              setshowDeleteDoc(false);
            }}
          >
            <DeletePopUp
              closePopup={() => {
                setshowDeleteDoc(false);
              }}
              deleteValue={() => confirmDeleteDoc()}
              heading="Procedure Mapping"
              content={deleteDocId?.name} // Use the 'content' property of the 'deleteItem' object
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showDeleteSuccessDoc}
            onDismiss={() => {
              setshowDelete(false);
              setshowDeleteSuccessDoc(false);
            }}
          >
            <DeleteSuccess
              closePopup={() => {
                setshowDeleteDoc(false);
                setshowDeleteSuccessDoc(false);
              }}
              heading="Procedure Mapping"
              content={deleteDocId?.name}
            />
          </ModalWrapper>
          <ModalWrapper
            visible={showDelete}
            onDismiss={() => {
              setshowDelete(false);
            }}
          >
            <DeletePopUp
              closePopup={() => {
                setshowDelete(false);
              }}
              deleteValue={() => confirmDeleteHandle(deleteItem?.id)}
              heading="Procedure Mapping"
              content={deleteItem?.taskType} // Use the 'content' property of the 'deleteItem' object
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showDeleteSuccess}
            onDismiss={() => {
              setshowDelete(false);
              setshowDeleteSuccess(false);
            }}
          >
            <DeleteSuccess
              closePopup={() => {
                setshowDelete(false);
                setshowDeleteSuccess(false);
              }}
              heading="Procedure Mapping"
              content={deleteItem?.taskType} // Use the 'content' property of the 'deleteItem' object
            />
          </ModalWrapper>

          <ModalWrapper
            visible={showWarning}
            onDismiss={() => {
              setshowWarning(false);
            }}
          >
            <WarnPopup
              closePopup={() => {
                setshowWarning(false);
              }}
            />
          </ModalWrapper>
        </>
      </div>
    </>
  );
};

export default ProcedureMapping;
