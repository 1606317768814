import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
};

const loaderSlice = createSlice({
  name: "save",
  initialState,
  reducers: {
    setLoader: (state, action) => {
        state.isLoading = action.payload;
    },
    // resetSave: (state) => {
    //     state.savedValue = true;
    // },
},
});

export const { setLoader } = loaderSlice.actions;
export default loaderSlice.reducer;