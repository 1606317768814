import { createSlice } from "@reduxjs/toolkit";
import { fetchCriticalContactDetails, deleteCriticalContactDetails, addCriticalContactDetails, updateCriticalContactDetails } from "../../Service/CriticalContacts";

const initialState = {
  criticalContactData: [],
  loading: false,
};

const criticalContactSlice = createSlice({
  name: "criticalContact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCriticalContactDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchCriticalContactDetails.fulfilled, (state, action) => {
      state.criticalContactData = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(fetchCriticalContactDetails.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });
    builder.addCase(deleteCriticalContactDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCriticalContactDetails.fulfilled, (state, action) => {
      state.criticalContactData = state.criticalContactData.filter((item) => item.contactId !== action.payload)
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteCriticalContactDetails.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });
    builder.addCase(addCriticalContactDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addCriticalContactDetails.fulfilled, (state, action) => {
      state.criticalContactData.push(action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(addCriticalContactDetails.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });
    builder.addCase(updateCriticalContactDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateCriticalContactDetails.fulfilled, (state, action) => {
      let arr = [...state.criticalContactData]
      const index = arr.findIndex((value) => value.contactId === action.payload.contactId);
      arr[index] = action.payload
      state.criticalContactData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateCriticalContactDetails.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });
  },
});

export default criticalContactSlice.reducer;