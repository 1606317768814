import React from 'react'
import mainLogo from "../../Assets/Images/whitelogo.png"
import './headerLogo.scss'
import { Link } from 'react-router-dom'

function HeaderLogo() {
  return (
    <Link to={"/"}><img id="img_headerLogo" src={mainLogo} alt="Logo"/></Link>
  )
}

export default HeaderLogo
