import { createSlice } from '@reduxjs/toolkit';
import { fetchppedetails,addppedetails, deleteppedetails, updateppedetails } from '../../Service/PPE';

const initialState = {
    ppeData: [],
    loading: false,
  };
export const ppeSlice = createSlice({
    name: 'ppe',
    initialState,
      reducers: {},
      extraReducers: (builder) => {
          builder.addCase(fetchppedetails.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(fetchppedetails.fulfilled, (state, action) => {
              state.ppeData = action.payload            
              state.loading = false
              state.error = ''
          })
          builder.addCase(fetchppedetails.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(deleteppedetails.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(deleteppedetails.fulfilled, (state, action) => {
              state.ppeData = state.ppeData.filter((item) => item.id !== action.payload)            
              state.loading = false
              state.error = ''
          })
          builder.addCase(deleteppedetails.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(addppedetails.pending, (state) => {
              state.loading = true
              state.error = ''
          })

          builder.addCase(addppedetails.fulfilled, (state, action) => {
              state.ppeData.push(action.payload);
              state.loading = false
              state.error = ''
          })
          builder.addCase(addppedetails.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(updateppedetails.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(updateppedetails.fulfilled, (state, action) => {
              let arr = [...state.ppeData]
              const index = arr.findIndex((value) => value.id === action.payload.id);
              arr[index] = action.payload
              state.ppeData = arr;
              state.loading = false
              state.error = ''
          })
          builder.addCase(updateppedetails.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
      },
  })
  
  export default ppeSlice.reducer