import React, { useState, useEffect } from "react";
import "./input.scss";
import "primeicons/primeicons.css";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import {
  FetchTemplates,
  UpdateTemplate,
  FetchPublishTemplate
} from "../../../Service/TemplateManagement";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../GenericPopup";
import SaveSuccess from "../../GenericPopup/saveSuccess";

const DataInput = (props) => {
  const [showField, setShowField] = useState(true);
  const [editField, setEditField] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [checkEnabled, setCheckEnabled] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [displaydata, setDisplaydata] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.value) {
      setInputValue(props?.value?.fieldDisplayName);
    }
  }, [props]);

  function showSavePopUp(data) {
    setDisplaydata(data);
    setShowSuccess((pre) => !pre);
  }

  const onEdit = () => {
    setShowField(false);
    setEditField(true);
  };

  const onCLose = (value) => {
    setShowField(true);
    setEditField(false);
    setInputValue(value?.fieldDisplayName);
    setCheckEnabled(false);
  };

  const handleEdit = (newvalue) => {
    let val = newvalue;
    let name = props.value.fieldInternalName;
    let reqbody = {
      fieldinternalName: name,
      fieldDisplayName: val,
    };
    dispatch(UpdateTemplate({ reqbody, saveSuccess: showSavePopUp, callbackFn: handleSave }));
    setShowField(true);
    setEditField(false);
    setCheckEnabled(false);
  };

  const handleSave = (success) => {
    if (success) {
      dispatch(FetchTemplates(dispatch));
      dispatch(FetchPublishTemplate(dispatch));
    }
  };

  const editInput = (e) => {
    let val = e.target.value.trimStart();
    // regex allowed are alphabets, numbers, ', *, (), #, -, /, ., @, &, _, =, +, comma, <, >, ?, : and white space.
    const regex = /^[a-zA-Z0-9'*?()\#\-"\//.\s\@\&\_\=\+\,\<\>\: ]*$/
    if (regex.test(val)) {
      setInputValue(val);
      setCheckEnabled(true);
    }
  };

  

  const { value, index } = props;
  return (
    <>
      {showField && (
        <div className="input-content" key={index}>
          <input id="txt_inputText"
            className="input-text"
            value={inputValue}
            type="text"
            readOnly
          />
          <img id="img_editIco"
            src={editIcon}
            className="edit-icon"
            onClick={() => onEdit()}
          ></img>
        </div>
      )}
      {editField && (
        <div className="edit-content" key={index}>
          <div className="temp-input">
            <input id="txt_editInput"
              className="edit-input"
              value={inputValue}
              type="text"
              maxLength="200"
              onChange={(e) => editInput(e)}
            />
            {inputValue.length === 200 && (
              <p className="temp-error">
                *Maximum entered characters should not exceed 200.
              </p>
            )}
          </div>
          <span>
            <img id="img_canceIco"
              src={cancelIcon}
              className="cancel-icon"
              onClick={() => onCLose(value)}
            ></img>
          </span>
          <span>
            {checkEnabled && inputValue.length > 0 ? (
              <img id="img_editEnabl"
                src={editEnable}
                className="edit-enable"
                onClick={() => handleEdit(inputValue)}
              ></img>
            ) : (
              <img id="img_editDis" src={editDisable} className="edit-disable"></img>
            )}
          </span>
        </div>
      )}

      <ModalWrapper
        visible={showSuccess}
        onDismiss={() => {
          showSavePopUp();
        }}
      >
        <SaveSuccess closePopup={() => { showSavePopUp(); }} heading="Template Management" content={displaydata} />
      </ModalWrapper>
    </>
  );
};

export default DataInput;
