import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import './popup.scss';

const ModalWrapper = ({ visible, onDismiss, children }) => {
    return (
        <div className='modalOuterWrapper'>
            <Dialog className='modalWrapper' visible={visible} onHide={onDismiss}>
                {children}
            </Dialog>
        </div>
    )
}

export default ModalWrapper;
        
