export const EndPoints = {
  baseUrl: " http://localhost:37008",
  hyperlink: "/Resources/FetchResources",
  addHyperLink: "/Resources/AddResources",
  updateHyperlink: "/Resources/UpdateResources",
  deleteHyperlink: "/Resources/DeleteResources",
  criticalContacts: "/CriticalContact/FetchCriticalContacts",
  addContacts: "/CriticalContact/AddCriticalContacts",
  updateContacts: "/CriticalContact/UpdateCriticalContacts",
  deleteCriticalContacts: "/CriticalContact/DeleteCriticalContacts",
  notification: "/Notification/FetchNotifications",
  addNotification: "/Notification/AddNotifications",
  deleteNotification: "/Notification/DeleteNotification",
  updateNotification: "/Notification/UpdateNotification",
  configuration: "/Configuration/FetchConfiguration",
  updateConfiguration: "/Configuration/UpdateConfiguration",
  fetchUploadFile: "UploadFile/FetchUploadFile",
  emergencyEquipments: "/EmergencyEquipment/FetchEmergencyEquipment",
  addEquipments: "/EmergencyEquipment/AddEmergencyEquipment",
  updateEquipments: "/EmergencyEquipment/UpdateEmergencyEquipment",
  deleteEquipments: "/EmergencyEquipment/DeleteEmergencyEquipment",
  mutcdPlans: "/MUTCDPlan/FetchMUTCDPlan",
  updateMutcdPlans: "/MUTCDPlan/UpdateMUTCDPlan",
  deleteMutcdPlan: "/MUTCDPlan/DeleteMUTCDPlan",
  //  procedureMapping:"/api/GasWorkTask?taskType=PIPs and Uprates&templateId=5",
  // procedureMapping:"/api/GasTaskDocuments/GetGasTaskDocumentByTemplateIdAndTaskType?taskType=Service Install&templateId=4",
  procedureMapping:
    "/api/GasTaskDocuments/GetGasTaskDocumentByTemplateIdAndTaskType",
  addDoc: "/api/GasTaskDocuments/AddGasTaskDocuments",
  updateDocList: "/api/GasTaskDocuments/UpdateGasTaskDocuments",
  deleteDocList: "/api/GasTaskDocuments/DeleteGasTaskDocuments",
  fetchppes: "/PpeDetails/FetchPPEDetails",
  addppes: "/PpeDetails/AddPPEDetails",
  updateppes: "/PpeDetails/UpdatePPEDetails",
  deleteppes: "/PpeDetails/DeletePPEDetails",
  // /api/GasTaskDocuments/GetGasTaskDocumentByTemplateIdAndTaskType?taskType=Service Install&templateId=4
  GasWorkTask: "/api/GasWorkTask/GetGasWorkTaskByTemplateId",
  fetchPotentialAoc: "/api/AocDetails/GetAllAOCDetails",
  addPotentialAoc: "/api/AocDetails/AddAOCDetails",
  deletePotentialAoc: "/api/AocDetails/DeleteAOCDetails",
  updatePotentialAoc: "/api/AocDetails/UpdateAOCDetails",

  FetchSwiHazardMessaging : "/api/SwiHazardMessaging/FetchSwiHazardMessagingDetails",
  updateSwiHazardMessaging : "/api/SwiHazardMessaging/UpdateSwiHazardMessagingDetails",
  deleteSwiHazardMessaging : "/api/SwiHazardMessaging/DeleteSwiHazardMessagingDetails",
  
  fetchHazardousEnergiesControlsSwiMessaging : "/api/GasHazardEnergyControlMessaging/FetchGasHazardEnergyControlMessagingDetails",
  updateSwiHECMessaging: "/api/GasHazardEnergyControlMessaging/UpdateGasHazardEnergyControlMessagingDetails",
  deleteSwiHECMessaging: "/api/GasHazardEnergyControlMessaging/DeleteGasHazardEnergyControlMessagingDetails",

  updateWorkTask: "/api/GasWorkTask/UpdateGasWorkTask/",
  deleteWorkTask: "/api/GasWorkTask/DeleteGasWorkTask",
  addWorkTask: "api/GasWorkTask/AddGasWorkTask",
  hec: "Hec/GetHighEnergies",
  hecSave: "Hec/SaveHighEnergy",
  hecUpdate: "Hec/UpdateHighEnergy",
  hecDelete: "Hec/DeleteHighEnergy",
  hecData: "Hec/GetHighEnergyDetails",
  hecSaveFile: "Hec/SaveFile",
  hecDeleteFile: "Hec/DeleteFile",
  hecSaveHighEnergyTask: "Hec/SaveHighEnergyTask",
  hecUpdateHighEnergyTask: "Hec/UpdateHighEnergyTask",
  hecDeleteHighEnergyTask: "Hec/DeleteHighEnergyTask",
  hecSaveHighEnergyHazard: "Hec/SaveHighEnergyHazard",
  hecUpdateHighEnergyHazard: "Hec/UpdateHighEnergyHazard",
  hecDeleteHighEnergyHazard: "Hec/DeleteHighEnergyHazard",
  hecSaveHighEnergyDirectControlsAndSafeGuard:
    "Hec/SaveHighEnergyDirectControlsAndSafeGuard",
  hecUpdateHighEnergyDirectControlsAndSafeGuard:
    "Hec/UpdateHighEnergyDirectControlsAndSafeGuard",
  hecDeleteHighEnergyDirectControlsAndSafeGuard:
    "Hec/DeleteHighEnergyDirectControlsAndSafeGuard",
  hecDeleteHighEnergyTaskHazardDirectControls:
    "Hec/DeleteHighEnergyTaskHazardDirectControls",
  logInformation: "Logger/LogInformation",
  logError: "Logger/LogError",
  //SWI Mapping
  fetchSWIMapping: "/Swi/FetchSWIMapping",

  swiAddJob: "/Swi/AddSWIJob",
  updateSWIJob: "/Swi/UpdateSWIJob",

  swiAddTask: "/Swi/AddSWITask",
  updateSWITask: "/Swi/UpdateSWITask",
  swiDeleteTask: "/Swi/DeleteSWITask",

  addSWIHighEnergyTask: "/Swi/AddSWIHighEnergyTask",
  upadteSWIHighEnergyTask: "/Swi/UpdateSWIHighEnergyTask",
  deleteSWIHighEnergyTask: "/Swi/DeleteSWIHighEnergyTask",

  addSWIIcon: "/Swi/AddSWIIcon",
  updateSWIIcon: "/Swi/UpdateSWIIcon",
  deleteSWIIcon: "/Swi/DeleteSWIIcon",

  addSWIHazard: "/Swi/AddSWIHazard",
  updateSWIHazard: "/Swi/UpdateSWIHazard",
  deleteSWIHazard: "/Swi/DeleteSWIHazard",

  addDirectControlSafeguards: "/Swi/AddDirectControlSafegaurd",
  updateDirectControlSafeguards: "/Swi/UpdateDirectControlSafegaurd",
  deleteDirectControlSafeguards: "/Swi/DeleteDirectControlSafegaurd",

  searchSwiIcon: "/Swi/SearchSwiIcon",
  searchHighEnergyTask: "/Swi/SearchHighEnergyTask",
  searchHazard: "/Swi/SearchHazard",
  searchDirectControl: "/Swi/SearchDirectControl",
  searchSafeGuard: "/Swi/SearchSafeGuard",
  fetchTemplate: "/TemplateManagement/FetchTemplateManagement",
  updateTemplate: "/TemplateManagement/UpdateTemplateManagement",
  fetchPublishTemplate: "/PublishTemplate/FetchPublishTemplate",
  addPublishTemplate: "/PublishTemplate/SavePublishStatus",
  fetchPublishStatus: "/PublishTemplate/FetchPublishStatus",

};
