import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    savedValue: true,
    saveCode: '',
    saveMessage: '',
};

const saveSlice = createSlice({
  name: "save",
  initialState,
  reducers: {
    setSave: (state, action) => {
        state.savedValue = action.payload;
        // state.saveCode = action.payload.errorCode;
        // state.saveMessage = action.payload.errorMessage;
    },
    resetSave: (state) => {
        state.savedValue = true;
        // state.saveCode = '';
        // state.saveMessage = '';
    },
},
});

export const { setSave, resetSave } = saveSlice.actions
export default saveSlice.reducer;