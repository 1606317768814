import { callLoggerError, callLoggerInfo } from ".";
import { fileUploadInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";

export const fetchImages = async (filePath, thunkApi) => {
    try {
      callLoggerInfo({
        screenName: "All",
        actionName: "Fetch images",
        message: { filePath: filePath },
        thunkApi: thunkApi,
      });
  
      let res = await fileUploadInstance.post(EndPoints.fetchUploadFile, {
        filePath: filePath,
      });
      if (res?.data?.data?.streamData) {
        callLoggerInfo({
          screenName: "All",
          actionName: "Fetch images",
          message: res?.data,
          thunkApi: thunkApi,
        });

        return res.data?.data?.streamData;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "All",
          actionName: "Fetch images",
          thunkApi: thunkApi,
        });
  
        return null;
      }
    } catch (error) {
      let errorCode = error?.response?.status || error?.code;
      let errorMessage = error?.response?.statusText || "Sorry, unexpected error!";
  
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "All",
        actionName: "Fetch images",
        thunkApi: thunkApi,
      });
    }
  };
  