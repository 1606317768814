import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Layouts";
// import Dashboard from "../Pages/Dashboard";
import NoMatch from "../Pages/NotFound";
import SideBar from "../Components/Sidebar";
import ErrorPage from "../Components/Error";
import Hyperlink from "../Components/Hyperlink";
import Configuration from "../Components/Configuration";
import Notification from "../Components/Notification";
import Lookup from "../Components/Lookupmanagement";
import Template from "../Components/Templatemanagement";
import AccessDenied from "../Layouts/AccessDenied";
import Publish from "../Components/Publishmanagement";

function ProtectedRoute({ role }) {
  if (role === "admin") {
    return (
      <SideBar>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* <Route index element={<Dashboard />} /> */}
            <Route path="error-page" element={<ErrorPage />} />
            <Route path="*" element={<NoMatch />} />
            <Route index element={<Hyperlink />} />
            <Route path="configuration" element={<Configuration />} />
            <Route path="notification" element={<Notification />} />
            <Route path="lookup" element={<Lookup />} />
            <Route path="template" element={<Template />} />
            <Route path="publish" element={<Publish />} />
          </Route>
        </Routes>
      </SideBar>
    );
  } else {
    return <AccessDenied />;
  }

}

export default ProtectedRoute;
