import axios from 'axios';

const CustomAxios = (dynamicBaseURL) => {

    const axiosInstance = axios.create({
        baseURL: dynamicBaseURL,
        headers: {
            'Content-Type': 'application/json',  ///add common headers
        }
    });

    // request interceptor
    axiosInstance.interceptors.request.use(
        (config) => {
            // Do something before request is sent  
            return config;
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error);
        });


    // response interceptor
    axiosInstance.interceptors.response.use(
        (response) => {
            // Do something with response data
            return response;
        },
        (error) => {
            // api error handling
            return Promise.reject(error);
        });

    return axiosInstance;

};

export default CustomAxios;