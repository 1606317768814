import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

export const FetchPotentialAOC = createAsyncThunk(
  "/PotentialAOC/FetchPotentialAOC",
  async (dispatch, thunkAPI) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Potential AOC List",
        actionName: "Get Potential AOC List",
        message: "Request - Potential AOC List",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.get(EndPoints.fetchPotentialAoc);
      if (res?.data?.data && res.data.statusCode === 200) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Potential AOC List",
          actionName: "Get Potential AOC List",
          message: res.data,
          thunkApi: thunkAPI,
        });
        return res.data.data;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Potential AOC List",
          actionName: "Get Potential AOC List",
          thunkApi: thunkAPI,
        });
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Potential AOC List",
        actionName: "Get Potential AOC List",
        thunkApi: thunkAPI,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const AddPotentialAOC = createAsyncThunk(
  "/PotentialAOC/addPotentialAOC",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Potential AOC List",
        actionName: "Add Potential AOC List",
        message: "Request - Potential AOC List",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.post(EndPoints.addPotentialAoc, args);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Potential AOC List",
          actionName: "Add Potential AOC List",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        args.saveSuccess(args?.aocName);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Potential AOC List",
          actionName: "Add Potential AOC List",
          thunkApi: thunkAPI,
        });
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage = error?.response?.message || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Potential AOC List",
        actionName: "Add Potential AOC List",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const UpdatePotentialAOC = createAsyncThunk(
  "/PotentialAOC/UpdatePotentialAOC",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Potential AOC List",
        actionName: "Update Potential AOC List",
        message: "Request - Potential AOC List",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.put(
        `${EndPoints.updatePotentialAoc}?AOCId=${args.id}`,
        args.reqbody
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Potential AOC List",
          actionName: "Update Potential AOC List",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        args.saveSuccess(args?.reqbody?.aocName);
        args.callbackFn(true);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.statusCode,
          screenName: "Potential AOC List",
          actionName: "Update Potential AOC List",
          thunkApi: thunkAPI,
        });
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage = error?.response?.message || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Potential AOC List",
        actionName: "Update Potential AOC List",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const DeletePotentialAOC = createAsyncThunk(
  "/PotentialAOC/DeletePotentialAOC",
  async ({ id, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Potential AOC List",
        actionName: "Delete Potential AOC List",
        message: "Request - Potential AOC List",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.delete(
        `${EndPoints.deletePotentialAoc}?id=${id}`
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Potential AOC List",
          actionName: "Delete Potential AOC List",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        deleteSuccess(id);
        return id;
      } else {
        thunkAPI.dispatch(setLoader(false));
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Potential AOC List",
          actionName: "Delete Potential AOC List",
          thunkApi: thunkAPI,
        });
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage = error?.response?.message|| "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Potential AOC List",
        actionName: "Delete Potential AOC List",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
