  import { createAsyncThunk } from "@reduxjs/toolkit";
  import { sharedInstance,  } from "../../Api";
  import { EndPoints } from "../../Api/ApiRelativeUrls";
  import { HandleError } from "../../Utils/handleError";
  import { setLoader } from "../../Store/loaderSlice";


  export const FetchProcedureMapping = createAsyncThunk(
    "fetchProcedureMapping",
    async (args,thunkAPI ) => {
      
      if (args.id === undefined || args.name === null) {
      
        // Handle the case where either Id or type is undefined, e.g., return an empty array or show an error.
        return [];
      }
      try {
        thunkAPI.dispatch(setLoader(true));
        // dispatch(setLoader(true));
        const encodedTaskType = encodeURIComponent(args.name);
        // let res = await sharedInstance.get(`${EndPoints.procedureMapping}?Id=${Id}`);
        let res = await sharedInstance.get(`${EndPoints.procedureMapping}?taskType=${encodedTaskType}&templateId=${args.id}`);
        // let res = await sharedInstance.get(`${EndPoints.procedureMapping}`);

        if (res?.data?.data && res.data.statusCode === 200 ||res.data.statusCode === 204) {
          thunkAPI.dispatch(setLoader(false));
          return res.data.data;

        } else {
          throw Error();
        }
      } catch (error) {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = error?.response?.status;
        let errorMessage =
          error?.response?.message || "Oops, Something went wrong";
         
        HandleError( thunkAPI.dispatch,errorCode, errorMessage);
      }
    }
  );

  export const AddDocument = createAsyncThunk(
    "addDocument",
    async (doc, thunkAPI) => {
      try {
        thunkAPI.dispatch(setLoader(true));
        let res = await sharedInstance.post(EndPoints.addDoc, doc);
        
        if (res?.data?.data || res?.data?.statusCode === 200) {
          thunkAPI.dispatch(setLoader(false));
          return res?.data?.data;
        } else {
          thunkAPI.dispatch(setLoader(false));

          let errorCode = res?.data?.statusCode;
          let errorMessage = res?.data?.message || "Oops, Something went wrong";
          HandleError(thunkAPI.dispatch, errorCode, errorMessage);
        }
      } catch (error) {
       
      }
    }
  );
 

export const UpdateDocList = createAsyncThunk(
  "UpdateDocList",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));

      let res = await sharedInstance.put(`${EndPoints.updateDocList}?docId=${args.reqBody.docId}`, args.reqBody);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        args.cb()
        thunkAPI.dispatch(setLoader(false));
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false)); 

        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {}
  }
);


export const DeleteDocList = createAsyncThunk(
  "DeleteDocList",
  async ( id , thunkAPI) => { 

    try {
      thunkAPI.dispatch(setLoader(true));

      let res = await sharedInstance.delete(
        `${EndPoints.deleteDocList}?docId=${id}`
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        // return id;
        thunkAPI.dispatch(setLoader(false));

        // deleteSuccess(id);
        return {id,statusCode: res.status};
      }else if( res?.data?.statusCode === 500) {
        thunkAPI.dispatch(setLoader(false));

        return {statusCode: res?.data?.statusCode};
      }
       else {
        thunkAPI.dispatch(setLoader(false));

        let errorCode = res?.data?.statusCode;
        // let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode);
      }
    } catch (error) {}
  }
);

export const FetchGasWorkTask = createAsyncThunk(
  "fetchGasWorkTask",
  async (tempId,thunkAPI ) => {
  
    if (tempId === undefined ) {
      // Handle the case where either Id or type is undefined, e.g., return an empty array or show an error.
      return [];
    }
    try {
      thunkAPI.dispatch(setLoader(true));


     let res = await sharedInstance.get(`${EndPoints.GasWorkTask}?templateId=${tempId}`);

      if (res?.data?.data && res.data.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));

        return res.data.data;
      } else {
        thunkAPI.dispatch(setLoader(false));

        throw Error();
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));

      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError( thunkAPI.dispatch,errorCode, errorMessage);
    }
  }
);
export const AddWorkTask = createAsyncThunk(
  "addWorkTask",
  async (workTask, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));

      let res = await sharedInstance.post(EndPoints.addWorkTask, workTask);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));

        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));

        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
    }
  }
);

export const UpdateWorkTask = createAsyncThunk(
  "UpdateDocList",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));

      let res = await sharedInstance.put(`${EndPoints.updateWorkTask}?workTaskId=${args.workTaskId}`, args);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));

        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));

        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {}
  }
);

export const DeleteWorkTask = createAsyncThunk(
  "DeleteWorkTask",
  async ( {id,cb,cb1} , thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));

     let res = await sharedInstance.delete(
       `${EndPoints.deleteWorkTask}?Id=${id}`
       );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));

        cb();
        // if (res?.data?.statusCode === 500) {  
        //   // Handle the internal server error (status code 500)
        //    alert('Internal server error: This item is not deletable.');
        // 
        // return id;

        // deleteSuccess(id);
        return {id,statusCode: res.status};
      }else if( res?.data?.statusCode === 500) {
        thunkAPI.dispatch(setLoader(false));

        cb1()
        //  alert('Internal server error: This item is not deletable.');
        return {statusCode: res?.data?.statusCode};
      }
       else {
        thunkAPI.dispatch(setLoader(false));

        let errorCode = res?.data?.statusCode;
        // let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode);
      }
    } catch (error) {
      

    }
  }
);