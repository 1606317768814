import React from "react";
import successLogo from "../../Assets/Images/tick.png";
import { Button } from "primereact/button";
import "./commonSavePopup.scss";

function DeleteSuccess({ closePopup, heading, content, header }) {
  console.log("deleteConfirm");
  return (
    <div className="success-popup">
      <div className="successFormWrapperInner">
      <div className="grid-container">
        <img id="img_successLogo" src={successLogo} className="successLogo" />
        <div className="text-container-">
        <p className="successTitleHead"> {heading}!</p>
        <p className="successTitle">You have successfully deleted {content}.</p>
        </div>
        </div>

        <div className="popupButtonWrapper">
          <Button id="btn_PopUpClose"
            className="close-button"
            label="Close"
            onClick={closePopup}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteSuccess;
