import React, { useState, useEffect, useRef } from "react";
import "./hec.scss";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import {
  getHighEnergyData,
  getHighEnergies,
  saveHighEnergy,
  updateHighEnergy,
  deleteHighEnergy,
  saveFile,
  deleteFile,
  saveHighEnergyTask,
  updateHighEnergyTask,
  deleteHighEnergyTask,
  saveHighEnergyHazard,
  updateHighEnergyHazard,
  deleteHighEnergyHazard,
  saveHighEnergyDirectControlsAndSafeGuard,
  updateHighEnergyDirectControlsAndSafeGuard,
  deleteHighEnergyDirectControlsAndSafeGuard,
  deleteHighEnergyTaskHazardDirectControls,
} from "../../../Service/HEC";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../GenericPopup";
import DeletePopup from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/deleteSuccess";
import SaveSuccess from "../../GenericPopup/saveSuccess";
import WarnPopup from "../../GenericPopup/warningPopup";
import trash from "../../../Assets/Images/trash.png";
import Loader from "../../Loader";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import defImage from "../../../Assets/Images/default_image.png";
import WarningMessagePopup from "../../GenericPopup/warningMessage";

const HEC = () => {
  const op = useRef(null);
  const opTask = useRef(null);
  const opHazard = useRef(null);
  const opDirectControl = useRef(null);
  const opSafeGuard = useRef(null);
  const [show, setShow] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [hecInputError, setHecInputError] = useState("");

  //#region [Constants of High Energy]
  const [highEnergy, setHighEnergy] = useState("");
  const [highEnergyId, setHighEnergyId] = useState(0);
  const [highEnergiesSectionEnable, setHighEnergiesSectionEnable] =
    useState(true);
  const [addHighEnergySectionEnable, setAddHighEnergySectionEnable] =
    useState(false);
  const [editHighEnergySectionEnable, setEditHighEnergySectionEnable] =
    useState(false);
  const [newHighEnergyName, setNewHighEnergyName] = useState("");
  const [highEnergyNameDuplicate, setHighEnergyNameDuplicate] = useState(false);
  const [modifiedHighEnergyName, setModifiedHighEnergyName] = useState("");

  const [deleteHighEnergyId, setDeleteHighEnergyId] = useState(0);
  const [deleteHighEnergyName, setDeleteHighEnergyName] = useState("");

  const [showSaveHighEnergySuccessModal, setShowSaveHighEnergySuccessModal] =
    useState(false);
  const [
    showUpdateHighEnergySuccessModal,
    setShowUpdateHighEnergySuccessModal,
  ] = useState(false);
  const [
    showDeleteHighEnergyConfirmModal,
    setShowDeleteHighEnergyConfirmModal,
  ] = useState(false);
  const [
    showDeleteHighEnergySuccessModal,
    setShowDeleteHighEnergySuccessModal,
  ] = useState(false);
  //#endregion [Constants of High Energy]

  //#region [Constants of High Energy Icon]
  const [iconId, setIconId] = useState(0);
  const [uploadIconEnable, setUploadIconEnable] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [uploadIconCheckEnabled, setUploadIconCheckEnabled] = useState(false);

  const [showSaveIconSuccessModal, setShowSaveIconSuccessModal] =
    useState(false);
  const [showDeleteIconConfirmModal, setShowDeleteIconConfirmModal] =
    useState(false);
  const [showDeleteIconSuccessModal, setShowDeleteIconSuccessModal] =
    useState(false);
  //#endregion [Constants of High Energy Icon]

  //#region [Constants of High Energy Task]
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskEnable, setNewTaskEnable] = useState(false);

  const [taskId, setTaskId] = useState(0);
  const [editTaskEnable, setEditTaskEnable] = useState(false);
  const [editTaskSubmitReady, setEditTaskSubmitReady] = useState(false);
  const [editTaskName, setEditTaskName] = useState("");
  const [taskNameDuplicate, setTaskNameDuplicate] = useState(false);

  const [deleteTaskId, setDeleteTaskId] = useState(0);
  const [deleteTaskName, setDeleteTaskName] = useState("");

  const [taskNameSuccessModal, setTaskNameSuccessModal] = useState("");
  const [showSaveTaskSuccessModal, setShowSaveTaskSuccessModal] =
    useState(false);
  const [showDeleteTaskConfirmModal, setShowDeleteTaskConfirmModal] =
    useState(false);
  const [showDeleteTaskSuccessModal, setShowDeleteTaskSuccessModal] =
    useState(false);
  //#endregion [Constants of High Energy Task]

  //#region [Constants of High Energy Hazard]
  const [newHazardName, setNewHazardName] = useState("");
  const [newHazardEnable, setNewHazardEnable] = useState(false);

  const [hazardId, setHazardId] = useState(0);
  const [editHazardEnable, setEditHazardEnable] = useState(false);
  const [editHazardSubmitReady, setEditHazardSubmitReady] = useState(false);
  const [editHazardName, setEditHazardName] = useState("");
  const [hazardNameDuplicate, setHazardNameDuplicate] = useState(false);

  const [deleteHazardId, setDeleteHazardId] = useState(0);
  const [deleteHazardName, setDeleteHazardName] = useState("");

  const [hazardNameSuccessModal, setHazardNameSuccessModal] = useState("");
  const [showSaveHazardSuccessModal, setShowSaveHazardSuccessModal] =
    useState(false);
  const [showDeleteHazardConfirmModal, setShowDeleteHazardConfirmModal] =
    useState(false);
  const [showDeleteHazardSuccessModal, setShowDeleteHazardSuccessModal] =
    useState(false);
  //#endregion [Constants of High Energy Hazard]

  //#region [Constants of High Energy Direct Control]
  const [newDirectControlName, setNewDirectControlName] = useState("");
  const [newDirectControlEnable, setNewDirectControlEnable] = useState(false);

  const [directControlId, setDirectControlId] = useState(0);
  const [editDirectControlEnable, setEditDirectControlEnable] = useState(false);
  const [editDirectControlSubmitReady, setEditDirectControlSubmitReady] =
    useState(false);
  const [editDirectControlName, setEditDirectControlName] = useState("");
  const [directControlNameDuplicate, setDirectControlNameDuplicate] =
    useState(false);

  const [deleteDirectControlId, setDeleteDirectControlId] = useState(0);
  const [deleteDirectControlName, setDeleteDirectControlName] = useState("");

  const [directControlNameSuccessModal, setDirectControlNameSuccessModal] =
    useState("");
  const [
    showSaveDirectControlSuccessModal,
    setShowSaveDirectControlSuccessModal,
  ] = useState(false);
  const [
    showDeleteDirectControlConfirmModal,
    setShowDeleteDirectControlConfirmModal,
  ] = useState(false);
  const [
    showDeleteDirectControlSuccessModal,
    setShowDeleteDirectControlSuccessModal,
  ] = useState(false);
  //#endregion [Constants of High Energy Direct Control]

  //#region [Constants of High Energy Safe Guard]
  const [newSafeGuardName, setNewSafeGuardName] = useState("");
  const [newSafeGuardEnable, setNewSafeGuardEnable] = useState(false);

  const [safeGuardId, setSafeGuardId] = useState(0);
  const [editSafeGuardEnable, setEditSafeGuardEnable] = useState(false);
  const [editSafeGuardSubmitReady, setEditSafeGuardSubmitReady] =
    useState(false);
  const [editSafeGuardName, setEditSafeGuardName] = useState("");
  const [safeGuardNameDuplicate, setSafeGuardNameDuplicate] = useState(false);

  const [deleteSafeGuardId, setDeleteSafeGuardId] = useState(0);
  const [deleteSafeGuardName, setDeleteSafeGuardName] = useState("");

  const [safeGuardNameSuccessModal, setSafeGuardNameSuccessModal] =
    useState("");
  const [showSaveSafeGuardSuccessModal, setShowSaveSafeGuardSuccessModal] =
    useState(false);
  const [showDeleteSafeGuardConfirmModal, setShowDeleteSafeGuardConfirmModal] =
    useState(false);
  const [showDeleteSafeGuardSuccessModal, setShowDeleteSafeGuardSuccessModal] =
    useState(false);
  //#endregion [Constants of High Energy Safe Guard]

  //#region page load
  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchHighEnergies();
  }, []);

  const highEnergies = useSelector((state) => state?.hec?.highEnergies || []);

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);
  //#endregion page load

  const handleOptionClick = () => {
    setShowOptions(!showOptions);
  };

  const handleTaskHeader = (e) => {};

  const handleHazardHeader = (e) => {};

  const handleDirectControlHeader = (e) => {};

  const handleSafeGuardHeader = (e) => {};

  function showWarnPopup() {
    setShowWarning((pre) => !pre);
  }

  function showWarningMessagePopup() {
    setShowWarningMessage((pre) => !pre);
  }

  //#region [High Energy Operations]

  // fetch high energies
  const fetchHighEnergies = () => {
    dispatch(getHighEnergies(dispatch));
  };

  //handles the change in the high energy drop down
  const handleHighEnergyChange = (value) => {
    let selectedHec = highEnergies.data.filter(
      (data) =>
        data?.highEnergyName.trim().toLowerCase() === value.trim().toLowerCase()
    );
    setHighEnergyId(selectedHec[0]?.highEnergyId);
    setHighEnergy(selectedHec[0]?.highEnergyName);
    if (uploadIconEnable) setUploadIconEnable(false);

    fetchHighEnergyData(selectedHec[0]?.highEnergyId);
    setShow(false);
    setHecInputError("");
  };

  //api call for to fetch high energy data
  const fetchHighEnergyData = (highEnergyId) => {
    dispatch(getHighEnergyData({ highEnergyId }));
  };

  const highEnergyData = useSelector(
    (state) => state?.hec?.highEnergyData || {}
  );

  //handle add high energy button click
  const handleAddHighEnergyClickEvent = (index) => {
    setAddHighEnergySectionEnable(true);
    setHighEnergiesSectionEnable(false);
    setEditHighEnergySectionEnable(false);

    setNewHighEnergyName("");
    setHighEnergyNameDuplicate(false);
    setHecInputError("");
  };

  //handle add high energy close button click
  const handleAddHighEnergyClose = () => {
    setHighEnergiesSectionEnable(true);
    setAddHighEnergySectionEnable(false);
    setHighEnergyNameDuplicate(false);
  };

  // Handle edit high energy click
  const handleEditHighEnergy = () => {
    setModifiedHighEnergyName(highEnergy);
    setHighEnergyNameDuplicate(false);
    setEditHighEnergySectionEnable(true);
    setHighEnergiesSectionEnable(false);
    setAddHighEnergySectionEnable(false);
    setHecInputError("");
  };

  //handle edit high energy close button click
  const handleEditHighEnergyClose = () => {
    setHighEnergiesSectionEnable(true);
    setEditHighEnergySectionEnable(false);
    setHighEnergyNameDuplicate(false);
  };

  //validates new high energy name
  const validateHighEnergyName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setNewHighEnergyName(val);
    }
    setHighEnergyNameDuplicate(false);
  };

  //validates modified high energy name
  const validateModifiedHighEnergyName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setModifiedHighEnergyName(val);
    }
    setHighEnergyNameDuplicate(false);
  };

  //New High Energy creation
  const handleNewHighEnergyName = (highEnergyName) => {
    const isDuplicate = highEnergies.data.some(
      (data) =>
        data?.highEnergyName.trim().toLowerCase() ===
        highEnergyName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      dispatch(
        saveHighEnergy({
          highEnergyName,
          saveSuccess: (status, result) => {
            if (status) {
              fetchHighEnergies();
              showSaveHighEnergyPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
      handleAddHighEnergyClose();
    } else {
      setHighEnergyNameDuplicate(true);
    }
  };

  //Update High Energy
  const handleUpdateHighEnergyName = (modifiedHighEnergyName) => {
    const isDuplicate = highEnergies.data.some(
      (data) =>
        data?.highEnergyName.trim().toLowerCase() ===
        modifiedHighEnergyName.toLowerCase()
    );
    if (!isDuplicate) {
      let iD = highEnergyId;
      let highEnergyName = modifiedHighEnergyName;
      dispatch(
        updateHighEnergy({
          iD,
          highEnergyName,
          saveSuccess: (status, result) => {
            if (status) {
              setHighEnergy(modifiedHighEnergyName);
              fetchHighEnergies();
              showUpdateHighEnergyPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
      handleEditHighEnergyClose();
    } else {
      setHighEnergyNameDuplicate(true);
    }
  };

  // Delete high energy
  const handleDeleteHighEnergy = (highEnergyId) => {
    dispatch(
      deleteHighEnergy({
        highEnergyId,
        deleteSuccess: showDeleteHighEnergySuccessPopup,
      })
    );
    setDeleteHighEnergyId(0);
    setHighEnergy("");
    setHighEnergyId(0);
    fetchHighEnergies();
    setShowDeleteHighEnergyConfirmModal((pre) => !pre);
  };

  function showSaveHighEnergyPopUp(value) {
    setShowSaveHighEnergySuccessModal((pre) => !pre);
  }

  function showUpdateHighEnergyPopUp(value) {
    setShowUpdateHighEnergySuccessModal((pre) => !pre);
  }

  function confirmDeleteHighEnergy(item) {
    if (item != undefined) {
      let selectedHec = highEnergies.data.filter(
        (data) =>
          data?.highEnergyName.trim().toLowerCase() ===
          item.trim().toLowerCase()
      );
      setDeleteHighEnergyId(selectedHec[0]?.highEnergyId);
      setDeleteHighEnergyName(selectedHec[0]?.highEnergyName);
      setShowDeleteHighEnergyConfirmModal((pre) => !pre);
    } else {
      setShowDeleteHighEnergyConfirmModal(false);
    }
  }

  function showDeleteHighEnergySuccessPopup() {
    setShowDeleteHighEnergyConfirmModal(false);
    setShowDeleteHighEnergySuccessModal((pre) => !pre);
  }
  //#endregion [High Energy Operations]

  //#region [High Energy Icon]

  // Handle Icon Upload
  const handleUploadIcon = (item) => {
    setHighEnergyId(item.taskId);
    setIconId(item.id);
    setUploadIconEnable(true);
    setSelectedImageName("");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        if (file.size <= 2e6) {
          setUploadIconCheckEnabled(true);
          setSelectedImage(e.target.files[0]);
          setSelectedImageName(file.name);
        } else {
          setWarningMessage("File size cannot be greater than 2mb");
          setShowWarningMessage((pre) => !pre);
        }
      } else {
        setWarningMessage("Support images with jpeg or png formats");
        setShowWarningMessage((pre) => !pre);
      }
    }
  };

  // Save image icon
  const saveImage = (id, taskId, image) => {
    setUploadIconCheckEnabled(false);
    dispatch(
      saveFile({
        id,
        taskId,
        image,
        saveSuccess: (status, result) => {
          if (status) {
            fetchHighEnergyData(taskId);
            setSelectedImageName("");
            cancelImageSave();
            showSaveIconPopUp(result);
          } else {
            showWarnPopup();
          }
        },
      })
    );
  };

  const cancelImageSave = () => {
    if (highEnergyData.data?.highEnergyIcons?.length > 0) {
      setUploadIconCheckEnabled(false);
      setUploadIconEnable(false);
    } else {
      setUploadIconCheckEnabled(false);
    }
  };

  function showSaveIconPopUp(value) {
    setShowSaveIconSuccessModal((pre) => !pre);
    setUploadIconEnable(false);
    setUploadIconCheckEnabled(false);
    setIconId(0);
  }

  function confirmDeleteIcon(item) {
    if (item != undefined) {
      setIconId(item.id);
      setShowDeleteIconConfirmModal((pre) => !pre);
    } else {
      setShowDeleteIconConfirmModal(false);
    }
  }

  function showDeleteIconSuccessPopup() {
    setShowDeleteIconConfirmModal(false);
    setShowDeleteIconSuccessModal((pre) => !pre);
  }

  //api call for delete icon
  const handleDeleteIcon = (iconId) => {
    dispatch(
      deleteFile({
        iconId,
        deleteSuccess: showDeleteIconSuccessPopup,
      })
    );
    setIconId(0);
    setSelectedImageName("");
    setShowDeleteIconConfirmModal((pre) => !pre);
  };

  //#endregion [High Energy Icon]

  //#region [[ High Energy Task Operations ]]
  //handle add task button click
  const handleAddTaskClickEvent = (index) => {
    setNewTaskEnable(true);
    setHecInputError("");
  };

  //validates new task name
  const validateNewTaskName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setNewTaskName(val);
      if (val.length > 0 && val.length <= 255) setHecInputError("");
      else
        setHecInputError(
          "*Task name must be between 1 and 255 characters long!"
        );
    }

    setTaskNameDuplicate(false);
  };

  //handle new task close button click
  const handleNewTaskClose = () => {
    setNewTaskName("");
    setNewTaskEnable(false);
    setTaskNameDuplicate(false);
    setHecInputError("");
  };

  //new task creation
  const handleNewTask = (highEnergyId, newTaskName) => {
    const isDuplicate = highEnergyData.data.highEnergyTasks.some(
      (data) =>
        data?.highEnergyNames.trim().toLowerCase() ===
        newTaskName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = 0;
      let highEnergyNames = newTaskName.trim();
      let taskID = highEnergyId;
      dispatch(
        saveHighEnergyTask({
          id,
          highEnergyNames,
          taskID,
          saveSuccess: (status, result) => {
            if (status) {
              setTaskNameSuccessModal(highEnergyNames);
              fetchHighEnergyData(taskID);
              showSaveTaskPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
      setTaskNameDuplicate(false);
      setNewTaskEnable(false);
      setNewTaskName("");
    } else {
      setTaskNameDuplicate(true);
    }
  };

  // Handle Task edit item
  const handleEditTask = (item) => {
    setHighEnergyId(item.taskID);
    setTaskId(item.id);
    setEditTaskName(item.highEnergyNames);
    setEditTaskEnable(true);
    setNewTaskEnable(false);
    setEditTaskSubmitReady(false);
  };

  const validateTaskName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setEditTaskName(val);
      setEditTaskEnable(true);
      if (val.length > 0 && val.length <= 255) {
        setHecInputError("");
        setEditTaskSubmitReady(true);
      } else {
        setHecInputError(
          "*Task name must be between 1 and 255 characters long!"
        );
        setEditTaskSubmitReady(false);
      }
    }
  };

  const handleEditTaskClose = () => {
    setEditTaskEnable(false);
    setTaskId(0);
    setTaskNameDuplicate(false);
    setHecInputError("");
    setEditTaskSubmitReady(false);
  };

  function confirmDeleteTask(item) {
    if (item != undefined) {
      setDeleteTaskId(item.id);
      setDeleteTaskName(item.highEnergyNames);
      setShowDeleteTaskConfirmModal((pre) => !pre);
    } else {
      setShowDeleteTaskConfirmModal(false);
    }
  }

  //api call for delete task
  const handleDeleteTask = (taskId) => {
    dispatch(
      deleteHighEnergyTask({
        taskId,
        deleteSuccess: showDeleteTaskSuccessPopup,
      })
    );
    setTaskId(0);
    setShowDeleteTaskConfirmModal((pre) => !pre);
  };

  //api call for update the task
  const handleUpdateTask = () => {
    const isDuplicate = highEnergyData.data.highEnergyTasks.some(
      (data) =>
        data?.highEnergyNames.trim().toLowerCase() ===
        editTaskName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = taskId;
      let highEnergyNames = editTaskName.trim();
      let taskID = highEnergyId;
      setTaskNameSuccessModal(editTaskName);
      dispatch(
        updateHighEnergyTask({
          id,
          highEnergyNames,
          taskID,
          saveSuccess: showSaveTaskPopUp,
        })
      );
      setEditTaskEnable(false);
      setTaskId(0);
      setTaskNameDuplicate(false);
    } else {
      setTaskNameDuplicate(true);
    }
  };

  function showSaveTaskPopUp(value) {
    setShowSaveTaskSuccessModal((pre) => !pre);
    setTaskId(0);
    setEditTaskEnable(false);
    setEditTaskEnable(false);
    setNewTaskName("");
    setNewTaskEnable(false);
  }

  function showDeleteTaskSuccessPopup() {
    setShowDeleteTaskConfirmModal(false);
    setShowDeleteTaskSuccessModal((pre) => !pre);
  }
  //#endregion [[ High Energy Task Operations ]]

  //#region [[ High Energy Hazard Operations ]]
  //handle add hazard button click
  const handleAddHazardClickEvent = (index) => {
    setNewHazardEnable(true);
    setHecInputError("");
  };

  //validates new task name
  const validateNewHazardName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setNewHazardName(val);
      setNewHazardEnable(true);
      if (val.length > 0 && val.length <= 500) setHecInputError("");
      else
        setHecInputError(
          "*Hazard name must be between 1 and 500 characters long!"
        );
    }
    setHazardNameDuplicate(false);
  };

  //handle new Hazard close button click
  const handleNewHazardClose = () => {
    setNewHazardName("");
    setNewHazardEnable(false);
    setHazardNameDuplicate(false);
    setHecInputError("");
  };

  //new Hazard creation
  const handleNewHazard = (highEnergyId, newHazardName) => {
    const isDuplicate = highEnergyData.data.hazards.some(
      (data) =>
        data?.hazardName.trim().toLowerCase() ===
        newHazardName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = 0;
      let hazardName = newHazardName.trim();
      let taskID = highEnergyId;
      dispatch(
        saveHighEnergyHazard({
          id,
          hazardName,
          taskID,
          saveSuccess: (status, result) => {
            if (status) {
              setHazardNameSuccessModal(hazardName);
              fetchHighEnergyData(taskID);
              showSaveHazardPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
      setHazardNameDuplicate(false);
      setNewHazardEnable(false);
      setNewHazardName("");
    } else {
      setHazardNameDuplicate(true);
    }
  };

  // Handle Task edit item
  const handleEditHazard = (item) => {
    setHighEnergyId(item.taskID);
    setHazardId(item.id);
    setEditHazardName(item.hazardName);
    setEditHazardEnable(true);
    setNewHazardEnable(false);
    setEditHazardSubmitReady(false);
  };

  const validateHazardName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setEditHazardName(val);
      setEditHazardEnable(true);
      if (val.length > 0 && val.length <= 500) {
        setHecInputError("");
        setEditHazardSubmitReady(true);
      } else {
        setHecInputError(
          "*Hazard name must be between 1 and 500 characters long!"
        );
        setEditHazardSubmitReady(false);
      }
    }
  };

  const handleEditHazardClose = () => {
    setEditHazardEnable(false);
    setHazardId(0);
    setHazardNameDuplicate(false);
    setHecInputError("");
    setEditHazardSubmitReady(false);
  };

  function confirmDeleteHazard(item) {
    if (item != undefined) {
      setDeleteHazardId(item.id);
      setDeleteHazardName(item.hazardName);
      setShowDeleteHazardConfirmModal((pre) => !pre);
    } else {
      setShowDeleteHazardConfirmModal(false);
    }
  }

  //api call for delete Hazard
  const handleDeleteHazard = (hazardId) => {
    dispatch(
      deleteHighEnergyHazard({
        hazardId,
        deleteSuccess: showDeleteHazardSuccessPopup,
      })
    );
    setTaskId(0);
    setShowDeleteHazardConfirmModal((pre) => !pre);
  };

  //api call for update the Hazard
  const handleUpdateHazard = () => {
    const isDuplicate = highEnergyData.data.hazards.some(
      (data) =>
        data?.hazardName.trim().toLowerCase() ===
        editHazardName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = hazardId;
      let hazardName = editHazardName.trim();
      let taskID = highEnergyId;
      setHazardNameSuccessModal(editHazardName);
      dispatch(
        updateHighEnergyHazard({
          id,
          hazardName,
          taskID,
          saveSuccess: showSaveHazardPopUp,
        })
      );
      setEditHazardEnable(false);
      setHazardId(0);
      setHazardNameDuplicate(false);
    } else {
      setHazardNameDuplicate(true);
    }
  };

  function showSaveHazardPopUp(value) {
    setShowSaveHazardSuccessModal((pre) => !pre);
    setHazardId(0);
    setEditHazardEnable(false);
    setEditHazardEnable(false);
    setNewHazardName("");
    setNewHazardEnable(false);
  }

  function showDeleteHazardSuccessPopup() {
    setShowDeleteHazardConfirmModal(false);
    setShowDeleteHazardSuccessModal((pre) => !pre);
  }
  //#endregion [[ High Energy Hazard Operations ]]

  //#region [[ High Energy Direct Control Operations ]]
  //handle add Direct Control button click
  const handleAddDirectControlClickEvent = (index) => {
    setNewDirectControlEnable(true);
    setHecInputError("");
  };

  //validates new Direct Control name
  const validateNewDirectControlName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setNewDirectControlName(val);
      setNewDirectControlEnable(true);
      if (val.length > 0 && val.length <= 255) setHecInputError("");
      else
        setHecInputError(
          "*Direct control name must be between 1 and 255 characters long!"
        );
    }
    setDirectControlNameDuplicate(false);
  };

  //handle new Direct Control close button click
  const handleNewDirectControlClose = () => {
    setNewDirectControlName("");
    setNewDirectControlEnable(false);
    setDirectControlNameDuplicate(false);
    setHecInputError("");
  };

  //new Direct Control creation
  const handleNewDirectControl = (highEnergyId, newDirectControlName) => {
    const isDuplicate = highEnergyData.data.directControlsAndSafeguards.some(
      (data) =>
        data?.type === 1 &&
        data?.directControlSafegaurdsName.trim().toLowerCase() ===
          newDirectControlName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = 0;
      let type = 1;
      let directControlSafegaurdsName = newDirectControlName.trim();
      let taskID = highEnergyId;
      dispatch(
        saveHighEnergyDirectControlsAndSafeGuard({
          id,
          directControlSafegaurdsName,
          type,
          taskID,
          saveSuccess: (status, result) => {
            if (status) {
              setDirectControlNameSuccessModal(directControlSafegaurdsName);
              fetchHighEnergyData(taskID);
              showSaveDirectControlPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
      setDirectControlNameDuplicate(false);
      setNewDirectControlEnable(false);
      setNewDirectControlName("");
    } else {
      setDirectControlNameDuplicate(true);
    }
  };

  // Handle Direct Control edit item
  const handleEditDirectControl = (item) => {
    setHighEnergyId(item.taskID);
    setDirectControlId(item.id);
    setEditDirectControlName(item.directControlSafegaurdsName);
    setEditDirectControlEnable(true);
    setNewDirectControlEnable(false);
    setEditDirectControlSubmitReady(false);
  };

  const validateDirectControlName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setEditDirectControlName(val);
      setEditDirectControlEnable(true);
      if (val.length > 0 && val.length <= 255) {
        setHecInputError("");
        setEditDirectControlSubmitReady(true);
      } else {
        setHecInputError(
          "*Direct control name must be between 1 and 255 characters long!"
        );
        setEditDirectControlSubmitReady(false);
      }
    }
  };

  const handleEditDirectControlClose = () => {
    setEditDirectControlEnable(false);
    setDirectControlId(0);
    setDirectControlNameDuplicate(false);
    setHecInputError("");
    setEditDirectControlSubmitReady(false);
  };

  function confirmDeleteDirectControl(item) {
    if (item != undefined) {
      setDeleteDirectControlId(item.id);
      setDeleteDirectControlName(item.directControlSafegaurdsName);
      setShowDeleteDirectControlConfirmModal((pre) => !pre);
    } else {
      setShowDeleteDirectControlConfirmModal(false);
    }
  }

  //api call for delete Direct Control
  const handleDeleteDirectControl = (directControlId) => {
    dispatch(
      deleteHighEnergyDirectControlsAndSafeGuard({
        directControlId,
        deleteSuccess: showDeleteDirectControlSuccessPopup,
      })
    );
    setDirectControlId(0);
    setShowDeleteDirectControlConfirmModal((pre) => !pre);
  };

  //api call for update the Direct Control
  const handleUpdateDirectControl = () => {
    const isDuplicate = highEnergyData.data.directControlsAndSafeguards.some(
      (data) =>
        data?.type === 1 &&
        data?.directControlSafegaurdsName.trim().toLowerCase() ===
          editDirectControlName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = directControlId;
      let directControlSafegaurdsName = editDirectControlName.trim();
      let type = 1;
      let taskID = highEnergyId;
      setDirectControlNameSuccessModal(editDirectControlName);
      dispatch(
        updateHighEnergyDirectControlsAndSafeGuard({
          id,
          directControlSafegaurdsName,
          type,
          taskID,
          saveSuccess: showSaveDirectControlPopUp,
        })
      );
      setEditDirectControlEnable(false);
      setDirectControlId(0);
      setDirectControlNameDuplicate(false);
    } else {
      setDirectControlNameDuplicate(true);
    }
  };

  function showSaveDirectControlPopUp(value) {
    setShowSaveDirectControlSuccessModal((pre) => !pre);
    setDirectControlId(0);
    setEditDirectControlEnable(false);
    setEditDirectControlEnable(false);
    setNewDirectControlName("");
    setNewDirectControlEnable(false);
  }

  function showDeleteDirectControlSuccessPopup() {
    setShowDeleteDirectControlConfirmModal(false);
    setShowDeleteDirectControlSuccessModal((pre) => !pre);
  }
  //#endregion [[ High Energy DirectControl Operations ]]

  //#region [[ High Energy Safe Guard Operations ]]
  //handle add Safe Guard button click
  const handleAddSafeGuardClickEvent = (index) => {
    setNewSafeGuardEnable(true);
    setHecInputError("");
  };

  //validates new Direct Control name
  const validateNewSafeGuardName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setNewSafeGuardName(val);
      setNewSafeGuardEnable(true);
      if (val.length > 0 && val.length <= 255) setHecInputError("");
      else
        setHecInputError(
          "*Safeguard control name must be between 1 and 255 characters long!"
        );
    }
    setSafeGuardNameDuplicate(false);
  };

  //handle new Safe Guard close button click
  const handleNewSafeGuardClose = () => {
    setNewSafeGuardName("");
    setNewSafeGuardEnable(false);
    setSafeGuardNameDuplicate(false);
    setHecInputError("");
  };

  //new Safe Guard creation
  const handleNewSafeGuard = (highEnergyId, newSafeGuardName) => {
    const isDuplicate = highEnergyData.data.directControlsAndSafeguards.some(
      (data) =>
        data?.type === 2 &&
        data?.directControlSafegaurdsName.trim().toLowerCase() ===
          newSafeGuardName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = 0;
      let type = 2;
      let directControlSafegaurdsName = newSafeGuardName.trim();
      let taskID = highEnergyId;
      dispatch(
        saveHighEnergyDirectControlsAndSafeGuard({
          id,
          directControlSafegaurdsName,
          type,
          taskID,
          saveSuccess: (status, result) => {
            if (status) {
              setSafeGuardNameSuccessModal(directControlSafegaurdsName);
              fetchHighEnergyData(taskID);
              showSaveSafeGuardPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
      setSafeGuardNameDuplicate(false);
      setNewSafeGuardEnable(false);
      setNewSafeGuardName("");
    } else {
      setSafeGuardNameDuplicate(true);
    }
  };

  // Handle Safe Guard edit item
  const handleEditSafeGuard = (item) => {
    setHighEnergyId(item.taskID);
    setSafeGuardId(item.id);
    setEditSafeGuardName(item.directControlSafegaurdsName);
    setEditSafeGuardEnable(true);
    setNewSafeGuardEnable(false);
    setEditSafeGuardSubmitReady(false);
  };

  const validateSafeGuardName = (e) => {
    const val = e.target.value;
    // Allowed characters
    // [lower case letters, a-z]
    // [upper case letters, A-Z]
    // [numbers 0-9]
    // [dot, comma, backslash, hyphen, question mark, ampersand, forward slash, single quote, open paranthesis, closing paranthesis, space]
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val !== "") {
      setEditSafeGuardName(val);
      setEditSafeGuardEnable(true);
      if (val.length > 0 && val.length <= 255) {
        setHecInputError("");
        setEditSafeGuardSubmitReady(true);
      } else {
        setEditSafeGuardSubmitReady(false);
        setHecInputError(
          "*Safeguard control name must be between 1 and 255 characters long!"
        );
      }
    }
  };

  const handleEditSafeGuardClose = () => {
    setEditSafeGuardEnable(false);
    setSafeGuardId(0);
    setSafeGuardNameDuplicate(false);
    setEditSafeGuardSubmitReady(false);
  };

  function confirmDeleteSafeGuard(item) {
    if (item != undefined) {
      setDeleteSafeGuardId(item.id);
      setDeleteSafeGuardName(item.directControlSafegaurdsName);
      setShowDeleteSafeGuardConfirmModal((pre) => !pre);
    } else {
      setShowDeleteSafeGuardConfirmModal(false);
    }
  }

  //api call for delete Safe Guard
  const handleDeleteSafeGuard = (safeGuardId) => {
    let directControlId = safeGuardId;
    dispatch(
      deleteHighEnergyDirectControlsAndSafeGuard({
        directControlId,
        deleteSuccess: showDeleteSafeGuardSuccessPopup,
      })
    );
    setSafeGuardId(0);
    setShowDeleteSafeGuardConfirmModal((pre) => !pre);
  };

  //api call for update the Safe Guard
  const handleUpdateSafeGuard = () => {
    const isDuplicate = highEnergyData.data.directControlsAndSafeguards.some(
      (data) =>
        data?.type === 2 &&
        data?.directControlSafegaurdsName.trim().toLowerCase() ===
          editSafeGuardName.trim().toLowerCase()
    );
    if (!isDuplicate) {
      let id = safeGuardId;
      let directControlSafegaurdsName = editSafeGuardName.trim();
      let type = 2;
      let taskID = highEnergyId;
      setSafeGuardNameSuccessModal(editSafeGuardName);
      dispatch(
        updateHighEnergyDirectControlsAndSafeGuard({
          id,
          directControlSafegaurdsName,
          type,
          taskID,
          saveSuccess: showSaveSafeGuardPopUp,
        })
      );
      setEditSafeGuardEnable(false);
      setSafeGuardId(0);
      setSafeGuardNameDuplicate(false);
    } else {
      setSafeGuardNameDuplicate(true);
    }
  };

  function showSaveSafeGuardPopUp(value) {
    setShowSaveSafeGuardSuccessModal((pre) => !pre);
    setSafeGuardId(0);
    setEditSafeGuardEnable(false);
    setEditSafeGuardEnable(false);
    setNewSafeGuardName("");
    setNewSafeGuardEnable(false);
  }

  function showDeleteSafeGuardSuccessPopup() {
    setShowDeleteSafeGuardConfirmModal(false);
    setShowDeleteSafeGuardSuccessModal((pre) => !pre);
  }
  //#endregion [[ High Energy Safe Guard Operations ]]

  const renderOption_HighEnergy = (option) => {
    return (
      <div className="p-d-flex p-ai-center">
        <span className="p-ml-2">{option}</span>
        <span className="p-ml-auto" style={{ float: "right" }}>
          <i
            className="pi pi-trash"
            onClick={() => confirmDeleteHighEnergy(option)}
            style={{ cursor: "pointer" }}
          ></i>
        </span>
      </div>
    );
  };

  return (
    <div className="hec">
      {isLoading && <Loader />}
      {highEnergiesSectionEnable && (
        <div className="dropdown">
          <label className="highenergylabel" for="highenergy">
            High Energy
          </label>
          <Dropdown
            id="drd_highEnergy"
            options={highEnergies?.data?.map((he) => he.highEnergyName)}
            value={highEnergy}
            onChange={(e) => {
              handleHighEnergyChange(e.target.value, "");
            }}
            itemTemplate={renderOption_HighEnergy}
            placeholder="Select a high energy"
            dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
          />
          <Button
            id="btn_HECAddHighEnergy"
            className="addHighEnergyBtn"
            label="+ Add High Energy"
            onClick={handleAddHighEnergyClickEvent}
          />
        </div>
      )}
      {addHighEnergySectionEnable && (
        <div className="new-highenergy-section">
          <p>Add High Energy</p>
          <div>
            <input
              id="txt_HECEditName"
              className="edit-name"
              type="text"
              maxLength="51"
              onChange={(e) => validateHighEnergyName(e)}
            ></input>
            {newHighEnergyName.length > 50 && (
              <p className="add-highenergy-error">
                *Maximum number of characters should not exceed 50.
              </p>
            )}
            {highEnergyNameDuplicate && (
              <p className="edit-duplicate">
                *Cannot add duplicate high energy.
              </p>
            )}
          </div>
          <div>
            <button
              id="btn_HECCancel"
              className="new-highenergy-button-inactive"
              onClick={() => handleAddHighEnergyClose()}
            >
              Cancel
            </button>
            <button
              id="btn_HECSave"
              disabled={
                !(
                  newHighEnergyName.length > 0 && newHighEnergyName.length <= 50
                )
              }
              className={
                newHighEnergyName.length > 0 && newHighEnergyName.length <= 50
                  ? "new-highenergy-button"
                  : "new-highenergy-button-inactive"
              }
              onClick={() => handleNewHighEnergyName(newHighEnergyName.trim())}
            >
              Save
            </button>
          </div>
        </div>
      )}

      {editHighEnergySectionEnable && (
        <div className="new-highenergy-section">
          <p> Modify High Energy </p>
          <div>
            <input
              id="txt_HECModifiedHighEnergyName"
              className="edit-name"
              type="text"
              maxLength="51"
              value={modifiedHighEnergyName}
              onChange={(e) => validateModifiedHighEnergyName(e)}
            ></input>
            {modifiedHighEnergyName.length > 50 && (
              <p className="contact-error">
                *Maximum number of characters should not exceed 50.
              </p>
            )}
            {highEnergyNameDuplicate && (
              <p className="edit-duplicate">
                *Cannot add duplicate high energy.
              </p>
            )}
          </div>
          <div>
            <button
              id="btn_HECnewCancel"
              className="new-highenergy-button-inactive"
              onClick={() => handleEditHighEnergyClose()}
            >
              Cancel
            </button>
            <button
              id="btn_HECnewUpdate"
              disabled={
                !(
                  modifiedHighEnergyName.length > 0 &&
                  modifiedHighEnergyName.length <= 50
                )
              }
              className={
                modifiedHighEnergyName.length > 0 &&
                modifiedHighEnergyName.length <= 50
                  ? "new-highenergy-button"
                  : "new-highenergy-button-inactive"
              }
              onClick={() =>
                handleUpdateHighEnergyName(modifiedHighEnergyName.trim())
              }
            >
              Update
            </button>
          </div>
        </div>
      )}

      {highEnergyData &&
        highEnergyData?.data &&
        !addHighEnergySectionEnable &&
        !editHighEnergySectionEnable &&
        highEnergyId > 0 && (
          <div className="hec-data-table">
            {highEnergy && (
              <div className="edit-highenergy">
                <p>
                  <span>{highEnergy}</span>
                  <img id="img_HECEditIcon"
                    src={editIcon}
                    className="edit-icon"
                    onClick={() => handleEditHighEnergy()}
                  ></img>
                  <p className="hec-input-error">{hecInputError}</p>
                </p>
              </div>
            )}
            <div className="table-container">
              <table>
                <tr>
                  <td className="colspan" colSpan="5"></td>
                </tr>
                <tr className="table-heading">
                  <th>
                    <div className="heading-icon">High Energy Icon</div>
                  </th>
                  <th>
                    <div className="heading-icon">
                      High Energy Tasks
                      <i
                        class="pi pi-ellipsis-v"
                        onClick={(e) => opTask.current.toggle(e)}
                      >
                        <OverlayPanel ref={opTask}>
                          <div class="overlay-icon-container">
                            <span class="menuitem">
                              <p onClick={() => handleAddTaskClickEvent()}>
                                + Add Task
                              </p>
                            </span>
                          </div>
                        </OverlayPanel>
                      </i>
                    </div>
                  </th>
                  <th>
                    <div className="heading-icon">
                      Hazards
                      <i
                        class="pi pi-ellipsis-v"
                        onClick={(e) => opHazard.current.toggle(e)}
                      >
                        <OverlayPanel ref={opHazard}>
                          <div class="overlay-icon-container">
                            <span class="menuitem">
                              <p onClick={() => handleAddHazardClickEvent()}>
                                + Add Hazard
                              </p>
                            </span>
                          </div>
                        </OverlayPanel>
                      </i>
                    </div>
                  </th>
                  <th>
                    <div className="heading-icon">
                      Direct Controls
                      <i
                        class="pi pi-ellipsis-v"
                        onClick={(e) => opDirectControl.current.toggle(e)}
                      >
                        <OverlayPanel ref={opDirectControl}>
                          <div class="overlay-icon-container">
                            <span class="menuitem">
                              <p
                                onClick={() =>
                                  handleAddDirectControlClickEvent()
                                }
                              >
                                + Add Direct Control
                              </p>
                            </span>
                          </div>
                        </OverlayPanel>
                      </i>
                    </div>
                  </th>
                  <th>
                    <div className="heading-icon">
                      Safeguards
                      <i
                        class="pi pi-ellipsis-v"
                        onClick={(e) => opSafeGuard.current.toggle(e)}
                      >
                        <OverlayPanel ref={opSafeGuard}>
                          <div class="overlay-icon-container">
                            <span class="menuitem">
                              <p onClick={() => handleAddSafeGuardClickEvent()}>
                                + Add Safe Guard
                              </p>
                            </span>
                          </div>
                        </OverlayPanel>
                      </i>
                    </div>
                  </th>
                </tr>

                <tr className="">
                  <td>
                    {highEnergyData.data?.highEnergyIcons?.map(
                      (item, index) => {
                        return (
                          <>
                            {!uploadIconEnable && (
                              <div className="hec-icon-avatar">
                                {item?.imgBase64Data && (
                                  <img id="img_HECIcon"
                                    className="hec-icon"
                                    src={
                                      "data:image/png;base64," +
                                      item.imgBase64Data
                                    }
                                    alt=""
                                  ></img>
                                )}
                                {!item?.imgBase64Data && (
                                  <img id="img_HECDefImage"
                                    className="hec-icon"
                                    src={defImage}
                                    alt=""
                                  ></img>
                                )}
                                <i
                                  class="pi pi-ellipsis-v"
                                  onClick={(e) => op.current.toggle(e)}
                                >
                                  <OverlayPanel ref={op}>
                                    <div class="overlay-icon-container">
                                      <span class="menuitem">
                                        <p
                                          onClick={() => handleUploadIcon(item)}
                                        >
                                          Upload new icon
                                        </p>
                                      </span>
                                      <span class="menuitem">
                                        <p
                                          onClick={() =>
                                            confirmDeleteIcon(item)
                                          }
                                        >
                                          Delete icon
                                        </p>
                                      </span>
                                    </div>
                                  </OverlayPanel>
                                </i>
                              </div>
                            )}
                            {item?.id === iconId && uploadIconEnable && (
                              <div className="icon-upload-section">
                                <div className="upload-section">
                                  <label
                                    for="upload-image"
                                    className="image-upload"
                                  >
                                    <i className="pi pi-upload"></i>
                                  </label>
                                  <input
                                    id="upload-image"
                                    type="file"
                                    name="file"
                                    accept=".jpg, .png, .jpeg"
                                    onChange={(e) => handleImageUpload(e)}
                                  />
                                  <p>{selectedImageName}</p>
                                </div>
                                <div className="upload-section-button">
                                  <span className="times-icon">
                                    <img id="img_HECCancel"
                                      src={cancelIcon}
                                      className="cancel-icon"
                                      onClick={cancelImageSave}
                                    ></img>
                                  </span>
                                  <span>
                                    {uploadIconCheckEnabled ? (
                                      <img id="img_HECEnableEdit"
                                        src={editEnable}
                                        className="enable-edit"
                                        onClick={() =>
                                          saveImage(
                                            iconId,
                                            highEnergyId,
                                            selectedImage
                                          )
                                        }
                                      ></img>
                                    ) : (
                                      <img id="img_HECDisableEdit"
                                        src={editDisable}
                                        className="disable-edit"
                                      ></img>
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    )}
                    {highEnergyData.data?.highEnergyIcons?.length === 0 && (
                      <div className="icon-upload-section">
                        <div className="upload-section">
                          <label for="upload-image" className="image-upload">
                            <i className="pi pi-upload"></i>
                          </label>
                          <input
                            id="upload-image"
                            type="file"
                            name="file"
                            accept=".jpg, .png, .jpeg"
                            onChange={(e) => handleImageUpload(e)}
                          />
                          <p>{selectedImageName}</p>
                        </div>
                        <div className="upload-section-button">
                          <span className="times-icon">
                            <img id="img_HECCancelIcon"
                              src={cancelIcon}
                              className="cancel-icon"
                              onClick={cancelImageSave}
                            ></img>
                          </span>
                          <span>
                            {uploadIconCheckEnabled ? (
                              <img id="img_HECEditEnable"
                                src={editEnable}
                                className="enable-edit"
                                onClick={() =>
                                  saveImage(iconId, highEnergyId, selectedImage)
                                }
                              ></img>
                            ) : (
                              <img id="img_HECEditDisable"
                                src={editDisable}
                                className="disable-edit"
                              ></img>
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    {newTaskEnable && (
                      <ul>
                        <li className="list">
                          <input
                            id="txt_HECNewTaskName"
                            className="new-name"
                            placeholder="New Task Name"
                            type="text"
                            maxLength="256"
                            value={newTaskName}
                            onChange={(e) => validateNewTaskName(e)}
                          ></input>
                          <div className="div-crud-icon">
                            <img id="img_HECCancelIco"
                              src={cancelIcon}
                              className="cancel-icon"
                              onClick={() => handleNewTaskClose()}
                            ></img>
                          </div>
                          <div className="div-crud-icon">
                            {newTaskName.length > 0 &&
                            newTaskName.length <= 255 ? (
                              <img id="img_HECEnable"
                                src={editEnable}
                                className="edit-enable"
                                onClick={() =>
                                  handleNewTask(
                                    highEnergyId,
                                    newTaskName.trim()
                                  )
                                }
                              ></img>
                            ) : (
                              <img id="img_HECDisable"
                                src={editDisable}
                                className="edit-disable"
                              ></img>
                            )}
                          </div>
                        </li>
                        {taskNameDuplicate && (
                          <p className="edit-duplicate">
                            *Cannot add duplicate task.
                          </p>
                        )}
                      </ul>
                    )}

                    {highEnergyData.data?.highEnergyTasks?.map(
                      (item, index) => {
                        return (
                          <>
                            {item?.id !== taskId && (
                              <ul
                                key={index}
                                onChange={() => handleTaskHeader(item?.taskId)}
                              >
                                <li className="list">
                                  <p>{item?.highEnergyNames}</p>
                                  <div className="div-crud-icon">
                                    <img id="img_HECEditIcon"
                                      src={editIcon}
                                      className="edit-icon"
                                      onClick={() => handleEditTask(item)}
                                    ></img>
                                  </div>
                                  <div className="div-crud-icon">
                                    <img id="img_HECtrashIcon"
                                      src={trash}
                                      className="trash-icon"
                                      onClick={() => confirmDeleteTask(item)}
                                    ></img>
                                  </div>
                                </li>
                              </ul>
                            )}
                            {item?.id === taskId && editTaskEnable && (
                              <ul>
                                <li className="list">
                                  <input
                                    id="txt_HECEditTaskName"
                                    className="edit-name"
                                    type="text"
                                    maxLength="256"
                                    value={editTaskName}
                                    onChange={(e) => validateTaskName(e)}
                                  ></input>
                                  <div className="div-crud-icon">
                                    <img id="img_HECIconCancel"
                                      src={cancelIcon}
                                      className="cancel-icon"
                                      onClick={() => handleEditTaskClose()}
                                    ></img>
                                  </div>
                                  <div className="div-crud-icon">
                                    {editTaskEnable &&
                                    editTaskName.length > 0 &&
                                    editTaskName.length <= 255 &&
                                    editTaskSubmitReady ? (
                                      <img id="img_HECCEditEnable"
                                        src={editEnable}
                                        className="edit-enable"
                                        onClick={() => handleUpdateTask()}
                                      ></img>
                                    ) : (
                                      <img id="img_HECCEditDisable"
                                        src={editDisable}
                                        className="edit-disable"
                                      ></img>
                                    )}
                                  </div>
                                </li>
                                {taskNameDuplicate && (
                                  <p className="edit-duplicate">
                                    *Cannot add duplicate task.
                                  </p>
                                )}
                              </ul>
                            )}
                          </>
                        );
                      }
                    )}
                  </td>
                  <td>
                    {newHazardEnable && (
                      <ul>
                        <li className="list">
                          <input
                            id="txt_HECNewHazardName"
                            className="new-name"
                            placeholder="New Hazard Name"
                            type="text"
                            maxLength="501"
                            value={newHazardName}
                            onChange={(e) => validateNewHazardName(e)}
                          ></input>
                          <div className="div-crud-icon">
                            <img id="img_HECCCancel"
                              src={cancelIcon}
                              className="cancel-icon"
                              onClick={() => handleNewHazardClose()}
                            ></img>
                          </div>
                          <div className="div-crud-icon">
                            {newHazardName.length > 0 &&
                            newHazardName.length <= 500 ? (
                              <img id="img_HECCEditEnabled"
                                src={editEnable}
                                className="edit-enable"
                                onClick={() =>
                                  handleNewHazard(
                                    highEnergyId,
                                    newHazardName.trim()
                                  )
                                }
                              ></img>
                            ) : (
                              <img id="img_HECCEditDisabled"
                                src={editDisable}
                                className="edit-disable"
                              ></img>
                            )}
                          </div>
                        </li>
                        {hazardNameDuplicate && (
                          <p className="edit-duplicate">
                            *Cannot add duplicate hazard.
                          </p>
                        )}
                      </ul>
                    )}

                    {highEnergyData.data?.hazards?.map((item, index) => {
                      return (
                        <>
                          {item?.id !== hazardId && (
                            <ul
                              key={index}
                              onChange={() => handleHazardHeader(item?.taskId)}
                            >
                              <li className="list">
                                <p>{item?.hazardName}</p>
                                <div className="div-crud-icon">
                                  <img id="img_HECCEditHazard"
                                    src={editIcon}
                                    className="edit-icon"
                                    onClick={() => handleEditHazard(item)}
                                  ></img>
                                </div>
                                <div className="div-crud-icon">
                                  <img id="img_HECCConfirmDeleteHazard"
                                    src={trash}
                                    className="trash-icon"
                                    onClick={() => confirmDeleteHazard(item)}
                                  ></img>
                                </div>
                              </li>
                            </ul>
                          )}
                          {item?.id === hazardId && editHazardEnable && (
                            <ul>
                              <li className="list">
                                <input
                                  id="txt_HECEditHazardName"
                                  className="edit-name"
                                  type="text"
                                  maxLength="501"
                                  value={editHazardName}
                                  onChange={(e) => validateHazardName(e)}
                                ></input>
                                <div className="div-crud-icon">
                                  <img id="img_HECCCancelIco"
                                    src={cancelIcon}
                                    className="cancel-icon"
                                    onClick={() => handleEditHazardClose()}
                                  ></img>
                                </div>
                                <div className="div-crud-icon">
                                  {editHazardEnable &&
                                  editHazardName.length > 0 &&
                                  editHazardName.length <= 500 &&
                                  editHazardSubmitReady ? (
                                    <img id="img_HECCUpdateHazard"
                                      src={editEnable}
                                      className="edit-enable"
                                      onClick={() => handleUpdateHazard()}
                                    ></img>
                                  ) : (
                                    <img id="img_HECCDisabled"
                                      src={editDisable}
                                      className="edit-disable"
                                    ></img>
                                  )}
                                </div>
                              </li>
                              {hazardNameDuplicate && (
                                <p className="edit-duplicate">
                                  *Cannot add duplicate hazard.
                                </p>
                              )}
                            </ul>
                          )}
                        </>
                      );
                    })}
                  </td>
                  <td>
                    {newDirectControlEnable && (
                      <ul>
                        <li className="list">
                          <input
                            id="txt_HECNewDirectControlName"
                            className="new-name"
                            placeholder="New Direct Control Name"
                            type="text"
                            maxLength="256"
                            value={newDirectControlName}
                            onChange={(e) => validateNewDirectControlName(e)}
                          ></input>
                          <div className="div-crud-icon">
                            <img id="img_DirectControlClose"
                              src={cancelIcon}
                              className="cancel-icon"
                              onClick={() => handleNewDirectControlClose()}
                            ></img>
                          </div>
                          <div className="div-crud-icon">
                            {newDirectControlName.length > 0 &&
                            newDirectControlName.length <= 255 ? (
                              <img id="img_NewDirectControl"
                                src={editEnable}
                                className="edit-enable"
                                onClick={() =>
                                  handleNewDirectControl(
                                    highEnergyId,
                                    newDirectControlName.trim()
                                  )
                                }
                              ></img>
                            ) : (
                              <img id="img_EditDisable"
                                src={editDisable}
                                className="edit-disable"
                              ></img>
                            )}
                          </div>
                        </li>
                        {directControlNameDuplicate && (
                          <p className="edit-duplicate">
                            *Cannot add duplicate Direct Control.
                          </p>
                        )}
                      </ul>
                    )}

                    {highEnergyData.data?.directControlsAndSafeguards?.map(
                      (item, index) => {
                        return (
                          <>
                            {item?.type === 1 &&
                              item?.id !== directControlId && (
                                <ul
                                  key={index}
                                  className=""
                                  onChange={() =>
                                    handleDirectControlHeader(item?.taskID)
                                  }
                                >
                                  <li className="list">
                                    <p>{item?.directControlSafegaurdsName}</p>
                                    <div className="div-crud-icon">
                                      <img id="img_EditDirect"
                                        src={editIcon}
                                        className="edit-icon"
                                        onClick={() =>
                                          handleEditDirectControl(item)
                                        }
                                      ></img>
                                    </div>
                                    <div className="div-crud-icon">
                                      <img id="img_trashIco"
                                        src={trash}
                                        className="trash-icon"
                                        onClick={() =>
                                          confirmDeleteDirectControl(item)
                                        }
                                      ></img>
                                    </div>
                                  </li>
                                </ul>
                              )}
                            {item?.type === 1 &&
                              item?.id === directControlId &&
                              editDirectControlEnable && (
                                <ul>
                                  <li className="list">
                                    <input
                                      id="txt_HECEditDirectControlName"
                                      className="edit-name"
                                      type="text"
                                      maxLength="256"
                                      value={editDirectControlName}
                                      onChange={(e) =>
                                        validateDirectControlName(e)
                                      }
                                    ></input>
                                    <div className="div-crud-icon">
                                      <img id="img_Cancelled"
                                        src={cancelIcon}
                                        className="cancel-icon"
                                        onClick={() =>
                                          handleEditDirectControlClose()
                                        }
                                      ></img>
                                    </div>
                                    <div className="div-crud-icon">
                                      {editDirectControlEnable &&
                                      editDirectControlName.length > 0 &&
                                      editDirectControlName.length <= 255 &&
                                      editDirectControlSubmitReady ? (
                                        <img id="img_EditHECCEnabled"
                                          src={editEnable}
                                          className="edit-enable"
                                          onClick={() =>
                                            handleUpdateDirectControl()
                                          }
                                        ></img>
                                      ) : (
                                        <img id="img_EditHECCDisabled"
                                          src={editDisable}
                                          className="edit-disable"
                                        ></img>
                                      )}
                                    </div>
                                  </li>
                                  {directControlNameDuplicate && (
                                    <p className="edit-duplicate">
                                      *Cannot add duplicate direct control.
                                    </p>
                                  )}
                                </ul>
                              )}
                          </>
                        );
                      }
                    )}
                  </td>
                  <td>
                    {newSafeGuardEnable && (
                      <ul>
                        <li className="list">
                          <input
                            id="txt_HECNewSafeGuardName"
                            className="new-name"
                            placeholder="New Safe Guard Name"
                            type="text"
                            maxLength="256"
                            value={newSafeGuardName}
                            onChange={(e) => validateNewSafeGuardName(e)}
                          ></input>
                          <div className="div-crud-icon">
                            <img id="img_CancelHECC"
                              src={cancelIcon}
                              className="cancel-icon"
                              onClick={() => handleNewSafeGuardClose()}
                            ></img>
                          </div>
                          <div className="div-crud-icon">
                            {newSafeGuardName.length > 0 &&
                            newSafeGuardName.length <= 255 ? (
                              <img id="img_newSafeGuardNameEnabled"
                                src={editEnable}
                                className="edit-enable"
                                onClick={() =>
                                  handleNewSafeGuard(
                                    highEnergyId,
                                    newSafeGuardName.trim()
                                  )
                                }
                              ></img>
                            ) : (
                              <img id="img_newSafeGuardNameDisabled"
                                src={editDisable}
                                className="edit-disable"
                              ></img>
                            )}
                          </div>
                        </li>
                        {safeGuardNameDuplicate && (
                          <p className="edit-duplicate">
                            *Cannot add duplicate safe guard.
                          </p>
                        )}
                      </ul>
                    )}

                    {highEnergyData.data?.directControlsAndSafeguards?.map(
                      (item, index) => {
                        return (
                          <>
                            {item?.type === 2 && item?.id !== safeGuardId && (
                              <ul
                                key={index}
                                onChange={() =>
                                  handleSafeGuardHeader(item?.taskID)
                                }
                              >
                                <li className="list">
                                  <p>{item?.directControlSafegaurdsName}</p>
                                  <div className="div-crud-icon">
                                    <img id="img_newSafeGuardNameEdit"
                                      src={editIcon}
                                      className="edit-icon"
                                      onClick={() => handleEditSafeGuard(item)}
                                    ></img>
                                  </div>
                                  <div className="div-crud-icon">
                                    <img id="img_newSafeGuardNameTrash"
                                      src={trash}
                                      className="trash-icon"
                                      onClick={() =>
                                        confirmDeleteSafeGuard(item)
                                      }
                                    ></img>
                                  </div>
                                </li>
                              </ul>
                            )}
                            {item?.type === 2 &&
                              item?.id === safeGuardId &&
                              editSafeGuardEnable && (
                                <ul>
                                  <li className="list">
                                    <input
                                      id="txt_HECEditSafeGuardName"
                                      className="edit-name"
                                      type="text"
                                      maxLength="256"
                                      value={editSafeGuardName}
                                      onChange={(e) => validateSafeGuardName(e)}
                                    ></input>
                                    <div className="div-crud-icon">
                                      <img id="img_CrudICon"
                                        src={cancelIcon}
                                        className="cancel-icon"
                                        onClick={() =>
                                          handleEditSafeGuardClose()
                                        }
                                      ></img>
                                    </div>
                                    <div className="div-crud-icon">
                                      {editSafeGuardEnable &&
                                      editSafeGuardName.length > 0 &&
                                      editSafeGuardName.length <= 255 &&
                                      editSafeGuardSubmitReady ? (
                                        <img id="img_HECEnabled"
                                          src={editEnable}
                                          className="edit-enable"
                                          onClick={() =>
                                            handleUpdateSafeGuard()
                                          }
                                        ></img>
                                      ) : (
                                        <img id="img_HECDisabled"
                                          src={editDisable}
                                          className="edit-disable"
                                        ></img>
                                      )}
                                    </div>
                                  </li>
                                  {safeGuardNameDuplicate && (
                                    <p className="edit-duplicate">
                                      *Cannot add duplicate safe guard.
                                    </p>
                                  )}
                                </ul>
                              )}
                          </>
                        );
                      }
                    )}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}

      <ModalWrapper
        visible={showWarning}
        onDismiss={() => {
          showWarnPopup();
        }}
      >
        <WarnPopup
          closePopup={() => {
            showWarnPopup();
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showWarningMessage}
        onDismiss={() => {
          showWarningMessagePopup();
        }}
      >
        <WarningMessagePopup
          closePopup={() => {
            showWarningMessagePopup();
          }}
          content={warningMessage}
        />
      </ModalWrapper>

      {/* #region High Energy Modals */}
      <ModalWrapper
        visible={showSaveHighEnergySuccessModal}
        onDismiss={() => {
          showSaveHighEnergyPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSaveHighEnergyPopUp();
          }}
          heading="High Energy"
          content={newHighEnergyName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showUpdateHighEnergySuccessModal}
        onDismiss={() => {
          showUpdateHighEnergyPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showUpdateHighEnergyPopUp();
          }}
          heading="High Energy"
          content={modifiedHighEnergyName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteHighEnergyConfirmModal}
        onDismiss={() => {
          confirmDeleteHighEnergy();
        }}
      >
        <DeletePopup
          closePopup={() => {
            confirmDeleteHighEnergy();
          }}
          deleteValue={() => handleDeleteHighEnergy(deleteHighEnergyId)}
          heading="High Energy"
          content={deleteHighEnergyName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteHighEnergySuccessModal}
        onDismiss={() => {
          showDeleteHighEnergySuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteHighEnergySuccessPopup();
          }}
          heading="High Energy"
          content={deleteHighEnergyName}
        />
      </ModalWrapper>

      {/* #endregion High Energy Modals */}

      {/* #region High Energy Icon Modals */}
      <ModalWrapper
        visible={showSaveIconSuccessModal}
        onDismiss={() => {
          showSaveIconPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSaveIconPopUp();
          }}
          heading="High Energy Icon"
          content={highEnergy}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteIconConfirmModal}
        onDismiss={() => {
          confirmDeleteIcon();
        }}
      >
        <DeletePopup
          closePopup={() => {
            confirmDeleteIcon();
          }}
          deleteValue={() => handleDeleteIcon(iconId)}
          heading="High Energy Icon"
          content={highEnergy}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteIconSuccessModal}
        onDismiss={() => {
          showDeleteIconSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteIconSuccessPopup();
          }}
          heading="High Energy Icon"
          content={highEnergy}
        />
      </ModalWrapper>
      {/* #endregion High Energy Icon Modals */}

      {/* #region Task Modals */}
      <ModalWrapper
        visible={showSaveTaskSuccessModal}
        onDismiss={() => {
          showSaveTaskPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSaveTaskPopUp();
          }}
          heading="High Energy Task"
          content={taskNameSuccessModal}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteTaskConfirmModal}
        onDismiss={() => {
          confirmDeleteTask();
        }}
      >
        <DeletePopup
          closePopup={() => {
            confirmDeleteTask();
          }}
          deleteValue={() => handleDeleteTask(deleteTaskId)}
          heading="High Energy Task"
          content={deleteTaskName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteTaskSuccessModal}
        onDismiss={() => {
          showDeleteTaskSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteTaskSuccessPopup();
          }}
          heading="High Energy Task"
          content={deleteTaskName}
        />
      </ModalWrapper>
      {/* #endregion Task Modals */}

      {/* #region Hazard Modals */}
      <ModalWrapper
        visible={showSaveHazardSuccessModal}
        onDismiss={() => {
          showSaveHazardPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSaveHazardPopUp();
          }}
          heading="High Energy Hazard"
          content={hazardNameSuccessModal}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteHazardConfirmModal}
        onDismiss={() => {
          confirmDeleteHazard();
        }}
      >
        <DeletePopup
          closePopup={() => {
            confirmDeleteHazard();
          }}
          deleteValue={() => handleDeleteHazard(deleteHazardId)}
          heading="High Energy Hazard"
          content={deleteHazardName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteHazardSuccessModal}
        onDismiss={() => {
          showDeleteHazardSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteHazardSuccessPopup();
          }}
          heading="High Energy Hazard"
          content={deleteHazardName}
        />
      </ModalWrapper>
      {/* #endregion Hazard Modals */}

      {/* #region DirectControl Modals */}
      <ModalWrapper
        visible={showSaveDirectControlSuccessModal}
        onDismiss={() => {
          showSaveDirectControlPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSaveDirectControlPopUp();
          }}
          heading="High Energy Direct Control"
          content={directControlNameSuccessModal}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteDirectControlConfirmModal}
        onDismiss={() => {
          confirmDeleteDirectControl();
        }}
      >
        <DeletePopup
          closePopup={() => {
            confirmDeleteDirectControl();
          }}
          deleteValue={() => handleDeleteDirectControl(deleteDirectControlId)}
          heading="High Energy Direct Control"
          content={deleteDirectControlName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteDirectControlSuccessModal}
        onDismiss={() => {
          showDeleteDirectControlSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteDirectControlSuccessPopup();
          }}
          heading="High Energy Direct Control"
          content={deleteDirectControlName}
        />
      </ModalWrapper>
      {/* #endregion Direct Control Modals */}

      {/* #region Safe Guard Modals */}
      <ModalWrapper
        visible={showSaveSafeGuardSuccessModal}
        onDismiss={() => {
          showSaveSafeGuardPopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSaveSafeGuardPopUp();
          }}
          heading="High Energy Safe Guard"
          content={safeGuardNameSuccessModal}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSafeGuardConfirmModal}
        onDismiss={() => {
          confirmDeleteSafeGuard();
        }}
      >
        <DeletePopup
          closePopup={() => {
            confirmDeleteSafeGuard();
          }}
          deleteValue={() => handleDeleteSafeGuard(deleteSafeGuardId)}
          heading="High Energy Safe Guard"
          content={deleteSafeGuardName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSafeGuardSuccessModal}
        onDismiss={() => {
          showDeleteSafeGuardSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteSafeGuardSuccessPopup();
          }}
          heading="High Energy Safe Guard"
          content={deleteSafeGuardName}
        />
      </ModalWrapper>
      {/* #endregion SafeGuard Modals */}
    </div>
  );
};
export default HEC;
