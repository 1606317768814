import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance, fileUploadInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";


//#region [[ Get High Energy Details]]
export const getHighEnergyData = createAsyncThunk(
  "hec/getHighEnergyData",
  async ({ highEnergyId }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.get(`${EndPoints.hecData}?taskId=${highEnergyId}`);
      if (res?.data?.data != null && res?.data?.statusCode === 200) {
        let iconData = [...res?.data?.data?.highEnergyIcons];
        let newIconData = [];
        for (let i = 0; i < iconData.length; i++) {
          let element = { ...iconData[i] };
          if (element?.path) {
            try {
              let fileStreamData = await fetchImages(element.path);
              newIconData.push({ ...element, imgBase64Data: fileStreamData });
            } catch (err) {
              newIconData.push({ ...element, imgBase64Data: null });
            }
          } else {
            newIconData.push({ ...element, imgBase64Data: null });
          }
        }
        res.data.data.highEnergyIcons = newIconData;
        thunkAPI.dispatch(setLoader(false));
        return res.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
//#endregion [[ Ends Get High Energy Details]]

//#region [[ High Energy]]
export const getHighEnergies = createAsyncThunk(
  "hec/getHighEnergies",
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      let result = await sharedInstance.get(
        EndPoints.hec
      );

      if (result?.data?.data?.length > 0 && result?.data?.statusCode === 200) {
        dispatch(setLoader(false));
        return result.data;
      } else {
        throw Error();
      }
    }
    catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const saveHighEnergy = createAsyncThunk(
  "hec/saveHighEnergy",
  async (hecRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.hecSave, hecRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        hecRequest.saveSuccess(res.data.data, hecRequest.highEnergyName);
        return hecRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateHighEnergy = createAsyncThunk(
  "hec/updateHighEnergy",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(EndPoints.hecUpdate, args);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        args.saveSuccess(res.data.data, args.highEnergyName);
        return args;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);


export const deleteHighEnergy = createAsyncThunk(
  "hec/deleteHighEnergy",
  async ({ highEnergyId, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(`${EndPoints.hecDelete}?highEnergyId=${highEnergyId}`);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(highEnergyId);
        return highEnergyId;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
//#endregion [[ High Energy]]

//#region [[File (Icon) Operations]]

export const saveFile = createAsyncThunk(
  "hec/saveFile",
  async (hecIconRequest, thunkAPI) => {

    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "High Energy Hazardous & Controls",
        actionName: "Add High Energy Icon",
        message: "Request - High Energy Icon Upload",
        thunkApi: thunkAPI,
      });

      let fileName = "";
      if (hecIconRequest?.image) {
        fileName = hecIconRequest?.image?.name.replace(/[^a-zA-Z0-9.]/g, '')
      } else {
        fileName = "";
      }
      const formData = new FormData();
      if (fileName !== "")
        formData.append("File", hecIconRequest.image, fileName);
      else
        formData.append("File", hecIconRequest.image);

      formData.append("Id", hecIconRequest.id);
      formData.append("IconName", fileName);
      formData.append("TaskId", hecIconRequest.taskId);

      let res = await sharedInstance.post(EndPoints.hecSaveFile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "High Energy Hazardous & Controls",
          actionName: "Add High Energy Icon",
          message: res?.data,
          thunkApi: thunkAPI,
        });

        hecIconRequest.saveSuccess(res.data.data, fileName);
        return hecIconRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "High Energy Hazardous & Controls",
          actionName: "Add High Energy Icon",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "High Energy Hazardous & Controls",
        actionName: "Add High Energy Icon",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "hec/deleteFile",
  async ({ iconId, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(`${EndPoints.hecDeleteFile}?iconId=${iconId}`);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(iconId);
        return iconId;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

//#endregion [[File (Icon) Operations]]

//#region [[ High Energy Task]]
export const saveHighEnergyTask = createAsyncThunk(
  "hec/saveHighEnergyTask",
  async (hecTaskRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.hecSaveHighEnergyTask, hecTaskRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        hecTaskRequest.saveSuccess(res.data.data, hecTaskRequest.highEnergyNames);
        return hecTaskRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateHighEnergyTask = createAsyncThunk(
  "hec/updateHighEnergyTask",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(EndPoints.hecUpdateHighEnergyTask, args);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        args.saveSuccess(args.highEnergyNames)
        return args;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteHighEnergyTask = createAsyncThunk(
  "hec/deleteHighEnergyTask",
  async ({ taskId, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(`${EndPoints.hecDeleteHighEnergyTask}?taskId=${taskId}`);
      // let res = await sharedInstance.delete(EndPoints.hecDeleteHighEnergyTask, hecDeleteTaskRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(taskId);
        return taskId;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
//#endregion [[ High Energy Task]]

//#region [[ High Energy Hazard]]
export const saveHighEnergyHazard = createAsyncThunk(
  "hec/saveHighEnergyHazard",
  async (hecHazardRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.hecSaveHighEnergyHazard, hecHazardRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        hecHazardRequest.saveSuccess(res.data.data, hecHazardRequest.hazardName)
        return hecHazardRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateHighEnergyHazard = createAsyncThunk(
  "hec/updateHighEnergyHazard",
  async (hecUpdateHazardRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(EndPoints.hecUpdateHighEnergyHazard, hecUpdateHazardRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        hecUpdateHazardRequest.saveSuccess(hecUpdateHazardRequest.hazardName)
        return hecUpdateHazardRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteHighEnergyHazard = createAsyncThunk(
  "hec/deleteHighEnergyHazard",
  async ({ hazardId, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(`${EndPoints.hecDeleteHighEnergyHazard}?hazardId=${hazardId}`);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(hazardId);
        return hazardId;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
//#endregion [[ High Energy Hazard]]

//#region [[ High Energy  DirectControls And SafeGuard ]]
export const saveHighEnergyDirectControlsAndSafeGuard = createAsyncThunk(
  "hec/saveHighEnergyDirectControlsAndSafeGuard",
  async (hecDrectControlsAndSafeGuardsRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.hecSaveHighEnergyDirectControlsAndSafeGuard, hecDrectControlsAndSafeGuardsRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        hecDrectControlsAndSafeGuardsRequest.saveSuccess(res.data.data, hecDrectControlsAndSafeGuardsRequest.directControlSafegaurdsName);
        return hecDrectControlsAndSafeGuardsRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateHighEnergyDirectControlsAndSafeGuard = createAsyncThunk(
  "hec/updateHighEnergyDirectControlsAndSafeGuard",
  async (hecUpdateDirectControlsAndSafeGuardRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(EndPoints.hecUpdateHighEnergyDirectControlsAndSafeGuard, hecUpdateDirectControlsAndSafeGuardRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        hecUpdateDirectControlsAndSafeGuardRequest.saveSuccess(hecUpdateDirectControlsAndSafeGuardRequest.directControlSafegaurdsName)
        return hecUpdateDirectControlsAndSafeGuardRequest;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteHighEnergyDirectControlsAndSafeGuard = createAsyncThunk(
  "hec/deleteHighEnergyDirectControlsAndSafeGuard",
  async ({ directControlId, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(`${EndPoints.hecDeleteHighEnergyDirectControlsAndSafeGuard}?directControlsSafeGaurdId=${directControlId}`);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(directControlId);
        return directControlId;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
//#endregion [[ High Energy  DirectControls And SafeGuard]]

//#region [[ Delete HighEnergy Icon, Task, Hazard, DirectControls & SafeGuard ]]
export const deleteHighEnergyTaskHazardDirectControls = createAsyncThunk(
  "hec/deleteHighEnergyTaskHazardDirectControls",
  async ({ highEnergyDeleteRequest, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.delete(EndPoints.hecDeleteHighEnergyTaskHazardDirectControls, highEnergyDeleteRequest);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(highEnergyDeleteRequest.highEnergyId);
        return res.data.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
//#endregion [[ Delete HighEnergy Icon, Task, Hazard, DirectControls & SafeGuard ]]

const fetchImages = async (filePath, thunkApi) => {
  try {
    callLoggerInfo({
      screenName: "Look up Management - Hazardous Energies & Control",
      actionName: "Fetch High energy Icon",
      message: { filePath: filePath },
      thunkApi: thunkApi,
    });

    let res = await fileUploadInstance.post(EndPoints.fetchUploadFile, {
      filePath: filePath,
    });
    if (res?.data?.data?.streamData) {
      callLoggerInfo({
        screenName: "Look up Management - Hazardous Energies & Control",
        actionName: "Fetch High energy Icon",
        message: res?.data,
        thunkApi: thunkApi,
      });

      return res.data?.data?.streamData;
    } else {
      callLoggerError({
        errorCode: res?.data?.statusCode,
        errorDetails: res?.data?.message,
        screenName: "Look up Management - Hazardous Energies & Control",
        actionName: "Fetch High energy Icon",
        thunkApi: thunkApi,
      });

      return null;
    }
  } catch (error) {
    let errorCode = error?.response?.status || error?.code;
    let errorMessage = error?.response?.statusText || "Sorry, unexpected error!";

    callLoggerError({
      errorCode: errorCode,
      errorDetails: errorMessage,
      screenName: "Look up Management - Hazardous Energies & Control",
      actionName: "Fetch High energy Icon",
      thunkApi: thunkApi,
    });
  }
};

export const fetchImages1 = createAsyncThunk(
  "hec/fetchImages",
  async (imagePath, thunkAPI) => {
    try {
      let res = await fileUploadInstance.post(EndPoints.fetchUploadFile, {
        filePath: imagePath,
      });
      if (res?.data?.data && res?.data?.data?.streamData && res?.data?.statusCode === 200) {
        callLoggerInfo({
          screenName: "Look up Management - Hazardous Energies & Control",
          actionName: "Fetch High Energy Icon",
          message: res?.data,
          thunkApi: thunkAPI,
        });

        return res.data?.data?.streamData;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Look up Management - Hazardous Energies & Control",
          actionName: "Fetch High Energy Icon",
          thunkApi: thunkAPI,
        });

        return null;
      }
    } catch (error) {
      let errorCode = error?.response?.status || error?.code;
      let errorMessage = error?.response?.statusText || "Sorry, unexpected error!";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Look up Management - Hazardous Energies & Control",
        actionName: "Fetch High Energy Icon",
        thunkApi: thunkAPI,
      });
    }
  }
);