import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

export const fetchConfigurationDetails = createAsyncThunk(
  "configuration/fetchConfigurationDetails",
  async (dispatch, thunkAPI) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Configurations",
        actionName: "Get Configuration Details",
        message: "Request - Configuration Details",
        thunkApi: thunkAPI,
      });

      let res = await sharedInstance.get(EndPoints.configuration);
      if (res?.data?.data && res?.data?.statusCode === 200) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Configurations",
          actionName: "Get Configuration Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        return res?.data?.data;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Configurations",
          actionName: "Get Configuration Details",
          thunkApi: thunkAPI,
        });
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";

      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Configurations",
        actionName: "Get Configuration Details",
        thunkApi: thunkAPI,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const updateConfigurationDetails = createAsyncThunk(
  "configuration/updateConfigurationDetails",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Configurations",
        actionName: "Update Configuration Details",
        message: "Request - Configuration Details",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.put(
        EndPoints.updateConfiguration,
        args.data
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Configurations",
          actionName: "Update Configuration Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        args.saveSuccess();
        if (args.callBackFn) {
          args.callBackFn(true)
        }
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.statusText || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Configurations",
          actionName: "Update Configuration Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Configurations",
        actionName: "Update Configuration Details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
