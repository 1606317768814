import React, { useState, useEffect } from "react";
import "./template.scss";
import Labels from "../../Constants/Label";
import { TabView, TabPanel } from "primereact/tabview";
import Common from "./Common";
import Electric from "./Electric";
import Gas from "./Gas";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { FetchTemplates } from "../../Service/TemplateManagement";

const Template = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchTemplates(dispatch));
  }, []);

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  const templateDetails = useSelector(
    (state) => state?.template?.templatesData
  );

  let commonArr = [];
  let electricCommonArr = [];
  let gasCommonArr = [];
  let otherArr = [];
  const CommonData = templateDetails?.map((item) => {
    switch (item.fieldInternalName) {
      case Labels?.TemplateCommon?.WorkLocation:
        commonArr.push(item);
        break;
      case Labels?.TemplateCommon?.IsMusterPoint:
        commonArr.push(item);
        break;
      case Labels?.TemplateCommon?.MusterPointLoc:
        commonArr.push(item);
        break;
      case Labels?.TemplateCommon?.Landmark:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Datetime:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Weather:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.AdditionalHazards:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.MitigationMeasures:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Communication:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.CellPhone:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.RadioCheck:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Radio:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.WorkLocationAddress:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.CrewMembersDetail:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.CrewOthersDetail:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.NearestMedicalFacility:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.MedicalConsiderations:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Measures:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.EmergencyEquipAvailable:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.EmergencyReview:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.RescuePlan:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.WorkZoneVehicularActivity:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.MUTCDPlan:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.PublicSafety:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Housekeeping:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.PublicAccessSecured:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.Sidewalks:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.SignsDeployed:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.HousekeepingSite:
        commonArr = [...commonArr, item];
        break;
      case Labels?.TemplateCommon?.HousekeepingUnique:
        commonArr = [...commonArr, item];
        break;

      case Labels?.ElectricCommon?.ElectricCircuitInformation:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.MultiCircuitExposure:
        // electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.Circuit2:
        // electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.Circuit3:
        // electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.Circuit4:
        // electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.NoOfCircuits:
        // electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.FlaggersDetails:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.TrafficFlow:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.BucketBoom:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.HeavyEquipment:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.Vehicle:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.SpottersQualified:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.MinimumDistance:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.HPTools:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.ESOPLink:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.ExcavationActivities:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.SpecificHazards:
        // electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.WorkIsEnergized:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.GroundingRequirement:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.GroundingPlan:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.PgMad:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.PpMad:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.LatentTraps:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.JobDescription:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.ConfinedSpace:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.CBYDDigSafe:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.EASupervisor:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.EDSupervisor:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.MarkOutsValidation:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.CableAvoidance:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.ExcavationDepth:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.Identification:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.IdentifiedSpotter:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.Verbal:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.HandSignal:
        electricCommonArr = [...electricCommonArr, item];
        break;
      case Labels?.ElectricCommon?.GeneralJobNotes:
          electricCommonArr = [...electricCommonArr, item];
          break;
      case Labels?.ElectricCommon?.SWIJobs:
        break;
      case Labels?.TemplateCommon?.PPE:
        if (item.formType === "Electric") {
          electricCommonArr = [...electricCommonArr, item];
          break;
        }
      case Labels?.TemplateCommon?.OtherTask:
        if (item.formType === "Electric") {
          electricCommonArr = [...electricCommonArr, item];
          break;
        }

      case Labels?.GasCommon?.MaterialType:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.PressureType:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.UtilitiesPresent:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.GasMeter:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.WorkTask:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.CS:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.Procedures:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.SignOff:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.AOC:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.HazardControlPlan:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.EI:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.MarkOut:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.CGIDate:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.CGISerial:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.Others:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.ProcedureRequired:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.JSPs:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.SecondJSPs:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.Potential:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.Asbestos:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.PCBWork:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.MRW:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.SpillKits:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.MaterialsAvailable:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.Precautions:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.ControlRisk:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.GasCommon?.GreatestRisk:
        gasCommonArr = [...gasCommonArr, item];
        break;
      case Labels?.TemplateCommon?.PPE:
        if (item.formType === "Gas") {
          gasCommonArr = [...gasCommonArr, item];
          break;
        }
      case Labels?.TemplateCommon?.OtherTask:
        if (item.formType === "Gas") {
          gasCommonArr = [...gasCommonArr, item];
          break;
        }

      default:
        otherArr = [...otherArr, item];
        break;
    }
  });

  const uniqueCommonData = commonArr.reduce((dataArr, currentData) => {
    const existingObject = dataArr.find(
      (item) => item.fieldInternalName === currentData.fieldInternalName
    );
    if (!existingObject) {
      dataArr.push(currentData);
    }
    return dataArr;
  }, []);

  const uniqueElectricData = electricCommonArr.reduce(
    (dataArr, currentData) => {
      const existingObject = dataArr.find(
        (item) => item.fieldInternalName === currentData.fieldInternalName
      );
      if (!existingObject) {
        dataArr.push(currentData);
      }
      return dataArr;
    },
    []
  );

  const uniqueGasData = gasCommonArr.reduce((dataArr, currentData) => {
    const existingObject = dataArr.find(
      (item) => item.fieldInternalName === currentData.fieldInternalName
    );
    if (!existingObject) {
      dataArr.push(currentData);
    }
    return dataArr;
  }, []);

  const uniqueData = otherArr.reduce((dataArr, currentData) => {
    const existingObject = dataArr.find(
      (item) => item.fieldInternalName === currentData.fieldInternalName
    );
    if (!existingObject) {
      dataArr.push(currentData);
    }
    return dataArr;
  }, []);

  let electricData = [];
  let gasData = [];
  const specdata = otherArr?.map((item, index) => {
    switch (item?.formType) {
      case "Electric":
        electricData = [...electricData, item];
        break;
      case "Gas":
        gasData = [...gasData, item];
        break;
    }
  });

  return (
    <div className="template-title">
      {isLoading && <Loader />}
      <header className="template-header">{Labels?.Sidebar?.Template}</header>
      <div className="template-content">
        <TabView>
          <TabPanel header="Common" id="tabPanel_temp_common" className="tabPanel_temp_common">
            {uniqueCommonData?.length > 0 && (
              <Common commonData={uniqueCommonData} />
            )}
          </TabPanel>
          <TabPanel header="Electric" id="tabPanel_temp_electric" className="tabPanel_temp_electric">
            {uniqueElectricData.length > 0 && electricData.length > 0 && (
              <Electric
                electricCommonData={uniqueElectricData}
                electricData={electricData}
              />
            )}
          </TabPanel>
          <TabPanel header="Gas" id="tabPanel_temp_gas" className="tabPanel_temp_gas">
            {uniqueGasData.length > 0 && gasData.length > 0 && (
              <Gas gasCommonData={uniqueGasData} gasData={gasData} />
            )}
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Template;
