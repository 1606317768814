import React from "react";
import { useMsal } from "@azure/msal-react";
import "./auth.scss";
import { Button } from "primereact/button";
import esLogo from "../../Assets/Images/esLogo.png";
import eBriefLogo from "../../Assets/Images/eBriefLogo.png";
import { scopes } from "./Config";

function Auth() {
  const { instance } = useMsal();

  const handleLoginPopup = async () => {
    try {
      await instance.loginRedirect({
        // scopes: ['user.read'],
        scopes: scopes,
        redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URL,
      });
    } catch (error) {
      console.log("Authentication error:", error);
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="login-section">
        <img id="img_esLogo" className="es-logo" src={eBriefLogo} alt="e-brief Logo"></img>
        <p className="admin-portal">ADMIN PORTAL</p>
        <p className="from">from</p>
        <img id="img_esImage" className="es-image" src={esLogo} alt="eversource logo"></img>
        <Button id="btn_AuthLogin"
          className="login-button"
          label="Let's Get Started"
          severity="info"
          onClick={handleLoginPopup}
        />
      </div>
    </div>
  );
}

export default Auth;
