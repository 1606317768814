import { createSlice } from '@reduxjs/toolkit';
import { DeleteMUTCDPlan, FetchMUTCDPlan, UpdateMUTCDPlan} from '../../Service/MUTCDplan';

const initialState = {
    mutcdPlanData: [],
    loading: false,
  };


export const MUTCDPlanSlice = createSlice({
    name: 'mutcdPlan',
    initialState,
      reducers: {},
      extraReducers: (builder) => {
          builder.addCase(FetchMUTCDPlan.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(FetchMUTCDPlan.fulfilled, (state, action) => {
              state.mutcdPlanData = action.payload
              console.log( "storeMD",state.mutcdPlanData)
              state.loading = false
              state.error = ''
          })
          builder.addCase(FetchMUTCDPlan.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(DeleteMUTCDPlan.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(DeleteMUTCDPlan.fulfilled, (state, action) => {
              state.mutcdPlanData = state.mutcdPlanData.filter((item) => item.mutcdId !== action?.payload?.id)
              console.log("slice",action.payload,state.mutcdPlanData)
              state.loading = false
              state.error = ''
          })
          builder.addCase(DeleteMUTCDPlan.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
        //   builder.addCase(addEmergencyEquipment.pending, (state) => {
        //       state.loading = true
        //       state.error = ''
        //   })
        //   builder.addCase(addEmergencyEquipment.fulfilled, (state, action) => {
        //       state.emergencyEquipmentsData.push(action.payload);
        //       state.loading = false
        //       state.error = ''
        //   })
        //   builder.addCase(addEmergencyEquipment.rejected, (state) => {
        //       state.formData = []
        //       state.loading = false
        //       state.error = 'Something went wrong'
        //   })
          builder.addCase(UpdateMUTCDPlan.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(UpdateMUTCDPlan.fulfilled, (state, action) => {
            let arr = [...state.mutcdPlanData]
            console.log("action",action,state)

            const index = arr.findIndex((value) => value.mutcdId === action.payload.mutcdId);
            console.log("11111",index,arr)
              arr[index] = action.payload
              state.mutcdPlanData = arr;
              state.loading = false
              state.error = ''
          })
          builder.addCase(UpdateMUTCDPlan.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
      },
  })
  
  export default MUTCDPlanSlice.reducer