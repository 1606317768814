import React, { useEffect, useState, useRef } from "react";
import {
  fetchppedetails,
  addppedetails,
  deleteppedetails,
  updateppedetails,
} from "../../../Service/PPE";
import { useDispatch, useSelector } from "react-redux";
import { TabPanel, TabView } from "primereact/tabview";
import "./ppeindex.scss";
import ModalWrapper from "../../GenericPopup";
import SaveSuccess from "../../GenericPopup/commonSavePopup";
import DeletePopUp from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/commonDeletePopup";
import editIcon from "../../../Assets/Images/edit.png";
import trash from "../../../Assets/Images/trash.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import { setError } from "../../../Store/errorSlice";
const FetchPPE = () => {
  // State for the "Add" section
  const [formName, settemplateName] = useState("");
  const [showDeleteSuccess, setshowDeleteSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [ppes, setppEs] = useState([]);
  const DEFAULT_TRANS_GROUP = "Standard PPE";

  const [filteredData, setTransfilteredData] = useState([]);
  const [distfilteredData, setDistfilteredData] = useState([]);
  const [transuniqueGroups, setTransuniqueGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_TRANS_GROUP);
  const [selectedTransgroup, setSelectedTransgroup] = useState(DEFAULT_TRANS_GROUP);
  const [newPPE, setNewPPE] = useState(false);
  const [editppe, setEditppe] = useState(false);
  const [id, setId] = useState(0);
  const [editName, setEditName] = useState("");
  const [newName, setNewName] = useState("");
  const [transppegroup, setTransppegroup] = useState([]);
  const [distppegroup, setDistppegroup] = useState([]);
  const [electricStationgroup, setElectricStationgroup] = useState([]);
  const [gasdistgroup, setGasdistgroup] = useState([]);
  const [gasinstgroup, setGasinstgroup] = useState([]);
  const [gasmeterservicegroup, setGasmeterservicegroup] = useState([]);
  const [show, setShow] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [editenable, setEditEnable] = useState(false);
  const [currentindex, setCurrentindex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubIndex, setActiveSubIndex] = useState(0);
  const [newEnable, setNewEnable] = useState(false);
  const [valueName, setValueName] = useState("");
  const [editNameError, setEditNameError] = useState(false);
  const [nameDup, setNameDup] = useState(false);

  const ref = useRef();
  const newFieldRef = useRef(null);
  const regexPattern = /^[0-9]\-]$/;

  const dispatch = useDispatch();

  // Use useSelector to get ppes from Redux state
  const ppeDetails = useSelector(
    (state) => state?.ppe?.ppeData || []
  );

  useEffect(() => {
    dispatch(fetchppedetails());
  }, []);
  const templateNameMapping = {
    'distribution': "Electric - Distribution Lines",
    'stations': "Electric - Stations/Field Engineering/Communication & Control",
    'transmission': "Electric - Transmission Lines",
    'Gasdistribution': "Gas - Distribution/Construction",
    'Gasmeter': "Gas - Meter Service",
    'GasIR': "Gas - Instrumentation & Regulation",
    // Add more mappings as needed
  };
  useEffect(() => {
    // Initialize the local state with data from Redux
    setppEs(ppeDetails);
    const filterData = () => {
      const groups = new Set();
      const transuniqueSubGroups = [
        ...new Set(
          ppeDetails
            .filter((item) => item?.groupName !== null && item?.formName === templateNameMapping['transmission'])
            .map((item) => item?.groupName)
        ),
      ];
      const ElectricStationGroup = [
        ...new Set(
          ppeDetails
            .filter((item) => item?.groupName !== null && item?.formName === templateNameMapping['stations'])
            .map((item) => item?.groupName)
        ),
      ];
      const GasDistGroup = [
        ...new Set(
          ppeDetails
            .filter((item) => item?.groupName !== null && item?.formName === templateNameMapping['Gasdistribution'])
            .map((item) => item?.groupName)
        ),
      ];
      const GasInstRegGroup = [
        ...new Set(
          ppeDetails
            .filter((item) => item?.groupName !== null && item?.formName === templateNameMapping['GasIR'])
            .map((item) => item?.groupName)
        ),
      ];
      const GasMeterGroup = [
        ...new Set(
          ppeDetails
            .filter((item) => item?.groupName !== null && item?.formName === templateNameMapping['Gasmeter'])
            .map((item) => item?.groupName)
        ),
      ];
      const distuniqueSubGroups = [
        ...new Set(
          ppeDetails
            .filter((item) => item?.groupName !== null && item?.formName === templateNameMapping['distribution'])
            .map((item) => item?.groupName)
        ),
      ];
      let transfiltered = ppeDetails.filter((item) => {
        groups.add(item?.formName);
        return true;
      });
      transfiltered = [
        ppeDetails.filter(
          (item) => item?.formName === templateNameMapping['transmission']
        ),
      ];
      let distfiltered = ppeDetails.filter((item) => {
        groups.add(item?.formName);
        return true;
      });
      distfiltered = [
        ppeDetails.filter(
          (item) => item?.formName === templateNameMapping['distribution']
        ),
      ];
      setTransfilteredData(transfiltered);
      setDistfilteredData(distfiltered);
      setTransuniqueGroups(Array.from(groups));
      setTransppegroup(transuniqueSubGroups);
      setElectricStationgroup(ElectricStationGroup);
      setGasdistgroup(GasDistGroup)
      setGasinstgroup(GasInstRegGroup)
      setGasmeterservicegroup(GasMeterGroup)
      setDistppegroup(distuniqueSubGroups);
    };
    if (ppeDetails.length > 0) {
      filterData();
    }
  }, [ppeDetails]);

  // Group data by formType and formName
  const groupedData = {};
  ppes.forEach((item) => {
    if (!groupedData[item?.formType]) {
      groupedData[item?.formType] = {};
    }
    if (!groupedData[item?.formType][item?.formName]) {
      groupedData[item?.formType][item?.formName] = [];
    }
    groupedData[item?.formType][item?.formName].push(item);
  });
  // Function to handle editing an emergency item
  const handleEdit = (id) => {
    setNewPPE(false);
    setId(id);
    ppeDetails.map((data) => {
      if (data?.id === id) {
        setValueName(data?.ppe);
        setEditName(data?.ppe);
        setEditppe(true);
      }
    });
  };

  // Function to handle enabling/disabling the "Add" section
  const handleNewPPE = (formType, formName) => {
    setEditppe(false);
    setEditEnable(false);
    setId(0);
    setNameDup(false);
    // Update the AddEnable state for the specific tab panel  
    var messageBody = document.querySelector('.scroll');
    messageBody.scrollTop = 40000;
    setNewPPE((prevState) => ({
      ...prevState,
      ['${formType}_${formName}']: true,
    }));
  };

  function showDeleteSuccessPopup() {  
     setShowDelete(false);    
  }

  const confirmDeleteHandle = (id) => {   
    dispatch(deleteppedetails({
      id,
      deleteSuccess: showDeleteSuccessPopup,
    })
    )
      .then(() => {
        // After deleting, remove the item from local state
        const updatedppe = ppes.filter(
          (item) => item.id !== id
        );
        setppEs(updatedppe);
      })
      .catch((error) => {
        setError(error);
      });

    setShowDelete(false);
    setshowDeleteSuccess(true);
  };

  const handleClick = () => {
    setShowOptions(!showOptions);
    setNewPPE(false);
  };

  const handleStateChange = (value) => {
    setSelectedGroup(value);
    setShow(false);
  };
  const handleTransStateChange = (value) => {
    setSelectedTransgroup(value);
    setShow(false);
  };


  // const handleHeader = (e) => {
  //   if (currentindex !== e.index) {
  //     setActiveIndex(e.index);
  //     setNewPPE(false);
  //     setNewName("");
  //     // setSelectedGroup(DEFAULT_TRANS_GROUP);
  //   }
  // };

  function showDeletePopUp(id, groupName, ppe, formName) {
    let count = 0;
    setDeleteId(id);
    setValueName(ppe);
    setShowDelete((pre) => !pre);
  }

  const handleEditName = (e) => {
    const val = e.target.value;
    setNameDup(false);
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val.length >= 100) {
      setEditName(val);
      setEditEnable(true);
      setEditNameError(true);
    }
  };

  const handleNewName = (e) => {
    const val = e.target.value;
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val === '') {
      setNewName(val);
      setNewEnable(true);
    }
  };

  const handleClose = () => {
    setNewPPE(false);
    setNewName("");
    setNewEnable(false);
    setNameDup(false);
  };

  const handleEditClose = () => {
    setEditppe(false);
    setEditEnable(false);
    setId(0);
    setNameDup(false);
  };
  //api call for updating the data
  const handleUpdate = (
    id,
    ppe,
    template_Id,
    formName,
    groupId,
    groupName
  ) => {

    let selected_Group = '';
    let templateId = 0;
    switch (formName) {
      case templateNameMapping['distribution']:
        selected_Group = selectedGroup;
        templateId = 1;
        break;
      case templateNameMapping['transmission']:
        selected_Group = selectedTransgroup;
        templateId = 3;
        break;
      case templateNameMapping['stations']:
        selected_Group = (electricStationgroup && electricStationgroup.length > 0) ? electricStationgroup[0] : '';
        templateId = 2;
        break;
      case templateNameMapping['Gasdistribution']:
        selected_Group = (gasdistgroup && gasdistgroup.length > 0) ? gasdistgroup[0] : '';
        templateId = 4;
        break;
      case templateNameMapping['GasIR']:
        selected_Group = (gasinstgroup && gasinstgroup.length > 0) ? gasinstgroup[0] : '';
        templateId = 6;
        break;
      case templateNameMapping['Gasmeter']:
        selected_Group = (gasmeterservicegroup && gasmeterservicegroup.length > 0) ? gasmeterservicegroup[0] : '';
        templateId = 5;
        break;
      default:
        return;
    }

    const isDuplicate = ppeDetails.some((data) => data?.id === id && data?.formName === formName && data?.groupName === groupName && (data?.ppe.toLowerCase().trim() === ppe.toLowerCase().trim()))
    if (!isDuplicate) {     
      dispatch(
        updateppedetails({
          id,
          formId : templateId,
          groupId,
          ppe,
          saveSuccess: showEditedSavePopUp,
        })
      );
      setEditppe(false);
      setId(0);
      setNameDup(false);
      setEditNameError(false);
    } else {
      setNameDup(true);
    }
  };


  const handleAddContact = (
    ppe,
    active,
    selectedGroup,
    formName
  ) => {
    let selected_Group = '';
    let templateId = 0;
    switch (formName) {
      case templateNameMapping['distribution']:
        selected_Group = selectedGroup;
        templateId = 1;
        break;
      case templateNameMapping['transmission']:
        selected_Group = selectedTransgroup;
        templateId = 3;
        break;
      case templateNameMapping['stations']:
        selected_Group = (electricStationgroup && electricStationgroup.length > 0) ? electricStationgroup[0] : '';
        templateId = 2;
        break;
      case templateNameMapping['Gasdistribution']:
        selected_Group = (gasdistgroup && gasdistgroup.length > 0) ? gasdistgroup[0] : '';
        templateId = 4;
        break;
      case templateNameMapping['GasIR']:
        selected_Group = (gasinstgroup && gasinstgroup.length > 0) ? gasinstgroup[0] : '';
        templateId = 6;
        break;
      case templateNameMapping['Gasmeter']:
        selected_Group = (gasmeterservicegroup && gasmeterservicegroup.length > 0) ? gasmeterservicegroup[0] : '';
        templateId = 5;
        break;
      default:
        return;
    }

    const group_Id = [
      ...new Set(
        ppeDetails
          .filter((item) => item?.groupName === selected_Group && item?.formName === formName)
          .map((item) => item?.ppeGroupID)
      ),
    ];
    let groupId = (group_Id && group_Id.length > 0) ? group_Id[0] : 0

    const isDuplicate = ppeDetails.some((data) => data?.formName === formName && data?.groupName === selected_Group && (data?.ppe.toLowerCase().trim() === newName.toLowerCase().trim()))

    if (!isDuplicate) {
      dispatch(
        addppedetails({
          formId: templateId,
          groupId,
          ppe,
          saveSuccess: showSavePopUp,
        })
      );
      setNameDup(false);
      setNewPPE(false);
      setNewName("");
    } else {
      setNameDup(true);
    }
  };

  function showEditedSavePopUp(value) {
    dispatch(fetchppedetails());
    setValueName(value.ppe);
    setShowSaveSuccess((pre) => !pre);
    setEditppe(false);
    setNewPPE(false);
    setNewName("");
    setNewEnable(false);
    setId(0);
  }


  function showSavePopUp(value) {
    setValueName(value.ppe);
    setShowSaveSuccess((pre) => !pre);
    setEditppe(false);
    setNewPPE(false);
    setNewName("");
    setNewEnable(false);
    setId(0);
  }

  const handleHeader = (e) => {
  
     setActiveIndex(e.index);
   // if (currentindex !== e.index) {
      setValueName('');
      setEditName('');
      setEditppe(false);
      setNewPPE(false);
      setNewName("");
      setNewEnable(false);
      
    //  setSelectedGroup(DEFAULT_CITY);
   // }
  };
  const handleSubHeader = (e) => {
     setActiveSubIndex(e.index);
   // if (currentindex !== e.index) {
      setValueName('');
      setEditName('');
      setEditppe(false);
      setNewPPE(false);
      setNewName("");
      setNewEnable(false);
      
    //  setSelectedGroup(DEFAULT_CITY);
   // }
  };

  return (
    <div className="ppe-wrapper">
      <TabView activeIndex={activeIndex} onTabChange={(e) => handleHeader(e)}>
        {Object.keys(groupedData).map((formType) => (
          <TabPanel header={`${formType}`} key={formType} id={"tabPanel_ppe"}>
            <div className="tab-panels">
              <TabView activeIndex={activeSubIndex} class='noscroll'  onTabChange={(e) => handleSubHeader(e)}>
                {Object.keys(groupedData[formType]).map((formName) => (
                  <TabPanel
                    header={` ${(formName.split(" - ")[1] || "").trim()}`}
                    key={formName}
                    id={"tabPanel_ppe_elc"}
                  >
                    <div className="scroll" >
                      <table className="ppe-data">
                        {formName === templateNameMapping['distribution'] && (
                          <tr>
                            <div className="dropdown" ref={ref} onClick={() => handleClick()}>
                              <div
                                className="select-group"
                                id="grp"
                                onClick={() => setShow(true)}
                              >
                                {selectedGroup}
                              </div>
                              <div className="dropdown-caret">
                                <i
                                  className="pi pi-caret-down"
                                  onClick={() => setShow(true)}
                                ></i>
                              </div>
                              {show && showOptions && (
                                <div className="groups">
                                  {distppegroup.map((item, index) => {
                                    return (
                                      <div
                                        className="option-group"
                                        key={index}
                                        value={item}
                                        onClick={() => handleStateChange(item)}
                                      >
                                        {item}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </tr>
                        )}
                        {formName === templateNameMapping['transmission'] && (
                          <tr>
                            <div className="dropdown" ref={ref} onClick={() => handleClick()}>
                              <div
                                className="select-group"
                                id="grp"
                                onClick={() => setShow(true)}
                              >
                                {selectedTransgroup}
                              </div>
                              <div className="dropdown-caret">
                                <i
                                  className="pi pi-caret-down"
                                  onClick={() => setShow(true)}
                                ></i>
                              </div>
                              {show && showOptions && (
                                <div className="groups">
                                  {transppegroup.map((item, index) => {
                                    return (
                                      <div
                                        className="option-group"
                                        key={index}
                                        value={item}
                                        onClick={() => handleTransStateChange(item)}
                                      >
                                        {item}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </tr>
                        )}
                        {formName === templateNameMapping['stations'] && (
                          <tr>
                            <div className="grouptext" >
                              <>
                                {(electricStationgroup && electricStationgroup.length > 0) ? electricStationgroup[0] : ''}
                              </>
                            </div>
                          </tr>
                        )}
                        {formName === templateNameMapping['Gasdistribution'] && (
                          <tr>
                            <div className="grouptext" >
                              <>
                                {(gasdistgroup && gasdistgroup.length > 0) ? gasdistgroup[0] : ''}
                              </>
                            </div>
                          </tr>
                        )}
                        {formName === templateNameMapping['Gasmeter'] && (
                          <tr>
                            <div className="grouptext" >
                              <>
                                {(gasmeterservicegroup && gasmeterservicegroup.length > 0) ? gasmeterservicegroup[0] : ''}
                              </>
                            </div>
                          </tr>
                        )}
                        {formName === templateNameMapping['GasIR'] && (
                          <tr>
                            <div className="grouptext">
                              <>
                                {(gasinstgroup && gasinstgroup.length > 0) ? gasinstgroup[0] : ''}
                              </>
                            </div>
                          </tr>
                        )}
                      


                        {ppeDetails.map((item, index) => {
                          if (
                            item?.formName === formName &&
                            (item?.groupName === selectedGroup && formName !== templateNameMapping['transmission'] ||
                              (formName !== templateNameMapping['distribution'])) && (item?.groupName === selectedTransgroup && formName !== templateNameMapping['distribution'] ||
                                (formName !== templateNameMapping['transmission']))
                          ) {
                            return (

                              <>
                                {item?.id !== id && (
                                  <tr
                                    key={index}
                                    className="ppe-details"
                                    onChange={() => handleHeader(item?.formName)}
                                  >
                                    <td className="contact-name">{item?.ppe}</td>
                                    <td className="contact-edit">
                                      <img id="img_PPEeditIcon"
                                        src={editIcon}
                                        className="edit-icon"
                                        onClick={() => handleEdit(item?.id)}
                                      ></img>
                                    </td>
                                    <td className="contact-delete">
                                      <img id="img_PPEtrashIcon"
                                        src={trash}
                                        className="trash-icon"
                                        onClick={() =>
                                          showDeletePopUp(
                                            item?.id,
                                            item?.groupName,
                                            item?.ppe,
                                            item?.formName
                                          )
                                        }
                                      ></img>
                                    </td>
                                  </tr>
                                )}
                                {item?.id === id && editppe && (
                                  <tr className="ppe-details">
                                    <td className="edit-contact-name">
                                      <input id="txt_PPEEditName"
                                        className="edit-name"
                                        type="text"
                                        value={editName}
                                        maxLength="100"
                                        onChange={(e) => handleEditName(e)}
                                      ></input>
                                      {editNameError && editName.length >= 100 && (
                                        <div className="error-message">
                                          *Maximum number of characters should not exceed 100
                                        </div>
                                      )}
                                      {nameDup && <p className="edit-duplicate">*Cannot add duplicate values.</p>}
                                    </td>

                                    <td className="close-button">
                                      <img id="img_PPECancel"
                                        src={cancelIcon}
                                        className="cancel-icon"
                                        onClick={() => handleEditClose()}
                                      ></img>
                                    </td>
                                    <td>
                                      {editenable &&
                                        (editName.length !== 0 && editName.trim().length > 0 && editName.length <= 100) ? (
                                        <img id="img_PPEEditEnable"
                                          src={editEnable}
                                          className="edit-enable"
                                          onClick={() =>
                                            handleUpdate(
                                              id,
                                              editName,
                                              item?.templateId,
                                              formName,
                                              item?.ppeGroupID,
                                              formName === templateNameMapping['transmission'] ? selectedTransgroup : selectedGroup
                                            )
                                          }
                                        ></img>
                                      ) : (
                                        <img id="img_PPEEditDisable"
                                          src={editDisable}
                                          className="edit-disable"
                                        ></img>
                                      )}
                                    </td>

                                  </tr>
                                )}
                              </>
                            );
                          }
                        })}
                        {newPPE && (
                          <tr className="ppe-details">
                            <td className="new-ppe-name">
                              <input id="txt_PPEName"
                                className="new-name"
                                placeholder="PPE"
                                type="text"
                                value={newName}
                                maxLength="100"
                                onChange={(e) => handleNewName(e)}
                              ></input>                             
                              {newName.trim().length >= 100 && (
                                <div className="error-message">
                                  *Maximum number of characters should not exceed 100
                                </div>
                              )}
                              {nameDup && <p className="new-duplicate">*Cannot add duplicate values.</p>}
                            </td>
                            <td className="close-button">
                              <img id="img_PPEClose"
                                src={cancelIcon}
                                className="cancel-icon"
                                onClick={() => handleClose()}
                              ></img>
                            </td>
                            <td>
                              {(newName.length > 0 && newName.trim().length > 0 && newName.length <= 100) ? (
                                <img id="img_PPEEnableEdit"
                                  src={editEnable}
                                  className="edit-enable"
                                  onClick={() =>
                                    handleAddContact(
                                      newName,
                                      activeIndex,
                                      formName === templateNameMapping['transmission'] ? selectedTransgroup : selectedGroup,
                                      formName

                                    )
                                  }
                                ></img>
                              ) : (
                                <img id="img_PPEDisableEdit" src={editDisable} className="edit-disable"></img>
                              )}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                    <div className="footerPEE">
                      <button id="btn_PPE"
                        className="new-ppe-button"
                        onClick={() =>
                          handleNewPPE(formType, formName)
                        }
                      >
                        + PPE
                      </button>
                    </div>
                  </TabPanel>
                ))}
              </TabView>
            </div>
          </TabPanel>
        ))}
      </TabView>
      <ModalWrapper
        visible={showSaveSuccess}
        onDismiss={() => {
          showSavePopUp(valueName);
        }}
        tempName={formName}
        content={valueName}
      >
        <SaveSuccess
          closePopup={() => {
            showSavePopUp(valueName);
          }}
          heading="PPE Details"
          tempName={formName}
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDelete}
      >
        <DeletePopUp
          closePopup={() => {
            setShowDelete(false);
          }}
          deleteValue={() => {
            confirmDeleteHandle(deleteId);
          }}
          heading="PPE"
          content={valueName}
          tempName={formName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSuccess}
        onDismiss={() => {
          setshowDeleteSuccess(false);
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            setshowDeleteSuccess(false);
          }}
          heading="PPE"
          content={valueName}
          tempName={formName}
        />
      </ModalWrapper>
    </div>
  );
};

export default FetchPPE;
