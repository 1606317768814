import React, { useEffect } from "react";
import "./error.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetError } from "../../Store/errorSlice";
import { Button } from "primereact/button";
import { useMsal } from "@azure/msal-react";
import deleteIcon from '../../Assets/Images/delete.png';

function ErrorPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasError = useSelector((state) => state.errorSlice.hasError);
  const errorMessage = useSelector((state) => state.errorSlice.errorMessage);
  const { instance } = useMsal();

  useEffect(() => {
    if (!hasError) {
      navigate(-1);
    }
    return () => dispatch(resetError());
  }, []);

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  return (
    <div className="error_wrapper">
      <img id="img_deleteIcon" className="error-icon" src={deleteIcon}></img>
      <span className="error-heading">{errorMessage ? errorMessage : "Sorry, unexpected error!"}</span>
      <span className="error-message">We are working on fixing this problem, Be back soon.</span>
      <div className="error-button">
        <Button id="btn_ErrorHome" className="home" label="Home" onClick={() => navigate("/")} />
        <Button id="btn_ErrorLogout" className="logout" label="Logout" onClick={handleLogoutRedirect} />
      </div>
    </div>
  );
}

export default ErrorPage;
