import React, { useEffect, useState } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
import "./sidebar.scss";
import HyperLink from "../../Assets/Images/Hyperlink.png";
import Configuration from "../../Assets/Images/Configuration.png";
import Notifications from "../../Assets/Images/Notifications.png";
import Lookup from "../../Assets/Images/Lookup.png";
import Template from "../../Assets/Images/Template.png";
import Publish from "../../Assets/Images/Publish.png";
import { useSelector } from "react-redux";
import ModalWrapper from "../GenericPopup";
import SaveDataPopUp from "../GenericPopup/saveDataPopup";
import { useDispatch } from "react-redux";
import { setSave } from "../../Store/saveSlice";

const Sidebar = ({ children }) => {
  const sidebarItems = [
    {
      id: 1,
      path: "/",
      name: "Hyperlink Management",
      icon: HyperLink
    },
    {
      id: 2,
      path: "/configuration",
      name: "Configuration",
      icon: Configuration
    },
    {
      id: 3,
      path: "/notification",
      name: "Notifications",
      icon: Notifications
    },
    {
      id: 4,
      path: "/lookup",
      name: "Look up Management",
      icon: Lookup
    },
    {
      id: 5,
      path: "/template",
      name: "Template Management",
      icon: Template
    },
    {
      id: 6,
      path: "/publish",
      name: "Publish Management",
      icon: Publish
    },
  ];

  const dispatch = useDispatch();
  const saveValue = useSelector(state => state.saveSlice.savedValue)
  const [selectId, setSelectId] = useState(sidebarItems[0].name);
  const [isOpen, setIsOpen] = useState(false);
  const [showDataSave, setShowDataSave] = useState(false);
  const [continueSection, setContinueSection] = useState({});

  const navigate = useNavigate();
  const navigation = useResolvedPath();

  useEffect(() => {
    setSelectId(sidebarItems.find((s) => s.path === navigation.pathname)?.name); 
  }, [navigation.pathname])

  useEffect(() => {
    if (sidebarItems?.name) {
      setSelectId(selectId);
    }
  }, [])
  
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  function showDataSavePopup(section) {
    setShowDataSave((pre) => !pre);
    setContinueSection(section);
  }

  function handleContinue() {
    dispatch(setSave(true));
    showDataSavePopup();
    navigate(continueSection.path)
    setSelectId(continueSection.name);
  }

  const handleSectionClick = (section) => {
    if (saveValue === false) {
      showDataSavePopup(section)
      
    }
    else if (saveValue === true) {
      setSelectId(section.name);
      navigate(section.path);
    }
  }

  return (
    <div className="main-page">
      <div style={{width: isOpen ? '255px' : '80px'}} className="sidebar">
        <div className="sidebar-icon">
          <i className="pi pi-bars" onClick={() => toggle()}></i>
        </div>

        {sidebarItems.map((item, index) => (
          <div className="sidebar-content" key={index}>
              <div
              key={index}
              className={selectId === item.name ? "active" : ""}
              onClick={() => handleSectionClick(item)}
            >
              <div className="icon">
                <img id="img_sideBarImage" className="sidebar-image" src={item.icon}></img>
              <div style={{display: isOpen ? 'flex' : 'none'}} className="sidebar-link">{item.name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginLeft: isOpen ? '235px' : '60px', width: isOpen ? '83%' : '95.5%' }} className="main-content">{children}</div>
      
      <ModalWrapper visible={showDataSave} onDismiss={() => {showDataSavePopup();}}>
        <SaveDataPopUp closePopup={() => { showDataSavePopup(); }} saveData={() => { handleContinue(); }} />
      </ModalWrapper>
    </div>
  );
};

export default Sidebar;
