import React from 'react';
import './hyperlink.scss';
import Labels from "../../Constants/Label";
import HyperlinkMngment from './hyperlinkmanagement'

const Hyperlink = () => {
    return (
        <div className='hyperlink-wrapper'>
            <div className='hyperlink-title'>
            <header className='hyperlink-header'>
            {Labels?.Sidebar?.Hyperlink}
            </header>
            </div>
            <HyperlinkMngment/>
        </div>
    )
}

export default Hyperlink;