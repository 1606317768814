import React from "react";
import warnLogo from "../../Assets/Images/delete.png";
import { Button } from "primereact/button";
import "./warnPopup.scss";

function WarningMessagePopup({ closePopup, content }) {
    return (
        <div className="warn-popup">
            <div className="warnFormWrapperInner">
                <img id="img_warnMsg" src={warnLogo} className="warnLogo" />
                <p className="warnTitle">
                    {content}
                </p>
            </div>
            <div className="buttonWrapper">
                <Button id="btn_WarnClose"
                    className="warn-button"
                    label="Close"
                    onClick={closePopup}
                ></Button>
            </div>
        </div>
    );
}

export default WarningMessagePopup;