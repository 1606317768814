import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance,  } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";

export const FetchMUTCDPlan = createAsyncThunk(
  "/MUTCDPlan/FetchMUTCDPlan",
  async (dispatch) => {
    try {
      let res = await sharedInstance.get(EndPoints.mutcdPlans);
      if (res?.data?.data && res.data.statusCode === 200) {
        console.log("mutcd",res);
        return res.data.data;
      } else {
        throw Error();
      }
    } catch (error) {
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
        console.log("fetcherrorrrr")
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);


export const UpdateMUTCDPlan = createAsyncThunk(
  "UpdateMUTCDPlan",
  async (args, thunkAPI) => {
    try {
      console.log(args)
      let res = await sharedInstance.put(`${EndPoints.updateMutcdPlans}?mutcdId=${args.mutcdId}`, args.reqBody);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        args.cb(true)
        console.log("updateEmergencyService",res?.data?.data);
        return res?.data?.data;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {}
  }
);


export const DeleteMUTCDPlan = createAsyncThunk(
  "DeleteMUTCDPlan",
  async ({ id }, thunkAPI) => {
    console.log("service",id)
    try {
      let res = await sharedInstance.delete(
        `${EndPoints.deleteMutcdPlan}?mutcdId=${id}`
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        console.log(res?.data?.data);
        // return id;

        // deleteSuccess(id);
        return {id,statusCode: res.status};
      }else if( res?.data?.statusCode === 500) {
         
        return {statusCode: res?.data?.statusCode};
      }
       else {
        let errorCode = res?.data?.statusCode;
        console.log("err",errorCode)
        // let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode);
      }
    } catch (error) {}
  }
);