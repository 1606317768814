import React from "react";
import successLogo from "../../Assets/Images/tick.png";
import { Button } from "primereact/button";
import "./successPopup.scss";

function SaveSuccess({ closePopup, heading, content, header, tempName }) {
  return (
    <div className="success-popup">
      {header ? "" : <img id="img_svesuccessLogo" src={successLogo} className="successLogo" />}
      <div className="successFormWrapperInner">
        {header ? (
          <>
            <p className="successEE" style={{ display: "-webkit-inline-box" }}>
              <img id="img_successLogoImg"
                src={successLogo}
                className="successLogoEE"
                alt="successLogoImg"
              />{" "}
              &nbsp;
              <div className="successTitleEE">
                <p className="successTitleHead"> {heading} </p>
                <p className="successTitle">
                  You have successfully saved{" "}
                  <span style={{ fontWeight: "bold" }}>{content}</span>.
                </p>
              </div>
            </p>
          </>
        ) : (
          <>
            <p className="successTitleHead">Saved {heading} Successfully! </p>
            <p className="successTitle">
              You have successfully saved{" "}
              <span style={{ fontWeight: "bold" }}>{content}</span>.
            </p>
          </>
        )}
        {/* <p className="successTitleHead">Saved {heading} Successfully! </p>
        <p className="successTitle">You have successfully saved {content}.</p> */}
        <div className="popupButtonWrapper">
          <Button id="btn_saveSuccessClose"
            className="close-button"
            label="Close"
            onClick={closePopup}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default SaveSuccess;
