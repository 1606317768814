import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

export const fetchNotificationDetails = createAsyncThunk(
  "notification/fetchNotificationDetails",
  async (dispatch, thunkAPI) => {
    try {
      const userDetails = thunkAPI.getState().user.userData;
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Notifcation",
        actionName: "Fetch notification details",
        message:{
            userEmailId: userDetails.emailId },
        thunkApi: thunkAPI,
      });

      let res = await sharedInstance.get(EndPoints.notification);
      if (res?.data?.data || res.data.statusCode === 204) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Notifcation",
          actionName: "Fetch notification details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        return res?.data?.data;
      }
      else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Notifcation",
          actionName: "Fetch notification details",
          thunkApi: thunkAPI,
        });

        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Notifcation",
        actionName: "Fetch notification details",
        thunkApi: thunkAPI,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const addNotificationDetails = createAsyncThunk(
  "notification/addNotificationDetails",
  async (data, thunkAPI) => {
    try {
      const userDetails = thunkAPI.getState().user.userData;
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Notifcation",
        actionName: "Add notification details",
        message:{ userEmailId: userDetails.emailId },
        thunkApi: thunkAPI,
      });
      let fileName = "";
      if (data?.data?.FilesUpload) {
        fileName = data?.data?.FilesUpload?.name.replace(/[^a-zA-Z0-9.]/g, '')
      } else {
        fileName = "";
      }
      const formData = new FormData();
      formData.append("NotificationName", data.data.NotificationName);
      formData.append("Recipients", data.data.Recipients);
      formData.append("ValidityFrom", data.data.ValidityFrom);
      formData.append("ValidityTill", data.data.ValidityTill);
      formData.append("NotificationMessage", data.data.NotificationMessage);
      if (fileName !== "") { 
        formData.append("FilesUpload", data?.data?.FilesUpload, fileName);
      } else {
        formData.append("FilesUpload", data?.data?.FilesUpload);
      }
      formData.append("Active", data.data.Active);
      formData.append("isEnabled", data.data.isEnabled);

      let res = await sharedInstance.post(EndPoints.addNotification, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Notifcation",
          actionName: "Add notification details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        data.success(data.data.NotificationName);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Notifcation",
          actionName: "Add notification details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Notifcation",
        actionName: "Add notification details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteNotificationDetails = createAsyncThunk(
  "notification/deleteNotificationDetails",
  async ({ id, deleteSuccess }, thunkAPI) => {
    try {
      const userDetails = thunkAPI.getState().user.userData;
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Notifcation",
        actionName: "Delete notification details",
        message:{ userEmailId: userDetails.emailId },
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.delete(
        `${EndPoints.deleteNotification}?notificationId=${id}`
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Notifcation",
          actionName: "Delete notification details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        deleteSuccess(id);
        return id;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Notifcation",
          actionName: "Delete notification details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Notifcation",
        actionName: "Delete notification details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateNotificationDetails = createAsyncThunk(
  "notification/updateNotificationDetails",
  async (data, thunkAPI) => {
    try {
      const userDetails = thunkAPI.getState().user.userData;
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Notifcation",
        actionName: "Update notification details",
        message: { userEmailId: userDetails.emailId },
        thunkApi: thunkAPI,
      });
      let fileName = "";
      if (data?.data?.FilesUpload) {
        fileName = data?.data?.FilesUpload?.name.replace(/[^a-zA-Z0-9.]/g, '')
      } else {
        fileName = "";
      }
      const formData = new FormData();
      formData.append("NotificationName", data.data.NotificationName);
      formData.append("Recipients", data.data.Recipients);
      formData.append("ValidityFrom", data.data.ValidityFrom);
      formData.append("ValidityTill", data.data.ValidityTill);
      formData.append("NotificationMessage", data.data.NotificationMessage);
      if (fileName !== "") { 
        formData.append("FilesUpload", data?.data?.FilesUpload, fileName);
      } else {
        formData.append("FilesUpload", data?.data?.FilesUpload);
      }
      formData.append("Active", data.data.Active);
      formData.append("isEnabled", data.data.isEnabled);

      let res = await sharedInstance.put(
        `${EndPoints.updateNotification}?notificationId=${data.data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Notifcation",
          actionName: "Update notification details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        if (data.success) {
          data.success(data.data.NotificationName)
        }
        if (data.callBackFn) {
          data.callBackFn(true, res.data.data)
        }
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Notifcation",
          actionName: "Update notification details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Notifcation",
        actionName: "Update notification details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
