import { createSlice } from '@reduxjs/toolkit';
import { FetchTemplates, UpdateTemplate, FetchPublishTemplate, AddPublishTemplate, FetchPublishStatus } from '../../Service/TemplateManagement';

const initialState = {
    templatesData: [],
    publishTemplateData: [],
    publishStatus: [],
    loading: false,
}

export const templateSlice = createSlice({
  name: 'templates',
  initialState,
    reducers: {},
    extraReducers: (builder) => {
        //reducer for fetching template management
        builder.addCase(FetchTemplates.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(FetchTemplates.fulfilled, (state, action) => {
            state.templatesData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(FetchTemplates.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        //reducer to update the templates
        builder.addCase(UpdateTemplate.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(UpdateTemplate.fulfilled, (state, action) => {
            let arr = [...state.templatesData]
            const index = arr.findIndex((value) => value?.fieldInternalName === action?.payload?.fieldInternalName);
            arr[index] = action.payload
            state.templatesData = arr;
            state.loading = false
            state.error = ''
        })
        builder.addCase(UpdateTemplate.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        //reducer for fetching publish template data
        builder.addCase(FetchPublishTemplate.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(FetchPublishTemplate.fulfilled, (state, action) => {
            state.publishTemplateData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(FetchPublishTemplate.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        //reducer for publishing teh template data
        builder.addCase(AddPublishTemplate.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(AddPublishTemplate.fulfilled, (state, action) => {
            // state.publishTemplateData.push(action.payload);
            state.loading = false
            state.error = ''
        })
        builder.addCase(AddPublishTemplate.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        //reducer for fetching publish template status
        builder.addCase(FetchPublishStatus.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(FetchPublishStatus.fulfilled, (state, action) => {
            state.publishStatus = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(FetchPublishStatus.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
    },
})

export default templateSlice.reducer