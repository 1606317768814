import React from "react";
import warnLogo from "../../Assets/Images/delete.png";
import { Button } from "primereact/button";
import "./warnPopup.scss";

function WarnPopup({ closePopup }) {
  return (
    <div className="warn-popup">
      <div className="warnFormWrapperInner">
        <img id="img_warnPopwarn" src={warnLogo} className="warnLogo" />
        <p className="warnTitle">
          Cannot be deleted as only one critical contact is present.
        </p>
      </div>
      <div className="buttonWrapper">
        <Button id="btn_warnPopClose"
          className="warn-button"
          label="Close"
          onClick={closePopup}
        ></Button>
      </div>
    </div>
  );
}

export default WarnPopup;
