import { loggerInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";

const convertDateTimeFormat = () => {
  let dateTime = new Date();
  let dateTimeStr =
    ("00" + (dateTime.getMonth() + 1)).slice(-2) +
    "/" +
    ("00" + dateTime.getDate()).slice(-2) +
    "/" +
    dateTime.getFullYear() +
    " " +
    ("00" + dateTime.getHours()).slice(-2) +
    ":" +
    ("00" + dateTime.getMinutes()).slice(-2) +
    ":" +
    ("00" + dateTime.getSeconds()).slice(-2);
  return dateTimeStr;
};

export const callLoggerInfo = async (args) => {
  const userDetails = args?.thunkApi?.getState().user?.userData;

  let _body = {
    ScreenName: `UI - Admin - ${args.screenName}`,
    ActionName: args.actionName,
    DateTime: convertDateTimeFormat(),
    UserName: userDetails?.name,
    Message: args.message,
  };

  try {
    let res = await loggerInstance.post(EndPoints.logInformation, _body);
  } catch (error) {
    console.log(error);
  }
};

export const callLoggerError = async (args) => {
  const userDetails = args?.thunkApi?.getState().user?.userData;

  let errorCodeStrType = typeof args.errorCode;
  let _body = {
    ErrorCode: 
    errorCodeStrType === "string"
      ? args.errorCode
      : JSON.stringify(args?.errorCode),
    ErrorDetails: args?.errorDetails,
    ScreenName: `UI - Admin - ${args.screenName}`,
    ActionName: args.actionName,
    UserName: userDetails?.name,
    DateTime: convertDateTimeFormat(),
  };

  try {
    let res = await loggerInstance.post(EndPoints.logError, _body);
  } catch (error) {
    console.log(error);
  }
};
