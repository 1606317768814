import React, { useEffect, useState } from "react";
import { EndPoints } from "../../../Api/ApiRelativeUrls";
import { fileUploadInstance } from "../../../Api/index";

import { Accordion, AccordionTab } from "primereact/accordion";
import "./swiAndHazard.scss"; // Make sure to import your styles
import { TabPanel, TabView } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import trash from "../../../Assets/Images/trash.png";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";

import {
  FetchSWIHazardMessage,
  addSwiMessagingMappingDetails,
  deleteSwiMessagingMappingDetails,
} from "../../../Service/SWIHazardMessaging";
import { useDispatch, useSelector } from "react-redux";
import {
  HazardousEnergiesControlsSwiMessaging,
  addHECSwimessagingDetails,
  deleteHECSwimessagingDetails,
} from "../../../Service/HazardousEnergiesControlsSwiMessaging";
import DeletePopUp from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/commonDeletePopup";
import ModalWrapper from "../../GenericPopup";
import Loader from "../../Loader";

// ... other imports

const SwiAndHazard = () => {
  const [showDeleteSuccess, setshowDeleteSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [jobnameselectedTab, setjobnameSelectedTab] = useState({});
  const [swinameselectedTab, setswinameselectedTab] = useState({});
  const [selectedTab, setSelectedTab] = useState({});
  const dispatch = useDispatch();
  const [jobNameList, setjobNameList] = useState([]);
  const [center, setCenter] = useState([]);
  const [uniqueGroups, setUniqueGroups] = useState([]);
  const [switaskdropdown, setswitaskdropdown] = useState([]);
  const [swioptiondropdown, setswioptiondropdown] = useState([]);
  const [disablehec, setdisablehec] = useState(false);
  const [disableswi, setdisableswi] = useState(false);
  const [notificationmessage, setnotificationmessage] = useState("");
  const [swinotificationmessage, setswinotificationmessage] = useState("");
  const [editingIndex, setEditingIndex] = useState(null); // Track editing state
  const [swiEditingIndex, setswiEditingIndex] = useState(null);
  const [selectedTaskHighEnergies, setSelectedTaskHighEnergies] = useState([]);
  const [taskHighEnergies, setTaskHighEnergies] = useState([]);
  const [hectaskHighEnergies, sethectaskHighEnergies] = useState([]);
  const [swihecdropdown, setswihecdropdown] = useState([]);
  const [switextError, setswitextError] = useState("");
  const [hectextError, sethectextError] = useState("");
  const [base64Swidata, setbase64Swidata] = useState({});
  const [tabActiveIndex, settabActiveIndex] = useState(0);
  const [valueName, setValueName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [formtype, setformtype] = useState("");
  const [editenable, seteditenable] = useState(false);

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  useEffect(() => {
    dispatch(HazardousEnergiesControlsSwiMessaging(dispatch));
  }, []);
  const swihazardenergiesControlDetails = useSelector(
    (state) =>
      state?.SwiHazardenergiescontrolMessaging
        ?.SwiHazardenergiescontrolMessagingData || []
  );

  useEffect(() => {
    dispatch(FetchSWIHazardMessage(dispatch));
  }, []);
  const swihazardDetails = useSelector(
    (state) => state?.SwiHazardMessaging?.SwiHazardMessagingData || []
  );
  const [accordions, setAccordions] = useState();

  useEffect(() => {
    let arr = [];
    const SWIInitialLoad = () => {
      swihazardDetails?.forEach((item) => {
        let jobtypeItem = arr.find((data) => data?.id === item?.jobTypeId);
        if (jobtypeItem === undefined) {
          jobtypeItem = { id: item?.jobTypeId, name: item?.jobName, jobs: [] };
          arr.push(jobtypeItem);
        }

        let jobItem = jobtypeItem?.jobs.find(
          (data) => data?.swid === item?.jobTypeSwiId
        );
        if (jobItem === undefined) {
          jobItem = {
            swid: item?.jobTypeSwiId,
            swiname: item?.swiname,
            highEnergies: [],
          };
          jobtypeItem.jobs.push(jobItem);
        }

        let Energyitem = jobItem?.highEnergies?.find(
          (data) => data?.highEnergyId === item?.highEnergyId
        );
        if (Energyitem === undefined) {
          Energyitem = {
            highEnergyId: item?.highEnergyId,
            highEnergyName: item?.highEnergyName,
            Icons: [],
            hazards: [],
          };
          jobItem.highEnergies.push(Energyitem);
        }

        let HazardItem = Energyitem?.hazards.find(
          (data) => data?.hazardsId === item?.hazardsId
        );
        if (HazardItem === undefined) {
          HazardItem = {
            hazardsId: item?.hazardsId,
            hazardName: item?.hazardName,
            notificationMessage: item?.notificationMessage,
          };
          Energyitem?.hazards.push(HazardItem);
        }
        let IconItem = Energyitem?.Icons.find(
          (data) => data?.iconId === item?.iconId
        );
        if (IconItem === undefined) {
          IconItem = {
            iconId: item?.iconId,
            iconName: item?.iconName,
            iconpath: item?.iconPath,
          };
          Energyitem.Icons.push(IconItem);
        }
      });
      setTaskHighEnergies(arr);
      const options = arr.map((element) => ({
        name: element?.name,
        value: element?.id,
      }));
      setswitaskdropdown(options);
      let tempDropdown1Data = jobnameselectedTab?.id;
     
      if (jobnameselectedTab?.id === undefined) {
        setjobnameSelectedTab({ id: options[0]?.value });
      } else {
        setjobnameSelectedTab({});
        setjobnameSelectedTab({ id: tempDropdown1Data });
      }
    };
    if (swihazardDetails.length > 0) {
      SWIInitialLoad();
    }
  }, [swihazardDetails]);

  useEffect(() => {
    setswiEditingIndex(null);
    setEditingIndex(null);
  }, [jobnameselectedTab, selectedTab, swinameselectedTab, tabActiveIndex]);

  useEffect(() => {
    async function HECInitialLoad() {
      let tempArr = [];
      let tempId = [];
      let tempiconId = [];
      let temphecHazardid = [];
      for (let i=0; i< swihazardenergiesControlDetails.length; i++) {
        let item = swihazardenergiesControlDetails[i]
        if (!tempId.includes(item?.taskId)) {
          tempArr.push({
            taskid: item?.taskId,
            taskname: item?.task,
            iconData: [],
            hechazardData: [],
          });
          tempId.push(item?.taskId);
          tempiconId = [];
          temphecHazardid = [];
        }
        if (!tempiconId.includes(item?.iconId)) {
          tempArr[tempArr.length - 1].iconData.push({
            iconId: item?.iconId,
            iconPath: item?.iconPath,
          });
          tempiconId.push(item?.iconId);
          await fetchImages(item?.iconPath);
        }
        if (!temphecHazardid.includes(item?.hazardsId)) {
          tempArr[tempArr.length - 1].hechazardData.push({
            hazardId: item?.hazardsId,
            hazardName: item?.hazardName,
            notificationMessage: item?.notificationMessage,
          });
          temphecHazardid.push(item?.hazardsId);
        }
      }
      sethectaskHighEnergies(tempArr);
     // setAccordions(tempArr);
      const options = tempArr.map((element) => ({
        name: element?.taskname,
        value: element?.taskname,
        hechazardData: element?.hechazardData,
        iconData: element?.iconData,
        taskid: element?.taskid,
      }));
      setswihecdropdown(options);
    }
    if (swihazardenergiesControlDetails.length > 0) {
      HECInitialLoad();
    }
 
  }, [swihazardenergiesControlDetails]);

  useEffect(() => {
    if (selectedTab.taskname === undefined) {
      setSelectedTab({
        taskname: swihecdropdown[0]?.name,
        taskId: swihecdropdown[0]?.taskid,
      });
    }
  }, [swihecdropdown]);
  useEffect(() => {
    async function InitialSWIData() {
      let selectedJob = taskHighEnergies.find(
        (data) => data.id === jobnameselectedTab.id
      );
      if (selectedJob) {
        let tempArr1 = selectedJob.jobs.map((item) => {
          return {
            name: item.swiname,
            value: item.swiname,
            highEnergies: item.highEnergies,
            swiId: item.swid,
          };
        });
        setswioptiondropdown([...tempArr1]);
        // setjobnameSelectedTab(selectedJob)
        setswinameselectedTab({
          swiname: tempArr1[0].name,
          swiid: tempArr1[0].swiId,
        });

        for (let i = 0; i < tempArr1[0].highEnergies.length; i++) {
          let arr = tempArr1[0].highEnergies[i].Icons;
          for (let j = 0; j < arr.length; j++) {
            await fetchImages(arr[j].iconpath);
          }
        }
      }
    }
    if (jobnameselectedTab.id) {
      InitialSWIData();
    }
  }, [jobnameselectedTab.id]);

  const handleDelete = (id, message) => {
    setformtype("HEC");
    setDeleteId(id);
    setValueName(message);
    setShowDelete((pre) => !pre);
  };

  const handleSWIEdit = (hazarid, message) => {
    setswinotificationmessage(message);
    setswiEditingIndex(hazarid);
  };

  const handleHECEdit = (hazardid, message) => {
    setnotificationmessage(message);
    setEditingIndex(hazardid);
  };
  function showDeleteSuccessPopup() {
    setShowDelete(false);
  }
  const handleSWIDelete = (id, message) => {
    setDeleteId(id);
    setValueName(message);
    setShowDelete((pre) => !pre);
    //dispatch(deleteSwiMessagingMappingDetails(id));
  };

  const confirmSWIDeleteHandle = (id) => {
    if (formtype === "HEC") {
      dispatch(
        deleteHECSwimessagingDetails({
          id,
          deleteSuccess: showDeleteSuccessPopup,
        })
      );
    } else {
      dispatch(
        deleteSwiMessagingMappingDetails({
          id,
          deleteSuccess: showDeleteSuccessPopup,
        })
      );
    }

    setformtype("");
    setShowDelete(false);
    setshowDeleteSuccess(true);
  };

  const handleSWISave = (id, message) => {
    if (message.trim()) {
      dispatch(
        addSwiMessagingMappingDetails({
          id,
          message,
          callbackfn: handleSWIsectionData,
        })
      );
    } else {
      handleSWIDelete(id);
    }
    setswiEditingIndex(null);
    setswinotificationmessage("");
  };
  const handleSWIsectionData = (success) => {
    if (success) {
      dispatch(FetchSWIHazardMessage(dispatch));
    }
  };

  const handleSave = (id, message) => {
    if (message.trim()) {
      dispatch(addHECSwimessagingDetails({ id, message }));
    } else {
      handleDelete(id);
    }
    setnotificationmessage("");
    setEditingIndex(null);
  };

  const fetchImages = async (filePath) => {
    if (filePath) {
      if (base64Swidata[filePath] === undefined) {
        try {
          let res = await fileUploadInstance.post(EndPoints.fetchUploadFile, {
            filePath: filePath,
          });
          if (res?.data?.data?.streamData) {
            setbase64Swidata((prev) => ({
              ...prev,
              [filePath]: "data:image/png;base64," + res.data.data.streamData,
            }));
            return res.data?.data?.streamData;
          }
        } catch {}
      } else {
        return base64Swidata[filePath];
      }
    }
    return "";
  };

  const handletextchange = (e) => {
    const val = e.target.value;
    if (val.trim().length > 0) {
      if (/(^[a-zA-Z0-9\'\"\*\(\)\-\/\. ]+$)/.test(val)) {
        seteditenable(true);
        setswinotificationmessage(val);
      }
    } else {
      seteditenable(false);
      setswinotificationmessage(val);
    }
  };
  const handleHECtextchange = (e) => {
    const val = e.target.value;
    if (val.trim().length > 0) {
      if (/(^[a-zA-Z0-9\'\"\*\(\)\-\/\. ]+$)/.test(val)) {
        seteditenable(true);
        setnotificationmessage(val);
      }
    } else {
      seteditenable(false);
      setnotificationmessage(val);
    }
  };
  console.log(base64Swidata);
  return (
    <div>
      {isLoading && <Loader />}
      <TabView
        activeIndex={tabActiveIndex}
        onTabChange={(e) => settabActiveIndex(e.index)}
      >
        <TabPanel header="SWI's Mapping" id="tabPanel_hzdmsg_swi">
          <div className="swi-mapping">
            <Dropdown id="drd_switaskdropdown"
              className="dropDown-swi1"
              options={switaskdropdown}
              optionLabel="name"
              value={jobnameselectedTab.id}
              onChange={(e) => {
                setjobnameSelectedTab({ id: e.value });
              }}
              placeholder="Select a tab"
              dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
            />
            <Dropdown id="drd_swioptiondropdown"
              className="dropDown-swi2"
              options={swioptiondropdown}
              optionLabel="name"
              value={swinameselectedTab.swiname}
              onChange={(e) => {
                //handleSWIIconData(e);
                setswinameselectedTab({ swiname: e.target.value });
              }}
              placeholder="Select a tab"
              dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
            />
            <div className="table-container">
              <table className="custom-table">
                <thead className="swi-header">
                  <tr>
                    <th style={{ width: "25%" }}>Energy Wheel Hazards</th>
                    <th style={{ width: "75%" }}>Hazard</th>
                  </tr>
                </thead>
                <tbody className="swi-content">
                  {taskHighEnergies.map((item) => {
                    {
                      return item.jobs.map((data, index) => {
                        if (swinameselectedTab.swiname === data.swiname) {
                          return (
                            <>
                              {data.highEnergies.map(
                                (highEnergyData, hazardIndex) => {
                                  return (
                                    <tr key={hazardIndex}>
                                      <td>
                                        <div>
                                          {/* {accordionSet[0].energyheel} <br /> */}
                                          {highEnergyData.highEnergyName}
                                        </div>
                                        {highEnergyData.Icons.map(
                                          (icondata) => {
                                            return (
                                              <div>
                                                {/* {icondata.iconpath} */}
                                                <img id="img_HazardSWIIcon"
                                                  key={index}
                                                  className="swiIcon"
                                                  src={
                                                    base64Swidata[
                                                      icondata.iconpath
                                                    ]
                                                  }
                                                  alt="img"
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </td>

                                      <td>
                                        { highEnergyData.hazards.map(
                                          
                                          (hazardData, hazardINDEX) => {
                                            // const isAccordionExpanded = someFunctionToCheckIfAccordionIsExpanded(hazardINDEX); // Replace this line with your logic to check if the Accordion is expanded
                                            return (
                                              <Accordion
                                                key={hazardData.hazardsId}
                                              >
                                                {/* {accordionSet.map((accordion, index) => ( */}
                                                <AccordionTab
                                                  className="accordian-header"
                                                  header={hazardData.hazardName}
                                                  
                                                  onClick={() => {
                                                    setswitextError("");
                                                    // setswinotificationmessage("");
                                                  }}
                                                >
                                                  <div className="button-container">
                                                    {/* Delete and Edit buttons */}

                                                    {swiEditingIndex ===
                                                    hazardData.hazardsId ? (
                                                      <>
                                                        {editenable &&
                                                        swinotificationmessage.length !==
                                                          0 &&
                                                        swinotificationmessage.trim()
                                                          .length > 0 &&
                                                        swinotificationmessage.length <=
                                                          200 ? (
                                                          <img id="img_HazardSWIDisable"
                                                            src={editEnable}
                                                            className="edit-disable"
                                                            onClick={(e) =>
                                                              handleSWISave(
                                                                hazardData.hazardsId,
                                                                swinotificationmessage
                                                              )
                                                            }
                                                            alt="Save"
                                                          ></img>
                                                        ) : (
                                                          <img id="img_HazardSWIDisableEdit"
                                                            src={editDisable}
                                                             className="edit-disable"
                                                          ></img>
                                                        )}
                                                        {/* <img
                                                          className="save-button"
                                                          src={editEnable}
                                                          onClick={(e) =>
                                                            handleSWISave(
                                                              hazardData.hazardsId,
                                                              swinotificationmessage
                                                            )
                                                          }
                                                          alt="Save"
                                                        /> */}
                                                        <img id="img_HazardSWICancel"
                                                          className="cancel-button"
                                                          src={cancelIcon}
                                                          onClick={() => {
                                                            setswiEditingIndex(
                                                              null
                                                            );
                                                            setswinotificationmessage(
                                                              ""
                                                            );
                                                          }}
                                                          alt="Cancel"
                                                        />
                                                      </>
                                                    ) : hazardData.notificationMessage ===
                                                        null ||
                                                      hazardData
                                                        .notificationMessage
                                                        ?.length === 0 ? (
                                                      <>
                                                        <div>
                                                          <button id="btn_SWINotification"
                                                            className="newnotification"
                                                            onClick={() =>
                                                              setswiEditingIndex(
                                                                hazardData.hazardsId
                                                              )
                                                            }
                                                          >
                                                            + Notification
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img id="img_HazardSWIEdit"
                                                          src={editIcon}
                                                          className="edit-icon-swi"
                                                          onClick={(e) =>
                                                            handleSWIEdit(
                                                              hazardData.hazardsId,
                                                              hazardData.notificationMessage
                                                            )
                                                          }
                                                          alt="Edit"
                                                        />

                                                        <img id="img_HazardSWIDelete"
                                                          className="deleteicon"
                                                          src={trash}
                                                          onClick={(e) =>
                                                            handleSWIDelete(
                                                              hazardData.hazardsId,
                                                              hazardData.notificationMessage
                                                            )
                                                          }
                                                          alt="Delete"
                                                        />
                                                      </>
                                                    )}
                                                  </div>

                                                  <div className="accordian-content">
                                                    <div>
                                                      {/* <label htmlFor={`key1${accordion.id}`}>
                                                  previous serious injury
                                                </label> */}
                                                      <br />
                                                      {swiEditingIndex ===
                                                      hazardData.hazardsId ? (
                                                        <>
                                                          <textarea
                                                            maxLength={200}
                                                            type="text"
                                                            value={
                                                              swinotificationmessage
                                                            }
                                                            onChange={(e) => {
                                                              // setswinotificationmessage(
                                                              //   e.target.value
                                                              // );
                                                              handletextchange(
                                                                e
                                                              );
                                                              if (
                                                                e.target.value
                                                                  .length ===
                                                                200
                                                              ) {
                                                                setswitextError(
                                                                  true
                                                                );
                                                              } else {
                                                                setswitextError(
                                                                  false
                                                                );
                                                              }
                                                            }}
                                                          />
                                                          {switextError && (
                                                            <div
                                                              style={{
                                                                color: "red",
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              Max. 200
                                                              characters are
                                                              allowed
                                                            </div>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <span className="multi-line-text">
                                                          {
                                                            hazardData.notificationMessage
                                                          }
                                                        </span>
                                                      )}
                                                    </div>
                                                    <br />
                                                  </div>
                                                </AccordionTab>

                                                {/* ))} */}
                                              </Accordion>
                                            );
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </>
                          );
                        }
                      });
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Hazardous Energies & Controls" id="tabPanel_hzdControls">
          <div className="Hec-data">
            <Dropdown id="drd_swihecdropdown"
              className="dropDown"
              style={{ width: "484px" }}
              options={swihecdropdown}
              optionLabel="name"
              value={selectedTab.taskname}
              onChange={(e) => {
                //handleHECIconData(e);
                setSelectedTab({ taskname: e.target.value });
              }}
              placeholder="Select a tab"
              dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
            />
            <div className="table-container">
              <table className="custom-table">
                <thead className="swi-header">
                  <tr>
                    <th style={{ width: "25%" }}>Energy Wheel Hazards</th>
                    <th style={{ width: "75%" }}>Hazard</th>
                  </tr>
                </thead>
                <tbody className="swi-content">
                  {hectaskHighEnergies.map((item, setIndex) => {
                    if (selectedTab.taskname === item.taskname) {
                      return (
                        <tr key={setIndex}>
                          {item.iconData.map((data) => {
                            return (
                              <div>
                                {data.iconPath && data.iconPath.length > 0 && (
                                  <img id="img_HazardSWIIconPath"
                                    src={base64Swidata[data.iconPath]}
                                    className="swiIcon"
                                    alt="img"
                                    style={{
                                      left: "81px",
                                      top: "6px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            );
                          })}
                          <td>
                            {item.hechazardData.map((data, index) => {
                              return (
                                <Accordion>
                                  <AccordionTab
                                    className="accordian-header"
                                    key={data.hazardId}
                                    header={data.hazardName}
                                    onClick={() => {
                                      sethectextError("");
                                    }}
                                  >
                                    <div className="button-container">
                                      {/* Delete and Edit buttons */}

                                      {editingIndex === data.hazardId ? (
                                        <>
                                          {editenable &&
                                          notificationmessage.length !== 0 &&
                                          notificationmessage.trim().length >
                                            0 &&
                                          notificationmessage.length <= 200 ? (
                                            <img id="img_HazardSWIdisEdit"
                                              src={editEnable}
                                              className="edit-disable"
                                              onClick={(e) =>
                                                handleSave(
                                                  data.hazardId,
                                                  notificationmessage
                                                )
                                              }
                                              alt="Save"
                                            ></img>
                                          ) : (
                                            <img id="img_HazardSWIDisabEdit"
                                              src={editDisable}
                                              className="edit-disable"
                                            ></img>
                                          )}
                                          {/* <img
                                            src={editEnable}
                                            onClick={(e) =>
                                              handleSave(
                                                data.hazardId,
                                                notificationmessage
                                              )
                                            }
                                            alt="Save"
                                          /> */}
                                          <img id="img_HazardSWICancelBtn"
                                          className="cancel-button"
                                            src={cancelIcon}
                                            onClick={() => {
                                              setEditingIndex(null);
                                              setnotificationmessage("");
                                            }}
                                            alt="Cancel"
                                          />
                                        </>
                                      ) : data.notificationMessage === null ||
                                        data.notificationMessage?.length ===
                                          0 ? (
                                        <>
                                          <div>
                                            {" "}
                                            <button id="btn_SWINewNotifi"
                                              className="newnotification"
                                              onClick={() => {
                                                setEditingIndex(data.hazardId);
                                              }}
                                            >
                                              + Notification
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img id="img_HazardSWIEditIcon"
                                            src={editIcon}
                                            className="edit-icon-swi"
                                            onClick={(e) =>
                                              handleHECEdit(
                                                data.hazardId,
                                                data.notificationMessage
                                              )
                                            }
                                            alt="Edit"
                                          />

                                          <img id="img_HazardSWIDeleteIcon"
                                            className="deleteicon"
                                            src={trash}
                                            onClick={(e) =>
                                              handleDelete(
                                                data.hazardId,
                                                data.notificationMessage
                                              )
                                            }
                                            alt="Delete"
                                          />
                                        </>
                                      )}
                                    </div>

                                    <div className="accordian-content">
                                      <div>
                                        {/* <label htmlFor={`key1${accordion.id}`}>
                                              previous serious injury
                                            </label> */}
                                        <br />
                                        {editingIndex === data.hazardId ? (
                                          <>
                                            <textarea
                                              maxLength={200}
                                              type="text"
                                              value={notificationmessage}
                                              onChange={(e) => {
                                                // setnotificationmessage(
                                                //   e.target.value
                                                // );
                                                handleHECtextchange(e);
                                                if (
                                                  e.target.value.length === 200
                                                ) {
                                                  sethectextError(true);
                                                } else {
                                                  sethectextError(false);
                                                }
                                              }}
                                            />
                                            {hectextError && (
                                              <div
                                                style={{
                                                  color: "red",
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                Max. 200 characters are allowed
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <span className="multi-line-text">
                                            {data.notificationMessage}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </AccordionTab>
                                </Accordion>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
      </TabView>

      <ModalWrapper visible={showDelete}>
        <DeletePopUp
          closePopup={() => {
            setShowDelete(false);
          }}
          deleteValue={() => {
            confirmSWIDeleteHandle(deleteId);
          }}
          heading="SWI & Hazard Messaging"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSuccess}
        onDismiss={() => {
          setshowDeleteSuccess(false);
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            setshowDeleteSuccess(false);
          }}
          heading="SWI & Hazard Messaging"
          content={valueName}
        />
      </ModalWrapper>
    </div>
  );
};

export default SwiAndHazard;
