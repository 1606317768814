import React, { useEffect, useState } from "react";
import "./common.scss";
import "primeicons/primeicons.css";
import DataInput from "../Input";
import Labels from "../../../Constants/Label";

const Common = ({ commonData }) => {
  const uniqueGroup = [
    ...new Set(commonData.map((item) => item.sectionInternalName)),
  ];

  return (
    <div className="common">
      {uniqueGroup.map((subgroup, index) => {
        return (
          <div className="common-sections" key={index}>
            <div className="common-title">{Labels?.SectionName[subgroup]}</div>
            {commonData
              .filter((item) => item.sectionInternalName === subgroup)
              .map((item, index) => {
                return <DataInput value={item} key={index} />;
              })}
          </div>
        );
      })}
    </div>
  );
};

export default Common;
