import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance,  } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";

export const fetchEmergencyEquipment = createAsyncThunk(
  "EmergencyEquipment/FetchEmergencyEquipment'",
  async (dispatch) => {
    try {
      let res = await sharedInstance.get(EndPoints.emergencyEquipments);
      if (res?.data?.data && res.data.statusCode === 200) {
        console.log(res.data);
        return res.data.data;
      } else {
        throw Error();
      }
    } catch (error) {
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
        console.log("fetcherrorrrr")
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);


export const addEmergencyEquipment = createAsyncThunk(
  "EmergencyEquipment/AddEmergencyEquipment",
  async (equipment, thunkAPI) => {
    try {
      let res = await sharedInstance.post(EndPoints.addEquipments, equipment);
      console.log("ressssssssss", equipment);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        console.log("loggggg", res?.data?.data);
        console.log("rrrr", equipment)
        return res?.data?.data;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      console.log("errrrrr", error)
    }
  }
);

export const deleteEmergencyEquipment = createAsyncThunk(
  "EmergencyEquipment/DeleteEmergencyEquipment",
  async ( id , thunkAPI) => {
    try {
      
      let res = await sharedInstance.delete(`${EndPoints.deleteEquipments}?emergencyId=${id}`);
      
      if (res?.data?.data || res?.data?.statusCode === 200) {
        // console.log(res?.data?.data);
        // deleteSuccess(id);
        return id;
      } else if(res?.data?.statusCode === 500){

        
        return
      } 
      else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {}
  }
);


export const updateEmergencyEquipment = createAsyncThunk(
  "/EmergencyEquipment/UpdateEmergencyEquipment'",
  async (args, thunkAPI) => {
    try {
      let res = await sharedInstance.put(`${EndPoints.updateEquipments}?emergencyId=${args.id}`, args);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        console.log("updateEmergencyService",res?.data?.data);
        return res?.data?.data;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {}
  }
);