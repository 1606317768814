import React, { useState } from "react";
import { Button } from "primereact/button";
import "./popup.scss";
import deleteImage from "../../Assets/Images/delete.png";

function DeletePopUp({ closePopup, deleteValue, heading, content, tempName }) {
  return (
    <div className="popup-wrapper">
      <div className="popup-display">
        <img id="img_popupimg" className="popup-image" src={deleteImage}></img>
        {tempName ? (
          <p className="popup-content">
            Are you sure you want to delete <span>{content}</span> of{" "}
            <span>{tempName}</span> in {heading}?
          </p>
        ) : (
          <p className="popup-content">
            Are you sure you want to delete <span>{content}</span> in {heading}?
          </p>
        )}
      </div>

      <div className="popupButtonWrapper">
        <Button id="btn_PopUpCancel"
          className="cancel-button"
          label="Cancel"
          onClick={closePopup}
        ></Button>
        <Button id="btn_PopUpDelete"
          className="delete-button"
          label="Delete"
          onClick={deleteValue}
          autoFocus
        ></Button>
      </div>
    </div>
  );
}

export default DeletePopUp;
