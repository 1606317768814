import { createSlice } from '@reduxjs/toolkit';
import { FetchPotentialAOC, AddPotentialAOC, UpdatePotentialAOC, DeletePotentialAOC } from '../../Service/PotentialAoc';

const initialState = {
    potentialAOCData: [],
    loading: false,
}

export const potentialAOCSlice = createSlice({
  name: 'potentialAOC',
  initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(FetchPotentialAOC.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(FetchPotentialAOC.fulfilled, (state, action) => {
            state.potentialAOCData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(FetchPotentialAOC.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(DeletePotentialAOC.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(DeletePotentialAOC.fulfilled, (state, action) => {
            state.potentialAOCData = state.potentialAOCData.filter((item) => item.id !== action.payload)
            state.loading = false
            state.error = ''
        })
        builder.addCase(DeletePotentialAOC.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(AddPotentialAOC.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(AddPotentialAOC.fulfilled, (state, action) => {
            state.potentialAOCData.push(action.payload);
            state.loading = false
            state.error = ''
        })
        builder.addCase(AddPotentialAOC.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(UpdatePotentialAOC.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(UpdatePotentialAOC.fulfilled, (state, action) => {
            let arr = [...state.potentialAOCData]
            const index = arr.findIndex((value) => value?.id === action?.payload?.id);
            arr[index] = action.payload
            state.potentialAOCData = arr;
            state.loading = false
            state.error = ''
        })
        builder.addCase(UpdatePotentialAOC.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
    },
})

export default potentialAOCSlice.reducer