import { createSlice } from '@reduxjs/toolkit';
import { fetchEmergencyEquipment,addEmergencyEquipment, deleteEmergencyEquipment, updateEmergencyEquipment } from '../../Service/EmergencyEquipments';

const initialState = {
    emergencyEquipmentsData: [],
    loading: false,
  };


export const emergencyEquipmentSlice = createSlice({
    name: 'emergencyEquipment',
    initialState,
      reducers: {},
      extraReducers: (builder) => {
          builder.addCase(fetchEmergencyEquipment.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(fetchEmergencyEquipment.fulfilled, (state, action) => {
              state.emergencyEquipmentsData = action.payload
              console.log( "storeEE",state.emergencyEquipmentsData)
              state.loading = false
              state.error = ''
          })
          builder.addCase(fetchEmergencyEquipment.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(deleteEmergencyEquipment.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(deleteEmergencyEquipment.fulfilled, (state, action) => {
              state.emergencyEquipmentsData = state.emergencyEquipmentsData.filter((item) => item.emergencyId !== action.payload)
              console.log("slice",action.payload,state.emergencyEquipmentsData)
              state.loading = false
              state.error = ''
          })
          builder.addCase(deleteEmergencyEquipment.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(addEmergencyEquipment.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(addEmergencyEquipment.fulfilled, (state, action) => {
              state.emergencyEquipmentsData.push(action.payload);
              state.loading = false
              state.error = ''
          })
          builder.addCase(addEmergencyEquipment.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(updateEmergencyEquipment.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(updateEmergencyEquipment.fulfilled, (state, action) => {
              let arr = [...state.emergencyEquipmentsData]
              const index = arr.findIndex((value) => value.Id === action.payload.Id);
              arr[index] = action.payload
              state.emergencyEquipmentsData = arr;
              state.loading = false
              state.error = ''
          })
          builder.addCase(updateEmergencyEquipment.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
      },
  })
  
  export default emergencyEquipmentSlice.reducer