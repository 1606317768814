import React from 'react'
import HeaderLogo from '../../Components/HeaderLogo'
// import Notification from '../../Components/NotificationInfo'
import UserProfile from '../../Components/UserProfile'
import './headerContainer.scss'

function HeaderContainer() {
  return (
    <div className='header_container'>
      <div className='header_logo'>
        <HeaderLogo />
        <span className='admin-portal'>ADMIN PORTAL</span>
      </div>
      <div className='header_list'>
        {/* <Notification /> */}
        <UserProfile />
      </div>
    </div>
  )
}

export default HeaderContainer
