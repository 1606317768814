import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance,  } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";


export const HazardousEnergiesControlsSwiMessaging = createAsyncThunk(
  "/api/GasHazardEnergyControlMessaging/FetchGasHazardEnergyControlMessagingDetails",
  async (dispatch) => {
    try {
      dispatch(setLoader(true));
      let res = await sharedInstance.get(EndPoints.fetchHazardousEnergiesControlsSwiMessaging);
      if (res?.data?.data && res.data.statusCode === 200) {
        dispatch(setLoader(false));
        return res.data.data;
      } else {
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
      error?.response?.message || "Oops, Something went wrong";
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

  export const addHECSwimessagingDetails = createAsyncThunk(
"GasHazardEnergyControlMessaging/UpdateGasHazardEnergyControlMessagingDetails?hazardId=58&notificationMessage=testing",
 async (args, thunkAPI) => {
       try {
      thunkAPI.dispatch(setLoader(true));
        let res = await sharedInstance.put(`${EndPoints.updateSwiHECMessaging}?hazardId=${args.id}&notificationMessage=${args.message}` );
        if (res?.data?.data || res?.data?.statusCode === 200) {
      thunkAPI.dispatch(setLoader(false));
        return res?.data?.data;
        }
         else {
          throw Error();
        }
      } catch (error) {
      thunkAPI.dispatch(setLoader(false));
        let errorCode = error?.response?.status;
        let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    }
  );
  export const deleteHECSwimessagingDetails = createAsyncThunk(
    "/api/GasHazardEnergyControlMessaging/DeleteGasHazardEnergyControlMessagingDetails?hazardId=59",
    async ( {id, deleteSuccess} , thunkAPI) => {
      try {
          thunkAPI.dispatch(setLoader(true));
        let res = await sharedInstance.delete(`${EndPoints.deleteSwiHECMessaging}?hazardId=${id}`);
        if (res?.data?.data || res?.data?.statusCode === 200) {
          thunkAPI.dispatch(setLoader(false));
           deleteSuccess(id);
          return id;
        } else {
          let errorCode = res?.data?.statusCode;
          let errorMessage = res?.data?.message || "Oops, Something went wrong";
          HandleError(thunkAPI.dispatch, errorCode, errorMessage);
        }
      } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      }
    }
  );

