import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import "./addTask.scss";
import { InputText } from "primereact/inputtext";
import { saveSwiTask, FetchSWIMapping } from "../../../Service/SwiMapping";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../GenericPopup";
import WarnPopup from "../../GenericPopup/warningPopup";
import { freeTextBoxRegexTest } from "../../../Utils/regex";

const AddTask = ({
  taskOptions,
  handleCancelFromAddTask,
  jobTypeId,
  getSWIMappingData,
}) => {
  const [newTaskName, setNewTaskName] = useState("");
  const [taskNameDuplicate, setTaskNameDuplicate] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    FetchSWIMapping();
  }, []);

  const swiTask = useSelector(
    (state) => state?.swiMapping?.swiMappingData || []
  );

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  const validateTaskName = (e) => {
    const val = e.target.value;
    setNewTaskName(val.trim());
    setTaskNameDuplicate(false);
  };

  function showWarnPopup() {
    setShowWarning((pre) => !pre);
  }

  const handleNewTaskName = (taskName) => {
    const isDuplicate = taskOptions.some(
      (data) => data.trim().toLowerCase() === taskName.toLowerCase()
    );
    if (!isDuplicate) {
      dispatch(
        saveSwiTask({
          jobTypeId: jobTypeId,
          swiName: taskName,
          saveSuccess: (status, result) => {
            if (status) {
              FetchSWIMapping();
              // showSaveHighEnergyPopUp(result);
              handleCancelFromAddTask();
              getSWIMappingData();
            } else {
              showWarnPopup();
            }
          },
        })
      );
    } else {
      setTaskNameDuplicate(true);
    }
  };

  return (
    <div className="addTaskOuterWrapper">
      <div className="addTaskBtnWrapper">
        <Button id="btn_AddCancel"
          className="inActiveBtn"
          onClick={() => handleCancelFromAddTask()}
        >
          Cancel
        </Button>
        <Button id="btn_AddSave"
          className="activeBtn"
          onClick={() => handleNewTaskName(newTaskName)}
          disabled={newTaskName && newTaskName.length > 0 ? false : true}
        >
          Save
        </Button>
      </div>

      <div className="addTaskTextWrapper">
        <table>
          <tr>
            <td>
              <p className="addTaskInputText">Task Number & Name</p>
            </td>
            <td>
              <InputText id="txt_addTaskInputBox"
                className="addTaskInputBox"
                placeholder="###_Task Name"
                maxLength="100"
                keyfilter={freeTextBoxRegexTest()}
                onChange={(e) => validateTaskName(e)}
              />
              {newTaskName.length === 100 && (
                <p className="contact-error">
                  *Max. number of characters should not exceed 100.
                </p>
              )}
              {taskNameDuplicate && (
                <p className="edit-duplicate">*Cannot add duplicate task.</p>
              )}
            </td>
          </tr>
        </table>
      </div>

      <ModalWrapper
        visible={showWarning}
        onDismiss={() => {
          showWarnPopup();
        }}
      >
        <WarnPopup
          closePopup={() => {
            showWarnPopup();
          }}
        />
      </ModalWrapper>
    </div>
  );
};

export default AddTask;
