import React from "react";
import successLogo from "../../Assets/Images/tick.png";
import { Button } from "primereact/button";
import "./commonSavePopup.scss";

function SaveSuccess({ closePopup, heading, content }) {
  return (
    <div className="success-popup">
      <div className="successFormWrapperInner">
        <div className="grid-container">
          <img id="img_PopsuccessLogo" src={successLogo} className="successLogo" />
          <div className="text-container-">
            <p className="successTitleHead"> {heading} ! </p>
            <p className="successTitle">
              You have successfully saved 
              <span className="content-style "> {content}</span> .
            </p>
          </div>
        </div>
        <div className="popupButtonWrapper">
          <Button id="btn_PopUpCloseBtn"
            className="close-button"
            label="Close"
            onClick={closePopup}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default SaveSuccess;
