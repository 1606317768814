import React, { useState, useEffect } from 'react';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import './hyperlink.scss'
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import Switch from "../Toggle";
import { fetchResourceDetails, deleteResourceDetails, addResourceDetails, updateResourceDetails } from "../../Service/Hyperlink";
import ModalWrapper from "../GenericPopup";
import DeletePopup from "../GenericPopup/deletePopup";
import DeleteSuccess from '../GenericPopup/deleteSuccess';
import SaveSuccess from '../GenericPopup/saveSuccess';
import attachImage from '../../Assets/Images/attachImage.png';
import trash from '../../Assets/Images/trash.png';
import editIcon from '../../Assets/Images/edit.png';
import cancelIcon from '../../Assets/Images/cancel.png';
import editEnable from '../../Assets/Images/checkCircleEnabled.png';
import editDisable from '../../Assets/Images/checkCircleDisable.png';
import Loader from '../Loader';
import defImage from '../../Assets/Images/default_image.png';

export default function HyperlinkMngment() {
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [Namevalue, setNameValue] = useState('');
  const [Linkvalue, setLinkValue] = useState('');
  const [EditNamevalue, EditsetNameValue] = useState('');
  const [EditLinkvalue, EditsetLinkValue] = useState('');
  const [checkEnabled, setCheckEnabled] = useState(false);
  const [checkEditEnabled, setEditCheckEnabled] = useState(false);
  const [ID, setID] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [deleteId, steDeleteId] = useState('');
  const [valueName, setValueName] = useState('');
  const [toggleState, setToggleState] = useState({});
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [fileError, setFileError] = useState(false)
  const [nameError, setNameError] = useState(false);
  const [newlinkError, setNewLinkError] = useState(false);
  const [linkError, setLinkError] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchResourceDetails(dispatch));
  }, []);

  
  const SourceLink = useSelector((state) => state?.hyperlink?.hyperlinkData || []);
  const isLoading = useSelector(state => state.loaderSlice.isLoading);
    
  SourceLink?.map((item) => {
    toggleState[item?.resourceId] = item?.active;
  })

  //api call for delete
  const handleDelete = (id) => {
    dispatch(deleteResourceDetails({ id , deleteSuccess: showDeleteSuccess}));
    setShowDelete((pre) => !pre);
  }

  //api call for add
  const handleDuplicate = (name) => {
    setIsDuplicate(false);
    SourceLink.map((item) => {
      if (item?.resourceName.toLowerCase() === name.toLowerCase()) {
        alert("Duplicate");
        setIsDuplicate(true);
        return (isDuplicate);
      }
    })
  }

  const handleAdd = (image, name, link) => {
    handleDuplicate(name);
    if (!isDuplicate) {
      const urlRegex = /^(https?:\/\/)[^$#].*$/i;
      if (urlRegex.test(link.toLowerCase())) {
        setVisible(false);
        dispatch(addResourceDetails({ image, name, link, active: true, saveSuccess: showSavePopUp }));
        setLinkValue('');
        setNameValue('');
        setNameError(false);
        setNewLinkError(false)
      }
      else {
        setNewLinkError(true);
      }
    }
  }

  //api call for update
  const handelUpdate = (id, image, editname, link, dataimage, active) => {
    if (image === null || image === "") {
      image = dataimage;
    }
    const urlRegex = /^(https?:\/\/)[^$#].*$/i;
    if (urlRegex.test(link.toLowerCase())) {
      setLinkError(false);
      setEdit(false);
      setEditCheckEnabled(false);
      dispatch(updateResourceDetails({ id, image, editname, link, active, action: "", saveSuccess: showSavePopUp }));
    }
    else {
      setLinkError(true);
    }
    setNameError(false);
  }

  //api call for handling toggle in update
  const handleToggleUpdate = (id, image, editname, link, dataimage, active) => {
    if (image === null || image === "") {
      image = null;
    } 
    dispatch(updateResourceDetails({ id, image, editname, link, active, action: "toggle" }));  
  }

  function showDeletePopUp(id, name) {
    steDeleteId(id);
    setValueName(name)
    setShowDelete((pre) => !pre);
  }

  function showDeleteSuccess() {
    setShowSuccess((pre) => !pre);
    setShowDelete(false);
  }

  function showSavePopUp(value) {
    setValueName(value);
    setShowSaveSuccess((pre) => !pre);
  }

  const handleEditNameChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z0-9'*()\-"\//.\s]*$/. test(value) || value === ''){  
      EditsetNameValue(value);
      setNameError(true);
      if (!fileError) {
        setEditCheckEnabled(true);
      }
    }
  }
  const handleEditLinkChange = (event) => {
    const val = event.target.value.trim();
    if (/^[a-zA-Z]/.test(val) || val === '') { 
      EditsetLinkValue(val);
      if (!fileError) {
        setEditCheckEnabled(true);
      }
    }
  }

  const handleNameChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z0-9'*()\-"\//.\s]*$/.test(value) || value === '') {
      setNameValue(value);
      setNameError(true);
      if (!fileError) {
        setCheckEnabled(true);
      }
    }
  }
  const handleLinkChange = (event) => {
    const val = event.target.value.trim();
    if (/^[a-zA-Z]/.test(val) || val === '') { 
      setLinkValue(val);
      if (!fileError) {
        setCheckEnabled(true);
      }
    }
  }

  const addNewrow = () => {
    setVisible(!visible);
  }

  const onEdit = (hyperlinkid) => {
    setID(hyperlinkid);
    SourceLink.map((data) => {
      if (data?.resourceId === hyperlinkid) {
        EditsetNameValue(data?.resourceName);
        EditsetLinkValue(data?.resourceURL);
        setEdit(true);
      }
    })
  }

  const onValueclear = () => {
    setLinkValue('');
    setNameValue('');
    setCheckEnabled(false);
    setVisible(false);
    setFileError(false);
    setNewLinkError(false);
  }

  const onEditclear = () => {
    setEdit(false);
    EditsetLinkValue('');
    EditsetNameValue('');
    setEditCheckEnabled(false)
    setFileError(false);
    setLinkError(false)
    setEditCheckEnabled(false);
  }
  
  const handleUpdateToggle = (id, data) => {
    const newToggleState = { ...toggleState };
    newToggleState[id] = !newToggleState[id];
    toggleState[id] = newToggleState[id];
    handleToggleUpdate(id, selectedImage, data?.resourceName, data?.resourceURL, data?.images, toggleState[id])
  }

  const handleImageUpload = (e) => {
    if (e.target.files[0].size > 2e+6) {
      alert("file size cannot be greater than 2mb");
    }
    else {
      const file = e.target.files[0];
      if (file) {
        if (file.type === "image/jpeg" || file.type === "image/png") { 
          setFileError(false)
          setSelectedImage(e.target.files[0]);
          setEditCheckEnabled(true);
        } else {
          setFileError(true);
        }
      }
    }
  }

  const resourceVideoLinks = SourceLink.map((videoSource, index) => {
    if (videoSource?.resourceId === ID && edit) {
      return (
        <>
          <tr className='video-rows' key={index}>
            <td>
              <label for="edit-upload" className='upload-icon'>
                <i className='pi pi-upload'></i>
              </label>
              <input id="edit-upload" type="file" name="file" accept='.jpg, .png, .jpeg' onChange={(e) => handleImageUpload(e)} />
            </td>
            <td className='text-content'>
              <span>
                <InputText id="txt_editNameValue" maxLength='50' className='p-inputtext' value={EditNamevalue} onChange={(e) => handleEditNameChange(e)} />
              </span>
              {nameError && EditNamevalue.length === 50 && 
                <span className='name-error'>*Maximum characters should not exceed 50.</span>
              }
              {fileError && 
                <p className='file-edit-error'>*Please upload .jpg or .png files</p>
              }
              <span className={(nameError && EditNamevalue.length === 50) ? 'new-change-input' :'new-input'}>
                <InputText id="txt_editLinkValue" className='p-inputtext' value={EditLinkvalue} onChange={(e) => handleEditLinkChange(e)} />
                {linkError && 
                <span className='link-error'>*Hyperlink should start with http:// or https://.</span>
                }
              </span>
            
            </td>

            <td>
              <span >
                {(checkEditEnabled && EditNamevalue.length !== 0 && EditLinkvalue.length !== 0) ? 
                  <img id="img_editEnable" src={editEnable} className='enable-edit' onClick={() => handelUpdate(ID, selectedImage, EditNamevalue, EditLinkvalue, videoSource?.images, toggleState[videoSource?.resourceId])}></img>
                  : 
                  <img id="img_editDisable" src={editDisable} className='disable-edit'></img>
                }
              </span>
            </td>

            <td>
              <span className='times-icon'>
                <img id="img_cancelIcon" src={cancelIcon} className='cancel-icon' onClick={onEditclear}></img>
              </span>
            </td>

            <td>
              <span className='edit-switch'>
                <Switch isToggled={toggleState[videoSource?.resourceId]} onToggle={() => handleUpdateToggle(ID, videoSource)} />
              </span>
            </td>
          </tr>
          <hr className='line-edit-border' />
        </>
      )
    }
    return (
      <>
      {isLoading && <Loader/>}
        <tbody key={index}>
          {
            <>
              <tr className="video-rows">
                <td>
                  <span className='image-avatar'>
                    {videoSource?.base64 &&
                      <img id="img_ResourceImg" className="resource-image" src={ 'data:image/png;base64,' + videoSource.base64 } alt=""></img>
                    }
                    {!videoSource?.base64 &&
                      <img id="img_defImage" className="resource-image" src={defImage} alt=""></img>
                    }
                  </span>
                </td>
                <td>
                  <p className="video-links">
                    <a id="hyperLink_resourceURL" href={videoSource?.resourceURL} target="blank" className='link-container'>
                      {videoSource?.resourceName}
                    <span className='link-content'>
                        <img id="img_AttachLink" className='attach-link' src={attachImage}></img>
                    </span>
                    </a>
                  </p>
                </td>
                <td>
                  <span className='pencil-container'>
                    <img id="img_EditIcon" src={editIcon} className="edit-icon" onClick={() => onEdit(videoSource?.resourceId)}></img>
                  </span>
                </td>
                <td>
                  <span className='trash-container'>
                    <img id="img_trash" src={trash} className='trash-icon' onClick={() => showDeletePopUp(videoSource?.resourceId, videoSource?.resourceName)}></img>
                  </span>
                </td>
                <td className='switch-container'>
                  <Switch isToggled={toggleState[videoSource?.resourceId]} onToggle={() => handleUpdateToggle(videoSource?.resourceId, videoSource)} />
                </td>
              </tr>
              <hr className='line-border' />
            </>
          }
        </tbody>
      </>
    )
  });
  return (
    <div className="hyperlink" >
      <div className='hyperlink-container'>
        <table>{resourceVideoLinks}</table>

        <ModalWrapper visible={showDelete} onDismiss={() => { showDeletePopUp() }}>
          <DeletePopup closePopup={() => { showDeletePopUp(); }} deleteValue={() => handleDelete(deleteId)} heading='Hyperlink' content={valueName} />
        </ModalWrapper>
        
        <ModalWrapper visible={showSuccess} onDismiss={() => { showDeleteSuccess() }}>
          <DeleteSuccess closePopup={() => { showDeleteSuccess(); }} heading='Hyperlink' content={valueName} />
        </ModalWrapper>

        <ModalWrapper visible={showSaveSuccess} onDismiss={() => { showSavePopUp() }}>
         <SaveSuccess closePopup={() => { showSavePopUp(); }} heading='Hyperlink' content={valueName} />
        </ModalWrapper>
        

        {visible &&
          <table className='resource-table'>
          <tr className='video-rows'>
            <td>
              <label for="upload-image" className='image-upload'>
                <i className='pi pi-upload'></i>
            </label>
            <input id="upload-image" type="file" name="file" accept='.jpg, .png, .jpeg' onChange={(e) => handleImageUpload(e)} />
            </td>
              <td>
                <InputText id="txt_nameValue" maxLength='50' placeholder='Name' className='p-inputtext' value={Namevalue} onChange={handleNameChange} />
                {nameError && Namevalue.length === 50 && 
                <span className='name-error'>*Maximum characters should not exceed 50.</span>
              }
                {fileError && 
                <p className='file-edit-error'>*Please upload .jpg or .png files</p>
                }
                <InputText id="txt_nameLink" placeholder='https://example.org' className={nameError && Namevalue.length === 50 ? 'new-change-link' : 'new-link'} value={Linkvalue} onChange={handleLinkChange} />
                {newlinkError && 
                <span className='link-error'>*Hyperlink should start with http:// or https://.</span>
                }
              </td>
              <td>
                <span>
                  {(checkEnabled && Namevalue.length !== 0 && Linkvalue.length !== 0 && selectedImage) ? 
                    <img id="img_hypereditEnable" src={editEnable} className='enable-edit' onClick={() => handleAdd(selectedImage, Namevalue, Linkvalue)}></img>
                    :
                    <img id="img_hypereditDisable" src={editDisable} className='disable-edit'></img>
                }
                </span>
              </td>
              <td>
                <span className='times-icon'>
                  <img id="img_hypercancelIcon" src={cancelIcon} className='new-close' onClick={onValueclear}></img>
                </span>
              </td>
              <td></td>
            </tr>
          </table>
        }
      </div>
      <div className='footer'>
        <button id="btn_hyperActive" type="button" disabled={visible} className={visible ? 'hyperlink-button-inactive':'hyperlink-button-active'} onClick={addNewrow}>+ Hyperlink</button>
      </div>
    </div>

  );
}