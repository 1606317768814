import { createSlice } from '@reduxjs/toolkit';
import { fetchResourceDetails, deleteResourceDetails, addResourceDetails, updateResourceDetails } from '../../Service/Hyperlink';

const initialState = {
    hyperlinkData: [],
    loading: false,
}

export const hyperlinkSlice = createSlice({
  name: 'hyperlink',
  initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchResourceDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(fetchResourceDetails.fulfilled, (state, action) => {
            state.hyperlinkData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchResourceDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(deleteResourceDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(deleteResourceDetails.fulfilled, (state, action) => {
            state.hyperlinkData = state.hyperlinkData.filter((item) => item.resourceId !== action.payload)
            state.loading = false
            state.error = ''
        })
        builder.addCase(deleteResourceDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(addResourceDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(addResourceDetails.fulfilled, (state, action) => {
            state.hyperlinkData.push(action.payload);
            state.loading = false
            state.error = ''
        })
        builder.addCase(addResourceDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(updateResourceDetails.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(updateResourceDetails.fulfilled, (state, action) => {
            let arr = [...state.hyperlinkData]
            const index = arr.findIndex((value) => value?.resourceId === action?.payload?.resourceId);
            arr[index] = action.payload
            state.hyperlinkData = arr;
            state.loading = false
            state.error = ''
        })
        builder.addCase(updateResourceDetails.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
    },
})

export default hyperlinkSlice.reducer