import React from "react";
import { Button } from "primereact/button";
import "./popup.scss";
import alertImage from "../../Assets/Images/alert.png";

function SendPopUp({ closePopup, sendValue, heading, content }) {
  return (
    <div className="popup-wrapper">
      <div className="popup-display">
        <img id="img_alertImage" className="popup-image" src={alertImage}></img>
        <p className="popup-content">Are you sure you want to send <span className="screen-data">{content} </span>in {heading}?</p>
      </div>

      <div className="popupButtonWrapper">
        <Button id="btn_sendPopCancel"
          className="cancel-button"
          label="Cancel"
          onClick={closePopup}
        ></Button>
        <Button id="btn_sendPopSend"
          className="delete-button"
          label="Send"
          onClick={sendValue}
          autoFocus
        ></Button>
      </div>
    </div>
  );
}

export default SendPopUp;
