const searchTextBoxRegex = /(^[a-zA-Z0-9.,\-?&/'() ]*$)/;
const alphaNumericDotSpace = /(^[a-zA-Z0-9. ]+$)/;
const alphaNumericDotAtRateSpace = /(^[a-zA-Z0-9.@ ]+$)/;
const alphaNumericDotAtRateCommaSpace = /(^[a-zA-Z0-9.@, ]+$)/;
const alphaNumericDotCommaSpace = /(^[a-zA-Z0-9., ]+$)/;
const alphaNumericDotHypenCommaSpace = /(^[a-zA-Z0-9.\-, ]+$)/;
const alphaNumericDotAtRateSemiColonFSlashSpace = /(^[a-zA-Z0-9.@:/ ]+$)|(^(?!.*\S))/;
const freeTextBoxRegex=/(^[a-zA-Z0-9\'\"\*\(\)\-\/\. ]+$)/
const freeTextBoxAdditionalRegex = /(^[a-zA-Z0-9\'\"\*\(\)\-\/\.\@\#\&\_\=\+ ]+$)/

export const isSearchTextBoxRegex = () => {
  return searchTextBoxRegex;
};

export const isAlphaNumericDotSpace = () => {
  return alphaNumericDotSpace;
};

export const isAlphaNumericDotHypenCommaSpace = () => {
  return alphaNumericDotHypenCommaSpace;
};

export const isAlphaNumericDotCommaSpace = () => {
  return alphaNumericDotCommaSpace;
};

export const isAlphaNumericDotAtRateCommaSpace = () => {
  return alphaNumericDotAtRateCommaSpace;
};

export const isAlphaNumericDotAtRateSemiColonFSlashSpace = () => {
  return alphaNumericDotAtRateSemiColonFSlashSpace;
};

export const isAlphaNumericDotAtRateSpace = () => {
  return alphaNumericDotAtRateSpace;
};

export const freeTextBoxRegexTest = () => {
  return freeTextBoxRegex;
};

export const freeTextBoxRegexAdditional = () => {
  return freeTextBoxAdditionalRegex;
};
