import React, { useState, useEffect } from "react";
import "./configuration.scss";
import Labels from "../../Constants/Label";
import { InputTextarea } from "primereact/inputtextarea";
import Switch from "../Toggle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConfigurationDetails,
  updateConfigurationDetails,
} from "../../Service/Configuration";
import ModalWrapper from "../GenericPopup";
import SavePopup from "../GenericPopup/savePopup";
import SaveSuccess from "../GenericPopup/saveSuccess";
import { setSave } from "../../Store/saveSlice";
import Loader from "../Loader";

const Configuration = () => {
  const [heatMsg, setHeatMsg] = useState("default text");
  const [isToggled, setIsToggled] = useState(true);
  const [years, setYears] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(true);
  const [showSave, setShowSave] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const dispatch = useDispatch();
  const ConfigurationData = useSelector(
    (state) => state?.configuration?.configData || []
  );

  useEffect(() => {
    dispatch(fetchConfigurationDetails(dispatch));
  }, []);

  const userName = useSelector((state) => state.user.userData.username);

  useEffect(() => {
    if (ConfigurationData) {
      if (ConfigurationData[0]?.value) {
        setYears(ConfigurationData[0]?.value);
      }

      if (ConfigurationData[1]?.comments) {
        setHeatMsg(ConfigurationData[1]?.comments);
      }

      if (ConfigurationData[1]) {
        if (ConfigurationData[1]?.value === "True") {
          setIsToggled(true);
        } else {
          setIsToggled(false);
        }
        if (ConfigurationData[1]?.value === "True") {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    } else {
      return null;
    }
  }, [ConfigurationData]);

  const isLoading = useSelector((state) => state?.loaderSlice?.isLoading);

  const handleSavedValue = (val) => {
    dispatch(setSave(val));
  };

  const date = new Date();

  let arr = {
    createdBy: userName,
    createdDateTime: date.toISOString(),
    retention: {
      id: ConfigurationData[0]?.id,
      value: years,
    },
    heatIndex: {
      id: ConfigurationData[1]?.id,
      value: isToggled,
      comments: heatMsg,
    },
  };

  function showPopUp(data) {
    setShowSave((pre) => !pre);
    setMsgError(false);
  }

  function showSavePopUp() {
    setShowSuccess((pre) => !pre);
    setShowSave(false);
  }

  // api call for update
  const handleUpdate = (data) => {
    dispatch(
      updateConfigurationDetails({
        data,
        saveSuccess: showSavePopUp,
        callBackFn: handleNewData,
      })
    );
    setButtonDisable(true);
    setCancelDisable(true);
    handleSavedValue(true);
  };

  const handleNewData = (success) => {
    if (success) {
      dispatch(fetchConfigurationDetails(dispatch));
    }
  };

  const toggleSwitch = () => {
    setIsToggled((pre) => !pre);
    msgDisable();
    setButtonDisable(false);
    if (years === "") {
      setButtonDisable(true);
    }
    handleSavedValue(false);
    setCancelDisable(false);
  };

  const msgDisable = () => {
    if (isToggled === true) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const changeYears = (e) => {
    setButtonDisable(false);
    handleSavedValue(false);
    setCancelDisable(false);
    const val = e.target.value;
    if (val === "") {
      setButtonDisable(true);
    }
    if (/^[1-9][0-9]?$/.test(val) || val === "") {
      setYears(e.target.value);
      setYearError(false);
    } else {
      setYearError(true);
    }
  };

  const changeMessage = (e) => {
    let val = e.target.value.trimStart();
    setHeatMsg(val);
    setButtonDisable(false);
    if (years === "") {
      setButtonDisable(true);
    }
    handleSavedValue(false);
    setCancelDisable(false);
    setMsgError(true);
  };

  const handleCancel = () => {
    setYears(ConfigurationData[0]?.value);
    setHeatMsg(ConfigurationData[1]?.comments);
    if (ConfigurationData[1]?.value === "True") {
      setIsToggled(true);
    } else {
      setIsToggled(false);
    }
    setButtonDisable(true);
    handleSavedValue(true);
    setCancelDisable(true);
  };

  return (
    <div className="configuration">
      {isLoading && <Loader />}
      <div className="configuration-title">
        <header className="configuration-header">
          {Labels?.Sidebar?.configuration}
          <button id="btn_ConfigCancel"
            className={
              cancelDisable ? "cancel-disable" : "config-cancel-button"
            }
            onClick={() => handleCancel()}
            label="Confirm"
          >
            Cancel
          </button>
          <button id="btn_ConfigDisable"
            className={buttonDisable ? "save-disable" : "config-save-button"}
            disabled={buttonDisable}
            onClick={() => showPopUp(arr)}
          >
            Save
          </button>
        </header>
      </div>

      <div className="config-content">
        {ConfigurationData?.length !== 0 &&
          ConfigurationData.map((item, index) => {
            if (ConfigurationData.length !== 0) {
              if (item.id === 1) {
                return (
                  <div className="job-retention" key={index}>
                    <p className="content-title">{item.type}</p>
                    <div className="job-years">
                      <input id="txt_jobYears"
                        className="year-input"
                        type="number"
                        placeholder="00"
                        value={years}
                        onChange={(e) => changeYears(e)}
                      ></input>
                      {yearError ||
                        (years === "" && (
                          <span className="years-error">
                            *Please enter a number between 1 and 99 and years
                            should not be empty.
                          </span>
                        ))}
                      <p>Years</p>
                    </div>
                    <div className="job-info">
                      <div className="triangle-up"></div>
                      <p>{item.comments}</p>
                    </div>
                  </div>
                );
              } else if (item.id === 2) {
                return (
                  <div className="heat-index" key={index}>
                    <p className="content-title">{item.type}</p>
                    <Switch
                      className="heat-toggle"
                      isToggled={isToggled}
                      onToggle={() => toggleSwitch()}
                    />
                    <p className="heat-condition">(when &gt;= 80 degf)</p>
                    {/* regex allowed are alphabets, numbers, ', *, (), #, -, /, ., @, &, _, =, +, comma, <, >, ?, :, !, ’, bullet point and white space. */}
                    <InputTextarea
                      id="txt_heatMsg"
                      autoResize
                      value={heatMsg}
                      onChange={(e) => changeMessage(e)}
                      rows={7}
                      cols={90}
                      maxLength="500"
                      disabled={isDisabled}
                      keyfilter={
                        /^[a-zA-Z0-9'’•*?!()\#\-"\//.\s\@\&\_\=\+\,\<\>\: ]*$/
                      }
                    />
                    {msgError && heatMsg.length === 500 && (
                      <span className="message-error">
                        *Maximum characters should not exceed 500 characters.
                      </span>
                    )}
                  </div>
                );
              }
            } else {
              return null;
            }
          })}
      </div>

      <ModalWrapper
        visible={showSave}
        onDismiss={() => {
          showPopUp();
        }}
      >
        <SavePopup
          closePopup={() => {
            showPopUp();
          }}
          saveValue={() => handleUpdate(arr)}
          heading="Configuration"
          content="data"
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showSuccess}
        onDismiss={() => {
          showSavePopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSavePopUp();
          }}
          heading="Configuration"
          content="data"
        />
      </ModalWrapper>
    </div>
  );
};

export default Configuration;
