import React from "react";
import './loader.scss';

function Loader() {
    return (
        <div className="loader-overlay">
            <div className="loader">
                <div className="spinner"></div>
            </div>
        </div>
    )
}

export default Loader;