import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import "./SWIMapping.scss";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import trash from "../../../Assets/Images/trash.png";
import AddTask from "./addTask";
import AddJob from "./addJob";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchSWIMapping,
  UpdateSWITask,
  addDirectControlSafeguards,
  addSWIHazard,
  addSWIHighEnergyTask,
  deleteDirectControlSafeguards,
  deleteSWIHazard,
  deleteSWIHighEnergyTask,
  deleteSwiTask,
  deleteSWIIcon,
  upadteSWIHighEnergyTask,
  updateDirectControlSafeguards,
  updateSWIHazard,
  updateSWIIcon,
} from "../../../Service/SwiMapping";
import { fetchImages } from "../../../Service/Common/fetchImages";
import { Dropdown } from "primereact/dropdown";
import Loader from "../../Loader";
import { ProgressSpinner } from "primereact/progressspinner";
import SearchTextBox from "./searchTextBox";
import { OverlayPanel } from "primereact/overlaypanel";
import ModalWrapper from "../../GenericPopup";
import DeletePopup from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/deleteSuccess";
import SaveSuccess from "../../GenericPopup/saveSuccess";
import { freeTextBoxRegexTest } from "../../../Utils/regex";

const SWIMapping = () => {
  const dispatch = useDispatch();
  const op = useRef(null);

  const [selectedJob, setSelectedJob] = useState({ jobTypeId: 0, jobName: "" });
  const [selectedTask, setSelectedTask] = useState({ swiId: 0, swiName: "" });
  const [visibleSwiMapping, setVisibleSwiMapping] = useState(true);
  const [visibleAddJob, setVisibleAddJob] = useState(false);
  const [visibleAddTask, setVisibleAddTask] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [valueName, setValueName] = useState("");

  const [dropDownOptions_Job, setDropDownOptions_Job] = useState([]);
  const [dropDownOptions_Task, setDropDownOptions_Task] = useState([]);

  const [highEnergyTableElements, setHighEnergyTableElements] = useState([]);

  const [isTableCreationInProgress, setIsTableCreationInProgress] =
    useState(false);

  //#region [Constants of High Energy Icon]
  const hiddenFileInput = useRef(null);
  const uploadIconOP = useRef(null);
  const [swiIconId, setSwiIconId] = useState(0);
  const [swiIconDelete, setSwiIconDelete] = useState({});
  const [secondaryClickOP, setSecondaryClickOP] = useState(false);
  const [swiIconIdName, setSwiIconIdName] = useState("");
  const [swiIconIdDuplicate, setSwiIconIdDuplicate] = useState(0);
  const [highEnergyRowId, setHighEnergyRowId] = useState(0);
  const [highEnergyRowIdDuplicate, setHighEnergyRowIdDuplicate] = useState(0);
  const [highEnergyRowIdEllipsis, setHighEnergyRowIdEllipsis] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedImageNameEllipsis, setSelectedImageNameEllipsis] =
    useState("");
  const [swiIconOnDeleteData, setSwiIconOnDeleteData] = useState({});
  const [uploadIconCheckEnabled, setUploadIconCheckEnabled] = useState(false);

  const [editSwiTask, setEditSwiTask] = useState(false);
  const [editName, setEditName] = useState("");
  const [swiTaskDelete, setSwiTaskDelete] = useState({});
  const [editNameError, setEditNameError] = useState(false);
  const [editenable, setEditEnable] = useState(false);

  //#region [Constants of High Energy Tasks]
  const [addHighEnergy, setAddHighEnergy] = useState(false);
  const [highEnergyDelete, setHighEnergyDelete] = useState({});
  const [editHighEnergyTask, setEditHighEnergyTask] = useState(false);
  const [editHighEnergyTaskData, setEditHighEnergyTaskData] = useState({
    highEnergyTaskId: 0,
    highEnergyTaskName: "",
  });

  //#region [Constants of Hazard]
  const [hazardDelete, setHazardDelete] = useState({});
  const [editHazard, setEditHazard] = useState(false);
  const [editHazardData, setEditHazardData] = useState({
    highEnergyTaskId: 0,
    hazardId: 0,
    hazardName: "",
  });

  //#region [Constants of DirectControls & Safeguards]
  const [directControlSafegaurdDelete, setDirectControlSafegaurdDelete] =
    useState({});
  const [editDirectControlSafegaurd, setEditDirectControlSafegaurd] =
    useState(false);
  const [editDirectControlSafegaurdData, setEditDirectControlSafegaurdData] =
    useState({
      highEnergyTaskId: 0,
      directControlSafegaurdId: 0,
      directControlSafegaurdName: "",
    });

  const [enableInputAdd, setEnableInputAdd] = useState(false);
  const [enableInputAddData, setEnableInputAddData] = useState({
    highEnergyId: 0,
    tableColumnId: 1,
    newName: "",
  });

  const swiMappingData = useSelector(
    (state) => state?.swiMapping?.swiMappingData
  );

  const isLoading = useSelector((state) => state?.swiMapping?.loading);

  const [nameDup, setnameDup] = useState(false);
  const [showDummyTable, setShowDummyTable] = useState(false);

  function getSWIMappingData() {
    dispatch(FetchSWIMapping(dispatch));
  }

  //popup for showing delete success
  function showDeleteSuccessPopup() {
    setShowDelete(false);
    setShowDeleteSuccess((pre) => !pre);
  }

  //popup for showing save success
  function showSavePopUp(value) {
    setValueName(value);
    setShowSaveSuccess((pre) => !pre);
  }

  const resetAllEditControls = () => {
    setEditSwiTask(false);
    setEditEnable(false);

    setEditHighEnergyTask(false);
    setEditHighEnergyTaskData({
      highEnergyTaskId: 0,
      highEnergyTaskName: "",
    });

    setEditHazard(false);
    setEditHazardData({
      highEnergyTaskId: 0,
      hazardId: 0,
      hazardName: "",
    });

    setEditDirectControlSafegaurd(false);
    setEditDirectControlSafegaurdData({
      highEnergyTaskId: 0,
      directControlSafegaurdId: 0,
      directControlSafegaurdName: "",
    });

    setEnableInputAdd(false);
    setEnableInputAddData({ highEnergyId: 0, tableColumnId: 1, newName: "" });

    resetHighEnergyControls();
  };

  //********SWI JOB DROPDOWN*/
  const handleDropdwonJob = (e) => {
    let _jobName = e.target.value;
    let selectedJobDetails =
      dropDownOptions_Job &&
      dropDownOptions_Job.length > 0 &&
      dropDownOptions_Job !== undefined &&
      dropDownOptions_Job.filter((job) => job?.jobName === _jobName)[0];

    setSelectedJob(selectedJobDetails);

    setHighEnergyTableElements([]);
    setIsTableCreationInProgress(true);
    setShowDummyTable(false);

    if (
      swiMappingData !== undefined &&
      swiMappingData !== null &&
      swiMappingData.length > 0
    ) {
      let swiJobTypes = swiMappingData.filter(
        (data) => data?.jobName.trim() === _jobName.trim()
      )[0].swiJobTypes;

      setDropDownOptions_Task(swiJobTypes);

      //set initial dropdown data & Table - Task
      setSelectedTask({
        swiId: swiJobTypes[0]?.id,
        swiName: swiJobTypes[0]?.swiName,
      });

      handleDropdwonTask(swiJobTypes[0]?.swiName, swiJobTypes);
    }
  };
  const handleAddJobBtn = (e) => {
    resetAllEditControls();
    setVisibleSwiMapping(false);
    setVisibleAddJob(true);
  };
  const handleCancelFromAddJob = (e) => {
    setVisibleSwiMapping(true);
    setVisibleAddJob(false);
  };

  //********SWI TASK DROPDOWN*/
  const handleDropdwonTask = async (swiNameVal, swiJobTypes) => {
    let _swiName = swiNameVal;
    resetAllEditControls();
    setHighEnergyTableElements([]);

    if (swiJobTypes.length === 0) {
      setShowDummyTable(true);
      setIsTableCreationInProgress(false);
    } else {
      setShowDummyTable(false);
      setIsTableCreationInProgress(true);
    }

    if (
      swiMappingData !== undefined &&
      swiMappingData !== null &&
      swiMappingData.length > 0
    ) {
      let operatingTaskArr =
        swiJobTypes && swiJobTypes !== undefined
          ? swiJobTypes
          : dropDownOptions_Task !== undefined &&
            dropDownOptions_Task.length > 0
          ? dropDownOptions_Task
          : [];

      let swiHighEnergyTasks = operatingTaskArr.filter(
        (swi) => swi?.swiName.trim() === _swiName.trim()
      )[0]?.swiHighEnergyTasks;

      let _swiId = operatingTaskArr.filter(
        (swi) => swi?.swiName.trim() === _swiName.trim()
      )[0]?.id;

      setSelectedTask({ swiId: _swiId || 0, swiName: _swiName });

      if (swiHighEnergyTasks && swiHighEnergyTasks.length > 0) {
        //get base64 images for all swi Icons
        let swiIconsArr = [
          ...swiHighEnergyTasks.map((data) => ({
            highEnergyId: data?.id,
            swiIcons: data?.swiIcons,
          })),
        ];

        for (let i = 0; i < swiIconsArr.length; i++) {
          try {
            let arr = [...swiIconsArr[i].swiIcons];
            let arr1 = [];
            for (let j = 0; j < arr.length; j++) {
              let res = await fetchImages(arr[j].iconPath);

              let obj = { ...arr[j], base64: res ? res : null };
              arr1.push({
                ...obj,
                customSwiIconId: `${swiIconsArr[i].highEnergyId}_${arr[j].id}`,
              });
            }
            swiIconsArr[i].swiIcons = arr1;
          } catch (err) {}
        }

        swiHighEnergyTasks = [
          ...swiHighEnergyTasks.map((data) => ({
            ...data,
            swiIcons: swiIconsArr.filter(
              (item) => item.highEnergyId === data.id
            )[0].swiIcons,
          })),
        ];

        setHighEnergyTableElements(swiHighEnergyTasks);
      } else {
        setShowDummyTable(true);
      }
    }
  };
  const handleCancelFromAddTask = () => {
    setVisibleSwiMapping(true);
    setVisibleAddTask(false);
  };
  const handleAddTaskBtn = (e) => {
    resetAllEditControls();
    setVisibleSwiMapping(false);
    setVisibleAddTask(true);
  };
  const handleEditSwiTaskName = () => {
    resetAllEditControls();
    setEditSwiTask(true);
  };
  const handleEditName = (e) => {
    const val = e.target.value;
    if (val !== "") {
      setEditName(
        freeTextBoxRegexTest().test(val)
          ? val
          : val.substring(0, val.length - 1)
      );
      val.length > selectedTask.swiName && setEditEnable(true);
      setEditNameError(true);
    }
  };
  const handleUpdateSwiTaskName = () => {
    let reqBody = {
      id: selectedTask.swiId,
      swiName: editName,
    };

    dispatch(
      UpdateSWITask({
        reqBody,
        saveSuccess: showSavePopUp,
        callBackFn: (success, responseData) => {
          if (success) {
            setValueName(editName);
            setEditSwiTask(false); //remove the input box
            setEditName("");
          }
        },
      })
    );
  };
  const handleDeleteSWITask = () => {
    let swiTaskIdVal =
      dropDownOptions_Task &&
      dropDownOptions_Task.filter(
        (data) => data.swiName.trim() === swiTaskDelete.swiName.trim()
      )[0].id;

    let reqBody = {
      jobTypeId: selectedJob.jobTypeId,
      id: swiTaskIdVal,
      swiName: swiTaskDelete.swiName,
    };

    dispatch(
      deleteSwiTask({
        reqBody,
        deleteSuccess: (success, deletedSwiName) => {
          if (success) {
            showDeleteSuccessPopup();
            setSwiTaskDelete({});
            setValueName(deletedSwiName);
          }
        },
      })
    );
  };

  //********SWI ICON COLUMN*/
  const handleSWIIconBtnClick = (id) => {
    resetAllEditControls();
    setSelectedImage(null);
    setSelectedImageName("");
    setHighEnergyRowIdDuplicate(id);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = null; //clear the previous value so as to allow reselection
    }
    hiddenFileInput?.current?.click(); // trigger file selection input pop up
  };
  const handleEllipsisClick = (e, obj, swiIcon) => {
    op.current.toggle(e);
    setHighEnergyRowId(0);
    setSwiIconOnDeleteData({
      iconId: swiIcon?.customSwiIconId,
      iconName: swiIcon?.iconName,
    });

    if (!secondaryClickOP) {
      setHighEnergyRowIdDuplicate(obj?.id);
      setSwiIconIdDuplicate(swiIcon?.customSwiIconId);
      setSwiIconIdName(swiIcon?.iconName);
      setSecondaryClickOP(false);
    }
    setSecondaryClickOP(true);
  };
  // Handle Icon Upload
  const handleUploadIcon = () => {
    setSelectedImageNameEllipsis("");
    resetAllEditControls();
    if (uploadIconOP.current) {
      uploadIconOP.current.value = null; //clear the previous value so as to allow reselection
    }
    uploadIconOP?.current?.click(); // trigger file selection input pop up
  };
  const handleImageUpload = (e, controlFrom) => {
    if (e?.target?.files[0]?.size > 2e6) {
      alert("file size cannot be greater than 2mb");
    } else {
      const file = e.target.files[0];
      // file === undefined && setUploadIconEnable(false); //hide upload option until a valid file is selected
      if (file) {
        if (file.type === "image/jpeg" || file.type === "image/png") {
          setUploadIconCheckEnabled(true);
          setSecondaryClickOP(false);

          if (controlFrom === "BtnClick") {
            setHighEnergyRowId(highEnergyRowIdDuplicate);
            setSelectedImageName(file.name);
          }

          if (controlFrom === "ClickEllipsis") {
            setHighEnergyRowIdEllipsis(highEnergyRowIdDuplicate);
            setSwiIconId(swiIconIdDuplicate);
            setSelectedImageNameEllipsis(file.name);
          }

          setSelectedImage(e.target.files[0]);
        } else {
          // setUploadIconEnable(false);

          setHighEnergyRowId(0);
          setSelectedImageName("");

          setSwiIconId(0);
          setHighEnergyRowIdEllipsis(0);
          setSelectedImageNameEllipsis("");
        }
      }
    }
  };
  function handleSWIIIconDelete() {
    let SWIIconRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: highEnergyRowIdDuplicate,
      iconId: swiIconIdDuplicate.split("_")[1],
      iconName: swiIconIdName,
    };

    dispatch(
      deleteSWIIcon({
        SWIIconRequest,
        callBackFn: (success, deletedSWIIIconName) => {
          if (success) {
            setSwiIconDelete({});
            setValueName(deletedSWIIIconName);
            showDeleteSuccessPopup();
          }
        },
      })
    );
  }
  // Save image icon
  const addUpdateImage = (swiIcon, highEnergyIdVal, image, apiCallType) => {
    setUploadIconCheckEnabled(false);
    dispatch(
      updateSWIIcon({
        JobTypeId: selectedJob.jobTypeId,
        JobTypeSWIId: selectedTask.swiId,
        HighEnergyId: highEnergyIdVal,
        IconId: swiIcon.id,
        IconName: swiIcon.iconName,
        UploadIcon: image,
        apiCallType: apiCallType,
        callBackFn: (status, updatedIconName) => {
          setHighEnergyRowId(0);
          setHighEnergyRowIdEllipsis(0);
          if (status) {
            showSavePopUp();
            setSelectedImageName("");
            cancelImageSave("BtnClick");
            setValueName(updatedIconName);
            getSWIMappingData();
          } else {
            // showWarnPopup();
          }
        },
      })
    );
  };
  const cancelImageSave = (controlType) => {
    setUploadIconCheckEnabled(false);
    setHighEnergyRowId(0);

    if (controlType === "BtnClick") {
      setSelectedImage(null);
      setSelectedImageName("");
    }

    if (controlType === "ClickEllipsis") {
      setSwiIconId(0);
      setHighEnergyRowIdEllipsis(0);
      setSelectedImageNameEllipsis("");
    }
  };

  //********HIGH ENERGY COLUMN */
  const resetHighEnergyControls = () => {
    setAddHighEnergy(false);
    setEnableInputAddData({
      highEnergyId: 0,
      tableColumnId: 1,
      newName: "",
    });
  };
  const addHighEnergyTaskBtn = () => {
    return (
      <div className="addHighEnergyContainer">
        <Button id="btn_SWIAddHighEnergy"
          className="highEnergyTaskBtn"
          label="+ Add High Energy Task"
          onClick={() => {
            resetAllEditControls();
            setAddHighEnergy(true);
          }}
        />
        {addHighEnergy && (
          <div className="searchBoxValidationContainer">
            <div className="searchBoxContainer">
              <SearchTextBox
                searchType={2}
                inputProps={{
                  value: enableInputAddData.newName,
                  maxLength: 200,
                  searchIconVisible: false,
                  clearTextIconVisible: false,
                  style: {
                    height: "35px",
                    width: "230px",
                    "font-size": "13px",
                  },
                  onChangeText: (v) =>
                    setEnableInputAddData({
                      ...enableInputAddData,
                      tableColumnId: 2,
                      newName: v,
                    }),
                }}
                onSelect={handleSelectedDropDownData}
              />

              <img id="img_SWICancel"
                src={cancelIcon}
                className="cancel-icon"
                onClick={() => {
                  setnameDup(false);
                  setAddHighEnergy(false);
                  setEnableInputAddData({
                    highEnergyId: 0,
                    tableColumnId: 1,
                    newName: "",
                  });
                }}
                alt="cancelImg"
              ></img>
              {addHighEnergy &&
              !nameDup &&
              enableInputAddData.newName.length !== 0 ? (
                <img id="img_SWIEdit"
                  src={editEnable}
                  className="edit-enable"
                  onClick={() => handleAddHighEnergyTask()}
                  alt="editEnableImg"
                ></img>
              ) : (
                <img id="img_SWIDisable"
                  src={editDisable}
                  className="edit-disable"
                  alt="editDisableImg"
                ></img>
              )}
            </div>
            {nameDup && (
              <p className="edit-duplicate">*Cannot add duplicate values.</p>
            )}
          </div>
        )}
      </div>
    );
  };
  const handleEditHighEnergyTask = (idVal, highEnergyVal) => {
    resetAllEditControls();
    setEditHighEnergyTask(true);
    setEditHighEnergyTaskData({
      highEnergyTaskId: idVal,
      highEnergyTaskName: highEnergyVal,
    });
  };
  const handleEditHighEnergyTaskName = (e) => {
    const val = e.target.value;

    setEditEnable(false);
    if (val !== "") {
      setEditHighEnergyTaskData({
        ...editHighEnergyTaskData,
        highEnergyTaskName: freeTextBoxRegexTest().test(val)
          ? val
          : val.substring(0, val.length - 1),
      });
      setEditEnable(true);
      setEditNameError(true);
    }
  };
  const handleUpdateHighEnergyTaskName = () => {
    let reqBody = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: editHighEnergyTaskData.highEnergyTaskId,
      highEnergyName: editHighEnergyTaskData.highEnergyTaskName.substring(
        0,
        200
      ),
    };

    dispatch(
      upadteSWIHighEnergyTask({
        reqBody,
        saveSuccess: showSavePopUp,
        updateSuccess: (success, updatedHighEnergyName) => {
          if (success) {
            setEditHighEnergyTask(false);
            setEditHighEnergyTaskData({
              highEnergyTaskId: 0,
              highEnergyTaskName: "",
            });
            setValueName(updatedHighEnergyName);
            // getSWIMappingData();
          }
        },
      })
    );
  };
  const handleDeleteHighEnergyTask = () => {
    let swiDeleteSWIHighEnergyTaskRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: highEnergyDelete.highEnergyId,
      highEnergyName: highEnergyDelete.highEnergyName,
    };
    dispatch(
      deleteSWIHighEnergyTask({
        swiDeleteSWIHighEnergyTaskRequest,
        deleteSuccessPopup: showDeleteSuccessPopup,
        deleteSuccess: (success, deletedId) => {
          if (success) {
            setHighEnergyDelete({});
            setValueName(highEnergyDelete.highEnergyName);
          }
        },
      })
    );
  };
  const handleAddHighEnergyTask = () => {
    let addSWIHighEnergyTaskRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyName: enableInputAddData.newName,
      saveSuccess: (success, addedHighEnergyName) => {
        if (success) {
          showSavePopUp();
          setValueName(addedHighEnergyName);
          resetHighEnergyControls();
        }
      },
    };

    dispatch(addSWIHighEnergyTask(addSWIHighEnergyTaskRequest));
  };

  //********HAZARD COLUMN */
  const resetHazardDirectControlSafeguardControls = () => {
    setEnableInputAdd(false);
    setEnableInputAddData({
      highEnergyId: 0,
      tableColumnId: 1,
      newName: "",
    });
  };
  const handleEditHazard = (idVal, highEnergyIdVal, hazardNameVal) => {
    resetAllEditControls();
    setEditHazard(true);
    setEditHazardData({
      highEnergyTaskId: highEnergyIdVal,
      hazardId: idVal,
      hazardName: hazardNameVal,
    });
  };
  const handleEditHazardName = (e) => {
    const val = e.target.value;

    setEditEnable(false);
    if (val !== "") {
      setEditHazardData({
        ...editHazardData,
        hazardName: freeTextBoxRegexTest().test(val)
          ? val
          : val.substring(0, val.length - 1),
      });
      setEditEnable(true);
      setEditNameError(true);
    }
  };
  const handleUpdateHazardName = () => {
    let reqBody = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: editHazardData.highEnergyTaskId,
      hazardsId: editHazardData.hazardId,
      hazardName: editHazardData.hazardName.substring(0, 200),
    };

    dispatch(
      updateSWIHazard({
        reqBody,
        saveSuccess: showSavePopUp,
        updateSuccess: (success, updatedHazardName) => {
          if (success) {
            setEditEnable(false);
            setEditHazard(false);
            setEditHazardData({
              highEnergyTaskId: 0,
              hazardId: 0,
              hazardName: "",
            });
            setValueName(updatedHazardName);
          }
        },
      })
    );
  };
  const handleDeleteHazard = () => {
    let swiDeleteHazardRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: hazardDelete.highEnergyId,
      hazardsId: hazardDelete.hazardId,
      hazardName: hazardDelete.hazardName,
    };

    dispatch(
      deleteSWIHazard({
        swiDeleteHazardRequest,
        deleteSuccessPopup: showDeleteSuccessPopup,
        deleteSuccess: (success, deletedHazardName) => {
          if (success) {
            setHazardDelete({});
            setValueName(deletedHazardName);
          }
        },
      })
    );
  };
  const handleAddHazard = () => {
    let swiHazardRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: enableInputAddData.highEnergyId,
      hazardName: enableInputAddData.newName,
      saveSuccess: (success, addedHazardName) => {
        if (success) {
          showSavePopUp();
          setValueName(addedHazardName);
          resetHazardDirectControlSafeguardControls();
        }
      },
    };

    !nameDup && dispatch(addSWIHazard(swiHazardRequest));
  };

  //********DIRECT CONTROL & SAFEGUARD COLUMN */
  const handleEditDirectControlSafegaurd = (
    idVal,
    highEnergyIdVal,
    directControlSafeGaurdVal
  ) => {
    resetAllEditControls();
    setEditDirectControlSafegaurd(true);
    setEditDirectControlSafegaurdData({
      highEnergyTaskId: highEnergyIdVal,
      directControlSafegaurdId: idVal,
      directControlSafegaurdName: directControlSafeGaurdVal,
    });
  };
  const handleEditDirectControlSafegaurdName = (e) => {
    const val = e.target.value;

    setEditEnable(false);
    if (val !== "") {
      setEditDirectControlSafegaurdData({
        ...editDirectControlSafegaurdData,
        directControlSafegaurdName: freeTextBoxRegexTest().test(val)
          ? val
          : val.substring(0, val.length - 1),
      });
      setEditEnable(true);
      setEditNameError(true);
    }
  };
  const handleUpdateDirectControlSafegaurdName = (directControlType) => {
    let reqBody = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: editDirectControlSafegaurdData.highEnergyTaskId,
      directControlsSafegaurdsId:
        editDirectControlSafegaurdData.directControlSafegaurdId,
      directControlsSafegaurdsName:
        editDirectControlSafegaurdData.directControlSafegaurdName.substring(
          0,
          200
        ),
      type: directControlType,
    };

    dispatch(
      updateDirectControlSafeguards({
        reqBody,
        saveSuccess: showSavePopUp,
        updateSuccess: (success, updatedDirectControlsSafegaurdsName) => {
          if (success) {
            setEditEnable(false);
            setEditDirectControlSafegaurd(false);
            setEditDirectControlSafegaurdData({
              highEnergyTaskId: 0,
              directControlSafegaurdId: 0,
              directControlSafegaurdName: "",
            });
            setValueName(updatedDirectControlsSafegaurdsName);
          }
        },
      })
    );
  };
  const handleDeleteDirectControlSafegaurd = () => {
    let swiDeleteDirectControlsSafeGuardRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: directControlSafegaurdDelete.highEnergyId,
      directControlsSafegaurdsId:
        directControlSafegaurdDelete.directControlsSafegaurdsId,
      directControlsSafegaurdsName:
        directControlSafegaurdDelete.directControlsSafegaurdsName,
      type: directControlSafegaurdDelete.type,
    };

    dispatch(
      deleteDirectControlSafeguards({
        swiDeleteDirectControlsSafeGuardRequest,
        deleteSuccessPopup: showDeleteSuccessPopup,
        deleteSuccess: (success, deletedDirectControlsSafegaurdsName) => {
          if (success) {
            setDirectControlSafegaurdDelete({});
            setValueName(deletedDirectControlsSafegaurdsName);
            getSWIMappingData();
          }
        },
      })
    );
  };
  const handleAddDirectControlSafegaurd = (directControlType) => {
    let swiDrectControlsSafeGuardsRequest = {
      jobTypeId: selectedJob.jobTypeId,
      jobTypeSWIId: selectedTask.swiId,
      highEnergyId: enableInputAddData.highEnergyId,
      directControlsSafegaurdsName: enableInputAddData.newName,
      type: directControlType,
      saveSuccess: (success, addedDirectControlsSafegaurdsName) => {
        if (success) {
          showSavePopUp();
          setValueName(addedDirectControlsSafegaurdsName);
          resetHazardDirectControlSafeguardControls();
        }
      },
    };

    dispatch(addDirectControlSafeguards(swiDrectControlsSafeGuardsRequest));
  };

  const handleClickAddNewTableData = (idVal, columnId) => {
    resetHighEnergyControls();
    setEnableInputAdd(true);
    setEnableInputAddData({
      highEnergyId: idVal,
      tableColumnId: columnId,
      newName: "",
    });
  };

  const handleSelectedDropDownData = (value) => {
    let NewNameData =
      enableInputAddData.tableColumnId === 2
        ? value.highEnergyName
        : enableInputAddData.tableColumnId === 3
        ? value.hazardsMaster
        : (enableInputAddData.tableColumnId === 4 ||
            enableInputAddData.tableColumnId === 5) &&
          value.directControlsSafegaurdsName;

    let arr =
      enableInputAddData.tableColumnId === 2
        ? dropDownOptions_Task.filter(
            (data) => data.id === selectedTask.swiId
          )[0].swiHighEnergyTasks
        : enableInputAddData.tableColumnId === 3
        ? dropDownOptions_Task
            .filter((data) => data.id === selectedTask.swiId)[0]
            .swiHighEnergyTasks.filter(
              (item) => item.id === enableInputAddData.highEnergyId
            )[0].swiHazards
        : dropDownOptions_Task
            .filter((data) => data.id === selectedTask.swiId)[0]
            .swiHighEnergyTasks.filter(
              (item) => item.id === enableInputAddData.highEnergyId
            )[0].swiDirectControlSafegaurds;

    let isDuplicateValueSelected =
      arr.filter((data) => data.id === value.id).length > 0;

    setnameDup(isDuplicateValueSelected);

    setEnableInputAddData({
      ...enableInputAddData,
      newName: NewNameData,
    });
  };

  const dummyHighEnergyTableBody = () => {
    return (
      <tr className="emptyRow">
        <td className="emptyColumn"></td>
        <td className="emptyColumn"></td>
        <td className="emptyColumn"></td>
        <td className="emptyColumn"></td>
        <td className="emptyColumn"></td>
      </tr>
    );
  };

  const highEnergyTableHeader = () => {
    return (
      <tr>
        <th>Energy Wheel Hazards</th>
        <th>High Energy Tasks</th>
        <th>Hazards</th>
        <th>Direct Controls</th>
        <th>Safeguard</th>
      </tr>
    );
  };

  const highEnergyTable = highEnergyTableElements.map((obj, index) => {
    return (
      <tr>
        <td>
          <Button id="btn_SWIIcon"
            className="SWIIconBtn"
            label="+ Add Icon"
            onClick={() => handleSWIIconBtnClick(obj?.id)}
          />

          <div
            style={{
              display: obj?.id !== highEnergyRowId && "none",
            }} // ref doesn't set on initial load so used display as none
            className="icon-upload-section"
          >
            <div className="upload-section">
              <input
                id="upload-image"
                type="file"
                name="file"
                ref={hiddenFileInput}
                accept=".jpg, .png, .jpeg"
                onChange={(e) => handleImageUpload(e, "BtnClick")}
              />
              <p>{selectedImageName}</p>
            </div>
            <div className="upload-section-button">
              <span className="times-icon">
                <img id="img_SWICancelIco"
                  src={cancelIcon}
                  className="cancel-icon"
                  onClick={() => cancelImageSave("BtnClick")}
                  alt="cancelUploadImg"
                ></img>
              </span>
              <span>
                {uploadIconCheckEnabled ? (
                  <img id="img_SWIeditEnable"
                    src={editEnable}
                    className="enable-edit"
                    onClick={() =>
                      addUpdateImage(
                        { id: 0, iconName: selectedImageName },
                        obj?.id,
                        selectedImage,
                        "Add"
                      )
                    }
                    alt="enableEditImg"
                  ></img>
                ) : (
                  <img id="img_SWIdisableEditImg"
                    src={editDisable}
                    className="disable-edit"
                    alt="disableEditImg"
                  ></img>
                )}
              </span>
            </div>
          </div>

          {obj?.swiIcons.map((swiIcon) => {
            return (
              <>
                <span className="imagePath-edit">
                  {swiIcon?.customSwiIconId !== swiIconId && (
                    <>
                      <img id="img_swiIconImage"
                        key={index}
                        className="swiIconImage"
                        src={"data:image/png;base64," + swiIcon?.base64}
                        alt="img"
                      />
                      <i
                        id={swiIcon?.id}
                        class="pi pi-ellipsis-v"
                        onClick={(e) => {
                          handleEllipsisClick(e, obj, swiIcon);
                        }}
                      >
                        <OverlayPanel ref={op}>
                          <div class="overlay-icon-container">
                            <span class="menuitem">
                              <p
                                onClick={() => {
                                  handleUploadIcon();
                                }}
                              >
                                Upload new icon
                              </p>
                            </span>
                            <span class="menuitem">
                              <p
                                onClick={() => {
                                  resetAllEditControls();
                                  setSwiIconDelete({
                                    iconId: swiIconOnDeleteData.iconId,
                                    iconName: swiIconOnDeleteData.iconName,
                                    visible: true,
                                  });
                                }}
                              >
                                Delete icon
                              </p>
                            </span>
                          </div>
                        </OverlayPanel>
                      </i>
                    </>
                  )}

                  {
                    <div
                      style={{
                        display:
                          ((obj?.id === highEnergyRowIdEllipsis &&
                            swiIcon?.customSwiIconId !== swiIconId) ||
                            (obj?.id !== highEnergyRowIdEllipsis &&
                              swiIcon?.customSwiIconId !== swiIconId)) &&
                          "none",
                      }}
                      className="icon-upload-section"
                    >
                      <div className="upload-section">
                        <input
                          id="onupload-image"
                          type="file"
                          name="file"
                          ref={uploadIconOP}
                          accept=".jpg, .png, .jpeg"
                          onChange={(e) =>
                            handleImageUpload(e, "ClickEllipsis")
                          }
                        />
                        <p>{selectedImageNameEllipsis}</p>
                      </div>
                      <div className="upload-section-button">
                        <span className="times-icon">
                          <img id="img_SWICancelImg"
                            src={cancelIcon}
                            className="cancel-icon"
                            onClick={() => cancelImageSave("ClickEllipsis")}
                            alt="cancelUploadImg"
                          ></img>
                        </span>
                        <span>
                          {uploadIconCheckEnabled ? (
                            <img id="img_SWIEnableEdit"
                              src={editEnable}
                              className="enable-edit"
                              onClick={() =>
                                addUpdateImage(
                                  swiIcon,
                                  obj?.id,
                                  selectedImage,
                                  "Update"
                                )
                              }
                              alt="enableEditImg"
                            ></img>
                          ) : (
                            <img id="img_SWIeditDisable"
                              src={editDisable}
                              className="disable-edit"
                              alt="disableEditImg"
                            ></img>
                          )}
                        </span>
                      </div>
                    </div>
                  }
                </span>
              </>
            );
          })}
        </td>

        <td>
          <ol>
            <li>
              {obj?.id !== editHighEnergyTaskData.highEnergyTaskId && (
                <>
                  {obj?.highEnergyName}
                  <span className="highEnergyTask-edit">
                    <img id="img_EditIco"
                      src={editIcon}
                      className="edit-icon"
                      onClick={() =>
                        handleEditHighEnergyTask(obj?.id, obj?.highEnergyName)
                      }
                      alt="editPenImg"
                    ></img>
                  </span>
                  <span className="highEnergyTask-delete">
                    <img id="img_MappingTrash"
                      src={trash}
                      className="trash-icon"
                      onClick={() =>
                        setHighEnergyDelete({
                          visible: true,
                          highEnergyId: obj?.id,
                          highEnergyName: obj?.highEnergyName,
                        })
                      }
                      alt="trashImg"
                    ></img>
                  </span>
                </>
              )}
              {editHighEnergyTask &&
                obj?.id === editHighEnergyTaskData.highEnergyTaskId && (
                  <span className="highenergy-task-edit-container">
                    <span className="edit-highenergy-name">
                      <input id="txt_SWIHighEnergyTaskName"
                        className="edit-name"
                        type="text"
                        value={editHighEnergyTaskData.highEnergyTaskName.substring(
                          0,
                          200
                        )}
                        onChange={(e) => handleEditHighEnergyTaskName(e)}
                      ></input>
                    </span>

                    <span className="edit-buttons">
                      <img id="img_MappingCancel"
                        src={cancelIcon}
                        className="cancel-icon"
                        onClick={() => {
                          setEditEnable(false);
                          setEditHighEnergyTask(false);
                          setEditHighEnergyTaskData({
                            highEnergyTaskId: 0,
                            highEnergyTaskName: "",
                          });
                        }}
                        alt="cancelImg"
                      ></img>
                      {editenable &&
                      editHighEnergyTaskData.highEnergyTaskName.length !== 0 ? (
                        <img id="img_SWIHeditEnable"
                          src={editEnable}
                          className="edit-enable"
                          onClick={() => handleUpdateHighEnergyTaskName()}
                          alt="editEnableImg"
                        ></img>
                      ) : (
                        <img id="img_SWIHeditDisable"
                          src={editDisable}
                          className="edit-disable"
                          alt="editDisableImg"
                        ></img>
                      )}
                    </span>

                    {editNameError &&
                      editHighEnergyTaskData.highEnergyTaskName.length >
                        200 && (
                        <p className="word-limit-error">
                          *Max. number of characters should not exceed 200.
                        </p>
                      )}
                  </span>
                )}
            </li>
          </ol>
        </td>

        <td>
          <Button id="btn_SWIAddHazard"
            className="hazardBtn"
            label="+ Add Hazard"
            onClick={() => handleClickAddNewTableData(obj?.id, 3)}
          />
          {enableInputAdd &&
            enableInputAddData.tableColumnId === 3 &&
            obj?.id === enableInputAddData.highEnergyId && (
              <>
                <div className="search-edit-container">
                  <SearchTextBox
                    searchType={3}
                    inputProps={{
                      value: enableInputAddData.newName,
                      maxLength: 200,
                      searchIconVisible: false,
                      clearTextIconVisible: false,
                      style: {
                        height: "30px",
                        width: "230px",
                        "font-size": "13px",
                      },
                      onChangeText: (v) =>
                        setEnableInputAddData({
                          ...enableInputAddData,
                          newName: v,
                        }),
                    }}
                    onSelect={handleSelectedDropDownData}
                  />
                  <img id="img_SWIHCancel"
                    src={cancelIcon}
                    className="cancel-icon"
                    onClick={() => {
                      setEnableInputAdd(false);
                      setEnableInputAddData({
                        highEnergyId: 0,
                        tableColumnId: 1,
                        newName: "",
                      });
                      setnameDup(false);
                    }}
                    alt="cancelImg"
                  ></img>
                  {enableInputAdd &&
                  !nameDup &&
                  enableInputAddData.newName.length !== 0 ? (
                    <img id="img_SWIHEnable"
                      src={editEnable}
                      className="edit-enable"
                      onClick={() => handleAddHazard()}
                      alt="editEnableImg"
                    ></img>
                  ) : (
                    <img id="img_SWIHDisable"
                      src={editDisable}
                      className="edit-disable"
                      alt="editDisableImg"
                    ></img>
                  )}
                </div>

                {nameDup && (
                  <p className="edit-duplicate">
                    *Cannot add duplicate values.
                  </p>
                )}
              </>
            )}

          {obj.swiHazards &&
            obj.swiHazards.map((hazard) => {
              return (
                <ol>
                  <li>
                    {hazard.id !== editHazardData.hazardId && (
                      <>
                        {hazard.hazardName}
                        <span className="hazard-edit">
                          <img id="img_SWIHeditPenImg"
                            src={editIcon}
                            className="edit-icon"
                            onClick={() =>
                              handleEditHazard(
                                hazard.id,
                                obj.id,
                                hazard.hazardName
                              )
                            }
                            alt="editPenImg"
                          ></img>
                        </span>
                        <span className="hazard-delete">
                          <img id="img_SWIHazardTrash"
                            src={trash}
                            className="trash-icon"
                            onClick={() =>
                              setHazardDelete({
                                visible: true,
                                hazardId: hazard.id,
                                highEnergyId: obj.id,
                                hazardName: hazard.hazardName,
                              })
                            }
                            alt="trashImg"
                          ></img>
                        </span>
                      </>
                    )}
                    {editHazard && hazard.id === editHazardData.hazardId && (
                      <span className="hazard-edit-container">
                        <span className="edit-hazard-name">
                          <input id="txt_SWIHazardName"
                            className="edit-name"
                            type="text"
                            value={editHazardData.hazardName.substring(0, 200)}
                            onChange={(e) => handleEditHazardName(e)}
                          ></input>
                        </span>

                        <span className="edit-buttons">
                          <img id="img_MappingCancelIco"
                            src={cancelIcon}
                            className="cancel-icon"
                            onClick={() => {
                              setEditEnable(false);
                              setEditHazard(false);
                              setEditHazardData({
                                highEnergyTaskId: 0,
                                hazardId: 0,
                                hazardName: "",
                              });
                            }}
                            alt="cancelImg"
                          ></img>

                          {editenable &&
                          editHazardData.hazardName.length !== 0 ? (
                            <img id="img_MappingEnableEdit"
                              src={editEnable}
                              className="edit-enable"
                              onClick={() => handleUpdateHazardName()}
                              alt="editEnableImg"
                            ></img>
                          ) : (
                            <img id="img_MappingDisableEdit"
                              src={editDisable}
                              className="edit-disable"
                              alt="editDisableImg"
                            ></img>
                          )}
                        </span>

                        {editNameError &&
                          editHazardData.hazardName.length > 200 && (
                            <p className="word-limit-error">
                              *Max. number of characters should not exceed 200.
                            </p>
                          )}
                      </span>
                    )}
                  </li>
                </ol>
              );
            })}
        </td>

        <td>
          <Button id="btn_SWIAddDirect"
            className="directControlBtn"
            label="+ Add Direct Control"
            onClick={() => handleClickAddNewTableData(obj?.id, 4)}
          />
          {enableInputAdd &&
            enableInputAddData.tableColumnId === 4 &&
            obj?.id === enableInputAddData.highEnergyId && (
              <>
                <div className="search-edit-container">
                  <SearchTextBox
                    searchType={4}
                    inputProps={{
                      value: enableInputAddData.newName,
                      maxLength: 200,
                      searchIconVisible: false,
                      clearTextIconVisible: false,
                      style: {
                        height: "30px",
                        width: "230px",
                        "font-size": "13px",
                      },
                      onChangeText: (v) =>
                        setEnableInputAddData({
                          ...enableInputAddData,
                          newName: v,
                        }),
                    }}
                    onSelect={handleSelectedDropDownData}
                  />
                  <img id="img_MappingCance"
                    src={cancelIcon}
                    className="cancel-icon"
                    onClick={() => {
                      setEnableInputAdd(false);
                      setEnableInputAddData({
                        highEnergyId: 0,
                        tableColumnId: 1,
                        newName: "",
                      });
                      setnameDup(false);
                    }}
                    alt="cancelImg"
                  ></img>
                  {enableInputAdd &&
                  !nameDup &&
                  enableInputAddData.newName.length !== 0 ? (
                    <img id="img_SWIdirectControls"
                      src={editEnable}
                      className="edit-enable"
                      onClick={() =>
                        handleAddDirectControlSafegaurd("DirectControls")
                      }
                      alt="editEnableImg"
                    ></img>
                  ) : (
                    <img id="img_SWIMappDisable"
                      src={editDisable}
                      className="edit-disable"
                      alt="editDisableImg"
                    ></img>
                  )}
                </div>
                {nameDup && (
                  <p className="edit-duplicate">
                    *Cannot add duplicate values.
                  </p>
                )}
              </>
            )}
          {obj.swiDirectControlSafegaurds &&
            obj.swiDirectControlSafegaurds.length > 0 &&
            obj.swiDirectControlSafegaurds
              .filter((data) => data?.type === "DirectControls")
              .map((directControl) => {
                return (
                  <ol>
                    <li>
                      {directControl.id !==
                        editDirectControlSafegaurdData.directControlSafegaurdId && (
                        <>
                          {directControl?.directControlSafeGaurdName}
                          <span className="directControl-edit">
                            <img id="img_MappingeditPenImg"
                              src={editIcon}
                              className="edit-icon"
                              onClick={() =>
                                handleEditDirectControlSafegaurd(
                                  directControl?.id,
                                  obj?.id,
                                  directControl?.directControlSafeGaurdName
                                )
                              }
                              alt="editPenImg"
                            ></img>
                          </span>
                          <span className="directControl-delete">
                            <img id="img_MappingdirectControlDelete"
                              src={trash}
                              className="trash-icon"
                              onClick={() =>
                                setDirectControlSafegaurdDelete({
                                  visible: true,
                                  highEnergyId: obj.id,
                                  directControlsSafegaurdsId: directControl.id,
                                  directControlsSafegaurdsName:
                                    directControl.directControlSafeGaurdName,
                                  type: "DirectControls",
                                })
                              }
                              alt="trashImg"
                            ></img>
                          </span>
                        </>
                      )}
                      {editDirectControlSafegaurd &&
                        directControl.id ===
                          editDirectControlSafegaurdData.directControlSafegaurdId && (
                          <span className="directcontrol-edit-container">
                            <span className="edit-directcontrol-name">
                              <input id="txt_SWIDirectControlSafegaurdName"
                                className="edit-name"
                                type="text"
                                value={editDirectControlSafegaurdData.directControlSafegaurdName.substring(
                                  0,
                                  200
                                )}
                                onChange={(e) =>
                                  handleEditDirectControlSafegaurdName(e)
                                }
                              ></input>
                            </span>

                            <span className="edit-buttons">
                              <img id="img_MappingcancelImg"
                                src={cancelIcon}
                                className="cancel-icon"
                                onClick={() => {
                                  setEditEnable(false);
                                  setEditDirectControlSafegaurd(false);
                                  setEditDirectControlSafegaurdData({
                                    highEnergyTaskId: 0,
                                    hazardId: 0,
                                    directControlSafegaurdId: 0,
                                    directControlSafegaurdName: "",
                                  });
                                }}
                                alt="cancelImg"
                              ></img>

                              {editenable &&
                              editDirectControlSafegaurdData
                                .directControlSafegaurdName.length !== 0 ? (
                                <img id="img_SWIMenabled"
                                  src={editEnable}
                                  className="edit-enable"
                                  onClick={() =>
                                    handleUpdateDirectControlSafegaurdName(
                                      "DirectControls"
                                    )
                                  }
                                  alt="editEnableImg"
                                ></img>
                              ) : (
                                <img id="img_SWIMdisabled"
                                  src={editDisable}
                                  className="edit-disable"
                                  alt="editDisableImg"
                                ></img>
                              )}
                            </span>

                            {editNameError &&
                              editDirectControlSafegaurdData
                                .directControlSafegaurdName.length > 200 && (
                                <p className="word-limit-error">
                                  *Max. number of characters should not exceed
                                  200.
                                </p>
                              )}
                          </span>
                        )}
                    </li>
                  </ol>
                );
              })}
        </td>

        <td>
          <Button id="btn_SWIAddSafe"
            className="safeguardBtn"
            label="+ Add Safeguard"
            onClick={() => handleClickAddNewTableData(obj?.id, 5)}
          />
          {enableInputAdd &&
            enableInputAddData.tableColumnId === 5 &&
            obj?.id === enableInputAddData.highEnergyId && (
              <>
                <div className="search-edit-container">
                  <SearchTextBox
                    searchType={5}
                    inputProps={{
                      value: enableInputAddData.newName,
                      maxLength: 200,
                      searchIconVisible: false,
                      clearTextIconVisible: false,
                      style: {
                        height: "30px",
                        width: "230px",
                        "font-size": "13px",
                      },
                      onChangeText: (v) =>
                        setEnableInputAddData({
                          ...enableInputAddData,
                          newName: v,
                        }),
                    }}
                    onSelect={handleSelectedDropDownData}
                  />

                  <img id="img_SWIMapCancel"
                    src={cancelIcon}
                    className="cancel-icon"
                    onClick={() => {
                      setEnableInputAdd(false);
                      setEnableInputAddData({
                        highEnergyId: 0,
                        tableColumnId: 1,
                        newName: "",
                      });
                      setnameDup(false);
                    }}
                    alt="cancelImg"
                  ></img>
                  {enableInputAdd &&
                  !nameDup &&
                  enableInputAddData.newName.length !== 0 ? (
                    <img id="img_MappingeditEnableImg"
                      src={editEnable}
                      className="edit-enable"
                      onClick={() =>
                        handleAddDirectControlSafegaurd("Safegaurds")
                      }
                      alt="editEnableImg"
                    ></img>
                  ) : (
                    <img id="img_MappingdisabeImg"
                      src={editDisable}
                      className="edit-disable"
                      alt="editDisableImg"
                    ></img>
                  )}
                </div>
                {nameDup && (
                  <p className="edit-duplicate">
                    *Cannot add duplicate values.
                  </p>
                )}
              </>
            )}
          {obj.swiDirectControlSafegaurds &&
            obj.swiDirectControlSafegaurds.length > 0 &&
            obj.swiDirectControlSafegaurds
              .filter((data) => data?.type === "Safegaurds")
              .map((itemSafeguard) => {
                return (
                  <ol>
                    <li>
                      {itemSafeguard.id !==
                        editDirectControlSafegaurdData.directControlSafegaurdId && (
                        <>
                          {itemSafeguard?.directControlSafeGaurdName}
                          <span className="itemSafeguard-edit">
                            <img id="img_SWIMappingeditPenImg"
                              src={editIcon}
                              className="edit-icon"
                              onClick={() =>
                                handleEditDirectControlSafegaurd(
                                  itemSafeguard?.id,
                                  obj?.id,
                                  itemSafeguard?.directControlSafeGaurdName
                                )
                              }
                              alt="editPenImg"
                            ></img>
                          </span>
                          <span className="itemSafeguard-delete">
                            <img id="img_safeguards"
                              src={trash}
                              className="trash-icon"
                              onClick={() =>
                                setDirectControlSafegaurdDelete({
                                  visible: true,
                                  highEnergyId: obj.id,
                                  directControlsSafegaurdsId: itemSafeguard.id,
                                  directControlsSafegaurdsName:
                                    itemSafeguard.directControlSafeGaurdName,
                                  type: "Safegaurds",
                                })
                              }
                              alt="trashImg"
                            ></img>
                          </span>
                        </>
                      )}
                      {editDirectControlSafegaurd &&
                        itemSafeguard.id ===
                          editDirectControlSafegaurdData.directControlSafegaurdId && (
                          <span className="safeguard-edit-container">
                            <span className="edit-safeguard-name">
                              <input id="txt_SWIControlSafegaurdName"
                                className="edit-name"
                                type="text"
                                value={editDirectControlSafegaurdData.directControlSafegaurdName.substring(
                                  0,
                                  200
                                )}
                                onChange={(e) =>
                                  handleEditDirectControlSafegaurdName(e)
                                }
                              ></input>
                            </span>

                            <span className="edit-buttons">
                              <img id="img_SWIMCancelImg"
                                src={cancelIcon}
                                className="cancel-icon"
                                onClick={() => {
                                  setEditEnable(false);
                                  setEditDirectControlSafegaurd(false);
                                  setEditDirectControlSafegaurdData({
                                    highEnergyTaskId: 0,
                                    hazardId: 0,
                                    directControlSafegaurdId: 0,
                                    directControlSafegaurdName: "",
                                  });
                                }}
                                alt="cancelImg"
                              ></img>
                              {editenable &&
                              editDirectControlSafegaurdData
                                .directControlSafegaurdName.length !== 0 ? (
                                <img id="img_SWImeditEnableImg"
                                  src={editEnable}
                                  className="edit-enable"
                                  onClick={() =>
                                    handleUpdateDirectControlSafegaurdName(
                                      "Safegaurds"
                                    )
                                  }
                                  alt="editEnableImg"
                                ></img>
                              ) : (
                                <img id="img_SWIMeditDisableImg"
                                  src={editDisable}
                                  className="edit-disable"
                                  alt="editDisableImg"
                                ></img>
                              )}
                            </span>

                            {editNameError &&
                              editDirectControlSafegaurdData
                                .directControlSafegaurdName.length > 200 && (
                                <p className="word-limit-error">
                                  *Max. number of characters should not exceed
                                  200.
                                </p>
                              )}
                          </span>
                        )}
                    </li>
                  </ol>
                );
              })}
        </td>
      </tr>
    );
  });

  useEffect(() => {
    getSWIMappingData();
  }, []);

  useEffect(() => {
    if (
      swiMappingData &&
      swiMappingData !== undefined &&
      swiMappingData !== null &&
      swiMappingData.length > 0
    ) {
      let jobTypes = swiMappingData.map((data) => ({
        jobTypeId: data.id,
        jobName: data.jobName,
      }));

      setDropDownOptions_Job(jobTypes);
      //set initial dropdown data - Job
      let selectedJobObj = selectedJob;
      if (selectedJob.jobTypeId === 0) {
        selectedJobObj = {
          jobTypeId: jobTypes[0].jobTypeId,
          jobName: jobTypes[0].jobName,
        };
        setSelectedJob(selectedJobObj);
      }

      let initTaskDropDownData = swiMappingData.filter(
        (data) => data.jobName.trim() === selectedJobObj.jobName.trim()
      )[0].swiJobTypes;
      setDropDownOptions_Task(initTaskDropDownData);

      let selectedTaskObj = selectedTask;

      //if user updates the swi task name need to reset the selected task state varaible to new swid id and name
      if (
        initTaskDropDownData.filter(
          (data) => data.id === selectedTask.swiId
        )[0] === undefined
      ) {
        selectedTaskObj = initTaskDropDownData.filter(
          (data) => data.swiName.trim() === editName.trim()
        )[0];
      }

      if (selectedTask.swiId === 0) {
        selectedTaskObj = {
          swiId: initTaskDropDownData[0].id,
          swiName: initTaskDropDownData[0].swiName,
        };
        setSelectedTask(selectedTaskObj);
      }
      handleDropdwonTask(selectedTaskObj.swiName, initTaskDropDownData);
    }
  }, [swiMappingData]);

  const renderOption_Job = (option) => {
    return (
      <div className="p-d-flex p-ai-center">
        <span className="p-ml-2">{option}</span>
        {/* <span className="p-ml-auto">
        <i
          className="pi pi-trash"
          onClick={(event) => handleTrashIconClick(event, option)}
          style={{ cursor: 'pointer' }}
        ></i>
      </span> */}
      </div>
    );
  };

  const renderOption_Task = (option) => {
    return (
      <div className="p-d-flex p-ai-center">
        <span className="p-ml-2">{option}</span>
        <span className="p-ml-auto" style={{ float: "right" }}>
          <i
            className="pi pi-trash"
            onClick={() => setSwiTaskDelete({ visible: true, swiName: option })}
            style={{ cursor: "pointer" }}
          ></i>
        </span>
      </div>
    );
  };

  return (
    <div className="swiMapping_Container">
      {isLoading && <Loader />}
      {visibleSwiMapping && (
        <div className="swiMapping">
          <div className="dropDown_Container">
            <div className="dropDowns">
              <div className="dropDownJob">
                <Dropdown id="drd_jobName"
                  options={[...dropDownOptions_Job.map((job) => job.jobName)]}
                  value={selectedJob.jobName}
                  onChange={(e) => {
                    handleDropdwonJob(e);
                  }}
                  itemTemplate={renderOption_Job}
                  placeholder="Select a job"
                  dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
                />
                <Button id="btn_SWIJob"
                  className="addJobBtn"
                  label="+ Job"
                  onClick={handleAddJobBtn}
                />
              </div>

              <div className="dropDownTask">
                <Dropdown id="drd_swiName"
                  options={[...dropDownOptions_Task.map((swi) => swi.swiName)]}
                  value={selectedTask.swiName}
                  onChange={(e) => {
                    handleDropdwonTask(e.target.value, dropDownOptions_Task);
                  }}
                  itemTemplate={renderOption_Task}
                  placeholder="Select a task"
                  dropdownIcon={<i className="custom-caret pi pi-caret-down" />}
                />
                <Button id="btn_SWITask"
                  className="addTaskBtn"
                  label="+ Task"
                  onClick={handleAddTaskBtn}
                />
              </div>
            </div>
          </div>

          {selectedTask.swiName && (
            <div className="selectedTask_Container">
              {!editSwiTask && (
                <>
                  <div className="selectedTask">{selectedTask.swiName}</div>
                  <span className="selectedTask-edit">
                    <img id="img_SWIMeditPenImg"
                      src={editIcon}
                      className="edit-icon"
                      onClick={() => handleEditSwiTaskName()}
                      alt="editPenImg"
                    ></img>
                  </span>
                </>
              )}
              {editSwiTask && (
                <tr className="selected-task">
                  <td className="edit-selected-task">
                    <input id="txt_SWIName"
                      className="edit-name"
                      type="text"
                      value={editName ? editName : selectedTask.swiName}
                      maxLength="100"
                      onChange={(e) => handleEditName(e)}
                    ></input>
                    {editNameError && editName.length === 100 && (
                      <p className="contact-error">
                        *Maximum number of characters should not exceed 100.
                      </p>
                    )}
                  </td>

                  <td className="close-button">
                    <img id="img_SWIMCancel"
                      src={cancelIcon}
                      className="cancel-icon"
                      onClick={() => {
                        setEditSwiTask(false);
                        setEditName("");
                      }}
                      alt="cancelImg"
                    ></img>
                  </td>
                  <td>
                    {editEnable && editName.length !== 0 ? (
                      <img id="img_SWIMeditEnable"
                        src={editEnable}
                        className="edit-enable"
                        onClick={() => handleUpdateSwiTaskName()}
                        alt="editEnableImg"
                      ></img>
                    ) : (
                      <img id="img_SWIMeditDisable"
                        src={editDisable}
                        className="edit-disable"
                        alt="editDisableImg"
                      ></img>
                    )}
                  </td>
                </tr>
              )}
              {addHighEnergyTaskBtn()}
            </div>
          )}

          {/* {highEnergyTableElements && highEnergyTableElements.length > 0 ? ( */}
          {showDummyTable ? (
            <div className="tableWrapper">
              <table>
                <thead>{highEnergyTableHeader()}</thead>
                <tbody>{dummyHighEnergyTableBody()}</tbody>
              </table>
            </div>
          ) : highEnergyTableElements && highEnergyTableElements.length > 0 ? (
            <div className="tableWrapper">
              <table>
                <thead>{highEnergyTableHeader()}</thead>
                <tbody>{highEnergyTable}</tbody>
              </table>
            </div>
          ) : (
            !isLoading &&
            isTableCreationInProgress && (
              <ProgressSpinner
                style={{
                  marginTop: "150px",
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            )
          )}
        </div>
      )}

      {visibleAddJob && (
        <AddJob
          jobOptions={[...dropDownOptions_Job.map((job) => job.jobName)]}
          handleCancelFromAddJob={handleCancelFromAddJob}
          getSWIMappingData={getSWIMappingData}
        />
      )}

      {visibleAddTask && (
        <AddTask
          taskOptions={[...dropDownOptions_Task.map((swi) => swi.swiName)]}
          handleCancelFromAddTask={handleCancelFromAddTask}
          jobTypeId={selectedJob.jobTypeId}
          getSWIMappingData={getSWIMappingData}
        />
      )}

      <ModalWrapper
        visible={swiTaskDelete.visible}
        onDismiss={() => {
          setSwiTaskDelete({});
        }}
      >
        <DeletePopup
          closePopup={() => {
            setSwiTaskDelete({ ...swiTaskDelete, visible: false });
          }}
          deleteValue={() => handleDeleteSWITask()}
          heading="SWI Mapping"
          content={swiTaskDelete.swiName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={swiIconDelete.visible}
        onDismiss={() => {
          setSwiIconDelete({});
        }}
      >
        <DeletePopup
          closePopup={() => {
            setSwiIconDelete({ ...swiIconDelete, visible: false });
          }}
          deleteValue={() => handleSWIIIconDelete()}
          heading="SWI Mapping"
          content={swiIconDelete.iconName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={highEnergyDelete.visible}
        onDismiss={() => {
          setHighEnergyDelete({});
        }}
      >
        <DeletePopup
          closePopup={() => {
            setHighEnergyDelete({ ...highEnergyDelete, visible: false });
          }}
          deleteValue={() => handleDeleteHighEnergyTask()}
          heading="SWI Mapping"
          content={highEnergyDelete.highEnergyName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={hazardDelete.visible}
        onDismiss={() => {
          setHazardDelete({});
        }}
      >
        <DeletePopup
          closePopup={() => {
            setHazardDelete({ ...hazardDelete, visible: false });
          }}
          deleteValue={() => handleDeleteHazard()}
          heading="SWI Mapping"
          content={hazardDelete.hazardName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={directControlSafegaurdDelete.visible}
        onDismiss={() => {
          setDirectControlSafegaurdDelete({});
        }}
      >
        <DeletePopup
          closePopup={() => {
            setDirectControlSafegaurdDelete({
              ...directControlSafegaurdDelete,
              visible: false,
            });
          }}
          deleteValue={() => handleDeleteDirectControlSafegaurd()}
          heading="SWI Mapping"
          content={directControlSafegaurdDelete.directControlsSafegaurdsName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSuccess}
        onDismiss={() => {
          showDeleteSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteSuccessPopup();
          }}
          heading="SWI Mapping"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showSaveSuccess}
        onDismiss={() => {
          showSavePopUp(valueName);
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSavePopUp(valueName);
          }}
          heading="SWI Mapping"
          content={valueName}
        />
      </ModalWrapper>
    </div>
  );
};

export default SWIMapping;
