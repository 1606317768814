import React, { useState, useEffect } from "react";
import "./publish.scss";
import Labels from "../../Constants/Label";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import {
  FetchPublishTemplate,
  AddPublishTemplate,
  FetchPublishStatus,
} from "../../Service/TemplateManagement";
import ModalWrapper from "../GenericPopup";
import SavePopup from "../GenericPopup/savePopup";
import SaveSuccess from "../GenericPopup/saveSuccess";
import { setSave } from "../../Store/saveSlice";

const Publish = () => {
  const [fromDate, setFromDate] = useState(null);
  const [showSave, setShowSave] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [lkdates, setlkDates] = useState([]);

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  useEffect(() => {
    dispatch(FetchPublishTemplate(dispatch));
    setlkDates([]);
    dispatch(FetchPublishStatus(dispatch));
  }, []);

  const publishTemplateDetails = useSelector(
    (state) => state?.template?.publishTemplateData || []
  );

  const publishStatus = useSelector((state) => state?.template?.publishStatus || []);

  useEffect(() => {
    if (publishStatus?.publishDate) {
      let newdate = new Date(publishStatus?.publishDate);
      setFromDate(newdate);
    }
  }, [publishStatus]);

  const publishrawData =
    publishTemplateDetails?.data?.templateManagement?.publishData;
  let publishData = [];
  publishData = publishrawData?.map((item) => {
    const date = item?.createdDateTime
      ? new Date(item.createdDateTime).toLocaleDateString()
      : null;
    const time = item?.createdDateTime
      ? new Date(item.createdDateTime).toLocaleTimeString()
      : null;
    return { ...item, date, time };
  });

  const lookupData = publishTemplateDetails?.data?.lookupManagement;

  useEffect(() => {
    if (lookupData !== undefined && lookupData !== null) {
      for (const [heading, section] of Object.entries(lookupData)) {
        for (const [data, details] of Object.entries(section)) {
          for (let item of details) {
            setlkDates((newSet) => [
              ...newSet,
              item?.createdDateTime
                ? new Date(item.createdDateTime).toLocaleDateString() +
                  " " +
                  new Date(item.createdDateTime).toLocaleTimeString()
                : "",
            ]);
          }
        }
      }
    }
  }, [lookupData]);

  const addrawData =
    publishTemplateDetails?.data?.fieldAddedDeleted?.publishData;

  let addData = [];
  addData = addrawData?.map((item) => {
    const date = item?.createdDateTime
      ? new Date(item.createdDateTime).toLocaleDateString()
      : null;
    const time = item?.createdDateTime
      ? new Date(item.createdDateTime).toLocaleTimeString()
      : null;
    return { ...item, date, time };
  });

  const currDate = new Date();
  const minDate = new Date(currDate);
  minDate.setDate(currDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  const hanldeFromDate = (e) => {
    setFromDate(e.value);
    handleSavedValue(false);
  };

  const handleSave = () => {
    if (fromDate !== null) {
      let publishDate = fromDate.toISOString();
      let date = publishDate.slice(0, 10);
      let time = fromDate.toTimeString();
      let timef = time.slice(0, 8);
      let ms = fromDate.getMilliseconds();
      let formatDate = date + "T" + timef + "." + ms + "Z";
      let reqbody = {
        publishDate: formatDate,
      };
      dispatch(
        AddPublishTemplate({
          reqbody,
          saveSuccess: showSavePopUp,
          callbackFn: handleStatus,
        })
      );
      handleSavedValue(true);
    }
  };

  function handleStatus(success) {
    if (success) {
      dispatch(FetchPublishTemplate(dispatch));
      setlkDates([]);
      dispatch(FetchPublishStatus(dispatch));
    }
  }

  function showPopUp(data) {
    setShowSave((pre) => !pre);
  }

  function showSavePopUp() {
    setShowSuccess((pre) => !pre);
    setShowSave(false);
  }

  const handleSavedValue = (val) => {
    dispatch(setSave(val));
  };

  return (
    <div className="publish-wrapper">
      {isLoading && <Loader />}
      <div className="publish-title">
        <header className="publish-header">{Labels?.Sidebar?.Publish}</header>
      </div>
      <div className="publish-container">
        <div className="publish-wrapper">
          <p className="publish-heading">Below are the changes made:</p>

          {publishData !== undefined && (
            <div className="publish-table-wrapper">
              {publishData.length > 0 && (
                <div className="table-main-heading">
                  {Labels.Sidebar?.Template}
                </div>
              )}
              {publishData.length > 0 && (
                <table>
                  <tr className="table-heading">
                    <th>No</th>
                    <th>Type</th>
                    <th>Section</th>
                    <th>Current Label</th>
                    <th>New Label</th>
                    <th>Updated By</th>
                    <th>Updated Date</th>
                  </tr>
                  {publishData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="long-data">{data.formType}</td>
                        <td>{data?.section}</td>
                        <td className="long-data">{data.currentLabel}</td>
                        <td className="long-data">{data.newLabel}</td>
                        <td>{data.createdBy}</td>
                        <td>
                          {data.date} {data.time}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              )}
            </div>
          )}

          {lookupData !== undefined && (
            <div className="publish-table-wrapper">
              {lookupData && (
                <div className="table-main-heading">
                  {Labels?.Sidebar?.Lookup}
                </div>
              )}

              {Object.entries(lookupData).map(([heading, section]) => (
                <div key={heading}>
                  {Object.entries(section).map(([section, details]) => (
                    <div className="lookup-table-wrapper" key={section}>
                      {details.length > 0 && (
                        <>
                          <div className="lookup-heading">
                            {Labels?.Publish[heading]}
                          </div>
                          <table>
                            <tr className="table-heading">
                              <th>No</th>
                              <th>Type</th>
                              <th>Section</th>
                              <th>Current Label</th>
                              <th>New Label</th>
                              <th>Updated By</th>
                              <th>Updated Date</th>
                            </tr>
                            {details.map((data, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="long-data">{data.formType}</td>
                                <td>{data?.section}</td>
                                <td className="long-data">
                                  {data.currentLabel}
                                </td>
                                <td className="long-data">{data.newLabel}</td>
                                <td>{data.createdBy}</td>
                                <td>{lkdates[index]}</td>
                              </tr>
                            ))}
                          </table>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}

          {addData !== undefined && (
            <div className="publish-table-wrapper">
              {addData && (
                <div className="table-main-heading">
                  {Labels.Publish?.FieldAdd}
                </div>
              )}
              {addData && (
                <table>
                  <tr className="table-heading">
                    <th>No</th>
                    <th>Type</th>
                    <th>Section</th>
                    <th>Added Field</th>
                    <th>Deleted Field</th>
                    <th>Added / Deleted By</th>
                    <th>Added / Deleted Date</th>
                  </tr>
                  {addData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="long-data">{data.formType}</td>
                        <td>{data?.section}</td>
                        <td className="long-data">
                          {data.newLabel !== null && data.newLabel !== "" ? (
                            <span>{data.newLabel}</span>
                          ) : (
                            <span>-----</span>
                          )}
                        </td>
                        <td className="long-data">
                          {data.currentLabel !== null &&
                          data.currentLabel !== "" ? (
                            <span>{data.currentLabel}</span>
                          ) : (
                            <span>-----</span>
                          )}
                        </td>
                        <td>{data.createdBy}</td>
                        <td>
                          {data.date} {data.time}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              )}
            </div>
          )}
          <div className="publish-save">
            <div className="publish-date">
              <p className="date-label">Publish Date: </p>
              <Calendar
                id="calendar-12h"
                value={fromDate}
                onChange={(e) => hanldeFromDate(e)}
                showTime
                hourFormat="12"
                showIcon
                minDate={minDate}
              />
            </div>
            <div className="publish-buttons">
              {/* <button className="cancel-button" onClick={() => handleHeader()}>
              Cancel
            </button> */}
              <button id="btn_PMSave"
                className={
                  (publishData?.length !== 0 ||
                    lookupData?.emergencyEquipment?.publishData?.length !== 0 ||
                    lookupData?.hazardousEnergies?.publishData?.length !== 0 ||
                    lookupData?.mutcd?.publishData?.length !== 0 ||
                    lookupData?.potentialAoc?.publishData?.length !== 0 ||
                    lookupData?.ppe?.publishData?.length !== 0 ||
                    lookupData?.procedureMapping?.publishData?.length !== 0 ||
                    lookupData?.swiMapping?.publishData?.length !== 0 ||
                    addData?.length !== 0) &&
                  fromDate !== null &&
                  fromDate >= currDate
                    ? "save-button"
                    : "save-disable"
                }
                disabled={
                  (publishData?.length !== 0 ||
                    lookupData?.emergencyEquipment?.publishData?.length !== 0 ||
                    lookupData?.hazardousEnergies?.publishData?.length !== 0 ||
                    lookupData?.mutcd?.publishData?.length !== 0 ||
                    lookupData?.potentialAoc?.publishData?.length !== 0 ||
                    lookupData?.ppe?.publishData?.length !== 0 ||
                    lookupData?.procedureMapping?.publishData?.length !== 0 ||
                    lookupData?.swiMapping?.publishData?.length !== 0 ||
                    addData?.length !== 0) &&
                  fromDate !== null &&
                  fromDate >= currDate
                    ? false
                    : true
                }
                onClick={() => showPopUp()}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalWrapper
        visible={showSave}
        onDismiss={() => {
          showPopUp();
        }}
      >
        <SavePopup
          closePopup={() => {
            showPopUp();
          }}
          saveValue={() => handleSave()}
          heading="Publish Management"
          content="data"
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showSuccess}
        onDismiss={() => {
          showSavePopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSavePopUp();
          }}
          heading="Publish Management"
          content="data"
        />
      </ModalWrapper>
    </div>
  );
};

export default Publish;
