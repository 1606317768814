import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import "./addJob.scss";
import { InputText } from "primereact/inputtext";
import { saveSwiJob, FetchSWIMapping } from "../../../Service/SwiMapping";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../GenericPopup";
import WarnPopup from "../../GenericPopup/warningPopup";
import { freeTextBoxRegexTest } from "../../../Utils/regex";

const AddJob = ({ jobOptions, handleCancelFromAddJob, getSWIMappingData }) => {
  const [newJobName, setNewJobName] = useState("");
  const [jobNameDuplicate, setJobNameDuplicate] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    FetchSWIMapping();
  }, []);

  const swiJob = useSelector(
    (state) => state?.swiMapping?.swiMappingData || []
  );

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  const validateJobName = (e) => {
    const val = e.target.value;
    setNewJobName(val.trim());
    setJobNameDuplicate(false);
  };

  function showWarnPopup() {
    setShowWarning((pre) => !pre);
  }

  const handleNewJobName = (jobName) => {
    const isDuplicate = jobOptions?.some(
      (data) => data.trim().toLowerCase() === jobName?.toLowerCase()
    );

    if (!isDuplicate) {
      dispatch(
        saveSwiJob({
          jobName,
          saveSuccess: (status, result) => {
            if (status) {
              FetchSWIMapping();
              handleCancelFromAddJob();
              getSWIMappingData();
              // showSaveHighEnergyPopUp(result);
            } else {
              showWarnPopup();
            }
          },
        })
      );
    } else {
      setJobNameDuplicate(true);
    }
  };

  return (
    <div className="addJobOuterWrapper">
      <div className="newJobDiv">
        <p className="newJob">Add New Job</p>
      </div>
      <div className="addJobBtnWrapper">
        <Button id="btn_SWICancel"
          className="inActiveBtn"
          onClick={() => handleCancelFromAddJob()}
        >
          Cancel
        </Button>
        <Button id="btn_SWISave"
          className="activeBtn"
          onClick={() => handleNewJobName(newJobName)}
          disabled={newJobName && newJobName.length > 0 ? false : true}
        >
          Save
        </Button>
      </div>

      <div className="addJobTextWrapper">
        <table>
          <tr>
            <td>
              <p className="addJobInputText">Job Name</p>
            </td>
            <td>
              <InputText id="txt_AddJobInputBox"
                className="addJobInputBox"
                maxLength="100"
                keyfilter={freeTextBoxRegexTest()}
                onChange={(e) => validateJobName(e)}
              />
              {newJobName.length === 100 && (
                <p className="contact-error">
                  *Max. number of characters should not exceed 100.
                </p>
              )}
              {jobNameDuplicate && (
                <p className="edit-duplicate">*Cannot add duplicate job.</p>
              )}
            </td>
          </tr>
        </table>
      </div>
      <ModalWrapper
        visible={showWarning}
        onDismiss={() => {
          showWarnPopup();
        }}
      >
        <WarnPopup
          closePopup={() => {
            showWarnPopup();
          }}
        />
      </ModalWrapper>
    </div>
  );
};

export default AddJob;
