import React, { useState, useEffect } from "react";
import "./FooterMenu.scss";
import { Divider } from "primereact/divider";
import trash from "../../../Assets/Images/trash.png";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import {
  FetchPotentialAOC,
  AddPotentialAOC,
  DeletePotentialAOC,
  UpdatePotentialAOC,
} from "../../../Service/PotentialAoc";
import { useDispatch, useSelector } from "react-redux";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import ModalWrapper from "../../GenericPopup";
import DeletePopup from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/deleteSuccess";
import SaveSuccess from "../../GenericPopup/saveSuccess";
import Loader from "../../Loader";

const PotentialAocList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isInputVisible, setInputVisible] = useState(false);
  const [inputText, setInputText] = useState("");
  const [currentEditableTitle, setCurrentEditableTitle] = useState(null);
  const [editPotentialAoc, setEditPotentialAoc] = useState("");
  const [editTrue, setEditTrue] = useState(false);
  const [newAoc, setNewAoc] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [valueName, setValueName] = useState("");
  const [newAocError, setNewAocError] = useState(false);
  const [editAocError, setEditAocError] = useState(false);
  const [newDuplicateError, setNewDuplicateError] = useState(false);
  const [editDuplicateError, setEditDuplicateError] = useState(false);

  const userDetails = useSelector((state) => state.user.userData.username);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchPotentialAOC(dispatch));
  }, []);

  const potentialAocDetails = useSelector(
    (state) => state?.potentialAOC?.potentialAOCData || []
  );

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  // Function to toggle input box visibility
  const toggleInput = () => {
    setInputVisible(!isInputVisible);
    setInputText(""); // Clear input text when toggling
  };

  // Function to filter titles based on the search term
  const filteredTitles = potentialAocDetails.filter((item) =>
    item?.aocName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const groupedTitles = filteredTitles.reduce((groups, item) => {
    const firstLetter = item.aocName.charAt(0).toUpperCase();

    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }

    groups[firstLetter].push(item);

    return groups;
  }, {});

  const sortedLetters = Object.keys(groupedTitles).sort();

  const handleNewAoc = (e) => {
    let val = e.target.value.trimStart();
    if (/^[a-zA-Z0-9'*()\-"\//.\s]*$/.test(val)) {
      setInputText(val);
      setNewAoc(true);
      setNewAocError(true);
      setNewDuplicateError(false);
    }
  };

  const handleCloseNewAoc = () => {
    setInputVisible(false);
    setNewAoc(false);
    setNewAocError(false);
    setNewDuplicateError(false);
  };

  // Function to add text to titles
  const handleAddText = () => {
    if (inputText.trim() !== "") {
      const isDuplicate = potentialAocDetails.some(
        (titleObj) =>
          titleObj?.aocName.replace(/\s+/g, "").toLowerCase() ===
          inputText.replace(/\s+/g, "").toLowerCase()
      );

      const date = new Date();
      let createdDate = date.toISOString();
      if (!isDuplicate) {
        dispatch(
          AddPotentialAOC({
            createdBy: userDetails,
            createdDateTime: createdDate,
            aocName: inputText,
            saveSuccess: showSavePopUp,
          })
        );
        setNewAoc(false);
        setNewAocError(false);
        toggleInput(); // Hide the input box after adding text
      } else {
        // Handle duplicate value (e.g., show an error message)
        setNewDuplicateError(true);
      }
    }
  };

  const handleEditClick = (title) => {
    setEditPotentialAoc(title);
    setCurrentEditableTitle(title);
  };

  const handleEditAoc = (e) => {
    let val = e.target.value.trimStart();
    if (/^[a-zA-Z0-9'*()\-"\//.\s]*$/.test(val)) {
      setEditPotentialAoc(val);
      setEditTrue(true);
      setEditAocError(true);
      setEditDuplicateError(false);
    }
  };

  const handleSaveEdit = (id, title) => {
    const isDuplicate = potentialAocDetails.some(
      (titleObj) =>
        titleObj?.aocName.replace(/\s+/g, "").toLowerCase() ===
        title.replace(/\s+/g, "").toLowerCase()
    );
    if (!isDuplicate) {
      const date = new Date();
      let createdDate = date.toISOString();
      let reqbody = {
        createdBy: userDetails,
        createdDateTime: createdDate,
        aocName: title,
      };
      dispatch(
        UpdatePotentialAOC({
          id: id,
          reqbody,
          saveSuccess: showSavePopUp,
          callbackFn: handleData,
        })
      );
      setEditTrue(false);
      setEditAocError(false);
      setCurrentEditableTitle(null);
    } else {
      setEditDuplicateError(true);
    }
  };

  const handleData = (success) => {
    if (success) {
      dispatch(FetchPotentialAOC(dispatch));
    }
  };

  const handleCloseEdit = () => {
    setCurrentEditableTitle(null);
    setEditTrue(false);
    setEditAocError(false);
    setEditDuplicateError(false);
  };

  const handleDelete = (id) => {
    dispatch(DeletePotentialAOC({ id, deleteSuccess: showDeleteSuccessPopup }));
    setCurrentEditableTitle(null);
  };

  //popup for showing delete confirmation
  function showDeletePopUp(id, name) {
    setDeleteId(id);
    setValueName(name);
    setShowDelete((pre) => !pre);
  }

  //popup for showing delete success
  function showDeleteSuccessPopup() {
    setShowDelete(false);
    setShowDeleteSuccess((pre) => !pre);
  }

  //popup for showing save success
  function showSavePopUp(value) {
    setValueName(value);
    setShowSaveSuccess((pre) => !pre);
  }

  return (
    <div className="aoc-wrapper">
      {isLoading && <Loader />}
      <div className="header-Aoc">
        {/* Toggle input box visibility */}
        {isInputVisible ? (
          <div className="add-Aoc">
            <button id="btn_PALAOC" className="add-button" onClick={toggleInput}>
              + AOC
            </button>
            <input id="txt_AOCText"
              className="new-aoc-text"
              type="text"
              placeholder="Enter text..."
              value={inputText}
              maxLength="100"
              onChange={(e) => handleNewAoc(e)}
            />
            {newAoc && inputText.length > 0 ? (
              <img id="img_PAOCLEnable"
                src={editEnable}
                className="new-enable"
                onClick={handleAddText}
              ></img>
            ) : (
              <img id="img_PAOCLDisable" src={editDisable} className="new-disable"></img>
            )}
            <img id="img_PAOCLClose"
              src={cancelIcon}
              className="new-close"
              onClick={() => handleCloseNewAoc()}
            ></img>
            {newAocError && inputText.length === 100 && (
              <p className="new-aoc-error">
                *Maximum number of characters should not exceed 100.
              </p>
            )}
            {newDuplicateError && (
              <p className="new-dupe-error">*Cannot add duplicate values</p>
            )}
          </div>
        ) : (
          <div>
            <button id="btn_PAAOC" className="add-button" onClick={toggleInput}>
              + AOC
            </button>
          </div>
        )}
        <input id="txt_AOCSearch"
          type="search"
          placeholder="Search AOC"
          className="search-bar"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <Divider />
      <div className="aoc-content-wrapper">
        <div className="footer-menu">
          {filteredTitles.length > 0 ? (
            <>
              {sortedLetters.map((letter) => (
                <div key={letter} className="menu-section">
                  <div className="letter-head">{letter}</div>
                  <ul>
                    {groupedTitles[letter].map((item, index) => (
                      <li key={index} className="title-row">
                        {currentEditableTitle === item.aocName ? (
                          <div className="aoc-edit-content">
                            <input id="txt_AOCInput"
                              className="aoc-input"
                              type="text"
                              maxLength="100"
                              value={editPotentialAoc}
                              onChange={(e) => handleEditAoc(e)}
                            />
                            {editTrue && editPotentialAoc.length > 0 ? (
                              <img id="img_PAOCLEditEnable"
                                src={editEnable}
                                className="edit-enable"
                                onClick={() =>
                                  handleSaveEdit(item.id, editPotentialAoc)
                                }
                              />
                            ) : (
                              <img id="img_PAOCLEditDisable"
                                src={editDisable}
                                className="edit-disable"
                              ></img>
                            )}
                            <img id="img_PAOCLCloseEdit"
                              src={cancelIcon}
                              className="close-edit"
                              onClick={() => handleCloseEdit()}
                            />
                            {editAocError &&
                              editPotentialAoc.length === 100 && (
                                <p className="edit-aoc-error">
                                  *Maximum number of characters should not
                                  exceed 100.
                                </p>
                              )}
                            {editDuplicateError && (
                              <p className="edit-dupe-error">
                                *Cannot add duplicate values
                              </p>
                            )}
                          </div>
                        ) : (
                          <div className="aoc-content-clickables">
                            <span className="aoc-names">{item.aocName}</span>
                            <img id="img_PAOCLEditIcon"
                              src={editIcon}
                              className="edit-icon"
                              onClick={() => handleEditClick(item.aocName)}
                              alt="Edit"
                            />
                            <img id="img_PAOCLTrash"
                              src={trash}
                              className="trash-icon"
                              onClick={() =>
                                showDeletePopUp(item.id, item.aocName)
                              }
                            />
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          ) : (
            <p className="no-result">No results found</p>
          )}
        </div>
      </div>
      <ModalWrapper
        visible={showDelete}
        onDismiss={() => {
          showDeletePopUp();
        }}
      >
        <DeletePopup
          closePopup={() => {
            showDeletePopUp();
          }}
          deleteValue={() => handleDelete(deleteId)}
          heading="Potential AOC"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSuccess}
        onDismiss={() => {
          showDeleteSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteSuccessPopup();
          }}
          heading="Potential AOC"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showSaveSuccess}
        onDismiss={() => {
          showSavePopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSavePopUp();
          }}
          heading="Potential AOC"
          content={valueName}
        />
      </ModalWrapper>
    </div>
  );
};

export default PotentialAocList;
