import React from 'react'
import 'primeicons/primeicons.css'
import Popup from './popup';  

function UserProfile() {
  return (
    <Popup/>
  )
}


export default UserProfile
