import React, { useEffect, useState } from "react";
import {
  fetchEmergencyEquipment,
  addEmergencyEquipment,
  deleteEmergencyEquipment,
  updateEmergencyEquipment,
} from "../../../Service/EmergencyEquipments";
import { useDispatch, useSelector } from "react-redux";
import { TabPanel, TabView } from "primereact/tabview";

import "./emergency.scss";
import ModalWrapper from "../../GenericPopup";
import SaveSuccess from "../../GenericPopup/commonSavePopup";
import WarnPopup from "../../GenericPopup/warningPopup";
import DeletePopUp from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/commonDeletePopup";
import trash from "../../../Assets/Images/trash.png";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";

const FetchEE = () => {
  // State for the "Add" section
  const [formName, setFormName] = useState(""); // Updated state variable
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteEmergency, setDeleteEmergency] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [newEmergency, setNewEmergency] = useState("");
  const [emergencyName, setEmergencyName] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editEmergency, setEditEmergency] = useState("");
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [emergencyEquipments, setEmergencyEquipments] = useState([]);
  const [selectedFormName, setSelectedFormName] = useState("");


  // pattern includes= Letters (both uppercase and lowercase): a-zA-Z,Digits: \d
  // Symbols and punctuation: +.?&$,-_;:"'*(\/)#\s (including space)
  const pattern = /^[a-zA-Z\d+.?&$,-_;:"'*(\/)#\s]*$/;


  // AddEnable state for each tab panel
  const [addEnableState, setAddEnableState] = useState({});
  const [inputError, setInputError] = useState(""); // Track input errors
  const dispatch = useDispatch();

  // Use useSelector to get emergencyEquipments from Redux state
  const emergencyEquipmentsData = useSelector(
    (state) => state.emergencyEquipment.emergencyEquipmentsData || []
  );

  useEffect(() => {
    dispatch(fetchEmergencyEquipment());
  }, []);

  useEffect(() => {
    // Initialize the local state with data from Redux
    setEmergencyEquipments(emergencyEquipmentsData);
  }, [emergencyEquipmentsData]);

  // Group data by formType and formName
  const groupedData = {};
  emergencyEquipments.forEach((item) => {
    if (!groupedData[item.formType]) {
      groupedData[item.formType] = {};
    }
    if (!groupedData[item.formType][item.formName]) {
      groupedData[item.formType][item.formName] = [];
    }
    groupedData[item.formType][item.formName].push(item);
  });

  // Function to handle adding a new emergency item
  const handleAddEmergency = (formType, formName) => {

    function findFormId(groupedData, formName) {
      for (const category in groupedData) {
        if (groupedData[category][formName]) {
          return groupedData[category][formName][0].formId;
        }
      }
      return null; // Return null if the formName is not found
    }
    const formTypeId = findFormId(groupedData, formName);
    // Check if the input is empty or exceeds the character limit
    setEmergencyName(newEmergency);
    if (newEmergency.trim() === "") {
      setInputError("Please enter a non-empty value");
      return;
    }
    if (newEmergency.length > 30) {
      setInputError("Character limit exceeded (max 30 characters)");
      return;
    }

    if (!pattern.test(newEmergency)) {
      setInputError(
        "Invalid characters. Please use only alphanumeric and specific symbols."
      );
      return;
    }

    // Check if the newEmergency already exists in the list
    const isDuplicate = emergencyEquipments.some(
      (item) => item.emergency === newEmergency && item.formName === formName
    );

    if (isDuplicate) {
      setInputError("Duplicate emergency item for this form");
      return;
    }

    const encodedFormName = encodeURIComponent(formName);

    const requestBody = {
      formId: formTypeId,
      formType: formType,
      formName: formName,
      emergency: newEmergency.trim(),
    };

    dispatch(addEmergencyEquipment(requestBody))
      .then(() => {
        setNewEmergency("");
        // After adding, update the local state with the new data
        setEmergencyEquipments([
          ...emergencyEquipments,
          { formType, formName, emergency: newEmergency },
        ]);
        dispatch(fetchEmergencyEquipment());
        setShowSaveSuccess(true);
        setAddEnableState((prevState) => ({
          ...prevState,
          [`${formType}_${formName}`]: false,
        }));
        setInputError(""); // Clear the error message
      })
      .catch((error) => {
        setShowSaveSuccess(false);
        // Handle the error
      });
  };

  // Function to handle input change for adding a new emergency item
  const handleInputChange = (e) => {
    setNewEmergency(e.target.value);
    if (e.target.value.length >= 30) {
      setInputError("max. 30 characters");
    } else {
      setInputError("");
    }
  };

  // Function to handle input change for editing an emergency item
  const handleEditInputChange = (e) => {
    setEditEmergency(e.target.value);
    if (e.target.value.length >= 30) {
      setInputError("max. 30 characters");
    } else {
      setInputError("");
    }
  };

  // Function to handle editing an emergency item
  const handleEdit = (id, currentEmergency, formName) => {
    setCurrentName(currentEmergency);
    setEditingId(id);
    setEditEmergency(currentEmergency);
    setFormName(formName); // Update the formName state
  };

  // Function to handle saving an edited emergency item
  const handleSaveEdit = (id, item) => {
    const updatedEmergency = editEmergency;

    // Check if the input length is within the limit
    if (updatedEmergency.length > 30 || updatedEmergency.length == 0) {
      setInputError("max. 30 characters");
      return;
    }
    if (!pattern.test(updatedEmergency)) {
      setInputError(
        "Invalid characters. Please use only alphanumeric and specific symbols."
      );
      return;
    }
    const isDuplicate = emergencyEquipments.some(
      (item) =>
        item.emergency === updatedEmergency && item.formName === formName
    );

    if (isDuplicate) {
      setInputError("Duplicate emergency item for this form");
      return;
    }

    const reqBody = {
      id: id,
      emergency: updatedEmergency.trim(),
      formType: item.formType,
      formName: formName, // Update to use formName
      formId: item.formId,
    };

    dispatch(updateEmergencyEquipment(reqBody))
      .then(() => {
        setEditingId(null);
        // After updating, find the item in local state and update it
        const updatedEquipments = emergencyEquipments.map((item) => {
          if (item.id === id) {
            return { ...item, emergency: updatedEmergency };
          }
          return item;
        });
        setEmergencyEquipments(updatedEquipments);
        dispatch(fetchEmergencyEquipment());
        setInputError(""); // Clear the error message
      })
      .catch((error) => {
        // Handle the error
      });
    if (currentName !== editEmergency) {
      setEmergencyName(updatedEmergency);
      setShowSaveSuccess(true);
    }
  };

  // Function to handle enabling/disabling the "Add" section
  const handleNewEquipment = (formType, formName) => {
    setNewEmergency("");
    // Update the AddEnable state for the specific tab panel
    setAddEnableState((prevState) => ({
      ...prevState,
      [`${formType}_${selectedFormName}`]: false, // Disable the previous formName
      [`${formType}_${formName}`]: true, // Enable the current formName
    }));
    setSelectedFormName(formName); // Update the selectedFormName
  };

  const confirmDeleteHandle = (id) => {
    dispatch(deleteEmergencyEquipment(id))
      .then(() => {
        // After deleting, remove the item from local state
        const updatedEquipments = emergencyEquipments.filter(
          (item) => item.id !== id
        );
        setEmergencyEquipments(updatedEquipments);
      })
      .catch((error) => {
        // Handle the error
      });

    setShowDelete(false);
    setShowDeleteSuccess(true);
  };

  // Function to handle deleting an emergency item
  const handleDelete = (id, emergency, formName) => {
    setDeleteEmergency(emergency);
    setDeleteId(id);
    setFormName(formName);

    setShowDelete((pre) => !pre);
  };

  return (
    <div className="EE">
      <TabView>
        {Object.keys(groupedData).map((formType) => (
          <TabPanel header={formType} key={formType} id={"tabPanel_emergEE"}>
            <div className="tab-panels">
              <TabView>
                {Object.keys(groupedData[formType]).map((formName) => (
                  <TabPanel
                    header={
                      formName.split(" - ")[1]
                        ? formName.split(" - ")[1]
                        : formName
                    }
                    key={formName}
                    id={"tabPanel_emerges"}
                  >
                    <div>
                      <div className="Main-Content">
                        {groupedData[formType][formName]?.map((item) => (
                          <div key={item?.id} className="content-Row">
                            {editingId === item?.id ? (
                              <>
                                <input id="txt_EEEditEmergency"
                                  type="text"
                                  className="input-edit"
                                  value={editEmergency}
                                  onChange={handleEditInputChange}
                                  maxLength={30}
                                />

                                {editEmergency !== item?.emergency ? (
                                  <img id="img_EEEnableEdit"
                                    src={editEnable}
                                    className="pi edit-enable"
                                    onClick={() =>
                                      handleSaveEdit(item?.id, item)
                                    }
                                  ></img>
                                ) : (
                                  <img id="img_EEDisableEdit"
                                    src={editDisable}
                                    className=" pi edit-disable"
                                  ></img>
                                )}

                                <img id="img_EECancelIcon"
                                  src={cancelIcon}
                                  className="pi cancel-icon"
                                  onClick={() => setEditingId(null)}
                                ></img>
                                {inputError && (
                                  <div className="error-message">
                                    {inputError}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <input id="txt_EEEmergency"
                                  type="text"
                                  value={item?.emergency}
                                  readOnly
                                />

                                <img id="img_EEPencil"
                                  className={"pi pi-pencil"}
                                  src={editIcon}
                                  onClick={() =>
                                    handleEdit(
                                      item?.id,
                                      item?.emergency,
                                      formName
                                    )
                                  }
                                  alt="Edit"
                                />

                                <img id="img_EETrash"
                                  className="pi pi-trash"
                                  src={trash}
                                  onClick={() =>
                                    handleDelete(
                                      item?.id,
                                      item?.emergency,
                                      formName
                                    )
                                  }
                                  alt="Delete"
                                />
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      {addEnableState[`${formType}_${formName}`] && (
                        <div className="Add-Input">
                          <input id="txt_EEnewEmergency"
                            type="text"
                            value={newEmergency}
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                            className="input-edit"
                            placeholder="Enter new emergency item"
                            maxLength={30}
                          />
 
                          <img id="img_EECheckCircle"
                            className={"pi pi-check-circle save-edit"}
                            src={editEnable}
                            onClick={(e) =>
                              handleAddEmergency(formType, formName)
                            }
                            alt="Save"
                          />
                          <img id="img_EETImes"
                            className={"pi pi-times"}
                            src={cancelIcon}
                            onClick={() => {
                              setAddEnableState((prevState) => ({
                                ...prevState,
                                [`${formType}_${formName}`]: false,
                              }));
                              setInputError(""); // Clear the error message
                              setNewEmergency("");
                            }}
                            alt="Cancel"
                          />
                          {inputError && (
                            <div className="error-message">{inputError}</div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="footerEE">
                      <button id="btn_EEequipment"
                        className="new-equipment-button"
                        onClick={() => handleNewEquipment(formType, formName)}
                      >
                        + Equipment
                      </button>
                    </div>
                  </TabPanel>
                ))}
              </TabView>
            </div>
          </TabPanel>
        ))}
      </TabView>
      <ModalWrapper visible={showSaveSuccess}>
        <SaveSuccess
          closePopup={() => {
            setShowSaveSuccess(false);
          }}
          heading="Emergency Equipment"
          content={emergencyName}
        />
      </ModalWrapper>
      <ModalWrapper visible={showDelete}>
        <DeletePopUp
          closePopup={() => {
            setShowDelete(false);
          }}
          deleteValue={() => {
            confirmDeleteHandle(deleteId);
          }}
          heading="Emergency Equipment"
          content={deleteEmergency}
          tempName={formName}
        />
      </ModalWrapper>
      <ModalWrapper
        visible={showDeleteSuccess}
        onDismiss={() => setShowDeleteSuccess(false)}
      >
        <DeleteSuccess
          closePopup={() => {
            setShowDeleteSuccess(false);
          }}
          heading="Emergency Equipments"
          content={deleteEmergency}
          tempName={formName}
        />
      </ModalWrapper>
    </div>
  );
};

export default FetchEE;
