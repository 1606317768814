import { createSlice } from "@reduxjs/toolkit";
import {
  FetchSWIMapping,
  saveSwiJob,
  saveSwiTask,
  deleteSwiTask,
  UpdateSWIJob,
  UpdateSWITask,
  addSWIHighEnergyTask,
  upadteSWIHighEnergyTask,
  deleteSWIHighEnergyTask,
  updateSWIIcon,
  deleteSWIIcon,
  addSWIHazard,
  updateSWIHazard,
  deleteSWIHazard,
  addDirectControlSafeguards,
  updateDirectControlSafeguards,
  deleteDirectControlSafeguards,
} from "../../Service/SwiMapping";

const initialState = {
  swiMappingData: [],
  loading: false,
  jobName: [],
  result: false,
};

const swiMappingSlice = createSlice({
  name: "swiMappingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(FetchSWIMapping.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(FetchSWIMapping.fulfilled, (state, action) => {
      state.swiMappingData = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(FetchSWIMapping.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(saveSwiJob.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveSwiJob.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveSwiJob.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(saveSwiTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveSwiTask.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveSwiTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteSwiTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSwiTask.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));

      const jobArr = arr.filter(
        (value) => value.id === action.meta.arg.reqBody.jobTypeId
      );
      
      jobArr[0].swiJobTypes.splice(
        jobArr[0].swiJobTypes.findIndex(
          (e) => e.id === action.meta.arg.reqBody.id
        ),
        1
      );

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteSwiTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(UpdateSWIJob.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(UpdateSWIJob.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(UpdateSWIJob.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(UpdateSWITask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(UpdateSWITask.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));

      const jobArr = arr.filter(
        (value) => value.id === action.payload.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.meta.arg.reqBody.id
      );

      swiJobTypeArr.forEach((element) => {
        element.swiName = action.payload.swiName;
        element.id = action.payload.id;
      });

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(UpdateSWITask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateSWIIcon.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSWIIcon.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.payload.data.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.payload.data.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) => value.id === action.payload.data.highEnergyId
      );
      const swiIconArr = swiHighEnergyTaskArr[0].swiIcons.filter(
        (value) => value.id === action.meta.arg.IconId
      );

      if (action.meta.arg.apiCallType === "Update") {
        swiIconArr.forEach((element) => {
          element.id = action.payload.data.iconId;
          element.iconName = action.payload.data.iconName;
          element.iconPath = action.payload.data.path;
        });
      } else {
        swiHighEnergyTaskArr[0].swiIcons.push({
          iconName: action.payload.data.iconName,
          iconPath: action.payload.data.path,
          id: action.payload.data.iconId,
        });
      }

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateSWIIcon.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteSWIIcon.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSWIIcon.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.meta.arg.SWIIconRequest.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.meta.arg.SWIIconRequest.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) => value.id === action.meta.arg.SWIIconRequest.highEnergyId
      );

      swiHighEnergyTaskArr[0].swiIcons.splice(
        swiHighEnergyTaskArr[0].swiIcons.findIndex(
          (e) => e.id === action.meta.arg.SWIIconRequest.IconId
        ),
        1
      );

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteSWIIcon.rejected, (state) => {
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(addSWIHighEnergyTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addSWIHighEnergyTask.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.payload.data.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.payload.data.jobTypeSWIId
      );

      swiJobTypeArr[0].swiHighEnergyTasks.push({
        id: action.payload.data.highEnergyId,
        highEnergyName: action.payload.data.highEnergyName,
        swiDirectControlSafegaurds: [],
        swiHazards: [],
        swiIcons: [],
      });

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(addSWIHighEnergyTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(upadteSWIHighEnergyTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(upadteSWIHighEnergyTask.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.payload.data.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.payload.data.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) => value.id === action.meta.arg.reqBody.highEnergyId
      );

      swiHighEnergyTaskArr.forEach((element) => {
        element.highEnergyName = action.payload.data.highEnergyName;
        element.id = action.payload.data.highEnergyId;
      });

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(upadteSWIHighEnergyTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteSWIHighEnergyTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSWIHighEnergyTask.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) =>
          value.id ===
          action.meta.arg.swiDeleteSWIHighEnergyTaskRequest.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) =>
          value.id ===
          action.meta.arg.swiDeleteSWIHighEnergyTaskRequest.jobTypeSWIId
      );

      swiJobTypeArr[0].swiHighEnergyTasks.splice(
        swiJobTypeArr[0].swiHighEnergyTasks.findIndex(
          (e) =>
            e.id ===
            action.meta.arg.swiDeleteSWIHighEnergyTaskRequest.highEnergyId
        ),
        1
      );

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteSWIHighEnergyTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(addSWIHazard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addSWIHazard.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.payload.data.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.payload.data.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) => value.id === action.payload.data.highEnergyId
      );

      swiHighEnergyTaskArr[0].swiHazards.push({
        createdBy: null,
        createdDateTime: null,
        hazardName: action.payload.data.hazardName,
        id: action.payload.data.hazardsId,
        modifiedBy: null,
        notificationMessage: null,
        updatedDateTime: null,
      });

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(addSWIHazard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateSWIHazard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSWIHazard.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.payload.data.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.payload.data.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) => value.id === action.payload.data.highEnergyId
      );
      const swiHazardArr = swiHighEnergyTaskArr[0].swiHazards.filter(
        (value) => value.id === action.meta.arg.reqBody.hazardsId
      );

      swiHazardArr.forEach((element) => {
        element.hazardName = action.payload.data.hazardName;
        element.id = action.payload.data.hazardsId;
      });

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateSWIHazard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteSWIHazard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSWIHazard.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.meta.arg.swiDeleteHazardRequest.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) =>
          value.id === action.meta.arg.swiDeleteHazardRequest.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) =>
          value.id === action.meta.arg.swiDeleteHazardRequest.highEnergyId
      );

      swiHighEnergyTaskArr[0].swiHazards.splice(
        swiHighEnergyTaskArr[0].swiHazards.findIndex(
          (e) => e.id === action.meta.arg.swiDeleteHazardRequest.hazardsId
        ),
        1
      );

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteSWIHazard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(addDirectControlSafeguards.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addDirectControlSafeguards.fulfilled, (state, action) => {
      let arr = JSON.parse(JSON.stringify(state.swiMappingData));
      const jobArr = arr.filter(
        (value) => value.id === action.payload.data.jobTypeId
      );
      const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
        (value) => value.id === action.payload.data.jobTypeSWIId
      );
      const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
        (value) => value.id === action.payload.data.highEnergyId
      );

      swiHighEnergyTaskArr[0].swiDirectControlSafegaurds.push({
        id: action.payload.data.directControlsSafegaurdsId,
        directControlSafeGaurdName:
          action.payload.data.directControlsSafegaurdsName,
        type: action.payload.data.type,
      });

      state.swiMappingData = arr;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(addDirectControlSafeguards.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateDirectControlSafeguards.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      updateDirectControlSafeguards.fulfilled,
      (state, action) => {
        let arr = JSON.parse(JSON.stringify(state.swiMappingData));
        const jobArr = arr.filter(
          (value) => value.id === action.payload.data.jobTypeId
        );
        const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
          (value) => value.id === action.payload.data.jobTypeSWIId
        );
        const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
          (value) => value.id === action.payload.data.highEnergyId
        );
        const swiDirectControlSafegaurdArr =
          swiHighEnergyTaskArr[0].swiDirectControlSafegaurds.filter(
            (value) =>
              value.id === action.meta.arg.reqBody.directControlsSafegaurdsId
          );

        swiDirectControlSafegaurdArr.forEach((element) => {
          element.directControlSafeGaurdName =
            action.payload.data.directControlsSafegaurdsName;
          element.id = action.payload.data.directControlsSafegaurdsId;
        });

        state.swiMappingData = arr;
        state.loading = false;
        state.error = "";
      }
    );
    builder.addCase(updateDirectControlSafeguards.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteDirectControlSafeguards.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      deleteDirectControlSafeguards.fulfilled,
      (state, action) => {
        let arr = JSON.parse(JSON.stringify(state.swiMappingData));
        const jobArr = arr.filter(
          (value) =>
            value.id ===
            action.meta.arg.swiDeleteDirectControlsSafeGuardRequest.jobTypeId
        );
        const swiJobTypeArr = jobArr[0].swiJobTypes.filter(
          (value) =>
            value.id ===
            action.meta.arg.swiDeleteDirectControlsSafeGuardRequest.jobTypeSWIId
        );
        const swiHighEnergyTaskArr = swiJobTypeArr[0].swiHighEnergyTasks.filter(
          (value) =>
            value.id ===
            action.meta.arg.swiDeleteDirectControlsSafeGuardRequest.highEnergyId
        );

        swiHighEnergyTaskArr[0].swiDirectControlSafegaurds.splice(
          swiHighEnergyTaskArr[0].swiDirectControlSafegaurds.findIndex(
            (e) =>
              e.id ===
              action.meta.arg.swiDeleteDirectControlsSafeGuardRequest
                .directControlsSafegaurdsId
          ),
          1
        );

        state.swiMappingData = arr;
        state.loading = false;
        state.error = "";
      }
    );
    builder.addCase(deleteDirectControlSafeguards.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
  },
});

export default swiMappingSlice.reducer;
