export const BaseUrl = {
  local: {
    shared: "http://localhost:11123/",
    fileUpload:"http://localhost:37003/",
    logger: "http://localhost:37004/",
  },
  dev: {
    shared: "https://shared-api-service-dev.eversource.me/",
    fileUpload: "https://fileupload-api-service-dev.eversource.me/",
    logger: 'https://logger-api-service-dev.eversource.me/',
    admin: 'https://admin-api-service-dev.eversource.me/'
  },
  sit: {
    shared: "https://shared-api-service-sit.eversource.me/",
    fileUpload: "https://fileupload-api-service-sit.eversource.me/",
    logger: "https://logger-api-service-sit.eversource.me/",
    admin: 'https://admin-api-service-sit.eversource.me/'
  },
  uat: {
    shared: "https://shared-api-service-uat.eversource.us/",
    fileUpload: "https://fileupload-api-service-uat.eversource.us/",
    logger: "https://logger-api-service-uat.eversource.us/",
    admin: 'https://admin-api-service-uat.eversource.us/'
  },
  preprod: {
    shared: "https://shared-api-service-sit​.eversource.me/",
    fileUpload: "https://fileupload-api-service-sit.eversource.me/",
    logger: "https://logger-api-service-sit.eversource.me/",
  },
  prod: {
    shared: "https://shared-api-service.eversource.com",
    fileUpload: "https://fileupload-api-service.eversource.com",
    logger: "https://logger-api-service.eversource.com",
    admin: 'https://admin-api-service.eversource.com'
  },
};

