import { createSlice } from "@reduxjs/toolkit";
import { fetchUserDetails } from "../../Service/UserDetails";

const initialState = {
  userData: "",
  loading: false,
  error: "",
  tokenData: "",
  tokenForImage: "",
  expiryTime: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeToken: (state, action) => {
      state.tokenData = action.payload;
    },
    storeUserData: (state, action) => {
      state.userData = action.payload;
    },
    storeExpiryTime: (state, action) => {
      state.expiryTime = action.payload;
    },
    storeTokenForImage: (state, action) => {
      state.tokenForImage = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //     builder.addCase(fetchUserDetails.pending, (state) => {
  //         state.loading = true
  //         state.error = ''
  //     })
  //     builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
  //         state.userData = action.payload
  //         state.loading = false
  //         state.error = ''
  //     })
  //     builder.addCase(fetchUserDetails.rejected, (state) => {
  //         state.formData = []
  //         state.loading = false
  //         state.error = 'Something went wrong'
  //     })
  // },
});

export const { storeToken, storeUserData, storeExpiryTime, storeTokenForImage } = userSlice.actions;
export default userSlice.reducer;