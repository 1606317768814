import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    hasError: false,
    errorCode: '',
    errorMessage: ''
}

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.hasError = true;
            state.errorCode = action.payload.errorCode;
            state.errorMessage = action.payload.errorMessage;
        },
        resetError: (state) => {
            state.hasError = false;
            state.errorCode = '';
            state.errorMessage = '';
        },
    },
})

export const { setError, resetError } = errorSlice.actions
export default errorSlice.reducer