import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./AccordionComponent.scss";
import trash from "../../../Assets/Images/trash.png";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";

import {
  DeleteMUTCDPlan,
  FetchMUTCDPlan,
  UpdateMUTCDPlan,
} from "../../../Service/MUTCDplan";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../GenericPopup";
import warningPopup from "../../GenericPopup/nonDeletablePopup";

import SaveSuccess from "../../GenericPopup/commonSavePopup";
import DeletePopUp from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/commonDeletePopup";
import WarnPopup from "../../GenericPopup/nonDeletablePopup";

const Mutcdplan = () => {
  const [showWarning, setshowWarning] = useState(false);
  const [showSaveSuccess, setshowSaveSuccess] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const [appNo, setappNo] = useState();
  const [showDeleteSuccess, setshowDeleteSuccess] = useState(false);
  const [warn, setwarn] = useState("");
  const [appDescError, setappDescError] = useState("")
  const [appNoError, setAppNoError] = useState("");

  //Matches any sequence of characters except whitespace
  // Alphabetic characters (both lowercase and uppercase: [a-zA-Z])
  // Digits [0-9]
  // Specific special characters: '*?()\#\-"\//.\s\@\&\_\=\+\,\<\>\:
  // Whitespace characters (\s)
  // Space character 
  const urlPattern =/^[a-zA-Z0-9'*?()\#\-"\//.\s\@\&\_\=\+\,\<\>\: ]*$/
  const dispatch = useDispatch();
  const MUTCDPlanData = useSelector(
    (state) => state.MUTCDPlan.mutcdPlanData || []
  );

  useEffect(() => {
    dispatch(FetchMUTCDPlan());
  }, []);

  const [activeIndex, setActiveIndex] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isEditing, setIsEditing] = useState(false); // Add state to track editing mode
  const [hasChanges, setHasChanges] = useState(false); // Add state to track changes

  function groupDataByGroupName(MUTCDPlanData) {
    const groupedData = {};
    MUTCDPlanData.forEach((item) => {
      const { groupName, ...rest } = item;
      if (!groupedData[groupName]) {
        groupedData[groupName] = [];
      }
      groupedData[groupName].push(rest);
    });
    return groupedData;
  }

  const groupedData = groupDataByGroupName(MUTCDPlanData);

  const onTabChange = (tabKey, index) => {
    const newActiveIndex = {
      [tabKey]: index,
    };
    setActiveIndex(newActiveIndex);
  };

  const handleEditClick = (tabKey, rowIndex, initialData) => {
    setEditingRow({ tabKey, rowIndex });
    setEditedData({
      ...initialData,
      groupName: tabKey,
    });
    setIsEditing(true); // Enable editing mode
    setHasChanges(true);
  };

  const Callback = (success) => {
    if (success) {
      dispatch(FetchMUTCDPlan());
    }
  };
  const handleEditSave = (tabKey, rowIndex, mutcdId,groupId) => {
    const editedAppNumber = editedData.appNumber;
    const previousAppNumber = MUTCDPlanData.find(item => item.mutcdId === mutcdId).appNumber;
    // Check if the edited app number already exists in the MUTCDPlanData
    

   
if(editedAppNumber !==previousAppNumber ){

  if (MUTCDPlanData.some((item) => item.appNumber === editedAppNumber)) {
    setAppNoError(`App Number already exists.`);
    return; // Prevent saving
  }
}

if(!urlPattern.test(editedData.appDesc)){
     
  setappDescError("not in right format")
  return;

  
}
setappDescError("")
    setAppNoError("");
    setappNo(editedData.appNumber);
    const reqBody = {
      appNumber: editedData.appNumber,
      appDesc: editedData.appDesc,
      groupName: editedData.groupName,
      groupId:groupId
    };
    dispatch(UpdateMUTCDPlan({ mutcdId, reqBody, cb: Callback }));
   
    setIsEditing(false); // Disable editing mode
    setshowSaveSuccess(true);
  };

  const handleCancelEdit = () => {
    setEditingRow(null);
    setAppNoError("");
    setappDescError("")
    setIsEditing(false); // Disable editing mode
  };

  const handleInputChange = (fieldName, value) => {
    if (value) {
      setHasChanges(false);
    }

    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    setappDescError("")

    setAppNoError("")
  };

  const handleDeleteClick = (tabKey, rowIndex, mutcdId, appNO) => {
    setappNo(appNO);
    setdeleteId(mutcdId);
    setshowDelete(true);
  };

  const confirmDeleteHandle = async (deleteId) => {
    try {
      const action = await dispatch(DeleteMUTCDPlan({ id: deleteId }));
      if (action?.payload?.statusCode === 500) {
        // Show the "DeleteSuccess" modal with the "not deletable" message
        setshowWarning(true);
      } else {
        setshowDeleteSuccess(true);
        // Handle other status codes as needed
      }
    } catch (error) {
      // Handle other errors if needed
    }
    setshowDelete(false);
  };


  return (
    <div className="scrolable-containerMP">
      <p className="titleMP">Index to Typical Applications</p>

      <hr />
      <div className="scrollable-content">
        {Object.keys(groupedData).map((tabKey, tabIndex) => (
          <div key={tabIndex}>
            <Accordion
              className="accordian-container"
              activeIndex={activeIndex[tabKey]}
              onTabChange={(e) => onTabChange(tabKey, e.index)}
            >
              <AccordionTab className="header " header={tabKey}>
                <table className="table-container">
                  <thead>
                    <tr className="table-header">
                      <th>Typical App Number</th>
                      <th>Typical App Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData[tabKey].map((item, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="App-Number">
                          {editingRow &&
                          isEditing &&
                          editingRow.tabKey === tabKey &&
                          editingRow.rowIndex === rowIndex ? (
                            <div>
                              <input id="txt_MUTCDAppNumber"
                                type="text"
                                value={editedData.appNumber || ""}
                                onChange={(e) =>
                                  handleInputChange("appNumber", e.target.value)
                                }
                                className="input-styling-appNo"
                              />
                              <span className="error-message"> {appNoError} </span>
                            </div>
                          ) : (
                            item.appNumber
                          )}
                        </td>
                        <td className="App-Desc">
                          {editingRow &&
                          isEditing &&
                          editingRow.tabKey === tabKey &&
                          editingRow.rowIndex === rowIndex ? (
                            <>
                              <input id="txt_MUTCDAppDescr"
                                type="text"
                                value={editedData.appDesc || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (inputValue.length <= 30) {
                                    handleInputChange("appDesc", inputValue);
                                  }
                                }}
                                className="input-styling-appDesc"
                              />
                              {editedData.appDesc.length == 30 && (
                                <span className="error-message">
                                  Max. length 30.
                                </span>
                              )} 
                                                     
                            </>
                          ) : (
                            item.appDesc
                          )}

                          {editingRow &&
                          isEditing &&
                          editingRow.tabKey === tabKey &&
                          editingRow.rowIndex === rowIndex ? (
                            <span>
                              {!hasChanges ? (
                                <img id="img_MUTCDeditEnable"
                                  src={editEnable}
                                  onClick={() =>
                                    handleEditSave(
                                      tabKey,
                                      rowIndex,
                                      item.mutcdId,item.groupId
                                    )
                                  }
                                  alt="Save"
                                />
                              ) : (
                                <img id="img_MUTCDSaveDisable"
                                  src={editDisable}
                                  className="edit-disable-MP"
                                  alt="save-disable"
                                ></img>
                              )}
                              <img id="img_MUTCDCancel"
                                src={cancelIcon}
                                onClick={handleCancelEdit}
                                alt="Cancel"
                              />
                              <span className="error-message"> {appDescError} </span>
                            </span>
                          ) : (
                            <>
                              <img id="img_MUTCDEditIco"
                                src={editIcon}
                                className="edit-iconEE"
                                onClick={() =>
                                  handleEditClick(tabKey, rowIndex, {
                                    appNumber: item.appNumber,
                                    appId: item.mutcdId,
                                    appDesc: item.appDesc,
                                  })
                                }
                                alt="Edit"
                              />
                              
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionTab>
            </Accordion>
          </div>
        ))}
      </div>
      <>
        <ModalWrapper visible={showSaveSuccess}>
          <SaveSuccess
            closePopup={() => {
              setshowSaveSuccess(false);
            }}
            heading="MUTCD Plan"
            content={appNo}
          />
        </ModalWrapper>

        <ModalWrapper
          visible={showDelete}
          onDismiss={() => {
            setshowDelete(false);
          }}
        >
          <DeletePopUp
            closePopup={() => {
              setshowDelete(false);
            }}
            deleteValue={() => confirmDeleteHandle(deleteId)}
            heading="MUTCD Plan"
            content={appNo}
          />
        </ModalWrapper>

        <ModalWrapper
          visible={showDeleteSuccess}
          onDismiss={() => {
            setshowDelete(false);
            setshowDeleteSuccess(false);
          }}
        >
          <DeleteSuccess
            closePopup={() => {
              setshowDelete(false);
              setshowDeleteSuccess(false);
            }}
            heading="MUTCD Plan"
            content={appNo}
          />
        </ModalWrapper>

        <ModalWrapper
          visible={showWarning}
          onDismiss={() => {
            setshowWarning(false);
          }}
        >
          <WarnPopup
            closePopup={() => {
              setshowWarning(false);
            }}
            content={appNo}
          />
        </ModalWrapper>
      </>
    </div>
  );
};

export default Mutcdplan;
