import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

//Fetch the data for template management screen
export const FetchTemplates = createAsyncThunk(
  "/Templates/FetchTemplates",
  async (dispatch, thunkAPI) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Template Management",
        actionName: "Get Template Details",
        message: "Request - Template Details",
        thunkApi: thunkAPI,
      });
      let res = await adminInstance.get(EndPoints.fetchTemplate);
      if (res?.data?.data && res.data.statusCode === 200) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Template Management",
          actionName: "Get Template Details",
          message: res.data,
          thunkApi: thunkAPI,
        });
        return res.data.data;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Template Management",
          actionName: "Get Template Details",
          thunkApi: thunkAPI,
        });
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Template Management",
        actionName: "Get Template Details",
        thunkApi: thunkAPI,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

// update the templates in template management screen
export const UpdateTemplate = createAsyncThunk(
  "/Templates/UpdateTemplate",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Template Management",
        actionName: "Update Template Details",
        message: "Request - Template Details",
        thunkApi: thunkAPI,
      });
      let res = await adminInstance.put(EndPoints.updateTemplate, args.reqbody);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Template Management",
          actionName: "Update Template Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        args.saveSuccess(args?.reqbody?.fieldDisplayName);
        args.callbackFn(true);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Template Management",
          actionName: "Update Template Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Template Management",
        actionName: "Update Template Details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

//Fetch the data for publish template management screen
export const FetchPublishTemplate = createAsyncThunk(
  "/Templates/FetchPublishTemplate",
  async (dispatch, thunkAPI) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Publish Management",
        actionName: "Get Publish Details",
        message: "Request - Publish Details",
        thunkApi: thunkAPI,
      });
      let res = await adminInstance.get(EndPoints.fetchPublishTemplate);
      if (res?.data?.data || res.data.statusCode === 204) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Publish Management",
          actionName: "Get Publish Details",
          message: res.data,
          thunkApi: thunkAPI,
        });
        return res.data;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Publish Management",
          actionName: "Get Publish Details",
          thunkApi: thunkAPI,
        });
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Publish Management",
        actionName: "Get Publish Details",
        thunkApi: thunkAPI,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

//Adds the the changed template names for publishing
export const AddPublishTemplate = createAsyncThunk(
  "/Templates/AddPublishTemplate",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Publish Management",
        actionName: "Add Publish Details",
        message: "Request - Publish Details",
        thunkApi: thunkAPI,
      });
      let res = await adminInstance.post(
        EndPoints.addPublishTemplate,
        args.reqbody
      );
      if (res?.data?.data || res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Publish Management",
          actionName: "Add Publish Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        args.saveSuccess();
        args.callbackFn(true);
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Publish Management",
          actionName: "Add Publish Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Publish Management",
        actionName: "Add Publish Details",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

//Fetch the data for publish template management screen
export const FetchPublishStatus = createAsyncThunk(
  "/Templates/FetchPublishStatus",
  async (dispatch, thunkAPI) => {
    try {
      dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "Publish Management",
        actionName: "Get Publish Status",
        message: "Request - Publish Status",
        thunkApi: thunkAPI,
      });
      let res = await adminInstance.get(EndPoints.fetchPublishStatus);
      if (res?.data?.data || res.data.statusCode === 204) {
        dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "Publish Management",
          actionName: "Get Publish Status",
          message: res.data,
          thunkApi: thunkAPI,
        });
        return res.data.data;
      } else {
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "Publish Management",
          actionName: "Get Publish Status",
          thunkApi: thunkAPI,
        });
        throw Error();
      }
    } catch (error) {
      dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "Publish Management",
        actionName: "Get Publish Status",
        thunkApi: thunkAPI,
      });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);
