import { useEffect, useState } from "react";
import Routes from "./Routes";
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { storeToken, storeUserData, storeExpiryTime, storeTokenForImage } from "./Store/userSlice";
import { scopes } from "./Pages/Auth/Config";
import { 
  sharedInstance,
  fileUploadInstance,
  loggerInstance,
  adminInstance
} from "./Api";
import { ProgressSpinner } from 'primereact/progressspinner';


function getUserRole(roles) {
  let order = {
    admin: 1,
    supervisor: 2,
    crew: 3,
    viewer: 4
  };
 
  return roles.sort((a, b) => order[a] - order[b])[0] || '';
}

function App() {
  const [role, setRole] = useState('');
  const [isTokenAvailable, setIsTokenAvailable] = useState(false)
  let isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.tokenData);
  const expiryTime = useSelector((state) => state.user.expiryTime);
  const acquireToken = () => {
    instance
      .acquireTokenSilent({
        // scopes: ["User.Read"],
        scopes: scopes,
        account: account,
      })
      .then((response) => {
        if (response && response?.accessToken) {
          setRole(getUserRole(response?.account?.idTokenClaims?.roles));
          dispatch(storeUserData(response?.account));
          dispatch(storeToken(response?.accessToken));
          dispatch(storeExpiryTime(response?.expiresOn.getTime()));
          //save token in local storage for axios instance header
          sharedInstance.defaults.headers.common['Authorization'] = `Bearer ${response?.accessToken}`
          fileUploadInstance.defaults.headers.common['Authorization'] = `Bearer ${response?.accessToken}`
          loggerInstance.defaults.headers.common["Authorization"] = `Bearer ${response?.accessToken}`
          adminInstance.defaults.headers.common["Authorization"] = `Bearer ${response?.accessToken}`
          setIsTokenAvailable(true);
          getImageToken();
        }
      })
      .catch(()=>{
        localStorage.clear()
        isAuthenticated = false
      })
  };

  useEffect(() => {
    if (accounts.length > 0 && inProgress !== "login") {
      acquireToken();
    }
  }, [account, instance]);

  useEffect(() => {
    let targetTime = expiryTime;
    let expireTime = new Date(targetTime);
    let currentTime = new Date();
    // console.log("difference :", expireTime - currentTime);
    if (token) {
      //A new token will be acquired if token expiry time is less than 2 mins
      setTimeout(acquireToken, expireTime - currentTime - 120000);
    }
  }, [token]);

  const getImageToken = () => {
    instance
    .acquireTokenSilent({
      scopes: ["User.Read"],
      account: account,
    })
    .then((response) => {
      if (response && response?.accessToken) {
        dispatch(storeTokenForImage(response?.accessToken));
      }
    });
  }

  if(isAuthenticated && !isTokenAvailable) {
    return <div style={{width:'100%', height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
      <ProgressSpinner />
    </div>
  }

  return <Routes isAuthenticated={isAuthenticated} role={role} isTokenAvailable={isTokenAvailable}/>;
}

export default App;