import React, { useState } from "react";
import { Button } from "primereact/button";
import "./popup.scss";
import infoImage from "../../Assets/Images/info.png";

function SavePopUp({ closePopup, saveValue, heading, content }) {
  return (
    <div className="popup-wrapper">
      <div className="popup-display">
        <img id="img_info" className="popup-image" src={infoImage}></img>
        <p className="popup-content">Are you sure you want to save <span className="screen-data">{content}</span> in {heading}?</p>
      </div>

      <div className="popupButtonWrapper">
        <Button id="btn_savePopCancel"
          className="cancel-button"
          label="Cancel"
          onClick={closePopup}
        ></Button>
        <Button id="btn_savePopSave"
          className="save-button"
          label="Save"
          onClick={saveValue}
          autoFocus
        ></Button>
      </div>
    </div>
  );
}

export default SavePopUp;
