import React from "react";
import { Button } from "primereact/button";
import "./popup.scss";
import infoImage from "../../Assets/Images/delete.png";

function SaveDataPopUp({ closePopup, saveData }) {
  return (
    <div className="popup-wrapper">
      <div className="popup-display">
        <img id="img_popupimg" className="popup-image" src={infoImage}></img>
        <p className="popup-content">
        You have unsaved changes. Clicking 'Continue' may discard them! Are you sure you want to continue?
        </p>
      </div>

      <div className="popupButtonWrapper">
        <Button id="btn_popcancel"
          className="cancel-button"
          label="Cancel"
          onClick={closePopup}
        ></Button>
        <Button id="btn_popcontinue"
          className="save-button"
          label="Continue"
          onClick={saveData}
          autoFocus
        ></Button>
      </div>
    </div>
  );
}

export default SaveDataPopUp;
