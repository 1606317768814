import React, { useState, useEffect, useRef } from "react";
import "./criticalcontacts.scss";
import Labels from "../../../Constants/Label";
import { TabView, TabPanel } from "primereact/tabview";
import {
  fetchCriticalContactDetails,
  deleteCriticalContactDetails,
  addCriticalContactDetails,
  updateCriticalContactDetails,
} from "../../../Service/CriticalContacts";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../GenericPopup";
import DeletePopup from "../../GenericPopup/deletePopup";
import DeleteSuccess from "../../GenericPopup/deleteSuccess";
import SaveSuccess from "../../GenericPopup/saveSuccess";
import WarnPopup from "../../GenericPopup/warningPopup";
import trash from "../../../Assets/Images/trash.png";
import editIcon from "../../../Assets/Images/edit.png";
import cancelIcon from "../../../Assets/Images/cancel.png";
import editEnable from "../../../Assets/Images/checkCircleEnabled.png";
import editDisable from "../../../Assets/Images/checkCircleDisable.png";
import Loader from "../../Loader";

const CriticalContacts = () => {
  const DEFAULT_CITY = "Connecticut";

  const [filteredData, setFilteredData] = useState([]);
  const [uniqueGroups, setUniqueGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_CITY);
  const [newContact, setNewContact] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [id, setId] = useState(0);
  const [editName, setEditName] = useState("");
  const [editNumber, setEditNumber] = useState("");
  const [newName, setNewName] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [center, setCenter] = useState([]);
  const [show, setShow] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [editenable, setEditEnable] = useState(false);
  const [currentindex, setCurrentindex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [newEnable, setNewEnable] = useState(false);
  const [valueName, setValueName] = useState("");
  const [editNameError, setEditNameError] = useState(false);
  const [editNumberError, setEditNumberError] = useState(false);
  const [nameDup, setNameDup] = useState(false);
  const ref = useRef();
  const newFieldRef = useRef(null);

  const regexPattern = /^[0-9]\-]$/;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCriticalContactDetails(dispatch));
  }, []);

  const criticalContactDetails = useSelector(
    (state) => state?.criticalContact?.criticalContactData || []
  );

  const isLoading = useSelector((state) => state.loaderSlice.isLoading);

  useEffect(() => {
    const filterData = () => {
      const groups = new Set();
      const uniqueSubGroups = [
        ...new Set(
          criticalContactDetails
            .filter((item) => item?.contactCenter !== null)
            .map((item) => item?.contactCenter)
        ),
      ];
      let filtered = criticalContactDetails.filter((item) => {
        groups.add(item?.contactType);
        return true;
      });
      filtered = [
        criticalContactDetails.filter(
          (item) => item?.contactType === "Electric"
        ),
      ];
      setFilteredData(filtered);
      setUniqueGroups(Array.from(groups));
      setCenter(uniqueSubGroups);
    };

    if (criticalContactDetails.length > 0) {
      filterData();
    }
  }, [criticalContactDetails]);

  const arr = ["Connecticut", "Massachusetts", "New Hampshire"];

  //api call for delete
  const handleDelete = (id) => {
    dispatch(
      deleteCriticalContactDetails({
        id,
        deleteSuccess: showDeleteSuccessPopup,
      })
    );
    setId(0);
    setShowDelete((pre) => !pre);
  };

  const handleAddContact = (
    contactName,
    contactNumber,
    active,
    contactCenter
  ) => {
    let contactType = "Common";
    if (active === 1) {
      contactType = "Electric";
    } else if (active === 2) {
      contactType = "Gas";
    } else {
      contactType = "Common";
    }

    if (contactType !== "Electric") {
      contactCenter = "";
    }
    const isDuplicate = criticalContactDetails.some((data) => data?.contactType === contactType && (data?.contactCenter === contactCenter || data?.contactCenter === null) && (data?.contactName.toLowerCase() === newName.toLowerCase() || data?.contactNumber === newNumber))
    if (!isDuplicate) {
      dispatch(
        addCriticalContactDetails({
          contactName,
          contactNumber,
          contactType,
          contactCenter,
          saveSuccess: showSavePopUp,
        })
      );
      setNameDup(false);
      setNewContact(false);
      setNewName("");
      setNewNumber("");
    } else {
      setNameDup(true);
    }
  };

  //api call for updating the data
  const handleUpdate = (
    contactId,
    contactName,
    contactNumber,
    contactType,
    contactCenter
  ) => {
    
    const isDuplicate = criticalContactDetails.some((data) => data?.contactId !== id && (data?.contactCenter === contactCenter || data?.contactCenter === null) && data?.contactType === contactType && (data?.contactName.toLowerCase() === editName.toLowerCase() || data?.contactNumber === editNumber))
    if (!isDuplicate) {      
      dispatch(
        updateCriticalContactDetails({
          id,
          contactId,
          contactName,
          contactNumber,
          contactCenter,
          contactType,
          saveSuccess: showSavePopUp,
        })
      );
      setEditContact(false);
      setId(0);
      setNameDup(false);
      setEditNameError(false);
      setEditNumberError(false);
    } else {
      setNameDup(true);
    }
  };

  function showDeletePopUp(id, type, center, name) {
    let count = 0;
    if (type === "Electric") {
      {
        criticalContactDetails.map((item) => {
          if (item?.contactCenter === center) {
            count++;
          }
        });
      }
    } else {
      {
        criticalContactDetails.map((item) => {
          if (item?.contactType === type) {
            count++;
          }
        });
      }
    }
    if (count === 1) {
      setShowWarning((pre) => !pre);
    } else {
      setDeleteId(id);
      setValueName(name);
      setShowDelete((pre) => !pre);
    }
  }

  function showDeleteSuccessPopup() {
    setShowDelete(false);
    setShowDeleteSuccess((pre) => !pre);
  }

  function showSavePopUp(value) {
    setValueName(value);
    setShowSaveSuccess((pre) => !pre);
    setEditContact(false);
    setNewContact(false);
    setNewName("");
    setNewNumber("");
    setNewEnable(false);
    setId(0);
  }

  function showWarnPopup() {
    setShowWarning((pre) => !pre);
  }

  const handleStateChange = (value) => {
    setSelectedGroup(value);
    setShow(false);
  };

  const handleNewContact = (index) => {
    setNewContact(true);
    setCurrentindex(index);
    if (newFieldRef.current) {
      newFieldRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClose = () => {
    setNewContact(false);
    setNewName("");
    setNewNumber("");
    setNewEnable(false);
    setNameDup(false);
  };

  const handleEditClose = () => {
    setEditContact(false);
    setEditEnable(false);
    setId(0);
    setNameDup(false);
  };

  const handleEdit = (id) => {
    setId(id);
    criticalContactDetails.map((data) => {
      if (data?.contactId === id) {
        setEditName(data?.contactName);
        setEditNumber(data?.contactNumber);
        setEditContact(true);
      }
    });
  };

  const handleEditName = (e) => {
    const val = e.target.value;
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val === '') {
      setEditName(val);
      setEditEnable(true);
      setEditNameError(true);
    }
  };

  const handleEditNumber = (e) => {
    const number = e.target.value;
    if (number.length === 1 && (number === "0" || number === "-")) {
      return;
    }
    const pattern = /^[0-9-]*$/;
    if (pattern.test(number)) {
      setEditNumber(number);
    }
    setEditEnable(true);
    setEditNumberError(true);
  };

  const handleNewName = (e) => {
    const val = e.target.value;
    if (/^[a-zA-Z0-9.,\-?&/'() ]*$/.test(val) || val === '') {
      setNewName(val);
      setNewEnable(true);
    }
  };

  const handleNewNumber = (e) => {
    const number = e.target.value;
    if (number.length === 1 && (number === "0" || number === "-")) {
      return;
    }
    const pattern = /^[0-9-]*$/;

    if (pattern.test(number)) {
      setNewNumber(number);
    }
    setNewEnable(true);
  };

  const handleClick = () => {
    setShowOptions(!showOptions);
    setNewContact(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showOptions && ref.current && !ref.current.contains(e.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showOptions]);

  const handleHeader = (e) => {
    setActiveIndex(e.index);
    if (currentindex !== e.index) {
      setNewContact(false);
      setNewName("");
      setNewNumber("");
      setSelectedGroup(DEFAULT_CITY);
    }
  };

  return (
    <div className="critical-contacts">
      {isLoading && <Loader />}
      <TabView activeIndex={activeIndex} onTabChange={(e) => handleHeader(e)}>
        {uniqueGroups.map((header, index) => (
          <TabPanel key={index} header={header} id={"tabPanel_critical_"+header} className={"tabPanel_critical"+header}>
            {header === "Electric" && (
              <div className="dropdown" ref={ref} onClick={() => handleClick()}>
                <label className="location" for="city">
                  Location
                </label>
                <div
                  className="select-location"
                  id="city"
                  onClick={() => setShow(true)}
                >
                  {selectedGroup}
                </div>
                <div className="dropdown-caret">
                  <i
                    className="pi pi-caret-down"
                    onClick={() => setShow(true)}
                  ></i>
                </div>
                {show && showOptions && (
                  <div className="cities">
                    {center.map((item, index) => {
                      return (
                        <div
                          className="option-location"
                          key={index}
                          value={item}
                          onClick={() => handleStateChange(item)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <table className="critical-data">
              <tr className="contact-details">
                <td colSpan="4"></td>
              </tr>
              {criticalContactDetails.map((item, index) => {
                if (
                  item?.contactType === header &&
                  (item?.contactCenter === selectedGroup ||
                    header !== "Electric")
                ) {
                  return (
                    <>
                      {item?.contactId !== id && (
                        <tr
                          key={index}
                          className="contact-details"
                          onChange={() => handleHeader(item?.contactType)}
                        >
                          <td className="contact-name">{item?.contactName}</td>
                          <td className="contact-number">
                            {item?.contactNumber}
                          </td>
                          <td className="contact-edit">
                            <img id="img_LookEdit"
                              src={editIcon}
                              className="edit-icon"
                              onClick={() => handleEdit(item?.contactId)}
                            ></img>
                          </td>
                          <td className="contact-delete">
                            <img id="img_LookTrash"
                              src={trash}
                              className="trash-icon"
                              onClick={() =>
                                showDeletePopUp(
                                  item?.contactId,
                                  item?.contactType,
                                  item?.contactCenter,
                                  item?.contactName
                                )
                              }
                            ></img>
                          </td>
                        </tr>
                      )}
                      {item?.contactId === id && editContact && (
                        <tr className="contact-details">
                          <td className="edit-contact-name">
                            <input id="txt_CrticalEditName"
                              className="edit-name"
                              type="text"
                              value={editName}
                              maxLength="100"
                              onChange={(e) => handleEditName(e)}
                            ></input>
                            {editNameError && editName.length === 100 && (
                              <p className="contact-error">
                                *Maximum number of characters should not exceed
                                100.
                              </p>
                            )}
                          </td>
                          <td className="edit-contact-number">
                            <input id="txt_CriticalEditNumber"
                              className="edit-number"
                              type="text"
                              value={editNumber}
                              maxLength="30"
                              onChange={(e) => handleEditNumber(e)}
                            ></input>
                            {editNumberError && editNumber.length >= 30 && (
                              <p className="contact-error">
                                *Maximum number of characters should not exceed
                                30.
                              </p>
                            )}
                          </td>
                          <td className="close-button">
                            <img id="img_LookCancel"
                              src={cancelIcon}
                              className="cancel-icon"
                              onClick={() => handleEditClose()}
                            ></img>
                          </td>
                          <td>
                            {editenable &&
                            editName.length !== 0 &&
                            editNumber.length !== 0 ? (
                              <img id="img_LookEditEnable"
                                src={editEnable}
                                className="edit-enable"
                                onClick={() =>
                                  handleUpdate(
                                    id,
                                    editName,
                                    editNumber,
                                    header,
                                    selectedGroup
                                  )
                                }
                              ></img>
                            ) : (
                              <img id="img_LookEditdisable"
                                src={editDisable}
                                className="edit-disable"
                              ></img>
                            )}
                          </td>
                          {nameDup && <p className="edit-duplicate">*Cannot add duplicate values.</p>}
                        </tr>
                      )}
                    </>
                  );
                }
              })}
              {newContact && (
                <tr className="contact-details">
                  <td className="new-contact-name">
                    <input id="txt_CriticalNewName"
                      className="new-name"
                      placeholder="Contact Name"
                      type="text"
                      value={newName}
                      maxLength="100"
                      onChange={(e) => handleNewName(e)}
                    ></input>
                    {newName.length === 100 && (
                      <p className="contact-error">
                        *Maximum number of characters should not exceed 100.
                      </p>
                    )}
                  </td>
                  <td className="new-contact-number">
                    <input id="txt_CriticalNewNumber"
                      className="new-number"
                      placeholder="Contact Number"
                      type="text"
                      value={newNumber}
                      maxLength="30"
                      onChange={(e) => handleNewNumber(e)}
                    ></input>
                    {newNumber.length === 30 && (
                      <p className="contact-error">
                        *Maximum number of characters should not exceed 30.
                      </p>
                    )}
                  </td>
                  <td className="close-button">
                    <img id="img_LookCancelIcon"
                      src={cancelIcon}
                      className="cancel-icon"
                      onClick={() => handleClose()}
                    ></img>
                  </td>
                  <td>
                    {newName.length !== 0 && newNumber.length !== 0 ? (
                      <img id="img_LookEnableEdit"
                        src={editEnable}
                        className="edit-enable"
                        onClick={() =>
                          handleAddContact(
                            newName,
                            newNumber,
                            activeIndex,
                            selectedGroup
                          )
                        }
                      ></img>
                    ) : (
                      <img id="img_LookdisableEdit" src={editDisable} className="edit-disable"></img>
                    )}
                  </td>
                  {nameDup && <p className="new-duplicate">*Cannot add duplicate values.</p>}
                </tr>
              )}
            </table>
            <div className="footer">
              <button id="btn_Contact"
                disabled={newContact}
                className={
                  newContact
                    ? "new-contact-button-inactive"
                    : "new-contact-button"
                }
                onClick={() => handleNewContact(activeIndex)}
              >
                + Contact
              </button>
              <div ref={newFieldRef}></div>
            </div>
          </TabPanel>
        ))}
      </TabView>
      <ModalWrapper
        visible={showDelete}
        onDismiss={() => {
          showDeletePopUp();
        }}
      >
        <DeletePopup
          closePopup={() => {
            showDeletePopUp();
          }}
          deleteValue={() => handleDelete(deleteId)}
          heading="Critical Contact"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showDeleteSuccess}
        onDismiss={() => {
          showDeleteSuccessPopup();
        }}
      >
        <DeleteSuccess
          closePopup={() => {
            showDeleteSuccessPopup();
          }}
          heading="Critical Contact"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showSaveSuccess}
        onDismiss={() => {
          showSavePopUp();
        }}
      >
        <SaveSuccess
          closePopup={() => {
            showSavePopUp();
          }}
          heading="Critical Contact"
          content={valueName}
        />
      </ModalWrapper>

      <ModalWrapper
        visible={showWarning}
        onDismiss={() => {
          showWarnPopup();
        }}
      >
        <WarnPopup
          closePopup={() => {
            showWarnPopup();
          }}
        />
      </ModalWrapper>
    </div>
  );
};

export default CriticalContacts;
