import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";

export const FetchSWIMapping = createAsyncThunk(
  "/Swi/FetchSWIMapping",
  async (dispatch) => {
    try {
      let res = await sharedInstance.get(EndPoints.fetchSWIMapping);
      if (res?.data?.data && res.data.statusCode === 200) {
        return res.data.data;
      } else {
        throw Error();
      }
    } catch (error) {
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const UpdateSWIJob = createAsyncThunk(
  "/Swi/UpdateSWIJob",
  async (args, dispatch, thunkAPI) => {
    try {
      let res = await sharedInstance.put(EndPoints.updateSWIJob, args.reqBody);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        args.callBackFn(true, res?.data?.message);
        return res?.data?.data;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);

export const UpdateSWITask = createAsyncThunk(
  "/Swi/UpdateSWITask",
  async (args, thunkAPI) => {
    try {
      let res = await sharedInstance.put(EndPoints.updateSWITask, args.reqBody);
      if (res?.data?.data || res?.data?.statusCode === 200) {
        args.saveSuccess();
        args.callBackFn(true, res?.data);
        return res?.data?.data;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const saveSwiJob = createAsyncThunk(
  "Swi/saveSwiJob",
  async (swiRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.swiAddJob, swiRequest);
      if (res?.data?.data && res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        swiRequest.saveSuccess(res.data.data, swiRequest.jobName);
        return res.data.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const saveSwiTask = createAsyncThunk(
  "Swi/saveSwiTask",
  async (swiRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.swiAddTask, swiRequest);
      if (res?.data?.data && res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        swiRequest.saveSuccess(res.data.data, swiRequest.taskName);
        return res.data.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteSwiTask = createAsyncThunk(
  "Swi/deleteSwiTask",
  async ({ reqBody, deleteSuccess }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(EndPoints.swiDeleteTask, reqBody);
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccess(true, reqBody.swiName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const addSWIHighEnergyTask = createAsyncThunk(
  "Swi/addSWIHighEnergyTask",
  async (addSWIHighEnergyTaskRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(
        EndPoints.addSWIHighEnergyTask,
        addSWIHighEnergyTaskRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        addSWIHighEnergyTaskRequest.saveSuccess(
          true,
          addSWIHighEnergyTaskRequest.highEnergyName
        );
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const upadteSWIHighEnergyTask = createAsyncThunk(
  "Swi/upadteSWIHighEnergyTask",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(
        EndPoints.upadteSWIHighEnergyTask,
        args.reqBody
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        args.saveSuccess();
        args.updateSuccess(true, args.reqBody.highEnergyName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteSWIHighEnergyTask = createAsyncThunk(
  "Swi/deleteSWIHighEnergyTask",
  async (
    { swiDeleteSWIHighEnergyTaskRequest, deleteSuccessPopup, deleteSuccess },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(
        EndPoints.deleteSWIHighEnergyTask,
        swiDeleteSWIHighEnergyTaskRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccessPopup(swiDeleteSWIHighEnergyTaskRequest.highEnergyName);
        deleteSuccess(true, swiDeleteSWIHighEnergyTaskRequest.highEnergyName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const addSWIHazard = createAsyncThunk(
  "Swi/addSWIHazard",
  async (swiHazardRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(
        EndPoints.addSWIHazard,
        swiHazardRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        swiHazardRequest.saveSuccess(true, swiHazardRequest.hazardName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateSWIHazard = createAsyncThunk(
  "Swi/updateSWIHazard",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(
        EndPoints.updateSWIHazard,
        args.reqBody
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        args.saveSuccess();
        args.updateSuccess(true, args.reqBody.hazardName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteSWIHazard = createAsyncThunk(
  "Swi/deleteSWIHazard",
  async (
    { swiDeleteHazardRequest, deleteSuccessPopup, deleteSuccess },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(
        EndPoints.deleteSWIHazard,
        swiDeleteHazardRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccessPopup(swiDeleteHazardRequest.hazardName);
        deleteSuccess(true, swiDeleteHazardRequest.hazardName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const addDirectControlSafeguards = createAsyncThunk(
  "Swi/addDirectControlSafeguards",
  async (swiDrectControlsSafeGuardsRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(
        EndPoints.addDirectControlSafeguards,
        swiDrectControlsSafeGuardsRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        swiDrectControlsSafeGuardsRequest.saveSuccess(
          true,
          swiDrectControlsSafeGuardsRequest.directControlsSafegaurdsName
        );
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateDirectControlSafeguards = createAsyncThunk(
  "Swi/updateDirectControlSafeguards",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.put(
        EndPoints.updateDirectControlSafeguards,
        args.reqBody
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        args.saveSuccess();
        args.updateSuccess(true, args.reqBody.directControlsSafegaurdsName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteDirectControlSafeguards = createAsyncThunk(
  "Swi/DeleteDirectControlSafegaurd",
  async (
    {
      swiDeleteDirectControlsSafeGuardRequest,
      deleteSuccessPopup,
      deleteSuccess,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      let res = await sharedInstance.post(
        EndPoints.deleteDirectControlSafeguards,
        swiDeleteDirectControlsSafeGuardRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        deleteSuccessPopup(
          swiDeleteDirectControlsSafeGuardRequest.directControlsSafegaurdsName
        );
        deleteSuccess(
          true,
          swiDeleteDirectControlsSafeGuardRequest.directControlsSafegaurdsName
        );
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

const getEndpoint = (searchType) => {
  switch (searchType) {
    case 1:
      return EndPoints.searchSwiIcon;
    case 2:
      return EndPoints.searchHighEnergyTask;
    case 3:
      return EndPoints.searchHazard;
    case 4:
      return EndPoints.searchDirectControl;
    case 5:
      return EndPoints.searchSafeGuard;
    default:
      return null;
  }
};

export const searchMasterTableData = createAsyncThunk(
  "/Swi/SearchHazard",
  async ({ searchKey, searchType, callBackFn }, thunkAPI) => {
    try {
      let res = await sharedInstance.get(getEndpoint(searchType), {
        params: { searchKey },
      });

      if (res.data.successResponse) {
        callBackFn(res.data.data || [], res.data.message);
        return res.data.data;
      } else {
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const updateSWIIcon = createAsyncThunk(
  "Swi/UpdateSWIIcon",
  async (SWIIconRequest, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      // callLoggerInfo({
      //   screenName: "High Energy Hazardous & Controls",
      //   actionName: "Add High Energy Icon",
      //   message: "Request - High Energy Icon Upload",
      //   thunkApi: thunkAPI,
      // });

      let fileName = "";
      if (SWIIconRequest?.UploadIcon) {
        fileName = SWIIconRequest?.UploadIcon?.name.replace(
          /[^a-zA-Z0-9.]/g,
          ""
        );
      } else {
        fileName = "";
      }
      const formData = new FormData();
      if (fileName !== "")
        formData.append("UploadIcon", SWIIconRequest.UploadIcon, fileName);
      else formData.append("UploadIcon", SWIIconRequest.UploadIcon);

      formData.append("JobTypeId", SWIIconRequest.JobTypeId);
      formData.append("JobTypeSWIId", SWIIconRequest.JobTypeSWIId);
      formData.append("HighEnergyId", SWIIconRequest.HighEnergyId);
      SWIIconRequest.apiCallType === "Update" &&
        formData.append("IconId", SWIIconRequest.IconId);
      formData.append("IconName", fileName);

      let endPoint =
        SWIIconRequest.apiCallType === "Update"
          ? EndPoints.updateSWIIcon
          : EndPoints.addSWIIcon;

      let res = {};
      SWIIconRequest.apiCallType === "Update"
        ? (res = await sharedInstance.put(endPoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }))
        : (res = await sharedInstance.post(endPoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }));
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        // callLoggerInfo({
        //   screenName: "High Energy Hazardous & Controls",
        //   actionName: "Add High Energy Icon",
        //   message: res?.data,
        //   thunkApi: thunkAPI,
        // });

        SWIIconRequest.callBackFn(true, fileName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        // callLoggerError({
        //   errorCode: res?.data?.statusCode,
        //   errorDetails: res?.data?.message,
        //   screenName: "High Energy Hazardous & Controls",
        //   actionName: "Add High Energy Icon",
        //   thunkApi: thunkAPI,
        // });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      // callLoggerError({
      //   errorCode: errorCode,
      //   errorDetails: errorMessage,
      //   screenName: "High Energy Hazardous & Controls",
      //   actionName: "Add High Energy Icon",
      //   thunkApi: thunkAPI,
      // });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteSWIIcon = createAsyncThunk(
  "Swi/DeleteSWIIcon",
  async ({ SWIIconRequest, callBackFn }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      // callLoggerInfo({
      //   screenName: "High Energy Hazardous & Controls",
      //   actionName: "Add High Energy Icon",
      //   message: "Request - High Energy Icon Upload",
      //   thunkApi: thunkAPI,
      // });

      let res = await sharedInstance.post(
        EndPoints.deleteSWIIcon,
        SWIIconRequest
      );
      if (res?.data?.statusCode === 201) {
        thunkAPI.dispatch(setLoader(false));
        // callLoggerInfo({
        //   screenName: "High Energy Hazardous & Controls",
        //   actionName: "Add High Energy Icon",
        //   message: res?.data,
        //   thunkApi: thunkAPI,
        // });

        callBackFn(true, SWIIconRequest.iconName);
        return res?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        // callLoggerError({
        //   errorCode: res?.data?.statusCode,
        //   errorDetails: res?.data?.message,
        //   screenName: "High Energy Hazardous & Controls",
        //   actionName: "Add High Energy Icon",
        //   thunkApi: thunkAPI,
        // });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      // callLoggerError({
      //   errorCode: errorCode,
      //   errorDetails: errorMessage,
      //   screenName: "High Energy Hazardous & Controls",
      //   actionName: "Add High Energy Icon",
      //   thunkApi: thunkAPI,
      // });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);
