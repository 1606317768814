import { createSlice } from "@reduxjs/toolkit";
import {
  getHighEnergyData,
  getHighEnergies, saveHighEnergy, updateHighEnergy, deleteHighEnergy,
  saveFile, deleteFile,
  saveHighEnergyTask, updateHighEnergyTask, deleteHighEnergyTask,
  saveHighEnergyHazard, updateHighEnergyHazard, deleteHighEnergyHazard,
  saveHighEnergyDirectControlsAndSafeGuard, updateHighEnergyDirectControlsAndSafeGuard, deleteHighEnergyDirectControlsAndSafeGuard,
  deleteHighEnergyTaskHazardDirectControls
} from "../../Service/HEC";

const initialState = {
  highEnergies: [],
  highEnergyData: {},
  result: false,
  loading: false,
};

const hecSlice = createSlice({
  name: "hec",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //#region [[ Get High Energy Details]]
    builder.addCase(getHighEnergyData.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getHighEnergyData.fulfilled, (state, action) => {
      state.highEnergyData = action.payload;
      //state.highEnergyData.data.highEnergyIcons = state.highEnergyData.data.highEnergyIcons.filter((item) => item?.path.replace(' ', '%20'));
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getHighEnergyData.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ Get High Energy Details]]

    //#region [[ High Energy]]
    builder.addCase(getHighEnergies.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getHighEnergies.fulfilled, (state, action) => {
      state.highEnergies = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getHighEnergies.rejected, (state) => {
      state.formData = [];
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(saveHighEnergy.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveHighEnergy.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveHighEnergy.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateHighEnergy.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateHighEnergy.fulfilled, (state, action) => {
      let arrHighEnergies = [...state.highEnergies.data];
      const index = arrHighEnergies.findIndex((value) => value.id === action.payload.id);
      arrHighEnergies[index]["highEnergyName"] = action.payload.highEnergyName;
      state.highEnergies.data = arrHighEnergies;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateHighEnergy.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteHighEnergy.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHighEnergy.fulfilled, (state, action) => {
      state.highEnergies.data = state.highEnergies.data.filter((item) => item.highEnergyId !== action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteHighEnergy.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ High Energy]]

    //#region [[ High Energy File (Icon)]]
    builder.addCase(saveFile.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveFile.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveFile.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteFile.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {      
      state.highEnergyData.data.highEnergyIcons = state.highEnergyData.data.highEnergyIcons.filter((item) => item.id !== action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteFile.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ High Energy File (Icon)]]

    //#region [[ High Energy Task]]
    builder.addCase(saveHighEnergyTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveHighEnergyTask.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveHighEnergyTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateHighEnergyTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateHighEnergyTask.fulfilled, (state, action) => {
      let arrHighEnergyTasks = [...state.highEnergyData.data.highEnergyTasks];
      const index = arrHighEnergyTasks.findIndex((value) => value.id === action.payload.id);
      arrHighEnergyTasks[index]["highEnergyNames"] = action.payload.highEnergyNames;
      state.highEnergyData.data.highEnergyTasks = arrHighEnergyTasks;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateHighEnergyTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteHighEnergyTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyTask.fulfilled, (state, action) => {
      state.highEnergyData.data.highEnergyTasks = state.highEnergyData.data.highEnergyTasks.filter((item) => item.id !== action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyTask.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ High Energy Task]]

    //#region [[ High Energy Hazard]]
    builder.addCase(saveHighEnergyHazard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveHighEnergyHazard.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveHighEnergyHazard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateHighEnergyHazard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateHighEnergyHazard.fulfilled, (state, action) => {
      let arrHighEnergyHazards = [...state.highEnergyData.data.hazards];
      const index = arrHighEnergyHazards.findIndex((value) => value.id === action.payload.id);
      arrHighEnergyHazards[index]["hazardName"] = action.payload.hazardName;
      state.highEnergyData.data.hazards = arrHighEnergyHazards;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateHighEnergyHazard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteHighEnergyHazard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyHazard.fulfilled, (state, action) => {
      state.highEnergyData.data.hazards = state.highEnergyData.data.hazards.filter((item) => item.id !== action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyHazard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ High Energy Hazard]]

    //#region [[ High Energy  DirectControls And SafeGuard ]]
    builder.addCase(saveHighEnergyDirectControlsAndSafeGuard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(saveHighEnergyDirectControlsAndSafeGuard.fulfilled, (state, action) => {
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveHighEnergyDirectControlsAndSafeGuard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(updateHighEnergyDirectControlsAndSafeGuard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateHighEnergyDirectControlsAndSafeGuard.fulfilled, (state, action) => {
      let arrHighDirectControlsAndSafeGuards = [...state.highEnergyData.data.directControlsAndSafeguards];
      const index = arrHighDirectControlsAndSafeGuards.findIndex((value) => value.id === action.payload.id);
      arrHighDirectControlsAndSafeGuards[index]["directControlSafegaurdsName"] = action.payload.directControlSafegaurdsName;
      state.highEnergyData.data.directControlsAndSafeguards = arrHighDirectControlsAndSafeGuards;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateHighEnergyDirectControlsAndSafeGuard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });

    builder.addCase(deleteHighEnergyDirectControlsAndSafeGuard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyDirectControlsAndSafeGuard.fulfilled, (state, action) => {
      state.highEnergyData.data.directControlsAndSafeguards = state.highEnergyData.data.directControlsAndSafeguards.filter((item) => item.id !== action.payload);
      state.result = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyDirectControlsAndSafeGuard.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ High Energy  DirectControls And SafeGuard ]]

    //#region [[ Delete HighEnergy Icon, Task, Hazard, DirectControls & SafeGuard ]]
    builder.addCase(deleteHighEnergyTaskHazardDirectControls.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyTaskHazardDirectControls.fulfilled, (state, action) => {
      state.highEnergyData.data = {};
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteHighEnergyTaskHazardDirectControls.rejected, (state) => {
      state.formData = {};
      state.loading = true;
      state.error = "Something went wrong";
    });
    //#endregion [[ Delete HighEnergy Icon, Task, Hazard, DirectControls & SafeGuard ]]  
  }
});

export default hecSlice.reducer;