import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import errorSlice from "./errorSlice";
import notificationSlice from "./notificationSlice";
import configurationSlice from "./configurationSlice";
import hyperlinkSlice from "./hyperlinkSlice";
import criticalContactSlice from "./criticalContactSlice";
import saveSlice from "./saveSlice";
import loaderSlice from "./loaderSlice";
import emergencyEquipmentSlice from "./emergencyEquipmentSlice";
import procedureMappingSlice from "./procedureMappingSlice";
import MUTCDPlanSlice from "./MUTCDPlanSlice";
import SwiMappingSlice from "./SwiMapping";
import hecSlice from "./hecSlice";
import ppeSlice from "./ppeSlice";
import potentialAOCSlice from "./potentialAOCSlice";
import SwiHazardMessagingDataSlice from "./swiHazardMessagingSlice";
import  SwiHazardenergiescontrolMessagingSlice  from "./HazardousECSwiMessagingSlice";
import templateSlice from "./templateSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    errorSlice,
    saveSlice,
    loaderSlice,
    notification: notificationSlice,
    configuration: configurationSlice,
    hyperlink: hyperlinkSlice,
    criticalContact: criticalContactSlice,
    emergencyEquipment: emergencyEquipmentSlice,
    procedureMapping: procedureMappingSlice,
    MUTCDPlan: MUTCDPlanSlice,
    swiMapping: SwiMappingSlice,
    hec: hecSlice,
    ppe: ppeSlice,
    potentialAOC: potentialAOCSlice,
    SwiHazardMessaging : SwiHazardMessagingDataSlice,
    SwiHazardenergiescontrolMessaging : SwiHazardenergiescontrolMessagingSlice,
    template: templateSlice,
  },
});
