import { createSlice } from '@reduxjs/toolkit';
import { FetchProcedureMapping , FetchGasWorkTask,UpdateDocList, DeleteDocList,AddDocument, DeleteWorkTask, AddWorkTask } from '../../Service/ProcedureMapping';

const initialState = {
    procedureMappingData: [],
    WorkTaskData:[],
    loading: false,
  };


export const procedureMappingSlice = createSlice({
    name: 'procedureMappingSlice',
    initialState,
      reducers: {},
      extraReducers: (builder) => {
          builder.addCase(FetchProcedureMapping.pending, (state) => {
              state.loading = true
              state.error = ''
          })
          builder.addCase(FetchProcedureMapping.fulfilled, (state, action) => {
              state.procedureMappingData = action.payload || []
              state.loading = false
              state.error = ''
          })
          builder.addCase(FetchProcedureMapping.rejected, (state) => {
              state.formData = []
              state.loading = false
              state.error = 'Something went wrong'
          })
          builder.addCase(DeleteDocList.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(DeleteDocList.fulfilled, (state, action) => {
            state.procedureMappingData = state.procedureMappingData.filter((item) => item.id !== action?.payload?.id)
            state.loading = false
            state.error = ''
        })
        builder.addCase(DeleteDocList.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(AddDocument.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(AddDocument.fulfilled, (state, action) => {
            state.procedureMappingData.push(action.payload);
            state.loading = false
            state.error = ''
        })
        builder.addCase(AddDocument.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(UpdateDocList.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(UpdateDocList.fulfilled, (state, action) => {
          let arr = [...state.procedureMappingData]

          const index = arr.findIndex((value) => value.id === action.payload.id);
            arr[index] = action.payload
            state.procedureMappingData = arr;
            state.loading = false
            state.error = ''
        })
        builder.addCase(UpdateDocList.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })

          builder.addCase(FetchGasWorkTask.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(FetchGasWorkTask.fulfilled, (state, action) => {
            state.WorkTaskData = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(FetchGasWorkTask.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        builder.addCase(DeleteWorkTask.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(DeleteWorkTask.fulfilled, (state, action) => {
            // state.WorkTaskData = state.WorkTaskData.filter((item) => item.docId !== action?.payload?.id)
            state.loading = false
            state.error = ''
        })
        builder.addCase(DeleteWorkTask.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })

        builder.addCase(AddWorkTask.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(AddWorkTask.fulfilled, (state, action) => {
            state.WorkTaskData.push(action.payload);
            state.loading = false
            state.error = ''
        })
        builder.addCase(AddWorkTask.rejected, (state) => {
            state.formData = []
            state.loading = false
            state.error = 'Something went wrong'
        })
        // builder.addCase(UpdateWorkTask.pending, (state) => {
        //     state.loading = true
        //     state.error = ''
        // })
        // builder.addCase(UpdateWorkTask.fulfilled, (state, action) => {
        //   let arr = [...state.WorkTaskData]

        //   const index = arr.findIndex((value) => value.id === action.payload.id);
        //     arr[index] = action.payload
        //     state.WorkTaskData = arr;
        //     state.loading = false
        //     state.error = ''
        // })
        // builder.addCase(UpdateWorkTask.rejected, (state) => {
        //     state.formData = []
        //     state.loading = false
        //     state.error = 'Something went wrong'
        // })

          
      },
  })
  
  export default procedureMappingSlice.reducer