import React, { useState, useEffect } from "react";
import "./electric.scss";
import { TabView, TabPanel } from "primereact/tabview";
import "primeicons/primeicons.css";
import DataInput from "../Input";
import Labels from "../../../Constants/Label";

const Electric = ({ electricCommonData, electricData }) => {
  const uniqueGroup = [
    ...new Set(electricCommonData.map((item) => item.sectionInternalName)),
  ];

  const Data = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const { templateName, sectionInternalName, ...details } = item;

      if (!groupedData[templateName]) {
        groupedData[templateName] = {};
      }

      if (!groupedData[templateName][sectionInternalName]) {
        groupedData[templateName][sectionInternalName] = [];
      }

      groupedData[templateName][sectionInternalName].push(details);
    });
    return groupedData;
  };

  const groupedData = Data(electricData);
  return (
    <div className="electric">
      <TabView>
        <TabPanel header="Electric Common" id="tabPanel_temp_elcCommon" className="tabPanel_temp_elcCommon" >
          <div className="electric-content">
            {uniqueGroup.map((subgroup, index) => {
              return (
                <div className="electric-sections" key={index}>
                  <div className="electric-heading">{Labels?.SectionName[subgroup]}</div>
                  {electricCommonData
                    .filter((item) => item.sectionInternalName === subgroup)
                    .map((item, index) => {
                      return (
                        <DataInput value={item} key={index} />
                      );
                    })}
                </div>
              );
            })}
          </div>
        </TabPanel>
        {Object.entries(groupedData).map(
          ([templateName, sectionInternalName]) => (
            <TabPanel id={"tabPanel_elctemp"+templateName}key={templateName} header={templateName.slice(10)} className={"tabPanel_elctemp"+templateName}>
              <div className="electric-content">
                {Object.entries(sectionInternalName).map(
                  ([sectionInternalName, details]) => (
                    <div
                      className="electric-sections"
                      key={sectionInternalName}
                    >
                      <div className="electric-heading">
                        {Labels?.SectionName[sectionInternalName]}
                      </div>
                      <div>
                        {details.map((item, index) => (
                          <div>
                            <DataInput
                              value={item}
                              key={index}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </TabPanel>
          )
        )}
      </TabView>
    </div>
  );
};

export default Electric;
