import { createAsyncThunk } from "@reduxjs/toolkit";
import { sharedInstance,  } from "../../Api";
import { EndPoints } from "../../Api/ApiRelativeUrls";
import { HandleError } from "../../Utils/handleError";
import { setLoader } from "../../Store/loaderSlice";
import { callLoggerError, callLoggerInfo } from "../Common";

export const fetchppedetails = createAsyncThunk(
  "PpeDetails/FetchPPEDetails'",
  
  async (dispatch, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "PPE",
        actionName: "Get PPE Details",
        message: "Request - PPE Details : Initiated",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.get(EndPoints.fetchppes);
      if (res?.data?.data && res?.data?.statusCode === 200) {       
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "PPE",
          actionName: "Get PPE Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        return res.data.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "PPE",
          actionName: "Get PPE Details",
          thunkApi: thunkAPI,
        });
        throw Error();
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";     
        callLoggerError({
          errorCode: errorCode,
          errorDetails: errorMessage,
          screenName: "PPE",
          actionName: "Get PPE Details",
          thunkApi: thunkAPI,
        });
      HandleError(dispatch, errorCode, errorMessage);
    }
  }
);


export const addppedetails = createAsyncThunk(
  "PpeDetails/AddPPEDetails",
  async (ppe, thunkAPI) => {
    try {
      let res = await sharedInstance.post(EndPoints.addppes, ppe);    
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "PPE",
          actionName: "Add PPE Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        ppe.saveSuccess(ppe);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "PPE",
          actionName: "Add PPE Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";     
        callLoggerError({
          errorCode: errorCode,
          errorDetails: errorMessage,
          screenName: "PPE",
          actionName: "Add PPE Details",
          thunkApi: thunkAPI,
        });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);

export const deleteppedetails = createAsyncThunk(
  "PpeDetails/DeletePPEDetails",
  async ({ id, deleteSuccess }, thunkAPI) => {
    try {
    
      let res = await sharedInstance.delete(`${EndPoints.deleteppes}?PPEId=${id}`);
      
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "PPE",
          actionName: "Delete PPE Details",
          message: res?.data,
          thunkApi: thunkAPI,
        });
        deleteSuccess(id);
        return id;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "PPE",
          actionName: "Delete PPE Details",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status;
      let errorMessage =
        error?.response?.message || "Oops, Something went wrong";     
        callLoggerError({
          errorCode: errorCode,
          errorDetails: errorMessage,
          screenName: "PPE",
          actionName: "Delete PPE Details",
          thunkApi: thunkAPI,
        });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);


export const updateppedetails = createAsyncThunk(
  "/PpeDetails/UpdatePPEDetails'",
  async (args, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoader(true));
      callLoggerInfo({
        screenName: "PPE",
        actionName: "Update PPE",
        message: "Request - PPE Update",
        thunkApi: thunkAPI,
      });
      let res = await sharedInstance.put(
        `${EndPoints.updateppes}?PPEId=${args.id}`,
        args
      );
      if (res?.data?.data || res?.data?.statusCode === 200) {
        thunkAPI.dispatch(setLoader(false));
        callLoggerInfo({
          screenName: "PPE",
          actionName: "Update PPE",
          message: res?.data,
          thunkApi: thunkAPI,
        });      
        args.saveSuccess(args);
        return res?.data?.data;
      } else {
        thunkAPI.dispatch(setLoader(false));
        let errorCode = res?.data?.statusCode;
        let errorMessage = res?.data?.message || "Oops, Something went wrong";
        callLoggerError({
          errorCode: res?.data?.statusCode,
          errorDetails: res?.data?.message,
          screenName: "PPE",
          actionName: "Update PPE",
          thunkApi: thunkAPI,
        });
        HandleError(thunkAPI.dispatch, errorCode, errorMessage);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoader(false));
      let errorCode = error?.response?.status || error?.code;
      let errorMessage =
        error?.response?.statusText || "Oops, Something went wrong";
      callLoggerError({
        errorCode: errorCode,
        errorDetails: errorMessage,
        screenName: "PPE",
        actionName: "Update PPE",
        thunkApi: thunkAPI,
      });
      HandleError(thunkAPI.dispatch, errorCode, errorMessage);
    }
  }
);