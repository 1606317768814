import React, { useState, useRef, useEffect } from "react";
import "./userprofile.scss";
import "primeicons/primeicons.css";
import userLogo from "../../Assets/Images/user.png";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Badge } from "primereact/badge";
import { version } from "../../version";

function Popup() {
  const ref = useRef();
  const { instance } = useMsal();
  const [showPopup, setShowPopup] = useState(false);
  const tagref = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const token = useSelector((state) => state.user.tokenForImage);
  const userDetails = useSelector((state) => state.user.userData);
  const op = useRef(null);

  const handleLogoutRedirect = () => {
    localStorage.removeItem('token');
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (token) {
      getProfilePicture();
    }
  }, [token]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        !tagref.current.contains(e.target)
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showPopup]);

  const getProfilePicture = async () => {
    try {
      axios
        .get(`${process.env.REACT_APP_AZURE_GRAPH_END_POINT}/photo/$value`, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        })
        .then((res) => {
          if (res?.data) {
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(res?.data);
            setImageUrl(blobUrl);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="user_profile">
      <span onClick={(e) => op.current.toggle(e)} style={{ cursor: "pointer" }}>
        <img id="img_userLogo"
          src={imageUrl ? imageUrl : userLogo}
          className="user_icon"
          alt="User Logo"
        />
      </span>

      <OverlayPanel ref={op}>
        <div className="header_overlay"></div>
        <div style={{ width: "15rem" }}>
          <div className="popover-parent">
            <div className="user-avatar-wrapper">
              {imageUrl ? (
                <img id="img_userAvatar" className="user-avatar" alt="user-avatar" src={imageUrl} />
              ) : (
                <img id="img_popupavatar" className="user-avatar" alt="user-avatar" src={userLogo} />
              )}
            </div>
            <div className="popover-name-wrapper">
              <span className="username_span">{userDetails?.name}</span>
              <span className="email_span">{userDetails?.username}</span>
            </div>
          </div>

          <div className="appversion">Version: {version}</div>

          <div className="logout_wrapper">
            <h6 className="line-style" onClick={handleLogoutRedirect}>
              <i
                className="pi pi-sign-out"
                style={{
                  fontSize: "1rem",
                  marginRight: "10px",
                  color: "#2F4656",
                }}
              ></i>
              Logout
            </h6>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}

export default Popup;